<script>

	import { createEventDispatcher } from 'svelte';
	import { fly } from "svelte/transition";

	import { autoblur } from '../../../lib/autoblur.js';

	const dispatch = createEventDispatcher();

	export let showPanel = false;

	function addEmoji(e) {
		dispatch('emoji',e);
		showPanel = false;
	}

	function handleKeydown(event){
		if (event.key === 'Escape') {
			showPanel = false;	
		}
	}

</script>

<style>
	.toggle:hover {
		color: var(--accentColor);
	}
	div {
		position: absolute;
		top: -2.8rem;
		height: 2.8rem;
		left: 0;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-radius: 4px;
		box-shadow: 0 2px 6px var(--shadow);
		padding: 0.4rem 1rem;
	}
	div:before {
		content: "";
		position: absolute;
		inset: 0;
		background: #2e3130;
		border-radius: 4px;
		/*opacity: 0.8;*/
	}
	div:after {
		content: "";
		position: absolute;
		bottom: -5px;
		right: 2.5rem;
		width: 0;
		height: 0;
		border-top: 5px solid #2e3130;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		/*opacity: 0.8;*/
	}
	.emoji {
		display: grid;
		place-content: center;
		width: 1.9rem;
		height: 1.9rem;
		padding: 0;
		border: 0;
		margin: 0;
		cursor: pointer;
		transition: transform 0.2s ease;
		line-height: 0;
	}
	.emoji span {
		font-size: 1.2rem;
		line-height: 0;
		position: relative;
	}
	/*.emoji:hover:before {
		content: "";
		position: absolute;
		inset: 2px;
		border-radius: 3px;
		background: #fff;
		opacity: 0.2;
	}*/
	.emoji:hover {
		transform: scale(1.2);
	}
</style>

<svelte:window on:keydown={handleKeydown}/>

<button class="toggle" type="button" on:click|stopPropagation={() => {showPanel = !showPanel}} use:autoblur>
	<svg viewBox="0 0 32 32"><path d="M16 28.95c-7.14 0-12.95-5.81-12.95-12.95s5.81-12.95 12.95-12.95 12.95 5.81 12.95 12.95-5.81 12.95-12.95 12.95zm0-23.91c-6.04 0-10.96 4.92-10.96 10.96s4.92 10.96 10.96 10.96 10.96-4.92 10.96-10.96-4.92-10.96-10.96-10.96z"/><circle cx="11.5" cy="12.42" r="1.49"/><circle cx="20.5" cy="12.42" r="1.49"/><path d="M16 23.71c-1.86 0-3.63-.59-5.11-1.71-.44-.33-.53-.96-.19-1.4.33-.44.96-.53 1.4-.19 2.26 1.71 5.55 1.71 7.81 0 .44-.33 1.06-.25 1.4.19.33.44.25 1.06-.19 1.4-1.49 1.12-3.26 1.71-5.12 1.71z"/></svg>
</button>

{#if showPanel}
	<div transition:fly={{ duration: 300, y: 5 }}>
		<button class="emoji" type="button" on:click|stopPropagation={() => {addEmoji("👏")}}><span>👏</span></button>
		<button class="emoji" type="button" on:click|stopPropagation={() => {addEmoji("😂")}}><span>😂</span></button>
		<button class="emoji" type="button" on:click|stopPropagation={() => {addEmoji("🙌")}}><span>🙌</span></button>
		<button class="emoji" type="button" on:click|stopPropagation={() => {addEmoji("😮")}}><span>😮</span></button>
		<button class="emoji" type="button" on:click|stopPropagation={() => {addEmoji("👍")}}><span>👍</span></button>
		<button class="emoji" type="button" on:click|stopPropagation={() => {addEmoji("👋")}}><span>👋</span></button>
	</div>
{/if}