<script>

	import { onMount } from 'svelte';
	import { fly } from "svelte/transition";

	import Button from "../ui/Button.svelte";

	import { agenda, session, studioSessions, epoch, displayInEventTz } from "../lib/stores.js";
	import {	showDate } from '../lib/dt.js';

	let mounted = false;
	let lookup = {};

	onMount(() => {

		for (const s of $agenda.sessions) {
			lookup[s.ref] = s;
		}

		mounted = true;

	});

</script>

<style>
	.panel {
		max-width: 85vw;
		width: 560px;
		box-sizing: border-box;
		background: var(--blend-10);
		color: var(--textColor);
		box-shadow: 0 20px 80px var(--shadow);
		position: relative;
		border-radius: 4px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 0.3rem 1rem;
		font-size: 0.876rem;
		line-height: 1.4;
		max-height: 70vh;
		overflow: scroll;
	}
	.session {
		margin: 0.8rem 0;
		display: flex;
		gap: 1rem;
		justify-content: space-between;
		align-items: center;
		background: var(--panelColor);
		padding: 0.5rem 0.8rem;
		border-radius: 4px;
/*		box-shadow: 0 4px 10px var(--shadow);*/
/*		transition: transform 0.2s ease;*/
	}
	/*.session:hover {
		transform: scale(1.02);
	}*/
	.session.past {
		opacity: 0.5;
	}
	/*.session.past:hover {
		transform: scale(1);
	}*/
	.date {
		font-size: 0.6875rem;
		color: var(--blend-80);
	}
</style>

{#if mounted}
	<div class="panel" in:fly={{ duration: 400, y: 20 }}>
		{#each $studioSessions as s}
			<div class="session" class:past={$epoch > lookup[s].epochEnds}>
				<div>
					<p><strong>{lookup[s].name}</strong></p>
					<p class="date">{showDate(lookup[s].starts, 'dddd DD MMM, HH:mm')}–{showDate(lookup[s].ends, 'HH:mm')}</p>
				</div>
				{#if $epoch <= lookup[s].epochEnds}
					<div class="join">
						<Button on:click={() => { $session = lookup[s] }}>Join studio</Button>
					</div>
				{/if}
			</div>
		{/each}
	</div>
{/if}