<script>

	import { onMount, tick } from 'svelte';

	import { resize, autoresize } from '../../lib/autoresize.js';

	export let ref;
	export let value = '';
	export let err;
	export let inFocus;
	export let placeholder;
	export let maxlength;
	export let disabled;

	let ta;

	function handleFocus() {
		inFocus = true;
	}

	function handleBlur() {
		inFocus = false;
	}

	onMount(async () => {
		await tick();
		resize({ target: ta });
	});

</script>

<style>
	textarea {
		box-sizing: border-box;
		width: 100%;
		border: 1px solid var(--blend-40);
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.8rem 1rem;
		line-height: 1.4;
		font-size: 0.8rem;
		border-radius: 4px;
		resize: none;
		min-height: 5rem;
		font-family: Inter, sans-serif;
	}
	textarea.err {
		border-color: var(--red);
	}
	textarea:focus {
		outline: none;
		border-color: var(--blend-60);
	}
	textarea::-webkit-input-placeholder {
		color: var(--blend-40);
	}
	textarea::-moz-placeholder {
		color: var(--blend-40);
	}
</style>

<textarea
	id={ref}
	bind:this={ta}
	bind:value="{value}"
	rows="2"
	use:autoresize
	autocomplete="off"
	class:err
	on:focus={handleFocus}
	on:blur={handleBlur}
	{placeholder}
	{maxlength}
	{disabled}
/>