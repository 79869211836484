<script>

	import { onMount, tick } from 'svelte';
	import { writable } from 'svelte/store';

	import Session from './Studio/Session.svelte';
	import SessionList from './Studio/SessionList.svelte';

	import { agenda, session, room, studioSessions } from "./lib/stores.js";

	let mounted = false;

	onMount(() => {

		$session = null;

		if ($studioSessions.length == 1) {
			for (const s of $agenda.sessions) {
				if (s.ref == $studioSessions[0]) {
					$session = s;
				}
			}
		}

		mounted = true;

	});

	function exitSession() {
		console.log('exitSession');
		if ($studioSessions.length > 1) {
			$session = null;
		} else {
			$session = null;
			$room = 'lobby';
		}
	}

</script>

{#if mounted}
	{#if $session}
		<Session on:exit={exitSession} />
	{:else}
		<SessionList/>
	{/if}
{/if}
