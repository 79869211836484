<script>

	import { tick } from 'svelte';

	import Portal from "svelte-portal";

	import Overlay from "../ui/Overlay.svelte";

	import { conversation } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';
	import { openConversation } from '../lib/conversations.js';

	let openOverlay = false;
	let remaining = 400;
	let messageText = '';
	let ta;

	function resize({ target }) {
		target.style.height = "1px";
		target.style.height = (+target.scrollHeight)+"px";
	}

	function autoresize(el) {
		resize({ target: el });
		el.style.overflow = 'hidden';
		el.addEventListener('input', resize);

		return {
			destroy: () => el.removeEventListener('input', resize)
		}
	}

	function checkMessage(e) {
		if (e.code == 'Enter') {
			e.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
			e.preventDefault();
		}
	}

	async function sendMessage() {
		if ((messageText != '') && (messageText != null) && (remaining >= 0)) {
			openConversation(null, null, true, messageText);
			messageText = '';
		}
	}

	async function setFocus() {
		await tick();
		if (ta) {
			ta.focus();
		}
	}

	$: remaining = 400 - messageText.length;

	$: if (openOverlay) {
		setFocus();
	}

	$: if ($conversation) {
		openOverlay = false;
	}

</script>

<style>
	.nav-help,
	.nav-help button {
		display: inline-block;
		width: 1.6rem;
		height: 1.6rem;
		line-height: 1;
	}
	.nav-help button {
		border: 0;
		padding: 0;
		background: transparent;
		color: var(--textColor);
	}
	.nav-help button:hover,
	.nav-help button:active,
	.nav-help button:focus {
		cursor: pointer;
		color: var(--accentColor, #e6007e);
	}

	.slate {
		margin: 2rem auto;
		max-width: 14rem;
	}
	.slate p {
		margin-top: 0.5rem;
		font-size: 0.8rem;
		line-height: 1.4;
		text-align: center;
	}
	.slate svg {
		width: 2rem;
		height: 2rem;
	}
	.slate strong {
		font-size: 1rem;
	}

	.send {
		position: relative;
	}
	.send > form {
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: flex-start;
		position: relative;
	}
	.counter {
		font-size: 0.625rem;;
		line-height: 1;
		position: absolute;
		bottom: 0;
		right: 0;
		color: var(--blend-60);
		width: 1.5rem;
		text-align: center;
	}
	.counter.over {
		color: var(--red);
	}
	.send textarea {
		border: 0;
		padding: 0;
		width: 100%;
		flex: 1 1 auto;
		min-height: 2.4rem;
		max-height: 6rem;
		background: none;
		resize: none;
		font-family: Inter;
		font-size: 0.6875rem;;
		border-radius: 0;
		color: var(--textColor);
		text-align: left;
	}
	.send textarea:focus {
		outline: none;
	}
	.send :global(button) {
		flex: 0 0 auto;
		margin-left: 0.5rem;
		padding: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		box-sizing: border-box;
		border-radius: 3px;
	}
	.send button {
		background: var(--blend-05);
	}
	.send :global(button > svg) {
		position: absolute;
		/*inset: 3px;*/
		top: 0.15rem;
		left: 0.15rem;
		width: calc(100% - 0.3rem);
		height: calc(100% - 0.3rem);
	}
	.send button:hover,
	.send button:active,
	.send button:focus {
		cursor: pointer;
		color: var(--panelColor);
		background: var(--textColor);
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: var(--blend-40);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: var(--blend-40);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: var(--blend-40);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: var(--blend-40);
	}
</style>

<li
	id="help"
	class="nav-help"
>
	<button type="button" on:click={() => {openOverlay = true}} use:autoblur>
		<svg viewBox="0 0 80 80"><title>Help</title><path d="M36.69 47.02c.03-6.19 1.87-8.08 4.97-9.98 1.99-1.24 3.54-2.99 3.54-5.48 0-2.95-2.3-4.84-5.14-4.84-2.55 0-5.08 1.55-5.28 5.09h-5.07c.22-6.01 4.72-9.3 10.35-9.3 6.13 0 10.23 3.67 10.23 9.1 0 3.76-1.84 6.29-4.82 8.1-2.84 1.75-3.94 3.47-4.01 7.31v.32h-4.77v-.32zm-.82 7.13c0-1.82 1.5-3.32 3.34-3.32 1.82 0 3.34 1.5 3.34 3.32 0 1.85-1.52 3.34-3.34 3.34-1.84 0-3.34-1.49-3.34-3.34zM12.8 67.3c-2.82-2.82-.92-6.5-.54-7.93l1.15-4.33c.13-.46.09-.97-.15-1.44-2.08-4.08-3.26-8.71-3.26-13.6 0-16.54 13.46-30 30-30s30 13.46 30 30-13.46 30-30 30c-4.89 0-9.52-1.18-13.61-3.27-.42-.22-.93-.28-1.42-.15l-4.23 1.12c-1.42.41-5.25 2.29-7.94-.4zm14.05-4.88c4.37 2.57 9.55 3.9 15.06 3.51 12.68-.91 23-11.14 24.01-23.81 1.2-15.16-10.68-27.9-25.54-28.12-14.07-.2-25.96 11.16-26.37 25.22-.15 4.98 1.12 9.67 3.42 13.68.33.5.4 1.1.25 1.64l-2.36 8.9c-.2.75.49 1.43 1.24 1.22l8.76-2.45c.53-.15 1.08-.05 1.53.21z"/></svg>
	</button>
</li>

{#if openOverlay}
	<Portal target="#breakout">
		<Overlay
			on:escape={() => { openOverlay = false; }}
			narrow={true}
			modal={true}
		>
			<div class="overlay-title">
				<h2>Help</h2>
			</div>
			<div class="overlay-main">
				<div class="slate">
					<p><svg viewBox="0 0 80 80"><title>Help</title><path d="M57.3 27.9c-1.4-8.5-7.2-12.9-17.3-12.9s-15.9 4.3-17.3 12.9c-1.4.2-2.5 1.5-2.5 3v5.4c0 1.5 1.1 2.8 2.6 3 .6 3.1 1.8 5.8 3.6 7.9-.1.3-.2.7-.2 1.1 0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7-.4 0-.7.1-1.1.2-1.5-1.9-2.6-4.2-3.1-6.9.9-.5 1.5-1.5 1.5-2.5v-.3c1.1 6.6 6.9 11.6 13.8 11.6s12.7-5 13.8-11.6v.3c0 1.7 1.3 3 3 3s3-1.3 3-3v-5.4c0-1.6-1.1-2.9-2.5-3.1zm-17.3 15.7c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm13.8-12.7v.3c-1.1-6.6-6.9-11.6-13.8-11.6s-12.7 5-13.8 11.6v-.3c0-1.1-.6-2.1-1.5-2.6 1.2-7.6 6.3-11.3 15.3-11.3s14.1 3.7 15.3 11.2c-.9.6-1.5 1.5-1.5 2.7zM32.5 57.5h15c6.4 0 10.6 4 12.8 7.4h4.5c-2.3-5.1-8.2-11.4-17.3-11.4h-15c-9.1 0-15 6.4-17.3 11.4h4.5c2.2-3.4 6.4-7.4 12.8-7.4z"/></svg></p>
					<p><strong>Need assistance?</strong></p>
					<p>Send us a message below and a member of our team will respond as soon as possible</p>
				</div>
			</div>
			<div class="overlay-actions send">
				<form on:submit|preventDefault={sendMessage}>
					<textarea
						bind:this={ta}
						bind:value={messageText}
						use:autoresize
						placeholder="Ask a question …"
						on:keypress={checkMessage}
					></textarea>
					<button type="submit" use:autoblur>
						<svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg>
					</button>
					{#if messageText}
						<span class="counter" class:over={remaining < 0}>{remaining}</span>
					{/if}
				</form>
			</div>
		</Overlay>
	</Portal>
{/if}