import { cubicInOut } from 'svelte/easing';

export function drawWithLineCap(node, {
	delay = 0,
	speed,
	duration,
	easing = cubicInOut
}) {
	const hasEndCaps = getComputedStyle(node).strokeLinecap !== 'butt'
	let capSize = hasEndCaps ? parseInt(getComputedStyle(node).strokeWidth) : 0
	const len = node.getTotalLength() + capSize;

	if (duration === undefined) {
		if (speed === undefined) {
			duration = 800;
		} else {
			duration = len / speed;
		}
	} else if (typeof duration === 'function') {
		duration = duration(len);
	}

	return {
		delay,
		duration,
		easing,
		css: (t, u) => `stroke-dasharray: ${t * len} ${u * len}`
	};
}