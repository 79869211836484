<script>

	import { displayLang, agenda } from '../../../lib/stores.js';

	export let stc;
	export let details;

</script>

<div class="component">
	<p class="label">{stc.label[$displayLang]}</p>
	{#each $agenda.locations as o}
		{#if o.ref == details}
			<p>{o.name[$displayLang]}</p>
		{/if}
	{/each}
</div>