<script>

	import { onMount, getContext, tick } from 'svelte';
	import { flip } from 'svelte/animate';
	import fadeScale from 'svelte-transitions-fade-scale';
	import { quintOut } from 'svelte/easing';
	import { fade, fly } from 'svelte/transition';

	import { dndzone } from "svelte-dnd-action";

	import NewBanner from "./Banners/NewBanner.svelte";
	import ListItem from "./Banners/ListItem.svelte";

	// import Overlay from "../../../ui/Overlay.svelte";
	import Button from "../../../ui/Button.svelte";

	import { session, event } from "../../../lib/stores.js";
	import { postServerData } from '../../../lib/prelude.js';

	const banners = getContext('banners');

	let mounted = false;
	// let overlay = false;

	let bannersList = [];

	let flipDurationMs = 200;
	let dragDisabled = false;

	let editing = null;

	// let heights = [];
	// let offsets = {};
	// let listElem;
	// let listHeight;

	onMount(() => {
		setBannersList();
		mounted = true;
	});

	// function handleConsider(e) {

	// 	const ref = e.detail.ref; // banner ref
	// 	const i = e.detail.i; // current index
	// 	const y = e.detail.y; // offset from start position, per neodrag
	// 	const s = e.detail.s; // start index
	// 	const h = heights[i]; // height of box
	// 	const c = h / 2; // midpoint of box

	// 	let sd = (s * h) + y; // offset distance from top of list, based on start position

	// 	if (sd < 0) {
	// 		sd = 0;
	// 	}

	// 	let maxSd = (bannersList.length - 1) * h;
	// 	if (sd > maxSd) {
	// 		sd = maxSd;
	// 	}

	// 	const m = (sd + c); // offset distance of the box midpoint from top of list, based on start position
	// 	let j = Math.floor(m / h); // new index position
	// 	if (j < 0) j = 0;
	// 	if (j > (bannersList.length - 1)) j = (bannersList.length - 1);
	// 	j = Math.abs(j);
	// 	if (j != i) {
	// 		// console.log('new index', sd, y, c, m, i, j);
	// 		moveBanner(ref, j);
	// 	}
	// 	const d = j * h; // current (index position) distance from top of list
	// 	offsets[ref] = sd - d;
	// 	const scr = listElem.scrollTop;
	// 	if (scr > (sd - h)) {
	// 		listElem.scrollTo({
	// 			top: scr - 5
	// 		});
	// 	} else if ((sd + h + c) > (listHeight - scr)) {
	// 		listElem.scrollTo({
	// 			top: scr + 5
	// 		});
	// 	}
	// }

	// function moveBanner(ref, j) {

	// 	let deleted = undefined;

	// 	let filtered = bannersList.filter(function(o){
	// 		if (o.ref == ref) {
	// 			deleted = o;
	// 		} else {
	// 			return o;
	// 		}
	// 	});

	// 	filtered.splice(j, 0, deleted);

	// 	bannersList = filtered;

	// }

    function handleDndConsider(e) {
		const {items: newItems, info: {source, trigger}} = e.detail;
		bannersList = newItems;
		// // Ensure dragging is stopped on drag finish via keyboard
		// if (source === SOURCES.KEYBOARD && trigger === TRIGGERS.DRAG_STOPPED) {
		// 	dragDisabled = true;
		// }
    }

    function handleDndFinalize(e) {
		const {items: newItems, info: {source}} = e.detail;
		bannersList = newItems;
		setBanners();
		// // Ensure dragging is stopped on drag finish via pointer (mouse, touch)
		// if (source === SOURCES.POINTER) {
		// 	dragDisabled = true;
		// }
    }

	async function setBanners() {
		let transformed = [];
		for (const b of bannersList) {
			transformed.push({
				ref: b.id,
				text: b.text
			});
		}
		// $banners = transformed;
		const json = JSON.stringify(transformed);
		const orig = JSON.stringify($banners);
		if (json != orig) {
			postServerData('virtual/studio/banners', {
				json: json,
				sessionRef: $session.ref
			});
		}
	}

	function setBannersList() {
		let transformed = [];
		let seen = {};
		for (const b of $banners) {
			if (!seen[b.ref]) {
				transformed.push({
					id: b.ref,
					text: b.text
				});
				seen[b.ref] = true;
			}
		}
		bannersList = transformed;
	}

	function transformDraggedElement(elem) {
		elem.classList.add("dragging");
	}

	function addBanner(e) {
		e.stopPropagation();
		bannersList.push({
			id: 'new',
			text: '',
			edit: true
		});
		bannersList = bannersList;
	}

	// $: setBanners(bannersList);
	$: setBannersList($banners);

</script>

<style>
	.list {
		flex: 1 1 auto;
		position: relative;
		overflow: scroll;
		padding: 1rem;
	}
	.list > div {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		/*position: absolute;
		inset: 0;
		overflow: scroll;*/
	}
	/*.actions {
		flex: 0 0 auto;
		padding: 0.75rem;
	}*/
	.actions {
		padding-top: 0.75rem;
	}
	.actions :global(.label) {
		font-size: 0.75rem;
		font-weight: 600;
	}
</style>

{#if mounted}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="list p{bannersList.length}" in:fly={{ duration: 300, y: 20 }} on:click={() => {editing = null; setBannersList();}}>
		<div
			use:dndzone={{
				items: bannersList,
				flipDurationMs: flipDurationMs,
				dragDisabled: (dragDisabled || editing),
				dropTargetStyle: {},
				constrainAxisX: true,
				transformDraggedElement: transformDraggedElement
			}}
			on:consider="{handleDndConsider}"
			on:finalize="{handleDndFinalize}"
		>
			{#each bannersList as item (item.id)}
				<div animate:flip={{ duration: flipDurationMs }}>
					<ListItem {item} bind:dragDisabled bind:editing on:refresh={setBannersList} />
				</div>
			{/each}
		</div>
		{#if !editing}
			<div class="actions" transition:fade|local={{ duration: 200}}>
				<Button
					subtle={true}
					on:click={addBanner}
					label="Create a new banner"
				>
					<svg viewBox="0 0 80 80"><path d="M43.24 43.21v15.55h-6.36v-15.55h-15.55v-6.36h15.55v-15.55h6.36v15.55h15.56v6.36h-15.56z"/></svg>
				</Button>
			</div>
		{/if}
	</div>

	<!-- <div class="actions" in:fly={{ duration: 300, y: 20 }}>
		<Button ghost={false} wide={true} on:click={() => { overlay = true }} label="Add a new banner"/>
	</div> -->
{/if}

<!-- {#if overlay}
	<Overlay on:escape={() => { overlay = false }} modal={true}>
		<NewBanner on:saved={() => { overlay = false }} />
	</Overlay>
{/if} -->