<script>

	import { getContext } from 'svelte';
	import { fade } from 'svelte/transition';
	import fadeScale from 'svelte-transitions-fade-scale';

	import Button from "../../../../ui/Button.svelte";
	import Dialog from "../../../../ui/Dialog.svelte";

	import { event, session } from "../../../../lib/stores.js";
	import { postServerData } from '../../../../lib/prelude.js';
	import { autoblur } from '../../../../lib/autoblur.js';

	const background = getContext('background');
	const backgrounds = getContext('backgrounds');

	export let image;

	let over = false;
	let deleteItemDialog = null;

	function toggleStatus() {
		if ($background == image.ref) {
			$background = null;
		} else {
			$background = image.ref;
		}
	}

	function openDeleteItemDialog() {
		deleteItemDialog = {
			text: "Are you sure you want to delete this background?",
			actions: [
				{
					label: "Confirm",
					dispatch: "confirm"
				},
				{
					label: "Cancel",
					ghost: true,
					dispatch: "escape"
				}
			]
		};
	}

	async function deleteItem() {
		if ($background == image.ref) {
			$background = null;
		}
		let filtered = $backgrounds.filter(a => image.ref != a.ref);
		const json = JSON.stringify(filtered);
		await postServerData('virtual/studio/backgrounds', {
			json: json,
			sessionRef: $session.ref
		});
		deleteItemDialog = null;
	}

</script>

<style>
	.item {
		position: relative;
		width: 70%;
		background: var(--dark, #101820);
		border-radius: 4px;
		display: flex;
		transform: width 0.3s ease;
	}

	:global(.list.p0) .item,
	:global(.list.p1) .item {
		width: 100%;
	}

	:global(.list.p2) .item {
		width: 90%;
	}

	:global(.list.p3) .item {
		width: 80%;
	}

	img {
		aspect-ratio: 16/9;
		object-fit: cover;
		border-radius: 4px;
		box-shadow: 0 0 2px var(--shadow);
	}

	.control {
		position: absolute;
		z-index: 70;
		top: 50%;
		left: 50%;
		max-width: 100%;
		width: max-content;
		box-sizing: border-box;
		padding: 0.4rem;
		transform: translate(-50%,-50%);
	}

	.control :global(button.mini),
	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--panelColor);
		border-color: var(--panelColor);
		color: var(--textColor);
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		box-shadow: 0 2px 10px var(--shadow);
		min-width: 7rem;
	}

	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--blend-10);
		border-color: var(--blend-10);
	}

	.actions {
		position: absolute;
		z-index: 70;
		top: 0.4rem;
		right: 0.4rem;
		display: flex;
		gap: 0.2rem;		
	}
	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	.actions button svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
</style>

<div
	class="item"
	in:fadeScale={{ duration: 200, baseScale: 0.9 }}
	on:mouseenter={() => { over = true }}
	on:mouseleave={() => { over = false }}
	on:focus={() => { over = true }}
	on:blur={() => { over = false }}
>
	<img
		style="object-position: {image.focalPointX}% {image.focalPointY}%;"
		src="https://cdn.attendzen.io/{$event.accountRef}/max_{image.filename}"
		alt=""
	/>
	{#if over}
		<div class="control" transition:fade|local={{ duration: 100 }}>
			<Button
				mini={true}
				customClass={($background && ($background == image.ref)) ? 'onstage' : ''}
				label={($background && ($background == image.ref)) ? 'Remove' : 'Add to stage'}
				on:click={toggleStatus}
			/>
		</div>
		{#if image.allowDelete}
			<div class="actions" transition:fade|local={{ duration: 100 }}>
				<button
					type="button"
					use:autoblur
					on:click|stopPropagation={openDeleteItemDialog}
				>
					<svg viewBox="0 0 80 80">
						<path d="M48.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM40.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM32.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM62 21.27c0-2.21-1.79-4-4-4h-9.94c0-2.21-1.79-4-4-4h-8c-2.21 0-4 1.79-4 4h-10.06c-2.21 0-4 1.79-4 4v7.99h4.01v32.51c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-32.51h3.99v-7.99zm-8 38.51c0 1.1-.9 2-2 2h-24c-1.1 0-2-.9-2-2v-30.52h28v30.52zm4-34.52h-36v-1.96c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2v1.96z"/>
					</svg>
				</button>
			</div>
		{/if}
	{/if}
</div>

{#if deleteItemDialog}
	<Dialog
		{...deleteItemDialog}
		on:escape={() => { deleteItemDialog = null }}
		on:confirm={deleteItem}
	/>
{/if}