<script>

	import { onMount, onDestroy, getContext, tick } from 'svelte';

	import { session } from '../../lib/stores.js';
	import { autoblur } from '../../lib/autoblur.js';

	const host = getContext('host');

	export let tab;
	export let spots;

	let lineLeft = 0;
	let lineWidth = 0;

	$: if (tab) {
		setLine();
	}

	async function setLine() {
		await tick();
		const current = document.getElementById("rightnav-" + tab);
		if (current) {
			lineLeft = current.offsetLeft + 'px';
			lineWidth = current.offsetWidth + 'px';
		}
	}

	onMount(() => {
		window.addEventListener('resize', setLine);
	});

	onDestroy(() => {
		window.removeEventListener('resize', setLine);
	});

</script>

<style>
	ul {
		position: relative;
		display: flex;
		justify-content: space-around;
		margin: 0;
		padding: 0 0.75rem;
		z-index: 1000;
		flex: 0 0 auto;
		background: var(--panelColor);
	}

	ul:after {
		content: "";
		position: absolute;
		left: var(--lineLeft, 0);
		bottom: 0.5rem;
		width: var(--lineWidth, 0);
		height: 2px;
		background: var(--accentColor);
		transition: left 0.15s ease-out, width 0.2s ease-out;
	}

	li {
		display: block;
		margin: 0;
		padding: 0 0.25rem;
		/*flex: 1 1 25%;*/
		color: var(--textColor);
		text-align: center;
	}

	button {
		padding: 0.75rem 0 1rem 0;
		margin: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		text-align: center;
		width: 100%;
		cursor: pointer;
	}

	li.current button {
		color: var(--accentColor);
	}

	button:focus {
		outline: none;
	}

	button span {
		display: inline-block;
		font-size: 0.6875rem;
		font-weight: 600;
		/*transition: transform 0.2s ease;*/
		position: relative;
	}

	button:hover span,
	button:active span,
	button:focus span {
		transform: scale(1.05);
	}

	li.current button:hover span,
	li.current button:active span,
	li.current button:focus span {
		transform: scale(1);
	}

	button span:after {
		content: '';
		width: 0.3rem;
		height: 0.3rem;
		background: var(--red);
		border-radius: 100%;
		opacity: 0;
		display: inline-block;
		position: absolute;
		/*top: 0.35rem;*/
		top: 0.05rem;
		right: -0.5rem;
	}

	button.spot span:after {
		opacity: 1;
		transition: opacity 0.2s ease;
		transition-delay: 0.4s;
	}
</style>

{#if $session}
	<ul style="--lineLeft:{lineLeft};--lineWidth:{lineWidth};">
		{#if host}
			<li class:current={tab == 'graphics'}>
				<button type="button" use:autoblur on:click={() => {tab = 'graphics'}} class:spot={spots.graphics}>
					<span id="rightnav-graphics">Graphics</span>
				</button>
			</li>
		{/if}
		<li class:current={tab == 'backstage'}>
			<button type="button" use:autoblur on:click={() => {tab = 'backstage'}} class:spot={spots.backstage}>
				<span id="rightnav-backstage">Backstage chat</span>
			</button>
		</li>
		{#if $session.virtual.interactions.includes('chat') || $session.virtual.interactions.includes('qa') || $session.virtual.interactions.includes('polling')}
			<li class:current={tab == 'interactions'}>
				<button type="button" use:autoblur on:click={() => {tab = 'interactions'}} class:spot={spots.interactions}>
					<span id="rightnav-interactions">Interactions</span>
				</button>
			</li>
		{/if}
	</ul>
{/if}