<script>

	import { onMount, onDestroy, tick } from 'svelte';
	import { leftSection, event, sponsors, speakers, room } from '../lib/stores.js';

	import { autoblur } from '../lib/autoblur.js';

	let lineLeft = 0;
	let lineWidth = 0;

	$: if ($leftSection || $room) {
		setLine();
	}

	async function setLine() {
		await tick();
		const current = document.getElementById("leftnav-" + $leftSection);
		if (current) {
			lineLeft = current.offsetLeft + 'px';
			lineWidth = current.offsetWidth + 'px';
		}
	}

	onMount(() => {
		window.addEventListener('resize', setLine);
	});

	onDestroy(() => {
		window.removeEventListener('resize', setLine);
	});

</script>

<style>
	ul {
		position: relative;
		display: flex;
		justify-content: space-around;
		margin: 0;
		padding: 0 0.75rem;
		z-index: 1000;
		flex: 0 0 auto;
		background: var(--panelColor);
	}

	ul:after {
		content: "";
		position: absolute;
		left: var(--lineLeft, 0);
		bottom: 0.5rem;
		width: var(--lineWidth, 0);
		height: 2px;
		background: var(--accentColor);
		transition: left 0.15s ease-out, width 0.2s ease-out;
	}

	li {
		display: block;
		margin: 0;
		padding: 0 0.25rem;
		/*flex: 1 1 25%;*/
		color: var(--textColor);
		text-align: center;
	}

	button {
		padding: 0.75rem 0 1rem 0;
		margin: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		text-align: center;
		width: 100%;
		cursor: pointer;
	}

	li.current button {
		color: var(--accentColor);
	}

	button:focus {
		outline: none;
	}

	button span {
		display: inline-block;
		font-size: 0.6875rem;
		font-weight: 600;
		/*transition: transform 0.2s ease;*/
	}

	button:hover span,
	button:active span,
	button:focus span {
		transform: scale(1.05);
	}

	li.current button:hover span,
	li.current button:active span,
	li.current button:focus span {
		transform: scale(1);
	}

</style>

<ul style="--lineLeft:{lineLeft};--lineWidth:{lineWidth};">
	{#if (!$event.setup.virtual.infoPanel || $event.setup.virtual.infoPanel.includes('agenda'))}
		<li class:current={$leftSection == 'agenda'}>
			<button use:autoblur type="button" on:click={() => { $leftSection = 'agenda' }}>
				<span id="leftnav-agenda">Agenda</span>
			</button>
		</li>
	{/if}
	{#if (($room != 'backstage') && (!$event.setup.virtual.infoPanel || $event.setup.virtual.infoPanel.includes('sponsors')))}
		<li class:current={$leftSection == 'sponsors'}>
			<button use:autoblur type="button" on:click={() => { $leftSection = 'sponsors' }}>
				<span id="leftnav-sponsors">Sponsors</span>
			</button>
		</li>
	{/if}
	{#if (($room == 'backstage') || (!$event.setup.virtual.infoPanel || $event.setup.virtual.infoPanel.includes('attendees')))}
		<li class:current={$leftSection == 'attendees'}>
			<button use:autoblur type="button" on:click={() => { $leftSection = 'attendees' }}>
				<span id="leftnav-attendees">Attendees</span>
			</button>
		</li>
	{/if}
	{#if (($room != 'backstage') && (!$event.setup.virtual.infoPanel || $event.setup.virtual.infoPanel.includes('speakers')))}
		<li class:current={$leftSection == 'speakers'}>
			<button use:autoblur type="button" on:click={() => { $leftSection = 'speakers' }}>
				<span id="leftnav-speakers">Speakers</span>
			</button>
		</li>
	{/if}
</ul>