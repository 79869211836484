<script>

	import { onMount, onDestroy, tick } from "svelte";
	import { fade } from 'svelte/transition';

	import { ParticipantEvent } from 'livekit-client';

	import Avatar from "../ui/Avatar.svelte";

	import { attendees } from '../lib/stores.js';

	export let participant;
	export let local = false;

	let videoElement;
	let audioElement;

	let videoTrack = null;
	let audioTrack = null;

	let invisible = false;

	// const trackSubscribed = track => {
	// 	if (track.kind === "video") {
	// 		videoTrack = track;
	// 		track.attach(videoElement);
	// 	} else {
	// 		audioTrack = track;
	// 		track.attach(audioElement);
	// 	}
	// };

	// const trackUnsubscribed = track => {
	// 	if (track.kind === "video") {
	// 		videoTrack.detach();
	// 		videoTrack = null;
	// 	} else {
	// 		audioTrack.detach();
	// 		audioTrack = null;
	// 	}
	// };

	const trackSubscribed = (track, publication) => {
		if (!publication) {
			publication = track;
			track = track.track;
		}
		if (track) {
			if (track.kind === "video") {
				videoTrack = track;
				track.attach(videoElement);
			} else {
				audioTrack = track;
				if (!local) track.attach(audioElement);
			}
		}
	};

	const trackUnsubscribed = track => {
		if (track.kind === "video") {
			if (videoTrack) {
				videoTrack.detach(videoElement);
				videoTrack = null;
			}
		} else {
			if (audioTrack) {
				audioTrack.detach(audioElement);
				audioTrack = null;
			}
		}
	};

	const trackEnabled = track => {
		if (track.kind === "video") {
			invisible = false;
		}
	};

	const trackDisabled = track => {
		if (track.kind === "video") {
			invisible = true;
		}
	};

	// onMount(() => {

	// 	participant.on("trackSubscribed", trackSubscribed);
	// 	participant.on("trackUnsubscribed", trackUnsubscribed);

	// 	participant.on("trackDisabled", trackDisabled);
	// 	participant.on("trackEnabled", trackEnabled);

	// 	participant.tracks.forEach(publication => {
	// 		// console.log(publication);
	// 		if (local || publication.isSubscribed) {
	// 			trackSubscribed(publication.track);
	// 		}
	// 	});

	// 	return () => {

	// 		participant.removeAllListeners();

	// 		if (videoTrack) {
	// 			videoTrack.detach();
	// 			videoTrack = null;
	// 		}

	// 		if (audioTrack) {
	// 			audioTrack.detach();
	// 			audioTrack = null;
	// 		}

	// 	};

	// });

	onMount(async () => {

		await tick();

		participant.on(ParticipantEvent.TrackMuted, trackDisabled);
		participant.on(ParticipantEvent.TrackUnmuted, trackEnabled);

		participant.on(ParticipantEvent.TrackSubscribed, trackSubscribed);
		participant.on(ParticipantEvent.TrackUnsubscribed, trackUnsubscribed);

		let publications = Array.from(participant.trackPublications.values());

		console.log({publications});

		publications.forEach(t => {
			trackSubscribed(t);
		});

		if (local) {
			participant.on(ParticipantEvent.LocalTrackPublished, trackSubscribed);
		}

	});

	onDestroy(async () => {

		if (videoTrack) {
			if (local) await videoTrack.stop();
			videoTrack.detach(videoElement);
			videoTrack = null;
		}

		if (audioTrack) {
			if (local) await audioTrack.stop();
			audioTrack.detach(audioElement);
			audioTrack = null;
		}

	});

</script>

<style>
	div {
		flex: 1 1 auto;
		position: relative;
		aspect-ratio: 4 / 3;
		background: var(--textColor);
		border-radius: 8px;
	}
	div.local {
		width: clamp(90px, 16vh, 12vw);
		position: absolute;
		/*top: 1.4rem;*/
		bottom: 3.4rem;
		right: 1.4rem;
		box-shadow: 0 2px 10px var(--shadow);
		z-index: 1001;
		border: 1px solid #fff;
	}
	@media (max-width: 600px) {
		div.local {
			right: 1rem;
			bottom: 6rem;
		}
	}
	div :global(.avatar) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 8rem;
		height: 8rem;
	}
	div.local :global(.avatar) {
		width: 3rem;
		height: 3rem;
	}
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #2e3130;
		transform: opacity 0.2s ease;
		border-radius: 8px;
		object-fit: cover;
	}
	video.invisible {
		opacity: 0;
	}
</style>

<div class:local in:fade>

	<Avatar identity={$attendees[participant.identity]} />

	<!-- svelte-ignore a11y-media-has-caption -->
	<video bind:this={videoElement} class:invisible />
	<audio bind:this={audioElement} />

</div>
