<script>

	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { quadIn } from 'svelte/easing';

	import { drawWithLineCap } from '../lib/drawWithLineCap.js';

	export let animate = true;
	export let thickness = 3;

	let mounted = false;

	let fadeDelay;
	let fadeDuration;
	let circleDuration;
	let tickDuration;

	onMount(() => {
		fadeDelay = animate ? 600 : 0;
		fadeDuration = animate ? 200 : 0;
		circleDuration = animate ? 400 : 0;
		tickDuration = animate ? 500 : 0;
		mounted = true;
	});

</script>

<style>
	div {
		text-align: center;
		padding: 1rem 0;
	}
	svg {
		width: 4rem;
		height: 4rem;
	}
	path {
		stroke: var(--accentColor);
		fill: transparent;
		stroke-width: var(--thickness);
		stroke-linecap: round;
	}
</style>

{#if mounted}
	<div in:fade={{ delay: fadeDelay, duration: fadeDuration }} class="bigtick" style="--thickness:{thickness}px;">
		<svg viewBox="0 0 80 80">
			<path in:drawWithLineCap={{ delay: fadeDelay, duration: circleDuration }} d="M61.9,9C55.7,4.6,48.2,2,40,2C19,2,2,19,2,40s17,38,38,38s38-17,38-38c0-4-0.6-7.8-1.7-11.4"/>
			<path in:drawWithLineCap={{ delay: fadeDelay, duration: tickDuration, easing: quadIn }} d="M22.4,43.5L33,54.1c0.2,0.2,0.5,0.2,0.8,0c1-1,44.3-44.3,44.3-44.3"/>
		</svg>
	</div>
{/if}