import App from './App.svelte'

import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://8969248c5b914c52a47d7510909d12dd@o4504492294995968.ingest.sentry.io/4504492297879552",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

const app = new App({
  target: document.body
})

export default app
