<script>

	import { onMount } from 'svelte';
	import { fly } from "svelte/transition";

	import Back from "./Back.svelte";
	import Button from "../../ui/Button.svelte";
	import AttendeeMenu from '../../ui/AttendeeMenu.svelte';

	import {	event, muted, bigError, shadowbans, attendee as atnd, socialMediaServiceOpts, conversations, conversation } from '../../lib/stores.js';
	import {	autoblur } from '../../lib/autoblur.js';
	import { getServerData, postServerData } from '../../lib/prelude.js';
	import { text_to_html } from '../../lib/html.js';
	import { openConversation, videoCall } from '../../lib/conversations.js';

	export let attendee;

	let mounted = false;
	let profile = {};

	let dotsMenu = false;
	let tooltip = null;

	let links = [];

	let instaCall = null;
	let canInvite = true;

	onMount(async () => {

		await loadProfile(true);

		if (!$bigError) {

			if ($event.setup.virtual.socialMedia) {
				for (const sm of $event.setup.virtual.socialMedia) {
					if (profile[sm.service]) {
						links.push({
							icon: getIcon(sm.service),
							href: profile[sm.service]
						});
					}
				}
				if (profile.website) {
					links.push({
						icon: '<svg role="img" viewBox="0 0 72 72"><title>Web site</title><path d="M25.71 71.87c-5.03 0-10.07-1.92-13.9-5.75l-5.89-5.89c-3.71-3.71-5.76-8.65-5.75-13.9 0-5.25 2.05-10.19 5.76-13.9l8.69-8.69 4.24 4.24-8.69 8.69c-2.58 2.58-4 6.01-4 9.66s1.42 7.08 4 9.66l5.89 5.89c5.33 5.32 13.99 5.32 19.31 0l8.69-8.69 4.24 4.24-8.69 8.69c-3.83 3.83-8.86 5.75-13.9 5.75zM57.15 48.49l-4.24-4.24 8.92-8.92c2.58-2.58 4-6.01 4-9.66s-1.42-7.08-4-9.66l-5.89-5.89c-5.32-5.32-13.99-5.32-19.31 0l-8.92 8.92-4.24-4.24 8.92-8.92c7.66-7.66 20.13-7.67 27.8 0l5.89 5.89c3.71 3.71 5.76 8.65 5.76 13.9s-2.04 10.19-5.76 13.9l-8.93 8.92zM21.646 46.05l24.321-24.328 4.243 4.242-24.321 24.328z"/></svg>',
						href: profile.website
					});
				}
			}

			checkConversations();

			mounted = true;

		}

	});

	async function loadProfile(m) {
		if (mounted || m) {

			const profileData = await getServerData('virtual/profile', {
				attendee: attendee.ref
			});

			if (!$bigError) {
				profile = profileData.profile;
				// profile.reported = 12;
			}

		}
	}

	function getIcon(sm) {
		for (const s of $socialMediaServiceOpts) {
			if (s.name == sm) {
				return s.icon;
			}
		}
	}

	async function inviteToVideoCall() {
		await openConversation([attendee.ref]);
		if ($conversation) {
			const ok = await videoCall($conversation.ref);
			if (ok) {
				$conversation.videoCalls = 'sent';
			}
		}
	}

	async function startVideoCall() {
		const ok = await videoCall(instaCall);
	}

	function checkConversations() {
		instaCall = null;
		canInvite = true;
		if (attendee.blockVideo || !$event.setup.virtual.videoChat) {
			canInvite = false;
		}
		for (const c of Object.keys($conversations)) {
			if ($conversations[c].participants.length == 2) {
				for (const p of $conversations[c].participants) {
					if (p == attendee.ref) {
						if ($conversations[c].videoCalls && ($conversations[c].videoCalls == 'accepted')) {
							instaCall = c;
						} else if ($conversations[c].videoCalls && ($conversations[c].videoCalls == 'declined')) {
							canInvite = false;
						} else if ($conversations[c].videoCalls && ($conversations[c].videoCalls == 'sent')) {
							canInvite = false;
						}
						break;
					}
				}
			}
		}
	}

	$: if ($shadowbans) {
		loadProfile();
	}

	$: if ($conversations) {
		checkConversations();
	}

</script>

<style>

	:global(svg ellipse) {
		fill: currentColor;
	}

	.attendee {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.attendee .details {
		flex: 1 0 auto;
		position: relative;
	}
	.attendee .details > div {
		position: absolute;
		inset: 0;
		overflow-y: auto;
		padding: 0 1rem 2rem 1rem;
		box-sizing: border-box;
	}
	p {
		margin: 2rem 0 0 0;
	}
	.avatar {
		display: block;
		width: 4rem;
		height: 4rem;
		position: relative;
		margin-bottom: 1rem;
	}
	.avatar img,
	.avatar abbr {
		border-radius: 100%;
	}
	.avatar abbr {
		position: absolute;
		inset: 0;
		display: grid;
		place-content: center;
		border-radius: 100px;
		background: var(--textColor);
		color: var(--panelColor);
		line-height: 0;
		text-decoration: none;
		border: 0;
		font-size: 1.6rem;
		font-weight: 200;
		user-select: none;
	}
	
	.dots {
		position: absolute;
		/*top: 1.2rem;
		right: 1rem;
		width: 1.2rem;
		height: 1.2rem;*/
		top: 1.3rem;
		right: 1rem;
		width: 1rem;
		height: 1rem;
		padding: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
	}
	.dots:hover,
	.dots:active,
	.dots:focus {
		color: var(--accentColor);
	}
	
	.attendee :global(.menu) {
		position: absolute;
		width: var(--menuWidth);
		top: 2.8rem;
		right: 0.5rem;
	}
	.attendee :global(.menu.arrow:before) {
		left: auto !important;
		/*right: 1rem !important;*/
		right: 0.9rem !important;
	}

	

	.lozenges {
		margin-top: 0.5rem;
	}

	.lozenge {
		display: inline-block;
		background: transparent;
		border: 1px solid var(--red);
		color: var(--red);
		padding: 0 0.2rem;
		border-radius: 4px;
		vertical-align: middle;
		line-height: 0;
		position: relative;
	}

	.lozenge.red {
		background: var(--red);
		color: var(--panelColor);
	}

	.lozenge svg {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		vertical-align: middle;
		pointer-events: none;
	}

	.lozenge span {
		display: inline-block;
		vertical-align: middle;
		font-size: 0.625rem;
		font-weight: 400;
		position: relative;
		left: -0.2rem;
		/*line-height: 1;*/
		pointer-events: none;
	}

	.lozenge .tooltip {
		position: absolute;
		top: 1.6rem;
		left: 0;
		/*transform: translate(100%, -50%);*/
		border-radius: 4px;
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.4rem 0.8rem;
		line-height: 1.2;
		font-size: 0.6875rem;
		font-weight: 400;
		width: 7rem;
		z-index: 1000;
	}
	.tooltip:before {
		content: "";
		position: absolute;
		top: -0.1rem;
		left: 0.5rem;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translateY(-50%);
		background: var(--panelColor);
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}

	.actions {
		flex: 0 0 auto;
		border-top: 2px solid var(--blend-20);
		padding: 0.5rem 0 1rem 0;
		margin: 0 1rem;
	}

	.actions p {
		margin: 0.5rem 0 0 0;
	}


	.bio {
		margin: 1rem 0 0 0;
		font-size: 0.6875rem;
	}

	.bio :global(p) {
		margin: 0.5rem 0 0 0;
	}

	.links {
		margin: 1.4rem 0 0 0;
		display: flex;
		gap: 1rem;
	}

	.links a {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		position: relative;
	}

	.links a {
		color: var(--accentColor);
	}

	.links a:hover,
	.links a:active,
	.links a:focus {
		color: var(--accentBlend-120);
	}

	.links :global(svg) {
		position: absolute;
		inset: 0;
	}

</style>

<div class="attendee">

	<Back on:back/>

	{#if mounted}
		<div class="details">
			<div>

				<p>
					<span class="avatar">
						{#if profile.avatar && profile.avatar.filename}
							<img src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{profile.avatar.filename}" alt=""/>
						{:else}
							<abbr title="{attendee.f} {attendee.l}">{attendee.f.charAt(0).toUpperCase()}{attendee.l.charAt(0).toUpperCase()}</abbr>
						{/if}
					</span>
					<strong>{attendee.f} {attendee.l}</strong>
					{#if attendee.j}<br/>{attendee.j}{/if}
					{#if attendee.o}<br/>{attendee.o}{/if}
				</p>

				{#if $atnd.type == 'login'}
					{#if profile.muted || profile.reports || profile.shadowban || profile.ejected}
						<div class="lozenges">
							{#if profile.muted}
								<div
									class="lozenge"
									on:mouseover={() => { tooltip = 'muted' }}
									on:mouseout={() => { tooltip = null }}
									on:focus={() => { tooltip = 'muted' }}
									on:blur={() => { tooltip = null }}
								>
									<svg viewBox="0 0 17 17"><path d="M4.59 10.22c-.13-.01-.22-.11-.22-.24v-2.96c0-.13.09-.23.22-.24.35-.03.71-.08 1.08-.15l-.75-.9c-.37.05-.74.08-1.07.08-.13 0-.25.05-.34.14-.09.09-.14.21-.14.34v4.43c0 .13.05.25.14.34.09.09.21.14.34.14 2.36 0 5.85 1.18 7.97 3.16.09.09.22.14.34.14l.1-.02-2.11-2.52c-1.84-1.01-3.89-1.6-5.56-1.74zM14.2 13.67l-1.57-1.87v-8.82c0-.19-.12-.36-.29-.44l-.18-.04c-.12 0-.24.05-.34.14-1.27 1.19-3.01 2.06-4.71 2.59l-2.13-2.54c-.18-.21-.49-.24-.7-.06-.21.18-.24.49-.06.7l9.21 10.98c.1.12.24.18.38.18.11 0 .23-.04.32-.12.22-.17.24-.48.07-.7zm-2.57-9.56v6.51l-3.82-4.56c1.33-.45 2.67-1.09 3.82-1.95z"/></svg>
									<span>{profile.muted}</span>
									{#if tooltip == 'muted'}
										<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>{profile.muted} {#if profile.muted > 1}people have{:else}person has{/if} muted this attendee</span>
									{/if}
								</div>
							{/if}
							{#if profile.reports}
								<div
									class="lozenge"
									on:mouseover={() => { tooltip = 'reported' }}
									on:mouseout={() => { tooltip = null }}
									on:focus={() => { tooltip = 'reported' }}
									on:blur={() => { tooltip = null }}
								>
									<svg viewBox="0 0 17 17"><path d="M6.01 14.41l-1 .09-1.03-11.77.99-.09zM6.36 9.12c.49-.19 1.62-.27 2.45.2.79.45 1.46.61 2.01.61 1.02 0 1.66-.54 2.04-.86.23-.2.35-.47.33-.75l-.48-5.39c-.01-.15-.1-.28-.23-.34-.13-.07-.29-.06-.41.02l-.4.26c-.92.6-1.38.9-2.95.08-.95-.5-2.15-.53-2.92-.33l.56 6.5zm5.52-.76c-.5.41-1.22.82-2.62.02-.58-.33-1.28-.43-1.89-.4l-.38-4.38c.45-.01.99.07 1.45.31 1.51.79 2.25.59 2.99.17.12-.07.27.01.28.15l.31 3.81c.01.12-.04.24-.14.32z"/></svg>
									<span>{profile.reports}</span>
									{#if tooltip == 'reported'}
										<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>{profile.reports} {#if profile.reports > 1}people have{:else}person has{/if} reported this attendee</span>
									{/if}
								</div>
							{/if}
							{#if profile.shadowban}
								<div
									class="lozenge red"
									on:mouseover={() => { tooltip = 'shadowban' }}
									on:mouseout={() => { tooltip = null }}
									on:focus={() => { tooltip = 'shadowban' }}
									on:blur={() => { tooltip = null }}
								>
									<svg viewBox="0 0 17 17"><ellipse cx="7.51" cy="6.99" rx=".63" ry="1.09"/><ellipse cx="9.65" cy="6.99" rx=".63" ry="1.09"/><path d="M8.54 13.27c-.26 0-.45.09-.66.25-.29.23-.65.51-1.14.51-.6 0-.91-.31-1.26-.72-.29-.35-.41-.45-.53-.45-.09 0-.23.06-.36.16-.27.2-.66.42-1.05.37-.19-.02-.36-.13-.46-.29-.25-.37-.12-.96-.06-1.19.07-.25.3-.89.54-1.52l.27-.74c.2-.54.31-.81.38-1.45.1-.89.38-2.18.87-3.1.74-1.4 1.93-2.13 3.46-2.13s2.73.73 3.46 2.13c.49.92.77 2.21.87 3.1.07.65.18.91.38 1.45l.27.74c.23.63.47 1.27.54 1.52.07.23.19.82-.06 1.19-.11.16-.27.27-.46.29-.39.05-.79-.17-1.05-.37-.13-.1-.26-.16-.36-.16-.12 0-.24.1-.53.45-.35.41-.66.72-1.26.72-.49 0-.85-.28-1.14-.51-.21-.16-.39-.25-.66-.25zm0-.98c.61 0 .99.27 1.26.48.17.13.34.27.51.27.15 0 .21 0 .55-.39.3-.35.64-.75 1.21-.76.29 0 .5.1.66.18.13.07.47.27.26-.32l-.39-1.09-.27-.71c-.21-.54-.34-.9-.43-1.65-.09-.76-.33-1.93-.75-2.73-.56-1.07-1.44-1.61-2.6-1.61s-2.05.55-2.61 1.62c-.42.8-.67 1.97-.75 2.73-.08.74-.22 1.1-.43 1.64l-.26.72-.39 1.09c-.22.59.13.38.26.32.16-.08.37-.19.66-.18.57.01.91.4 1.21.76.33.39.4.39.55.39.17 0 .34-.14.51-.27.25-.22.63-.49 1.24-.49z"/></svg>
									{#if tooltip == 'shadowban'}
										<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>This attendee is currently subject to a shadow ban</span>
									{/if}
								</div>
							{/if}
							{#if profile.ejected}
								<div
									class="lozenge red"
									on:mouseover={() => { tooltip = 'ejected' }}
									on:mouseout={() => { tooltip = null }}
									on:focus={() => { tooltip = 'ejected' }}
									on:blur={() => { tooltip = null }}
								>
									<svg viewBox="0 0 17 17"><path d="M11.53 4.96c-.13 0-.26.03-.38.07v-.61c0-.62-.51-1.13-1.13-1.13-.15 0-.29.03-.42.08-.12-.5-.57-.87-1.1-.87-.54 0-1 .39-1.11.9-.12-.05-.26-.08-.4-.08-.62 0-1.13.51-1.13 1.13v3.51l-.1-.17c-.28-.56-.96-.78-1.52-.49-.56.28-.78.96-.49 1.52l1.76 3.45.04.07c.33.59 1.21 2.16 3.37 2.16 1.13 0 2.22-.42 2.9-1.12.57-.58.86-1.33.84-2.16v-5.13c0-.63-.51-1.13-1.13-1.13zm.2 6.13c.01.61-.19 1.13-.6 1.55-.52.53-1.36.85-2.25.85-1.63 0-2.3-1.18-2.58-1.69l-1.7-3.32c-.09-.18-.02-.4.16-.49.09-.04.18-.05.28-.02.08.03.16.09.2.18l.74 1.42.06.11.42.81c.09.18.31.24.48.15.18-.09.24-.31.15-.48l-.41-.8v-4.71c0-.2.16-.36.36-.36.2 0 .36.16.36.36v3.47c0 .2.16.36.36.36.2 0 .36-.16.36-.36v-4.25c0-.2.16-.36.36-.36s.36.16.36.36v4.48c0 .2.16.36.36.36.2 0 .36-.16.36-.36v-3.73c0-.2.16-.36.36-.36.2 0 .36.16.36.36v3.87c0 .2.16.36.36.36.2 0 .36-.16.36-.36v-2.29c0-.2.16-.36.36-.36s.36.16.36.36v4.89z"/></svg>
									{#if tooltip == 'ejected'}
										<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>This attendee has been ejected from the event</span>
									{/if}
								</div>
							{/if}
						</div>
					{/if}
				{/if}

				{#if profile.headline }
					<div class="bio"><p><strong>{profile.headline}</strong></p></div>
				{/if}

				{#if profile.bio }
					<div class="bio">
						{@html text_to_html(profile.bio)}
					</div>
				{/if}

				{#if links.length}
					<div class="links">
						{#each links as link}
							<a href={link.href} target="_blank" rel="noreferrer">
								{@html link.icon}
							</a>
						{/each}
					</div>
				{/if}

			</div>
		</div>

		{#if (attendee.ref != $atnd.ref) && !profile.ejected}
			{#if $event.setup.virtual.textChat}
				<div class="actions">
					<p><Button label="Send message" on:click={() => { openConversation([attendee.ref]) }} ghost={true} wide={true}/></p>
					{#if instaCall}
						<p><Button label="Start video call" on:click={startVideoCall} ghost={true} wide={true}/></p>
					{:else if canInvite}
						<p><Button label="Invite to video call" on:click={inviteToVideoCall} ghost={true} wide={true}/></p>
					{/if}
				</div>
			{/if}
		{/if}

		{#if attendee.ref != $atnd.ref}
			<button type="button" class="dots" use:autoblur on:click|stopPropagation={() => { dotsMenu = !dotsMenu }}>
				<svg viewBox="0 0 142 142"><title>Menu</title><path d="M.8 70.9c-.1-8.9 7.3-16.1 16.3-16.1 8.8 0 16.3 7.3 16.3 16.2-.1 9-7.6 16.3-16.3 16.3-9-.1-16.4-7.3-16.3-16.4zm53.9 0c-.1-8.9 7.3-16.1 16.3-16.1 8.8 0 16.2 7.3 16.3 16.2-.1 9-7.6 16.3-16.3 16.3-9-.1-16.4-7.3-16.3-16.4zm53.9 0c-.1-8.9 7.3-16.2 16.3-16.2 8.7 0 16.2 7.3 16.3 16.2-.1 9-7.6 16.3-16.3 16.3-9.1 0-16.4-7.2-16.3-16.3z"/></svg>
			</button>
			<AttendeeMenu
				{attendee}
				bind:profile
				menuOpen={dotsMenu}
				on:escape={() => { dotsMenu = false }}
			/>
		{/if}
	{/if}

</div>