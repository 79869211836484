import linkifyHtml from 'linkify-html';

export const escapeHTML = (html) => {
	return document.createElement('div').appendChild(document.createTextNode(html)).parentNode.innerHTML;
};

export const text_to_html = (text) => {
	let html = escapeHTML(text);
	html = html_paragraph(html).replace(/(\r?\n)+/g,'<br/>');
	html = linkifyHtml(html, { className: null });
	return html;
};

export const html_paragraph = (text) => { // per Template Toolkit
	const arr = text.split(/(?:\r?\n){2,}/);
	return "<p>" + arr.join("</p><p>") + "</p>";
};
