<script>

	import { onMount, onDestroy } from 'svelte';
	import Button from '../../ui/Button.svelte';

	import {	epoch, epochInterval, sessions, currentSessions, upcomingSessions, agenda, timeMachine } from '../../lib/stores.js';
	import {	showEpoch, getEpoch } from '../../lib/dt.js';

	let curTime = 'now';
	// let debouncer;

	let newEpoch = $epoch;

	function setCurTime() {
		// curTime = showEpoch($epoch,"DD MMM YY [at] HH:mm:ss");
		curTime = showEpoch(newEpoch,"DD MMM YY [at] HH:mm:ss");
	}

	onMount(() => {
		// console.log('mount admin');
		clearInterval($epochInterval);
		setCurTime();
	});

	onDestroy(() => {
		// console.log('destroy admin');
		$timeMachine = newEpoch;
		$epoch = newEpoch;
		mountSessions();
		setTimeout(() => {
			$epochInterval = setInterval(() => {
				$epoch = $epoch + 1;
			}, 1000);
		}, 1000);
	});

	function jumpAhead(s) {
		// clearTimeout(debouncer);
		// clearInterval($epochInterval);
		// $epoch = $epoch + s;
		newEpoch = newEpoch + s;
		setCurTime();
		// debouncer = setTimeout(() => {
		// 	$epochInterval = setInterval(() => {
		// 		if ($epoch % 60) {
		// 			$epoch = $epoch + 1;
		// 		} else {
		// 			// console.log('epoch drift avoider...')
		// 			$epoch = getEpoch();
		// 		}
		// 	}, 1000);
		// 	mountSessions();
		// }, 1000);
	}

	function mountSessions() {

		$currentSessions = [];
		$upcomingSessions = [];

		const e = $epoch;

		if ($sessions) {
			for (const k of Object.keys($sessions).sort()) {
				if (k > e) {
					$upcomingSessions.push(k);
				}
			}
		}

		if ($agenda && $agenda.sessions) {
			for (const s of $agenda.sessions) {
				if (s.virtual && (e >= s.epochStarts) && (s.epochEnds >= e)) {
					$currentSessions.push(s);
				}
			}
		}

		$upcomingSessions = $upcomingSessions;
		$currentSessions = $currentSessions;

	}

	// $: if ($epoch) {
	// 	setCurTime();
	// }

</script>

<style>
	p {
		margin: 1rem 0;
		display: flex;
		gap: 1rem;
	}
	p :global(button) {
		margin: 0 !important;
		flex: 1 1 20%;
	}
</style>

<div class="overlay-title">
	<h2>{curTime}</h2>
</div>

<div class="overlay-main">

	<p>
		<Button label="+10d" on:click={() => { jumpAhead(864000) }}/>
		<Button label="+1d" on:click={() => { jumpAhead(86400) }}/>
		<Button label="+1h" on:click={() => { jumpAhead(3600) }}/>
		<Button label="+1m" on:click={() => { jumpAhead(60) }}/>
		<Button label="+10s" on:click={() => { jumpAhead(10) }}/>
	</p>

	<p>
		<Button red={true} label="-10d" on:click={() => { jumpAhead(-864000) }}/>
		<Button red={true} label="-1d" on:click={() => { jumpAhead(-86400) }}/>
		<Button red={true} label="-1h" on:click={() => { jumpAhead(-3600) }}/>
		<Button red={true} label="-1m" on:click={() => { jumpAhead(-60) }}/>
		<Button red={true} label="-10s" on:click={() => { jumpAhead(-10) }}/>
	</p>

</div>