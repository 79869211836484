<script>

	import { onMount, onDestroy, tick, createEventDispatcher } from 'svelte';
	import { crossfade, fade, fly } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import moment from 'moment-timezone';
	import { byValue, byNumber } from 'sort-es';

	import Participants from "./Participants.svelte";

	import Button from "../../ui/Button.svelte";

	import { conversations, conversation, attendees, attendee, muted } from '../../lib/stores.js';
	import { showDate } from '../../lib/dt.js';
	import { isMuted } from '../../lib/conversations.js';

	// export let conversations = {};
	export let datestamps = {};

	const dispatch = createEventDispatcher();

	const [send, receive] = crossfade({
		duration: 0,
		fallback(node, params) {
			return {
				duration: 300,
				easing: quintOut,
				css: t => `
					transform: scale(${1 - ((1 - t) / 32)});
					opacity: ${t}
				`
			};
		}
	});

	let displayConversations = [];

	function newConversation() {
		alert('soon');
	}

	function openConversation(c) {
		$conversation = {
			ref: c,
			sid: $conversations[c].sid,
			participants: $conversations[c].participants,
			encryptionKey: $conversations[c].encryptionKey,
			exhibitor: $conversations[c].exhibitor,
			help: $conversations[c].help,
			videoCalls: $conversations[c].videoCalls
		};
	}

	function setDisplayConversations() {
		let dc = [];
		dc = Object.keys($conversations).sort(byValue(c => datestamps[c], byNumber({ desc: true })));
		dc = (dc && dc.length) ? dc.filter(filterByValue) : [];
		displayConversations = dc;
		// console.log('setDisplayConversations', $conversations, datestamps, dc);
	}

	function filterByValue(c) {

		if (!datestamps[c]) return false;
		if (isMuted($conversations[c].participants)) return false;

		return true;

	}

	$: if ($conversations) {
		setDisplayConversations();
	}

	$: if (datestamps) {
		setDisplayConversations();
	}

</script>

<style>
	.wrapper {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}
	.conversation {
		position: relative;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		padding: 0.5rem;
		margin: 0.5rem -0.5rem;
		border-radius: 4px;
	}
	.conversation:hover {
		background: var(--blend-05);
	}
	.meta {
		margin-top: 0.25rem;
		font-size: 0.625rem;
		color: var(--blend-60);
	}
	.spot .meta:after {
		content: '';
		display: inline-block;
		width: 0.4rem;
		height: 0.4rem;
		background: var(--red);
		border-radius: 100%;
		margin-left: 0.4rem;
		position: relative;
		top: -0.05rem;
	}

	.overlay-title {
		display: flex;
		justify-content: center;
	}

	/*.overlay-main {
		margin: 0 -1rem;
	}*/
</style>

<div class="wrapper">
	<div class="overlay-title">
		<h2>Direct messages</h2>
	</div>
	<div class="overlay-main">
		{#each displayConversations as c (c)}
			<!-- TODO: nest a button instead -->
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<div
				in:receive|local="{{ key: c }}"
				out:send|local="{{ key: c }}"
				animate:flip="{{ duration: 200 }}"
				class="conversation"
				class:spot={datestamps[c] && (datestamps[c] > $conversations[c].viewed)}
				on:click={() => { openConversation(c) }}
			>
				<Participants
					conversation={$conversations[c]}
					on:click={() => { openConversation(c) }}
					alignTop={true}
				/>
				<p class="meta">{showDate(moment.unix(datestamps[c]), 'HH:mm')}</p>
			</div>
		{:else}
			<div class="slate">
				<p><svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg></p>
				<p><strong>No messages yet</strong></p>
				<p>Your direct messages will be shown here once you send or receive one</p>
			</div>
		{/each}
	</div>
	<div class="overlay-actions">
		<Button wide={true} label="Start a conversation" on:click={() => { dispatch('start') }}/>
	</div>
</div>

