<script>
	
	import { onMount, onDestroy, getContext, tick, createEventDispatcher } from 'svelte';
	import { fade } from "svelte/transition";

	import Avatar from "../../../../ui/Avatar.svelte";
	import Results from "../../../../Auditorium/Interactions/Polls/Results.svelte";

	import { session, syncClient, attendees, bigError } from '../../../../lib/stores.js';
	// import { getServerData } from '../../../../lib/prelude.js';
	import { showDate } from '../../../../lib/dt.js';

	const dispatch = createEventDispatcher();

	const highlight = getContext('highlight');

	export let pollResults = null;

	let channel;
	let msg;

	onMount(async () => {
		try {
			channel = await $syncClient.list($session.syncChannel);
			await getChatItems();
			// if ($highlight && msg && ($highlight.t == 'poll')) {
			// 	getPollResults();
			// }
		} catch (e) {
			console.log("Sync error", e);	
		}
	});

	onDestroy(() => {
		if (channel) channel.close();
	});

	function chatPageHandler(paginator) {
		paginator.items.forEach(function(item) {
			if (item.data.type == $highlight.t) {
				if ($highlight.t == 'poll') {
					if (item.data.poll && (item.data.poll.ref == $highlight.r)) {
						msg = item.data.poll;
					}
				} else {
					if (item.index == $highlight.r) {
						msg = item.data;
						dispatch('ready');
					}
				}
			}
		});
		// if (paginator.hasNextPage) {
		// 	await paginator.nextPage();
		// 	await chatPageHandler(paginator);
		// }
		return paginator.hasNextPage ? paginator.nextPage().then(chatPageHandler) : null;
	};

	async function getChatItems() {
		let paginator;
		try {
			paginator = await channel.getItems({
				from: 0,
				order: 'asc',
				pageSize: 100
			});
		} catch (error) {
			if (error.message.match('Maximum attempt')) {
				// back off and try again later...
				const smear = 1000 * randomIntFromInterval(5,10);
				setTimeout(async () => {
					await getChatItems();
				}, smear);
			} else {
				console.error('getChatItems failed', error);
			}
		}
		if (paginator) await chatPageHandler(paginator);
	}

	// async function getPollResults() {
	// 	const pollData = await getServerData('virtual/polls', {
	// 		poll: msg.ref
	// 	});
	// 	if (!$bigError) {
	// 		pollResults = pollData.results;
	// 	}
	// }

</script>

<style>
	.chat {
		position: relative;
		padding-left: calc(var(--sw) * 0.07);
	}
	.chat :global(.avatar) {
		top: 0;
		left: 0;
		width: calc(var(--sw) * 0.05);
		height: calc(var(--sw) * 0.05);
	}
	.chat .person {
		margin-bottom: 0.25em;
	}

	.poll :global(.progressOuter) {
		margin: 0.5em 0;
		height: 0.5em;
	}

	.poll :global(.progressInner) {
		height: 0.5em;
	}
</style>

{#if msg}
	{#if $highlight.t == 'poll'}
		{#if pollResults}
			<div class="poll" in:fade={{ duration: 200 }}>
				<Results poll={msg} results={pollResults} studioBanner={true}/>
			</div>
		{/if}
	{:else}
		<div class="chat" in:fade={{ duration: 200 }}>
			<Avatar
				identity={$attendees[msg.sender]}
				hasMenu={false}
				anon={msg.anon}
			/>
			<p class="person">
				{#if msg.anon}
					<strong>Anonymous</strong>
				{:else}
					{#if $attendees[msg.sender]}
						<strong>{$attendees[msg.sender].f} {$attendees[msg.sender].l}</strong>
					{/if}
				{/if}
			</p>
			<p>
				{msg.message}
			</p>
		</div>
	{/if}
{/if}