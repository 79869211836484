<script>

	import { onMount, setContext, tick } from 'svelte';
	import { writable } from 'svelte/store';

	import Interactions from "./Auditorium/Interactions.svelte";
	import Session from "./Backstage/Session.svelte";
	import Panel from "./Backstage/Panel.svelte";

	import {	sessions, agenda, syncClient, busy, bigError } from './lib/stores.js';
	import { getServerData } from './lib/prelude.js';

	let mounted = false;
	let channel;
	let livestreamStatus;

	let session = writable(null);
	setContext('session', session);

	onMount(async () => {

		$session = $sessions['1648220400'][0];

		await getSessionData(true);
		await tick();
		mounted = true;

	});

	async function getSessionData(m) {
		if (m || mounted) {

			$busy = true;

			let playbackId = '';
			let livestream = '';

			if (($session.virtual.source == 'simulive') && $session.virtual.asset) {
				playbackId = $session.virtual.asset.playback;
			} else if (($session.virtual.source == 'live') && $session.virtual.livestream) {
				livestream = $session.virtual.livestream;
			}

			const sessionData = await getServerData('virtual/session', {
				session: $session.ref,
				playback: playbackId,
				livestream: livestream
			});

			if (!$bigError) {

				$busy = false;

				$session.syncChannel = sessionData.syncChannel;
				// console.log({$session});

				try {
					channel = await $syncClient.list($session.syncChannel);
				} catch (e) {
					console.log("Sync error", e, $session.syncChannel);
				}
			
				livestreamStatus = sessionData.livestreamStatus;

			}

		}
	}

	$: if ($agenda) {
		getSessionData();
	}

</script>

<style>
	div {
		grid-area: c;
		display: grid;
		grid-template-columns: auto auto;
		grid-template-areas: "cl cr";
		grid-gap: 0 3rem;
		padding: 0;
	}
	@media (max-width: 600px) {
		div {
			grid-template-areas: "cl" "cr";
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			grid-gap: 2rem 0;
		}
	}
</style>

{#if mounted}

	<div>
		<Session/>
		<Panel/>
	</div>

	{#if $session.virtual && $session.virtual.interactions && $session.virtual.interactions.length}
		<Interactions {channel}/>
	{/if}

{/if}
