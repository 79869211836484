<script>

	import { onMount, createEventDispatcher } from 'svelte';

	import Avatar from "../Avatar.svelte";
	import Field from "../Field.svelte";
	import Button from "../Button.svelte";
	import BigTick from '../BigTick.svelte';

	import {	event, attendees, attendee as atnd, busy, bigError } from '../../lib/stores.js';
	import { postServerData } from '../../lib/prelude.js';

	const dispatch = createEventDispatcher();

	export let attendee;

	let mounted = false;
	let dataOK = false;
	let sent = false;

	let report = {};

	onMount(() => {
		report.reportee = attendee;
		report.reporter = $atnd.ref; 
		mounted = true;
	});

	function checkOK() {
		let err = false;
		if (!report.category) err = true;
		if (!report.details) err = true;
		dataOK = !err;
	}

	async function sendReport() {

		let json = JSON.stringify(report);

		$busy = true;

		await postServerData('virtual/reports', {
			json: json
		});

		if (!$bigError) {
			$busy = false;
			sent = true;
		}

	}

	$: if (report) {
		checkOK();
	}

</script>

<style>
	.reportee {
		position: relative;
		padding-left: 4rem;
		min-height: 3rem;
		margin-bottom: 2rem;
	}
	.reportee :global(.avatar) {
		width: 3rem;
		height: 3rem;
		left: 0;
		top: 0;
	}
	.overlay-main {
		overflow: visible !important;
	}

	.slate {
		margin: 1rem 0 2rem 0;
		text-align: center;
	}

	.slate p {
		margin-top: 0.5rem;
		font-size: 0.8rem;
		line-height: 1.4;
		max-width: 15rem;
		margin: 0.5rem auto;
	}
	.slate strong {
		font-size: 1rem;
	}
	.slate :global(.bigtick) {
		padding: 0;
		width: 3rem;
		height: 3rem;
		margin: 0 auto 2rem auto;
		/*margin: 0 0 2rem 0;*/
	}
</style>

{#if mounted}
	<div class="overlay-title">
		<h2>Report an attendee</h2>
		<p>Tell us about a problem with the behaviour of someone at this event.</p>
	</div>
	<div class="overlay-main">
		{#if sent}

			<div class="slate">
				<BigTick animate={true} thickness={4}/>
				<p><strong>Thank you.</strong></p>
				<p>We will investigate and, if appropriate, let you know the outcome.</p>
			</div>

		{:else}

			<form id="overlay-report" on:submit|preventDefault={sendReport}>

				<p class="reportee">
					<Avatar identity={$attendees[attendee]}/>
					<strong>{$attendees[attendee].f} {$attendees[attendee].l}</strong>
					{#if $attendees[attendee].j}<br/>{$attendees[attendee].j}{/if}
					{#if $attendees[attendee].o}<br/>{$attendees[attendee].o}{/if}
				</p>

				<Field
					ref="category"
					bind:value={report.category}
					component="SelectList"
					label="How would you categorise the comments / behaviour you’re reporting?"
					options="{[
						{ value: 'Bullying or harassment', label: 'Bullying or harassment' },
						{ value: 'Defamatory', label: 'Defamatory' },
						{ value: 'Misleading or misinformation', label: 'Misleading or misinformation' },
						{ value: 'Spammy or scammy', label: 'Spammy or scammy' },
						{ value: 'Promoting illegal goods / activity', label: 'Promoting illegal goods / activity' },
						{ value: 'Hateful or discriminatory', label: 'Hateful or discriminatory' },
						{ value: 'Violent or harmful', label: 'Violent or harmful' },
						{ value: 'Adult or sexually explicit', label: 'Adult or sexually explicit' },
						{ value: 'Other', label: 'Other' }
					]}"
				/>

				<Field
					ref="details"
					bind:value={report.details}
					component="TextArea"
					label="Tell us about the issue"
				/>

			</form>

		{/if}
	</div>
	<div class="overlay-actions">
		{#if sent}
			<Button label="OK" wide={true} on:click={() => { dispatch('escape') }} />
		{:else}
			<Button label="Send report" wide={false} red={true} type="submit" form="overlay-report" disabled={!dataOK} />
			<Button label="Cancel" wide={false} red={true} ghost={true} on:click={() => { dispatch('escape') }} />
		{/if}
	</div>
{/if}