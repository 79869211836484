<script>

	import { onMount } from 'svelte';

	import Button from "../../ui/Button.svelte";
	import Timer from '../../ui/Timer.svelte';
	import Dialog from "../../ui/Dialog.svelte";

	import Clipboard from "svelte-clipboard";

	import Chat from '../Interactions/Chat.svelte';
	import Presence from '../../ui/Presence.svelte';

	import {	displayInEventTz, epoch, session, attendee, backstage, event } from '../../lib/stores.js';
	import {	showDate } from '../../lib/dt.js';
	import { postServerData } from '../../lib/prelude.js';

	export let control = {};
	export let livestreamStatus;
	export let useAutoCut = false;
	export let rtmp;
	export let spot = [];

	let rtmpLabel = 'Copy RTMP URL';
	let labelTimer;

	let cutDialog = null;

	function updateControl(obj) {
		const json = JSON.stringify(obj);
		postServerData('virtual/control', {
			session: $session.ref,
			json: json
		});
	}

	function openCutDialog() {
		cutDialog = {
			text: "Are you sure you want to cut video for this session?",
			actions: [
				{
					label: "Confirm",
					red: true,
					dispatch: "confirm"
				},
				{
					label: "Cancel",
					ghost: true,
					red: true,
					dispatch: "escape"
				}
			]
		};
	}

	function cutVideo() {
		cutDialog = null;
		let obj = {
			...control
		};
		obj.cut = true;
		updateControl(obj);
	}

	function resumeVideo() {
		let obj = {
			...control
		};
		obj.cut = false;
		updateControl(obj);
	}

	function extendAutoCut() {
		let obj = {
			...control
		};
		let basis = (($session.epochEnds + control.autoCut) < $epoch) ? ($epoch - $session.epochEnds) : control.autoCut;
		// console.log(basis, control.autoCut, (($session.epochEnds + control.autoCut) < $epoch));
		obj.autoCut = basis + 300;
		updateControl(obj);
	}

	function reduceAutoCut() {
		// tmp
		let obj = {
			...control
		};
		obj.autoCut = control.autoCut - 30;
		updateControl(obj);
	}

	function editAgenda() {
		window.open('https://platform.attendzen.io/events/' + $event.slug + '/agenda', '_blank');
	}

	function virtualSettings() {
		window.open('https://platform.attendzen.io/events/' + $event.slug + '/virtual/settings', '_blank');
	}

	onMount(() => {
		spot = [];
	});

</script>

<style>

	h2 {
		display: flex;
		align-items: center;
		gap: 1.8rem;
	}

	h2 svg {
		width: 1rem;
		height: 1rem;
		transform: scale(3);
		transform-origin: left center;
	}

	h3 {
		font-weight: 700;
		font-style: normal;
		font-size: 1.2rem;
		line-height: 1.2;
		margin: 0.8rem 0 0.4rem 0;
		color: var(--textColor);
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}

	.details {
		display: flex;
		gap: 1rem;
/*		justify-content: space-between;*/
		justify-content: flex-start;
/*		width: 20rem;*/
		max-width: 100%;
		position: relative;
		top: 0.1rem;
	}

	.times {
		display: flex;
		align-items: center;
		/*position: relative;
		top: 0.1rem;*/
		color: var(--textColor);
	}
	.times svg {
		width: 0.8em;
		height: 0.8em;
		margin-right: 0.5em;
	}
	.details :global(p) {
		font-weight: 400;
		font-style: normal;
		font-size: 0.6875rem;
		min-width: unset;
	}

	.details :global(span) {
		font-variant-numeric: unset;
	}

	.player {
		margin: 1.4rem 0;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		align-items: flex-start;
	}

	#panelPlayer {
		width: 20rem;
		max-width: 100%;
		aspect-ratio: 16 / 9;
		position: relative;
		border-radius: 6px;
		overflow: hidden;
		box-shadow: 0 2px 4px -2px var(--shadow);
	}

	.timers {
		flex: 0 0 min-content;
		display: flex;
		flex-wrap: wrap;
		gap: 1.4rem;
		font-size: 0.6875rem;
		justify-content: flex-start;
	}

	.timers > div {
		flex: 0 0 auto;
		color: var(--blend-80);
	}

	.timers :global(span.timer) {
		font-size: 1.4rem;
		/*transition: font-size 0.4s ease;*/
		/*font-weight: 600;*/
		font-weight: 300;
	}

	.timers .next :global(span.timer) {
		/*font-size: 1.4rem;*/
		font-weight: 600;
	}

	.timers .next {
		color: var(--textColor);
	}

	.timers .next.cuts {
		color: var(--red);
	}

	.split {
		display: flex;
		width: 100%;
	}

	.split > div {
		flex: 0 0 60%;
		padding-right: 2rem;
		position: relative;
	}

	.split > div + div {
		flex: 0 0 40%;
		/*box-shadow: -2px 0 0 var(--panelColor);*/
		padding-right: 0;
		overflow: visible;
	}

	.split > div + div:before {
		content: '';
		position: absolute;
		top: -1rem;
		left: -1px;
		width: 2px;
		bottom: 0;
		background: var(--panelColor);
	}

	.overlay-title .split > div + div:before {
		background: var(--blend-10);
		top: -0.4rem;
		bottom: -2rem;
		z-index: 9001;
	}

	.overlay-actions .split > div + div:before {
		background: var(--blend-10);
		top: -1.4rem;
		bottom: -0.4rem;
		z-index: 9001;
	}

	.overlay-actions {
		min-height: 2rem;
	}

	@media (max-width: 700px) {

		.split {
			flex-wrap: wrap;
		}

		.split > div {
			width: 100%;
			flex: 0 0 100%;			
		}

		.split > div + div {
			flex: 0 0 calc(100% + 6rem);			
		}

		.split > div + div:before {
			display: none;
		}

	}

	.status {
		display: inline-block;
		background: var(--textColor);
		color: var(--panelColor);
		text-transform: uppercase;
		font-size: 0.6875rem;
		font-weight: 600;
		line-height: 1;
		padding: 0.15rem 0.5rem;
		border-radius: 4px;
	}

	.status.red {
		background-color: var(--red);
	}

	.buttons {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
		margin: 0.5rem 0;
		width: 100%;
		overflow: hidden;
		justify-content: flex-start;
	}

	/*.buttons :global(button) {
		flex: 1 0 calc(33% - 1rem);
	}*/

	.buttons :global(button + button) {
		margin-left: 0 !important;
	}

	.buttons.rtmp :global(button:first-child) {
/*		width: 8rem;*/
		width: 9.5rem;
	}

	.chat {
		z-index: 9000;
	}

	.chat > div {
		position: absolute;
		left: 1rem;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 700px) and (min-height: 700px) {

		.overlay-main {
			overflow: visible;
		}

		.chat {
			margin-top: -4.7rem;
			margin-bottom: -5rem;
		}

	}

	@media (max-width: 700px) {

		.overlay-main {
			max-height: min(28rem, 60vh);
		}

		.chat {
			/*margin: 2rem 0 -1rem -3rem;*/
			margin: 0 0 -1rem -3rem;
			height: min(28rem, 60vh);
			/*background: var(--blend-20);*/
		}

		.chat > div {
			top: 2rem;
			bottom: 1rem;
			left: 2rem;
			right: 2rem;
		}

		.chat .chat-heading {
			background: var(--panelColor);
			margin: 0 -2rem;
			padding: 1rem 2.8rem 0 4.8rem;
		}

		.chat .chat-heading > svg {
			left: 2.8rem;
			top: 0.8rem;
		}

	}

	@media (max-width: 600px) {

		.chat {
			margin: 0 0 -1rem -2rem;
		}

		.split > div + div {
			flex: 0 0 calc(100% + 4rem);			
		}

	}

	.chat > div > div {
		flex: 0 0 auto;
		padding: 0 2.8rem;
		position: relative;
		/*color: var(--blend-80);*/
	}

	.chat > div > div > svg {
		position: absolute;
		left: 0.8rem;
		top: 0;
		width: 1.6rem;
		height: 1.6rem;
	}

	.chat > div > div > h3 {
		font-size: 0.8rem;
		margin: 0 0 0.25rem 0;
		/*color: var(--blend-80);*/
	}

	.chat > div > div > p {
		font-size: 0.625rem;
		margin-bottom: 1rem;
		color: var(--blend-60);
	}

	@media (min-width: 700px) and (max-width: 950px) {
		.chat > div > div > p {
			font-size: 0.55rem;
			margin-right: -1rem;
			margin-bottom: 1.2rem;
		}
	}

	.chat > div > div + div {
		flex: 1 0 auto;
		padding: 0;
	}

	.chat :global(.wrap .chat .slate) {
		margin-top: 3rem;
		padding: 0 1rem;
	}
	.chat :global(.slate p) {
		font-size: 0.8rem;
		max-width: 14rem;
		line-height: 1.4;
		color: var(--blend-60);
	}
	/*.chat :global(.slate svg) {
		width: 2rem;
		height: 2rem;
	}
	.chat :global(.slate strong) {
		font-size: 1rem;
	}*/

</style>

<div class="overlay-title">
	<div class="split">
		<div>
			<h2>
				<svg viewBox="0 0 60 60"><path d="M32.11 14.67l-29.71 5.24c-1.58.28-2.64 1.82-2.36 3.44l.87 4.94 35.45-6.25-.86-4.94c-.29-1.62-1.81-2.71-3.39-2.43zm-24.75 10.46l-2.53-3.62 3.93-.69 2.53 3.62-3.93.69zm7.88-1.39l-2.53-3.62 3.93-.69 2.53 3.62-3.93.69zm7.88-1.39l-2.53-3.62 3.93-.69 2.53 3.62-3.93.69zm10.93-1.93l-3.05.54-2.53-3.62 3.99-.7c.5-.09.98.28 1.08.81l.51 2.97zM.93 28.3v14.09c0 1.65 1.31 2.98 2.92 2.98h30.16c1.61 0 2.92-1.34 2.92-2.98v-14.09h-36zm33.08 15.07h-30.16c-.51 0-.92-.44-.92-.98v-7.33h3.02l1.87-4h3.99l-1.87 4h4.01l1.87-4h3.99l-1.87 4h4.01l1.87-4h3.99l-1.87 4h4.01l1.87-4h3.11l.01 11.33c-.01.54-.42.98-.93.98z"/></svg>
				<span>Stage management</span>
			</h2>
		</div>
		<div></div>
	</div>
</div>

<div class="overlay-main">
	<div class="split">
		<div>

			{#if control.cut || (useAutoCut && (($session.epochEnds + control.autoCut) < $epoch))}
				<p class="status red">Video cut</p>
			{:else if ($session.virtual.source == 'simulive')}
				<p class="status">Simulated live</p>
			{:else if ($session.virtual.source == 'studio')}
				<p class="status">Studio</p>
			{:else if livestreamStatus == 'active'}
				<p class="status">RTMP active</p>
			{:else if ($event.setup.virtual.provider == 'managed')}
				<p class="status red">RTMP idle</p>
			{:else}
				<p class="status">{$event.setup.virtual.provider}</p>
			{/if}

			<h3>{$session.name}</h3>

			<div class="details">
				<p class="times">
					<svg viewBox="0 0 48.1 48.1"><path d="M24.1 48.1c-13.3 0-24.1-10.8-24.1-24 0-13.3 10.8-24.1 24.1-24.1s24.1 10.8 24.1 24.1-10.9 24-24.1 24zm0-44.1c-11.1 0-20.1 9-20.1 20.1s9 20.1 20.1 20.1 20.1-9 20.1-20.1-9.1-20.1-20.1-20.1zM32.6 22.5h-6.5v-11.6c0-1.1-.9-2-2-2s-2 .9-2 2v13.5c0 1.1.9 2 2 2h8.5c1.1 0 2-.9 2-2s-.9-1.9-2-1.9z"/></svg>
					{#key $displayInEventTz}
						<span>{showDate($session.starts, 'ddd DD MMM, HH:mm')}–{showDate($session.ends, 'HH:mm')}</span>
					{/key}
				</p>
				<Presence/>
			</div>

			<div class="player">
				<div id="panelPlayer"></div>
				<div class="timers">
					<div class:next={(!useAutoCut || control.cut || !($session.epochEnds < $epoch) || (useAutoCut && !(livestreamStatus == 'active')))}>
						<span>Session ends in:</span>
						<br/><Timer duration={$session.epochEnds - $epoch} />
					</div>
					{#if useAutoCut && !control.cut && (livestreamStatus == 'active')}
						{#key control.autoCut}
							<div class="cuts" class:next={($session.epochEnds < $epoch)}>
								<span>Video cuts in:</span>
								<br/><Timer duration={($session.epochEnds + control.autoCut) - $epoch} />
							</div>
						{/key}
					{/if}
				</div>
			</div>

			<div class="buttons" class:rtmp>
				{#if rtmp && ($session.virtual.source == 'live')}
					<Clipboard
						text={'rtmp://live.attendzen.io:5222/app/' + rtmp}
						let:copy
						on:copy={() => {
							clearTimeout(labelTimer);
							rtmpLabel = 'Copied!';
							labelTimer = setTimeout(() => {
								rtmpLabel = 'Copy RTMP URL';
							}, 2000);
						}}>
						<Button ghost={true} mini={true} grow={false} label={rtmpLabel} on:click={copy}/>
					</Clipboard>
				{/if}
				{#if $attendee.type == 'login'}
					<Button ghost={true} mini={true} grow={false} label="Edit agenda" on:click={editAgenda} />
					<Button ghost={true} mini={true} grow={false} label="Virtual settings" on:click={virtualSettings} />
				{/if}
			</div>

		</div>

		<div class="chat">
			<div>
				<div class="chat-heading">
					<svg viewBox="0 0 32 32"><path d="M17.13 13.22c-.62 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12 0 .61-.49 1.12-1.12 1.12zm-3.23 0c-.62 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12.01.61-.49 1.12-1.12 1.12zm-3.22 0c-.61 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12 0 .61-.49 1.12-1.12 1.12zM3.15 21.92c-1.74 0-3.15-1.46-3.15-3.25v-13.52c0-1.74 1.37-3.15 3.06-3.15h21.78c1.75 0 3.16 1.46 3.16 3.25v13.43c0 1.79-1.41 3.25-3.15 3.25l-5.75.01v6.21l-9.65-6.21-6.3-.02zm6.88-2l7.06 4.55v-4.55l7.75-.01c.58 0 1.15-.62 1.15-1.38v-13.15c.01-.76-.57-1.38-1.28-1.38h-21.51c-.66 0-1.2.58-1.2 1.29v13.26c0 .76.44 1.38 1.29 1.38l6.74-.01z"/></svg>
					<h3>Backstage chat</h3>
					<p>Only speakers and people with stage manager access can see this chat.</p>
				</div>
				<div>
					<Chat isPrivate={true}/>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="overlay-actions">
	<div class="split">
		<div>
			{#if (!useAutoCut || (($session.epochEnds + control.autoCut) > $epoch))}
				{#if control.cut}
					<Button red={true} label="Resume video" on:click={resumeVideo}/>
				{:else}
					<Button red={true} label="Cut video" on:click={openCutDialog}/>
				{/if}
			{/if}
			{#if ($session.virtual.source == 'live') && useAutoCut && !control.cut && (livestreamStatus == 'active')}
				<Button red={true} ghost={true} label="Extend video" on:click={extendAutoCut}/>
				<Button red={true} ghost={true} label="Reduce video" on:click={reduceAutoCut}/>
			{/if}
		</div>
		<div></div>
	</div>
</div>

{#if cutDialog}
	<Dialog
		{...cutDialog}
		on:escape={() => { cutDialog = null }}
		on:confirm={cutVideo}
	/>
{/if}
