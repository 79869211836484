<script>

	import { fly } from "svelte/transition";

	import { autoblur } from '../lib/autoblur.js';

	export let type = "button";
	export let form = undefined;
	export let label = null;
	export let red = false;
	export let ghost = false;
	export let disabled = false;
	export let wide = false;
	export let mini = false;
	export let subtle = false;
	export let customClass = '';
	export let tooltip = null;
	export let tooltipPosition = 'top';
	export let grow = true;
	export let shout = false;

	let showTooltip = false;

	if (type != "submit") form = null;

</script>

<style>

	.wrap {
		flex: 0 0 auto;
		display: inline-block;
	}

	.wrap.grow {
		flex-grow: 1;
	}

	.wrap.wide {
		width: 100%;
	}

	button {
		display: inline-block;
		box-sizing: border-box;
		padding: 0.6rem 1rem;
		border-radius: 4px;
		border: 1px solid var(--accentColor, #e5077e);
		background: var(--accentColor, #e5077e);
		color: var(--panelColor, #fff);
		font-size: 0.8rem;
		cursor: pointer;
		transition: transform 0.1s ease;
		width: 100%;
	}

	/*button :global(*) {
		pointer-events: none;
	}*/

	button.red {
		border-color: var(--red, #e2261e);
		background-color: var(--red, #e2261e);
	}

	button.ghost {
		background: var(--accentBlend-05);
		border-color: var(--accentColor);
		color: var(--accentColor);
		/*background: transparent;*/
		/*border-color: var(--textColor);
		color: var(--textColor);*/
	}

	button.ghost.red {
		background: transparent;
		border-color: var(--red, #e2261e);
		color: var(--red, #e2261e);
	}

	button:hover,
	button:active,
	button:focus {
		border: 1px solid var(--accentBlend-120);
		background: var(--accentBlend-120);
		color: var(--panelColor);
		/*box-shadow: 0 0 0 1px var(--accentBlend-120);*/
	}

	button.ghost:hover,
	button.ghost:active,
	button.ghost:focus {
		background: var(--accentBlend-10);
		border: 1px solid var(--accentBlend-120);
		color: var(--accentBlend-120);
	}

	button.red:hover,
	button.red:active,
	button.red:focus {
		background: var(--red-120);
		border: 1px solid var(--red-120);
		color: var(--panelColor);
	}

	button.red.ghost:hover,
	button.red.ghost:active,
	button.red.ghost:focus {
		background: var(--blend-05);
		border: 1px solid var(--red-120);
		color: var(--red-120);
	}

	button[disabled] {
		border: 1px solid var(--blend-20) !important;
		background: var(--blend-20) !important;
		color: var(--panelColor) !important;
		cursor: not-allowed !important;
	}

	button.ghost[disabled] {
		border: 1px solid var(--blend-40) !important;
		color: var(--blend-40) !important;
	}

	button span.buttonlabel {
		display: inline-block;
		vertical-align: middle;
	}

	button.icon {
		padding: 0.4rem 1rem 0.4rem 0.7rem;
	}

	button :global(svg) {
		display: inline-block;
		width: 1.4rem;
		height: 1.4rem;
		vertical-align: middle;
	}

	button.subtle {
		padding: 0;
		background: transparent;
		color: var(--accentColor);
		border: none;
		font-size: 0.6875rem;
		font-weight: 400;
		width: auto;
	}

	button.subtle:hover,
	button.subtle:active,
	button.subtle:focus {
		color: var(--accentBlend-120);
	}

	button.mini {
		padding: 0.25rem 1rem;
		font-size: 0.75rem;
	}

	button.shout {
		text-transform: uppercase;
		font-weight: 600;
	}

	/*:global(.wrap) + .wrap {
		margin-left: 0.5rem;
	}*/

	button.subtle.icon {
		padding-right: 0.25rem;
	}

	.wrap {
		position: relative;
	}

	.tooltip {
		position: absolute;
		left: 50%;
		top: -1.1rem;
		transform: translate(-50%, -100%);
		border-radius: 4px;
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.4rem 0.8rem;
		line-height: 1;
		font-size: 0.6875rem;
		font-weight: 600;
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: 1000;
		white-space: nowrap;
	}
	/*.tooltip:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 100%;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translate(-50%,-20%);
		background: var(--panelColor);
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}*/
	.tooltip.under {
		top: calc(100% + 0.8rem);
		transform: translate(-50%, 0);
	}
	.tooltip.under:before {
		top: 0;
		transform: rotate(45deg) translate(-50%,20%);
	}

</style>

<span class="wrap" class:wide	class:grow>
	<button
		{type}
		{disabled}
		{form}
		class={customClass}
		class:red
		class:ghost
		class:mini
		class:subtle
		class:shout
		class:icon={$$slots.default}
		on:mousedown
		on:touchstart
		on:click
		on:mouseup
		on:touchend
		on:mouseenter={() => { showTooltip = true }}
		on:mouseleave={() => { showTooltip = false }}
		on:focus={() => { showTooltip = true }}
		on:blur={() => { showTooltip = false }}
		use:autoblur
	>
		{#if $$slots.default}
			<slot/>
		{/if}
		{#if label}<span class="buttonlabel">{label}</span>{/if}
	</button>
	{#if tooltip && showTooltip}
		<span class="tooltip" class:under={tooltipPosition == 'bottom'} in:fly|local={{ duration: 400, delay: 600, y: 5 }}>{tooltip}</span>
	{/if}
</span>