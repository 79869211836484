<script>

	import { onMount, onDestroy, tick, createEventDispatcher } from 'svelte';
	import { crossfade, fade, fly } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import moment from 'moment-timezone';

	import Avatar from "../../ui/Avatar.svelte";
	import Emoji from "./Chat/Emoji.svelte";
	import Giphy from "./Chat/Giphy.svelte";
	import GifPreview from "./Chat/GifPreview.svelte";
	import Menu from "../../ui/Menu.svelte";
	import Button from "../../ui/Button.svelte";

	import { event, attendee, displayInEventTz, attendeeLikes, attendees, bigError, muted, shadowbans, session, syncClient, backstage } from '../../lib/stores.js';
	import { text_to_html } from '../../lib/html.js';
	import { showDate } from '../../lib/dt.js';
	import { postServerData } from '../../lib/prelude.js';
	import { autoblur } from '../../lib/autoblur.js';
	import { randomIntFromInterval } from '../../lib/utils.js';

	const dispatch = createEventDispatcher();

	const [send, receive] = crossfade({
		duration: 0,
		fallback(node, params) {
			return {
				duration: 300,
				easing: quintOut,
				css: t => `
					transform: scale(${1 - ((1 - t) / 32)});
					opacity: ${t}
				`
			};
		}
	});

	export let isPrivate = false;
	export let channelRef = isPrivate ? $session.privateChannel : $session.syncChannel;
	export let slateText = 'Say something when the session is live and it will appear here.';
	export let emojis = true;
	export let studio = false;
	export let studioHighlight = null;

	let channel = undefined;
	let messages = [];

	let remaining = 200;
	let messageText = '';
	let gif = null;

	let mounted = false;
	let ready = false;

	let emojiPanel = false;
	let giphyPanel = false;

	let msgs;
	let ta;

	let pinHeight = 0;
	let selectedMsg = null;

	let avatarMenu = null;
	let avatars = {};

	let mod = ($attendee.type == 'login') || ($backstage.includes($session.ref));

	onMount(async () => {

		try {

			// console.log("opening session channel (chat)", channelRef, isPrivate);
			channel = await $syncClient.list(channelRef);

			channel.on('itemAdded', function(i) {
				if (i.item.data.type == 'chat') {
					addMessage(i.item);
				}
			});

			channel.on('itemUpdated', function(i) {
				if (i.item.data.type == 'chat') {
					updateMessage(i.item);
				}
			});

			channel.on('itemRemoved', function(i) {
				if (i.previousItemData.type == 'chat') {
					deleteMessage(i.index);
				}
			});

			await getChatItems();
			
		} catch (e) {

			console.log("Sync error", e, channelRef);
			
		}

		mounted = true;

		setTimeout(() => {
			if (msgs) {
				setPinHeight();
			}
		}, 200);

		let delay = messages.length ? 500 : 0;

		setTimeout(() => {
			if (msgs) {
				msgs.parentNode.scrollBy(0, 1000000);
				ready = true;
			}
		}, delay);

		// console.log('chat mount', messages.length, delay);

	});

	onDestroy(() => {
		// console.log("closing session channel (chat)");
		if (channel) channel.close();
	});

	function chatPageHandler(paginator) {
		paginator.items.forEach(function(item) {
			if (item.data.type == 'chat') {
				addMessage(item);
			}
		});
		return paginator.hasNextPage ? paginator.nextPage().then(chatPageHandler) : null;
	};

	async function getChatItems() {
		let paginator;
		try {
			paginator = await channel.getItems({
				from: 0,
				order: 'asc',
				pageSize: 100
			});
		} catch (error) {
			if (error.message.match('Maximum attempt')) {
				// back off and try again later...
				const smear = 1000 * randomIntFromInterval(5,10);
				setTimeout(async () => {
					await getChatItems();
				}, smear);
			} else {
				console.error('getChatItems failed', error);
			}
		}

		if (paginator) await chatPageHandler(paginator);

	}

	function addMessage(item) {
		let msg = processMessage(item);
		messages.push(msg);
		messages = messages;
		scrollToEnd();
	}

	async function updateMessage(item) {
		// console.log('updateMessage', item.data);
		const idx = item.index;
		let msg = processMessage(item);
		for (let [i,r] of messages.entries()) {
			if (r.index == idx) {
				// console.log('replacing', idx, reply);
				messages[i] = msg;
				break;
			}
		}
		messages = messages;
		// if (msg.pinned) {
		// 	setPinHeight(msg.index);
		// }
		setPinHeight();
	}

	function deleteMessage(idx) {
		// console.log('deleteMessage', idx);
		if (studioHighlight && (studioHighlight.t == 'chat') && (studioHighlight.r == idx)) {
			dispatch('highlight', null);
		}
		messages = messages.filter(m => {
			if (m.index != idx) return m;
		});
	}

	function processMessage(item) {
		let gif = (item.data.message.match(/^https\:\/\/media\d+\.giphy\.com/)) ? item.data.message : null;
		let html = gif ? null : text_to_html(item.data.message);
		let sent = item.data.dt ? moment.unix(item.data.dt) : moment(item.dateUpdated);
		let msg = {
			revision: item.index + '/' + item.revision,
			index: item.index,
			gif: gif,
			message: html,
			sent: sent,
			sender: item.data.sender,
			likes: item.data.likes ? item.data.likes : 0,
			liked: $attendeeLikes.includes(channelRef + '/' + item.index) ? true : false,
			pinned: item.data.pinned ? true : false,
			urgent: item.data.urgent ? true : false
		};
		return msg;
	}

	async function sendMessage(e) {

		// if (message != '') {
		// 	channel.push({
		// 		type: 'chat',
		// 		sender: $attendee.ref,
		// 		message: message
		// 	}, { ttl: 604800 }).then(function(i) {
		// 		message = '';
		// 		tick().then(() => resize({ target: ta }));
		// 		ta.focus();
		// 	}).catch(function(error) {
		// 		console.error('List Item push() failed', error);
		// 	});
		// }

		// console.log({$session});

		if (gif || ((messageText != '') && (remaining >= 0))) {
			await postServerData('virtual/chat', {
				channelRef: channelRef,
				message: gif ? gif : messageText
			});
			if (!$bigError) {
				messageText = '';
				gif = null;
				await tick();
				resize({ target: ta });
				ta.focus();
			}
		}

	}

	function setGif(e) {
		gif = e.detail.gif;
	}

	function unsetGif() {
		gif = null;
	}

	function checkMessage(e) {
		if (e.code == 'Enter') {
			e.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
			e.preventDefault();
		}
	}

	function scrollToEnd() {
		if (mounted) {
			// check that we haven't scrolled up by more than a message or so...
			let scrolledBack = false;
			if (msgs && msgs.lastElementChild) {
				let scrollDiff = msgs.scrollHeight - (msgs.parentNode.scrollHeight + msgs.scrollTop);
				// console.log(scrollDiff, msgs.lastElementChild.scrollHeight);
				if (scrollDiff > (msgs.lastElementChild.scrollHeight > 200 ? msgs.lastElementChild.scrollHeight : 200)) {
					scrolledBack = true;
				}
				if (!scrolledBack) {
					tick().then(() => {
						msgs.lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
						// console.log(msgs.parentNode.scrollHeight, msgs.scrollHeight, msgs.scrollTop)
					});
				}
			}
		}
	}

	async function toggleLike(msg) {
		// Update immediately; Sync revision will update again...
		if (msg.liked) {
			msg.likes--;
			msg.liked = false;
		} else {
			msg.likes++;
			msg.liked = true;
		}
		selectedMsg = null;
		const d = new Date;
		msg.revision = msg.index + '/' + d.getTime();
		messages = messages;
		// console.log(reply);
		await postServerData('virtual/likes', {
			channelRef: channelRef,
			messageIndex: msg.index
		});
	}

	async function togglePin(msg) {

		let pin;
		let unpin;

		if (msg.pinned) {
			unpin = msg.index;
			msg.pinned = false;
			pinHeight = 0;
		} else {
			for (let m of messages) {
				if (m.pinned) {
					unpin = m.index;
					m.pinned = false;
				}
			}
			pin = msg.index;
			msg.pinned = true;
		}

		postServerData('virtual/chat/pin', {
			channelRef: channelRef,
			pin: pin,
			unpin: unpin
		});

		selectedMsg = null;
		messages = messages;

		if (msg) {
			setPinHeight(msg.index);
		}

	}

	async function toggleUrgent(msg) {

		if (msg.urgent) {
			msg.urgent = false;
		} else {
			msg.urgent = true;
		}

		postServerData('virtual/chat/urgent', {
			channelRef: channelRef,
			messageIndex: msg.index,
			urgent: msg.urgent
		});

		selectedMsg = null;
		messages = messages;

	}

	function setPinHeight(index) {
		// console.log('setPinHeight', index);
		if (!mounted) return;
		if (index === undefined) {
			for (const m of messages) {
				if (m.pinned) {
					index = m.index;
					// console.log({index});
					break;
				}
			}
		}
		tick().then(() => {
			pinHeight = 0;
			if (index != undefined) {
				const p = document.getElementById('msg-' + index);
				if (p && p.classList.contains('pinned')) {
					pinHeight = p.offsetHeight;
				}
			}
			// console.log({pinHeight});
		});
	}

	async function actionDelete(msg) {
		selectedMsg = null;
		await postServerData('virtual/chat/delete', {
			channelRef: channelRef,
			index: msg.index
		});
	}

	function resize({ target }) {
		target.style.height = "1px";
		target.style.height = (+target.scrollHeight)+"px";
		scrollToEnd();
	}

	function autoresize(el) {
		resize({ target: el });
		el.style.overflow = 'hidden';
		el.addEventListener('input', resize);

		return {
			destroy: () => el.removeEventListener('input', resize)
		}
	}

	// let pinObserver;

	// function setObserver() {
	// 	const el = document.querySelector(".pinned")
	// 	pinObserver = new IntersectionObserver( 
	// 	  ([e]) => e.target.classList.toggle("at-top", e.intersectionRatio < 1),
	// 	  { threshold: [1] }
	// 	);
	// 	pinObserver.observe(el);
	// }

	function addEmoji(e) {
		messageText += e.detail;
	}

	// onDestroy(() => {
	// 	if (pinObserver) pinObserver.disconnect();
	// });

	$: remaining = 200 - messageText.length;

	$: if (emojiPanel || messageText) {
		closeGiphy();
	}

	function closeGiphy() {
		giphyPanel = false;
	}

	$: if (giphyPanel) {
		closeEmojis();
	}

	function closeEmojis() {
		emojiPanel = false;
	}

	function handleWindowClick() {
		selectedMsg = null;
		closeEmojis();
		closeGiphy();
	}

	function handleKeydown(event){
		if (event.key === 'Escape') {
			selectedMsg = null;
		}
	}

	// function avatarAction(e,a) {
	// 	avatarMenu = null;
	// 	const action = e.detail.value;
	// 	if (action == "mute") {
	// 		toggleMute(a);
	// 	} else {
	// 		alert('Soon');			
	// 	}
	// }

	// function toggleMute(a) {

	// 	postServerData('virtual/mute', {
	// 		mute: a
	// 	});

	// 	if ($muted.includes(a)) {
	// 		$muted = $muted.filter(l => { l != a });
	// 	} else {
	// 		$muted.push(a);
	// 		$muted = $muted;
	// 	}
			
	// }

	function closeAvatarMenus() {
		for (const [k,v] of Object.entries(avatars)) {
			if (k != avatarMenu) {
				if (v) v.closeMenu();
			}
		}
	}

	function toggleHighlight(r) {
		if (studioHighlight && (studioHighlight.t == 'chat') && (studioHighlight.r == r)) {
			dispatch('highlight', null);
		} else {
			dispatch('highlight', { t: 'chat', r: r });
		}
	}

	$: if ($muted || $shadowbans) {
		setPinHeight();
		scrollToEnd();
	}

</script>

<style>
	.wrap {
		position: absolute;
		inset:  0;
		display: flex;
		flex-direction: column;
	}
	.chat {
		flex: 1 1 auto;
		overflow: auto;
		padding: 0 0 0.4rem 0;
		transition: padding-top 0.3s ease-out;
		/*scrollbar-width: thin;*/
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.chat::-webkit-scrollbar {
		display: none;
	}
	.chat > div {
		display: flex;
		flex-direction: column;
		opacity: 0;
		transition: opacity 0.2s ease-in;
	}
	.chat.ready > div {
		opacity: 1;
	}
	.msg {
		position: relative;
		/*background: var(--blend-10);*/
		padding: 0.3rem 0.4rem 0.1rem 0.4rem;
		/*background: var(--panelColor, #fff);*/
		box-sizing: border-box;
	}
	.msg:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 0.6rem;
		background: var(--panelColor, #fff);
	}

	.private .msg:before {
		background: var(--blend-10);
	}

	/*@media (max-width: 700px) {
		.private .msg:before {
			background: var(--blend-20);
		}
	}*/

	.msg > div {
		position: relative;
		background: var(--panelColor, #fff);
		border-radius: 4px;
		padding: 0.4rem 0.4rem 0.5rem 2.4rem;
		word-wrap: break-word;
	}
	/*.msg + .msg {
		margin-top: 0.4rem;
	}*/

	.msg > div:hover {
		background: var(--blend-05);
	}

	.msg.organiser > div {
		background: var(--accentBlend-10);
	}
	.msg.organiser > div:hover {
		background: var(--accentBlend-20);
	}

	.private .msg > div,
	.private .msg.organiser > div {
		background: var(--blend-10);
	}

	.private .msg > div:hover,
	.private .msg.organiser > div:hover {
		background: var(--blend-20);
	}

	.private .msg.urgent > div {
		background: var(--panelColor);
	}

	@keyframes border-pulse {
		0%   { opacity: 1; }
		50%  { opacity: 0.1; }
		100% { opacity: 1; }
	}

	.private .msg.urgent > div:before {
		content: '';
		border-radius: 4px;
		position: absolute;
		inset: 0;
		z-index: 1;
		box-shadow: 0 0 0 2px var(--red);
		animation: border-pulse 1s 3;
		animation-delay: 1s;
	}

	.private .msg.urgent > div > .person,
	.private .msg.urgent > div > .message {
		position: relative;
		z-index: 2;
	}

	.private .msg.urgent > div:hover {
		background: var(--blend-05);
	}

	.private .msg.urgent :global(.message p) {
		color: var(--red-120);
		font-size: 0.875rem;
	}

	/*@media (max-width: 700px) {
		.private .msg > div,
		.private .msg.organiser > div {
			background: var(--blend-20);
		}

		.private .msg > div:hover,
		.private .msg.organiser > div:hover {
			background: var(--blend-10);
		}
	}*/

	.chat :global(.avatar) {
		position: absolute;
		top: 0.4rem;
		left: 0.4rem;
	}
	.chat :global(p) {
		font-size: 0.6875rem;
		line-height: 1.4;
	}
	.person {
		margin-bottom: 0.2rem;
	}
	.person strong {
		font-weight: 700;
	}
	.person span {
		display: inline-block;
		margin-left: 0.3rem;
		opacity: 0.6;
		font-size: 0.625rem;;
	}
	.person em {
		display: inline-block;
		margin-left: 0.1rem;
		color: var(--accentColor);
		font-style: normal;
		font-weight: 600;
		font-size: 0.625rem;;
	}
	.person svg {
		display: inline-block;
		margin-left: 0.2rem;
		width: 0.6rem;
	}
	.person svg path {
		fill: var(--accentColor);
	}

	.likes {
		display: flex;
		margin-top: 0.2rem;
		line-height: 1;
		cursor: pointer;
		align-items: center;
		justify-content: flex-start;
		gap: 0.2em;
		margin-bottom: -0.2em;
	}
	.likes span {
		vertical-align: middle;
		/*color: var(--blend-60);*/
		font-size: 0.625rem;
		font-weight: 600;
	}
	.likes svg {
		display: inline-block;
		/*width: 1em;
		height: 1em;*/
		width: 1.6em;
		height: 1.6em;
		vertical-align: middle;
		/*margin-left: 0.1em;*/
	}
	/*.likes.set svg,
	.likes:hover svg {
		color: var(--accentColor);
	}*/
	.likes:hover {
		color: var(--accentColor);
	}

	.studio .msg:hover :global(.avatar),
	.studio .msg:hover .person,
	.studio .msg:hover .message,
	.studio .msg:hover .likes,
	.studio .msg.selected :global(.avatar),
	.studio .msg.selected .person,
	.studio .msg.selected .message,
	.studio .msg.selected .likes {
		opacity: 0.6;
	}

	.msg .actions {
		display: none;
		position: absolute;
		top: -0.3rem;
		right: -0.2rem;
		z-index: 100;
	}

	.msg:hover .actions,
	.msg.selected .actions {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
	}

	.studio .msg .actions {
		top: 50%;
		right: auto;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
	}

	.msg .actions > div {
		background: var(--panelColor);
		border-radius: 3px;
		height: 1.2rem;
		padding: 0.1rem 0.4rem;
		box-shadow: 0 1px 4px var(--shadow), 0 0 0 1px var(--blend-05);
		display: flex;
		gap: 0.5rem;
	}

	.studio .msg .actions > div {
		border-radius: 4px;
		box-shadow: 0 1px 4px var(--shadow);
	}

	.msg .actions button {
		position: relative;
		box-sizing: border-box;
		border: none;
		background: transparent;
		color: var(--textColor);
		width: 1.2rem;
		height: 1.2rem;
	}

	.msg .actions button:hover {
		color: var(--accentColor);
		cursor: pointer;
	}

	.msg .actions button svg {
		position: absolute;
		/*inset: 0;*/
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.msg .actions :global(.wrap) {
		flex: 0 0 auto;
	}

	.msg .actions :global(.wrap button) {
		background: var(--panelColor);
		color: var(--textColor);
		border: none;
		box-shadow: 0 1px 4px var(--shadow);
		height: 1.4rem;
		padding-top: 0;
		padding-bottom: 0;
		min-width: 7rem;
	}

	.msg .actions :global(.wrap button:hover),
	.msg .actions :global(.wrap button:active),
	.msg .actions :global(.wrap button:focus) {
		background: var(--blend-10);
	}

	.msg .actions :global(.wrap button.onstage) {
		background: var(--accentColor);
		color: var(--panelColor);
	}

	.msg .actions :global(.wrap button.onstage:hover),
	.msg .actions :global(.wrap button.onstage:active),
	.msg .actions :global(.wrap button.onstage:focus) {
		background: var(--accentBlend-120);
	}

	.msg.openMenu .actions {
		display: none !important;
	}

	.send > form {
		margin: 0 0.4rem;
		border-top: 1px solid var(--blend-20);
		padding: 0.5rem 0 0.5rem 0.4rem;
		display: flex;
		align-items: flex-start;
		position: relative;
	}

	.private .send > form {
		border-top: 0;
		padding-top: 1rem;
		padding-bottom: 0;
	}

	@media (max-width: 700px) {
		.private .send > form {
			border-top: 1px solid var(--blend-40);
		}
	}

	:global(.report) .private .send > form {
		border-top: 1px solid var(--blend-40);
	}

	.counter {
		font-size: 0.625rem;;
		line-height: 1;
		position: absolute;
		bottom: 0.6rem;
		right: 0;
		color: var(--blend-60);
		width: 1.5rem;
		text-align: center;
	}
	.counter.over {
		color: var(--red);
	}
	.send textarea {
		border: 0;
		padding: 0;
		width: 100%;
		flex: 1 1 auto;
		min-height: 2.4rem;
		max-height: 6rem;
		background: none;
		resize: none;
		font-family: Inter;
		font-size: 0.6875rem;;
		border-radius: 0;
		color: var(--textColor);
		text-align: left;
	}

	.private .send textarea {
		min-height: 3rem;
		max-height: 3rem;
	}

	.send textarea:focus {
		outline: none;
	}
	.send :global(button) {
		flex: 0 0 auto;
		margin-left: 0.5rem;
		padding: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		box-sizing: border-box;
		border-radius: 3px;
	}
	.send button {
		background: var(--blend-05);
	}
	.send :global(button > svg) {
		position: absolute;
		inset: 0.15rem;
	}

	@media (max-width: 700px) {
		.private .send button {
			background: var(--blend-20);
		}
	}

	:global(.report) .private .send button {
		background: var(--blend-20);
	}

	.send button:hover,
	.send button:active,
	.send button:focus {
		cursor: pointer;
		color: var(--panelColor);
		background: var(--textColor);
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: var(--blend-40);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: var(--blend-40);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: var(--blend-40);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: var(--blend-40);
	}
	.emoji > :global(p) {
		font-size: 1.4rem;
	}
	.msg.pinned {
		/*position: sticky;*/
		/*top: -1px;*/
		position: absolute;
		top: 0;
		z-index: 1000;
		width: 100%;
	}
	.msg.pinned > div {
		box-shadow: inset 0 0 2px rgba(0,0,0,0.05);
		background: var(--blend-05);
	}
	.msg.organiser.pinned > div {
		background: var(--accentBlend-10);
	}
	.private .msg.pinned > div {
		background: var(--accentBlend-10);
	}
	.private .msg.pinned:hover > div {
		background: var(--accentBlend-20);
	}
	.msg.pinned > div:after {
		content: '';
		position: absolute;
		inset: 0;
		z-index: -1;
		box-shadow: 0 5px 5px -3px rgba(0,0,0,0.15);
		/*opacity: 0;*/
		/*transition: opacity 0.3s ease;*/
	}
	/*.msg.pinned:global(.at-top) > div:after {
		opacity: 1;
	}*/
	.gif {
		max-width: 100%;
	}
	.msg.muted {
		display: none !important;
	}

	/*.msg :global(.menu) {
		position: absolute;
		left: 2.4rem;
		top: 0.3rem;
		width: 100px;
	}

	.msg :global(.menu.arrow:before) {
		top: 0.7rem !important;
		left: -0.4rem !important;
	}*/

	.msg:last-child:not(:first-child) :global(.menu) {
		top: auto;
		bottom: -0.4rem;
	}

	.msg:last-child:not(:first-child) :global(.menu.arrow:before) {
		top: auto !important;
		bottom: 0.7rem !important;
	}

	/*.msg:not(.organiser) :global(.avatar) {
		cursor: pointer;
	}*/

	.chat .slate {
		padding: 2rem;
	}

</style>

<svelte:window on:click={handleWindowClick} on:keydown|stopPropagation={handleKeydown}/>

{#if mounted && Object.keys($attendees).length}
	<div class="wrap" in:fly={{ duration: 300, y: 20 }} class:private={isPrivate}>

		{#key $displayInEventTz}
			<div class="chat" class:ready class:studio style="padding-top:{pinHeight}px;">
				<div bind:this={msgs}>

					{#each messages as msg (msg.index)}
						<!-- TODO: nest a button instead -->
						<!-- svelte-ignore a11y-click-events-have-key-events -->
						<div
							id="msg-{msg.index}"
							class="msg"
							class:pinned={msg.pinned}
							class:urgent={msg.urgent}
							class:organiser={$attendees[msg.sender].type == 'login'}
							class:selected={selectedMsg == msg.index}
							class:muted={($muted.includes(msg.sender) || ($shadowbans.includes(msg.sender) && (msg.sender != $attendee.ref)))}
							class:openMenu={avatarMenu == msg.index}
							in:receive|local="{{ key: msg.index }}"
							out:send|local="{{ key: msg.index }}"
							animate:flip="{{ duration: 200 }}"
							on:click|stopPropagation={() => { selectedMsg = (selectedMsg == msg.index) ? null : msg.index }}
						>
							<div>
								<Avatar
									bind:this={avatars[msg.index]}
									identity={$attendees[msg.sender]}
									hasMenu={($attendees[msg.sender].type != 'login')}
									on:open={() => { avatarMenu = msg.index; closeAvatarMenus(); }}
									on:close={() => { avatarMenu = null }}
								/>
								<p class="person">
									{#if $attendees[msg.sender]}
										<strong>{$attendees[msg.sender].f} {$attendees[msg.sender].l}</strong>
										{#if msg.pinned}<svg viewBox="0 0 32 32"><path d="M30.91 12.26l-11.17-11.17c-.78-.78-2.05-.78-2.83 0-.78.78-.78 2.05 0 2.83l1.46 1.46-9.92 8-1.05-1.06c-.78-.78-2.05-.78-2.83 0-.78.78-.78 2.05 0 2.83l5.43 5.43-9.21 9.21c-.39.39-.39 1.02 0 1.41.2.2.45.29.71.29s.51-.1.71-.29l9.21-9.21 5.43 5.43c.39.39.9.59 1.41.59s1.02-.2 1.41-.59c.78-.78.78-2.05 0-2.83l-1.05-1.05 8-9.92 1.46 1.46c.39.39.9.59 1.41.59s1.02-.2 1.41-.59c.8-.78.8-2.04.01-2.82z"/></svg>{/if}
										{#if (!isPrivate && ($attendees[msg.sender].type == 'login'))}<em>Organiser</em>{/if}
									{/if}
									<span>{showDate(msg.sent, 'HH:mm')}</span>
								</p>
								{#if msg.gif}
									<div class="message gif">
										<video class="gif" autoplay loop muted inline>
											<source src={msg.gif} type="video/mp4">
										</video>
									</div>
								{:else}
									<div class="message" class:emoji={(msg.message.length == 9) && (/\p{Extended_Pictographic}/u.test(msg.message))}>
										{@html msg.message}
									</div>
								{/if}
								{#if msg.likes}
									{#key msg.revision}
										<!-- TODO: nest a button instead -->
										<!-- svelte-ignore a11y-click-events-have-key-events -->
										<p class="likes" on:click|stopPropagation={toggleLike(msg)}>
											<svg viewBox="0 0 20 20">
												{#if msg.liked}
													<path d="M8.26 14.73c.56.48 1.28.48 1.31.48h4.55c.7-.04 1.24-.62 1.24-1.31 0-.11-.01-.21-.04-.31.48-.2.8-.67.8-1.21 0-.18-.04-.35-.1-.51.39-.23.63-.66.63-1.12 0-.36-.15-.7-.4-.94.18-.23.28-.51.28-.8 0-.34-.13-.67-.38-.92-.26-.26-.61-.41-.98-.41h-2.84l.18-1.18c.02-.15.03-.3.03-.45 0-1.17-.79-2.53-1.81-2.53-.29 0-.58.1-.81.29l-.09.07.15 1.95c.01.08 0 .17-.02.24l-.09.23-1.56 2.89-.05.05v5.49zM6.93 15.5c.3 0 .55-.24.55-.55v-5.7c0-.3-.24-.55-.55-.55h-3.03c-.3 0-.55.24-.55.55v5.7c0 .3.24.55.55.55h3.03z"/>
												{:else}
													<path d="M10.1 15.15c-.09 0-.87-.02-1.5-.55l-.18-.15v-4.97c0-.25.07-.51.19-.73l1.36-2.52-.14-2.02.29-.24c.29-.25.67-.38 1.05-.38 1.22 0 2.13 1.47 2.13 2.79 0 .16-.01.33-.04.48l-.08.49h1.98c.46 0 .91.19 1.24.52.32.32.49.74.49 1.18 0 .24-.05.47-.15.68.17.27.26.58.26.89 0 .46-.18.89-.5 1.2l.02.28c0 .55-.26 1.05-.69 1.36v.01c0 .9-.7 1.63-1.59 1.68h-4.14zm-.68-1.19c.33.19.68.19.68.19h4.1c.35-.02.63-.32.63-.69l-.02-.16-.1-.41.39-.17c.25-.11.42-.36.42-.63 0-.09-.02-.18-.05-.27l-.17-.4.37-.22c.21-.12.33-.34.33-.59 0-.18-.08-.36-.21-.49l-.32-.31.28-.35c.09-.12.14-.26.15-.42 0-.17-.07-.34-.2-.47-.14-.14-.33-.22-.53-.22h-3.14l.25-1.64.03-.33c0-.87-.58-1.79-1.13-1.79-.11 0-.21.03-.31.08l.11 1.48c.01.13 0 .25-.03.38-.03.12-.08.24-.14.35l-1.32 2.35c-.04.08-.07.16-.07.25v4.48zM6.44 15.42h-2.41c-.55 0-.99-.44-.99-.99v-5.15c0-.55.44-.99.99-.99h2.41c.55 0 .99.44.99.99v5.14c0 .55-.44 1-.99 1zm-.51-6.14h-1.4c-.28 0-.5.22-.5.5l.01 4.14c0 .28.23.5.5.5h1.4c.28 0 .5-.22.5-.5v-4.14c-.01-.27-.23-.5-.51-.5z"/>
												{/if}
											</svg>
											<span>{msg.likes}</span>
										</p>
									{/key}
								{/if}
								<div class="actions">
									{#if studio && !msg.gif}
										<Button
											mini={true}
											customClass={(studioHighlight && (studioHighlight.t == 'chat') && (studioHighlight.r == msg.index)) ? 'onstage' : ''}
											label={(studioHighlight && (studioHighlight.t == 'chat') && (studioHighlight.r == msg.index)) ? 'Remove' : 'Add to stage'}
											on:click={toggleHighlight(msg.index)}
										/>
									{/if}
									<div>
										{#if (!isPrivate && mod) || (msg.sender == $attendee.ref)}
											<button type="button" on:click|stopPropagation={actionDelete(msg)} use:autoblur>
												<svg viewBox="0 0 20 20"><title>Delete</title><path d="M12 14.5c-.28 0-.5-.22-.5-.5v-5.01c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .28-.22.5-.5.5zM10 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM8 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM15.5 5.5c0-.55-.45-1-1-1h-2.49c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1h-2.51c-.55 0-1 .45-1 1v2h1v8c0 .55.45 1 1 1h7c.55 0 1-.45 1-1v-8h1v-2zm-2 9.5c0 .28-.22.5-.5.5h-6c-.28 0-.5-.22-.5-.5v-7.5h7v7.5zm1-8.5h-9v-.49c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5v.49z"/></svg>
											</button>
										{/if}
										{#if isPrivate && mod}
											<button type="button" on:click|stopPropagation={toggleUrgent(msg)} use:autoblur>
												<svg viewBox="0 0 20 20"><title>Urgent</title><path d="M6.65 15.01c0-.65.54-1.19 1.2-1.19.65 0 1.2.54 1.2 1.19 0 .67-.55 1.2-1.2 1.2-.66 0-1.2-.53-1.2-1.2zm2.03-2.45h-1.66l-.15-8.74h1.97l-.16 8.74zM10.94 15.01c0-.65.54-1.19 1.2-1.19.65 0 1.2.54 1.2 1.19 0 .67-.55 1.2-1.2 1.2-.66 0-1.2-.53-1.2-1.2zm2.03-2.45h-1.66l-.16-8.75h1.97l-.15 8.75z"/></svg>
											</button>
										{/if}
										{#if mod}
											<button type="button" on:click|stopPropagation={togglePin(msg)} use:autoblur>
												<svg viewBox="0 0 20 20"><title>Pin</title><path d="M16.44 8.24l-4.47-4.47c-.35-.35-.91-.35-1.25 0-.35.35-.35.91 0 1.25l.58.58-3.83 3.09-.43-.42c-.35-.35-.91-.35-1.25 0-.35.35-.35.91 0 1.25l2.14 2.14-4.06 4.06c-.17.17-.17.45 0 .63.09.09.2.13.31.13s.23-.04.31-.13l4.06-4.06 2.14 2.14c.17.17.4.26.63.26s.45-.09.63-.26c.35-.35.35-.91 0-1.25l-.43-.43 3.09-3.83.58.58c.17.17.4.26.63.26s.45-.09.63-.26c.34-.35.34-.92-.01-1.26z"/></svg>
											</button>
										{/if}
										<button type="button" on:click|stopPropagation={toggleLike(msg)} use:autoblur>
											<svg viewBox="0 0 20 20"><title>Like</title><path d="M8.26 14.73c.56.48 1.28.48 1.31.48h4.55c.7-.04 1.24-.62 1.24-1.31 0-.11-.01-.21-.04-.31.48-.2.8-.67.8-1.21 0-.18-.04-.35-.1-.51.39-.23.63-.66.63-1.12 0-.36-.15-.7-.4-.94.18-.23.28-.51.28-.8 0-.34-.13-.67-.38-.92-.26-.26-.61-.41-.98-.41h-2.84l.18-1.18c.02-.15.03-.3.03-.45 0-1.17-.79-2.53-1.81-2.53-.29 0-.58.1-.81.29l-.09.07.15 1.95c.01.08 0 .17-.02.24l-.09.23-1.56 2.89-.05.05v5.49zM6.93 15.5c.3 0 .55-.24.55-.55v-5.7c0-.3-.24-.55-.55-.55h-3.03c-.3 0-.55.24-.55.55v5.7c0 .3.24.55.55.55h3.03z"/></svg>
										</button>
									</div>
								</div>
							</div>
						</div>
					{:else}
						<div class="slate">
							{#if !isPrivate}
								<p><svg viewBox="0 0 32 32"><path d="M17.13 13.22c-.62 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12 0 .61-.49 1.12-1.12 1.12zm-3.23 0c-.62 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12.01.61-.49 1.12-1.12 1.12zm-3.22 0c-.61 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12 0 .61-.49 1.12-1.12 1.12zM3.15 21.92c-1.74 0-3.15-1.46-3.15-3.25v-13.52c0-1.74 1.37-3.15 3.06-3.15h21.78c1.75 0 3.16 1.46 3.16 3.25v13.43c0 1.79-1.41 3.25-3.15 3.25l-5.75.01v6.21l-9.65-6.21-6.3-.02zm6.88-2l7.06 4.55v-4.55l7.75-.01c.58 0 1.15-.62 1.15-1.38v-13.15c.01-.76-.57-1.38-1.28-1.38h-21.51c-.66 0-1.2.58-1.2 1.29v13.26c0 .76.44 1.38 1.29 1.38l6.74-.01z"/></svg></p>
								<p><strong>No chat yet.</strong></p>
							{/if}
							<p>{slateText}</p>
						</div>
					{/each}

				</div>
			</div>
		{/key}

		<div class="send">
			<form on:submit|preventDefault={sendMessage}>
				{#if gif}
					<GifPreview {gif} on:cancel={unsetGif} on:send={sendMessage}/>
				{:else}
					<textarea
						bind:this={ta}
						bind:value={messageText}
						use:autoresize
						placeholder={isPrivate ? "Type your message" : "Say something …"}
						on:keypress={checkMessage}
					></textarea>
					{#if emojis}
						<Emoji on:emoji={addEmoji} bind:showPanel={emojiPanel}/>
					{/if}
				{/if}
				{#if isPrivate || messageText || gif}
					<button type="submit" use:autoblur>
						<svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg>
					</button>
					{#if messageText}
						<span class="counter" class:over={remaining < 0}>{remaining}</span>
					{/if}
				{:else if !isPrivate && ($event.setup.virtual.chatGifs)}
					<Giphy on:chosen={setGif} bind:showPanel={giphyPanel}/>
				{/if}
			</form>
		</div>

	</div>
{/if}
