<script>

	import { onMount, createEventDispatcher } from 'svelte';

	import isURL from 'validator/es/lib/isURL';

	import Field from '../../ui/Field.svelte';
	import Button from '../../ui/Button.svelte';
	import Uploader from '../../ui/Uploader.svelte';
	import Spinner from "../../ui/Spinner.svelte";
	import Switch from "../../ui/fields/Switch.svelte";

	import { postServerData } from '../../lib/prelude.js';
	import { event, attendee, displayInEventTz, socialMediaServiceOpts, bigError } from '../../lib/stores.js';
	import { now, fromUTC } from '../../lib/dt.js';

	const dispatch = createEventDispatcher();

	// console.log('attendee',$attendee);
	// console.log('event',$event);

	let workingData = JSON.parse(JSON.stringify($attendee));

	if (workingData.displayInBrowserTz == undefined) workingData.displayInBrowserTz = !$displayInEventTz;
	if (workingData.blockVideo == undefined) workingData.blockVideo = false;

	let mounted = false;
	let uploading = false;
	let dataOK = true;
	let sending = false;
	let tzDiff = true;

	let errors = {};

	function handleUploading() {
		uploading = true;
	}

	function handleUploaded(e) {
		if (e && e.detail && e.detail.image && e.detail.image.filename) {
			workingData.avatar = e.detail.image;
		}
		uploading = false;
		checkOK();
	}

	function checkOK() {
		let ok = true;

		if (!workingData.f) ok = false;
		if (!workingData.l) ok = false;

		if (workingData.website && !isURL(workingData.website, { protocols: ['http','https'] })) {
			errors.website = 'Please enter a valid website URL.';
			ok = false;
		} else {
			errors.website = null;
		}

		for (const s of $socialMediaServiceOpts) {
			if (workingData[s.name] && !isURL(workingData[s.name], { protocols: ['http','https'], host_whitelist: s.domains })) {
				errors[s.name] = 'Please enter a valid ' + s.name + ' URL.';
				ok = false;
			} else {
				errors[s.name] = null
			}
		}

		dataOK = ok;
	}

	function getPlaceholder(sm) {
		if (sm == 'Twitter') sm = 'X (Twitter)';
		for (const s of $socialMediaServiceOpts) {
			if (s.name == sm) {
				return s.placeholder;
			}
		}
		return '';
	}

	function getIcon(sm) {
		if (sm == 'Twitter') sm = 'X (Twitter)';
		for (const s of $socialMediaServiceOpts) {
			if (s.name == sm) {
				return s.icon;
			}
		}
		return '';
	}

	function getIconColor(sm) {
		if (sm == 'Twitter') sm = 'X (Twitter)';
		for (const s of $socialMediaServiceOpts) {
			if (s.name == sm) {
				return s.color;
			}
		}
		return '';
	}

	async function updateProfile() {

		sending = true;

		if (workingData.website) {
			if (!/^(?:f|ht)tps?\:\/\//.test(workingData.website)) {
				workingData.website = "http://" + workingData.website;
			}
		}

		for (const s of $socialMediaServiceOpts) {
			if (workingData[s.name]) {
				if (!/^(?:f|ht)tps?\:\/\//.test(workingData[s.name])) {
					workingData[s.name] = "https://" + workingData[s.name];
				}
			}
		}

		const profileJson = JSON.stringify(workingData);

		const profileData = await postServerData('virtual/profile', {
			profile: profileJson
		});

		if (!$bigError) {
			$attendee = profileData.attendee;
			$displayInEventTz = !workingData.displayInBrowserTz;
			dispatch('saved');
		}

	}

	// function setTZ(f) {
	// 	if (mounted) {
	// 		$displayInEventTz = !f;
	// 	}
	// }

	onMount(() => {

		let n = now();
		let localOffset = fromUTC(n).utcOffset()
		let eventOffset = fromUTC(n, $event.setup.timeZone).utcOffset();

		tzDiff = (localOffset == eventOffset) ? false : true;

		mounted = true;

	});

	$: if (workingData) {
		checkOK();
	}

	// $: setTZ(workingData.profile.displayInBrowserTz);

</script>

<style>
	.avatar {
		position: relative;
		display: flex;
		justify-content: flex-start;
		gap: 2rem;
		align-items: center;
		margin-bottom: 2rem;
		cursor: pointer;
	}
	.avatar img,
	.avatar abbr {
		display: block;
		width: 4rem;
		height: 4rem;
		position: relative;
		border-radius: 100%;
		flex: 0 0 auto;
	}
	.avatar abbr {
		display: grid;
		place-content: center;
		border-radius: 100px;
		background: var(--textColor);
		color: var(--panelColor);
		line-height: 0;
		text-decoration: none;
		border: 0;
		font-size: 1.6rem;
		font-weight: 200;
		user-select: none;
	}
	.avatar span {
		display: block;
		font-size: 0.6875rem;
		color: var(--blend-80);
		line-height: 1.4;
	}
	.avatar .uploading {
		position: absolute;
		top: 0;
		left: 0;
		width: 4rem;
		height: 4rem;
		display: grid;
		place-content: center;
		background: var(--panelColor);
		border-radius: 100%;
		box-shadow: 0 0 0 1px var(--blend-20);
	}
	/*.avatar .uploading:before {
		content: '';
		position: absolute;
		inset: 0;
		opacity: 0.8;
		background: var(--panelColor);
		border-radius: 100%;
	}*/

	.pair {
		display: flex;
		gap: 1rem;
	}
	.pair > :global(div.field) {
		flex: 1 0 auto;
		margin: 0;
	}

	@media (max-width: 600px) {
		.pair {
			flex-direction: column;
		}
	}

	.switch {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		margin: 1.5rem 0;
	}
	.switch label {
		font-size: 0.8rem;
	}
	.switch + .switch {
		margin-top: -0.5rem;
	}

	.sm {
		position: relative;
	}
	.sm :global(.inputwrapper input) {
		padding-left: 2.5rem;
	}
	.sm :global(.inputwrapper .icon svg) {
		position: absolute;
		width: 1rem;
		height: 1rem;
		left: 0.8rem;
		top: 2.2rem;
	}
	.sm :global(.inputwrapper .icon path) {
		fill: var(--icon);
	}

</style>

<div class="overlay-title">
	<h2>Update your profile</h2>
	{#if $attendee.type != 'login'}
		<p>Complete your profile to let people know more about you during the event.</p>
	{/if}
</div>
<div class="overlay-main">
	<form id="overlay-profile" on:submit|preventDefault={updateProfile}>
		<p class="avatar">
			{#if workingData.avatar && workingData.avatar.filename}
				<img src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{workingData.avatar.filename}" alt=""/>
			{:else}
				<abbr title="{workingData.f} {workingData.l}">{workingData.f.charAt(0).toUpperCase()}{workingData.l.charAt(0).toUpperCase()}</abbr>
			{/if}
			{#if uploading}
				<div class="uploading">
					<Spinner size="40" speed="1200" color={$event.theme.colors[$event.theme.virtual['accentColor']]} thickness="1" gap="30" />
				</div>
			{:else}
				<span>Click to choose a new image<br/>(JPEG, PNG)</span>
			{/if}
			<Uploader on:uploading={handleUploading} on:uploaded={handleUploaded}/>
		</p>
		<div class="pair">
			<Field
				bind:value={workingData.f}
				component="TextField"
				label="First name"
			/>
			<Field
				bind:value={workingData.l}
				component="TextField"
				label="Last name"
			/>
		</div>
		{#if $attendee.type != 'login'}
			<Field
				bind:value={workingData.j}
				component="TextField"
				label="Job title"
			/>
			<Field
				bind:value={workingData.o}
				component="TextField"
				label="Organisation"
			/>
			<Field
				bind:value={workingData.headline}
				component="TextField"
				label="Headline"
				placeholder="Global HR leader with a focus on policy development"
				maxlength={100}
			/>
			<Field
				bind:value={workingData.bio}
				component="TextArea"
				label="Biography"
				placeholder="With a background in organizational psychology, I’ve worked in human resources for a number of corporates and non-profit organisations…"
				maxlength={500}
			/>
			<Field
				bind:value={workingData.website}
				component="TextField"
				label="Website"
				err={errors.website}
			/>
			{#if $event.setup.virtual.socialMedia}
				{#each $event.setup.virtual.socialMedia as sm (sm.ref)}
					<div class="sm" style="--icon:{getIconColor(sm.service)}">
						<Field
							bind:value={workingData[sm.service]}
							component="TextField"
							label={sm.service}
							err={errors[sm.service]}
							placeholder={getPlaceholder(sm.service)}
						>
							<span class="icon">{@html getIcon(sm.service)}</span>
						</Field>
					</div>
				{/each}
			{/if}
		{/if}
		{#if tzDiff}
			<p class="switch">
				<Switch ref="tz" bind:value={workingData.displayInBrowserTz}/>
				<label for="tz">Use my local (browser) timezone</label>
			</p>
		{/if}
		{#if $attendee.type != 'login'}
			{#if $event.setup.virtual.textChat && $event.setup.virtual.videoChat}
				<p class="switch">
					<Switch ref="blockVideo" bind:value={workingData.blockVideo}/>
					<label for="blockVideo">Block video call requests</label>
				</p>
			{/if}
		{/if}
	</form>
</div>
<div class="overlay-actions">
	<Button label="Save" wide={true} type="submit" form="overlay-profile" disabled={!dataOK || sending} />
</div>