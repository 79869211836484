<script>
	
	import { fly } from 'svelte/transition';

	import { session } from '../../../lib/stores.js';
	import { autoblur } from '../../../lib/autoblur.js';

	export let subtab;
	export let spots = {};

	function clearSpot() {
		spots[subtab] = false;
	}

	$: if (subtab) {
		clearSpot();
	}

</script>

<style>
	ul {
		flex: 0 0 auto;
		display: flex;
		justify-content: space-around;
		margin: 0;
		z-index: 1000;
		flex: 0 0 auto;
		background: var(--panelColor);
		padding: 0.25rem 0.75rem 0.75rem 0.75rem;
		box-shadow: 0 5px 6px var(--blend-05);
	}

	li {
		display: block;
		margin: 0;
		padding: 0 0.1rem;
		color: var(--textColor);
		text-align: center;
	}

	button {
		line-height: 1;
		padding: 0.4rem 0.7rem;
		margin: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		text-align: center;
		width: 100%;
		cursor: pointer;
		outline: none;
		border-radius: 0.25rem;
		transition: background-color 0.4s ease;
	}

	li.current button {
		color: var(--accentBlend-120);
		background: var(--accentBlend-10);
	}

	button:hover,
	button:active {
		background: var(--blend-10);
	}

	button span {
		display: inline-block;
		font-size: 0.6875rem;
		position: relative;
	}

	button span:after {
		content: '';
		width: 0.3rem;
		height: 0.3rem;
		background: var(--red);
		border-radius: 100%;
		opacity: 0;
		display: inline-block;
		position: absolute;
		/*top: 0.05rem;
		right: -0.5rem;*/
		top: -0.2rem;
		right: -0.4rem;
	}

	button.spot span:after {
		opacity: 1;
		transition: opacity 0.2s ease;
		transition-delay: 0.4s;
	}

</style>

{#if $session}
	<ul in:fly={{ duration: 300, y: -20 }}>
		{#if $session.virtual.interactions.includes('chat')}
			<li class:current={subtab == 'chat'}>
				<button type="button" on:click={() => { subtab = 'chat' }} use:autoblur class:spot={spots.chat}>
					<span>Session chat</span>
				</button>
			</li>
		{/if}
		{#if $session.virtual.interactions.includes('qa')}
			<li class:current={subtab == 'qa'}>
				<button type="button" on:click={() => { subtab = 'qa' }} use:autoblur class:spot={spots.qa}>
					<span>Q&amp;A</span>
				</button>
			</li>
		{/if}
		{#if $session.virtual.interactions.includes('polling')}
			<li class:current={subtab == 'poll'}>
				<button type="button" on:click={() => { subtab = 'poll' }} use:autoblur class:spot={spots.poll}>
					<span>Polls</span>
				</button>
			</li>
		{/if}
	</ul>
{/if}
