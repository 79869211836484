<script>

	import { onMount, onDestroy, getContext } from 'svelte';
	import { fly } from "svelte/transition";

	import Nav from './RightPanel/Nav.svelte';
	import Graphics from './RightPanel/Graphics.svelte';
	import Backstage from './RightPanel/Backstage.svelte';
	import Interactions from './RightPanel/Interactions.svelte';

	import { event, rightDocked, syncClient, session } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';

	const host = getContext('host');

	let mounted = false;
	let tooltip = false;
	let tab = host ? 'graphics' : 'backstage';
	let spots = {};

	let backstageTracker;
	let interactionsTracker;

	let subtab;

	onMount(() => {
		mounted = true;
	});

	function setSpot(source) {
		console.log(source, tab, subtab);
		if (source == 'backstage') {
			if (tab != source) {
				spots[source] = true;
			}
		} else {
			if (tab == 'interactions') {
				if (subtab != source) {
					spots[source] = true;
				}
			} else {
				spots.interactions = true;
				spots[source] = true;
			}
		}
	}

	function clearSpot() {
		if (tab == 'backstage') {
			spots[tab] = false;
		} else {
			spots.interactions = false;
		}
	}

	async function openTrackers() {

		if (backstageTracker) backstageTracker.close();
		if (interactionsTracker) interactionsTracker.close();

		spots = {};

		try {
			interactionsTracker = await $syncClient.list($session.syncChannel);
		} catch (e) {
			console.log("Sync error", e, $session.syncChannel);
		}
		
		if (interactionsTracker) {

			interactionsTracker.on('itemAdded', function(i) {
				if (i.item.data.type == 'qa') {
					if (i.item.data.approved || mod) {
						setSpot(i.item.data.type);
					}
				} else if (i.item.data.type) {
					setSpot(i.item.data.type);
				}
			});

			interactionsTracker.on('itemUpdated', function(i) {
				if (i.item.data.type == 'qa') {
					if (i.item.data.approved && !mod) {
						setSpot(i.item.data.type);
					}
				}
			});
			
		}

		try {
			backstageTracker = await $syncClient.list($session.privateChannel);
		} catch (e) {
			console.log("Sync error", e, $session.privateChannel);
		}
		
		if (backstageTracker) {

			backstageTracker.on('itemAdded', function(i) {
				if (i.item.data.type == 'chat') {
					setSpot('backstage');
				}
			});

			backstageTracker.on('itemUpdated', function(i) {
				if ((i.item.data.urgent) && (!i.previousItemData.urgent)) {
					tab = 'backstage';
					$rightDocked = false;
				}
			});
			
		}

	}

	onDestroy(() => {
		// console.log("closing session channel (tracker)");
		if (interactionsTracker) interactionsTracker.close();
	});

	$: if ($session) {
		openTrackers();
	}

	$: if (tab) {
		clearSpot();
	}

</script>

<style>
	.panel {
		position: relative;
		grid-area: r;
		box-sizing: border-box;
		transition: margin-right 0.4s cubic-bezier(.24,.06,.23,.9);
		width: calc((100vw - 2rem) * 0.22);
		min-height: 400px;
	}
	.panel > div {
		position: absolute;
		inset: 0;
		border-radius: 4px;
		box-shadow: 0 2px 10px -2px var(--shadow);
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.panel > div > div {
		flex: 1 0 auto;
		position: relative;
	}
	.panel > div:before {
		content: '';
		/*background: var(--blend-10, #ddd);*/
		background: var(--panelColor, #fff);
		position: absolute;
		inset: 0;
		border-radius: 4px;
		z-index: 0;
	}
	.panel.docked {
		margin-right: calc(0px - ((100vw - 2rem) * 0.22));
		transform: translateX(1rem);
	}
	button {
		position: absolute;
		top: 50%;
		left: -0.8rem;
		width: 0.8rem;
		height: 2.5rem;
		transform: translateY(-50%);
		color: var(--captionColor, #fff);
		background: transparent;
		border: 0;
		padding: 0;
		transition: width 0.2s ease, left 0.2s ease;
	}
	button:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		background: var(--captionColor, #fff);
		opacity: 0.2;
	}
	button svg {
		position: absolute;
		top: 50%;
		right: 0.2rem;
		width: 0.35rem;
		transform: translateY(-50%) rotate(0);
		transition: right 0.2s ease;
	}
	button:hover,
	button:active,
	button:focus {
		outline: 0;
		color: var(--bgColor, #333);
		left: -1rem;
		width: 1rem;
	}
	button:hover:before,
	button:active:before,
	button:focus:before {
		opacity: 0.6;
	}
	button:hover svg,
	button:active svg,
	button:focus svg {
		right: 0.3rem;
	}
	.panel.docked button svg {
		transform: translateY(-50%) rotate(-180deg);
	}

	/*@media (max-width: 940px) {*/
	@media (max-width: 1100px) {
		button {
			display: none;
		}
		.panel,
		.panel > div,
		.panel.docked,
		.panel.docked > div {
			width: auto;
			transform: none;
		}
	}
	
	.tooltip {
		position: absolute;
		top: 50%;
		left: -1.8rem;
		transform: translate(-100%, -50%);
		border-radius: 4px;
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.4rem 0.8rem;
		line-height: 1;
		font-size: 0.6875rem;
		font-weight: 600;
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: 1000;
	}
	.tooltip:before {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translateY(-50%);
		background: var(--panelColor);
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}
	.panel :global(.slate) {
		padding: 1rem;
	}
	.panel :global(.slate p) {
		margin-top: 0.5rem;
		font-size: 0.8rem;
		max-width: 14rem;
		line-height: 1.4;
	}
	.panel :global(.slate svg) {
		width: 2rem;
		height: 2rem;
	}
	.panel :global(.slate strong) {
		font-size: 1rem;
	}
	/*@media (min-width: 601px) and (max-width: 1100px) {
		.panel.solo {
			transform: translateX(calc(-50% - 1rem));
		}
	}*/
</style>

{#if mounted && $session}
	<div class="panel" class:docked={$rightDocked} in:fly|local={{ duration: 800, x: 20, y: 20 }}>
		<div>
			<Nav bind:tab {spots}/>
			<div>
				{#if tab == 'graphics'}
					<Graphics/>
				{:else if tab == 'backstage'}
					<Backstage/>
				{:else if tab == 'interactions'}
					<Interactions bind:spots bind:subtab/>
				{/if}
			</div>
		</div>
		<button
			on:click={() => { $rightDocked = !$rightDocked }}
			on:mouseover={() => { tooltip = true }}
			on:mouseout={() => { tooltip = false }}
			on:focus={() => { tooltip = true }}
			on:blur={() => { tooltip = false }}
			use:autoblur
		>
			<svg viewBox="0 0 16.7 29.5"><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"/></svg>
		</button>
		{#if tooltip && $rightDocked}
			<span class="tooltip" in:fly|local={{ duration: 400, delay: 600, x: 5 }}>Expand side panel</span>
		{:else if tooltip}
			<span class="tooltip" in:fly|local={{ duration: 400, delay: 600, x: 5 }}>Collapse side panel</span>
		{/if}
	</div>
{/if}