<script>

	import { onMount, onDestroy } from 'svelte';
	import { fly } from "svelte/transition";

	import Button from '../ui/Button.svelte';

	import {	session, epoch, leftDocked, rightDocked, agenda, agendaTrack } from '../lib/stores.js';
	import {	showDate, isToday } from '../lib/dt.js';

	export let sessions;
	export let isCurrent = false;
	export let stageInteractions = true;

	let sessionJson;

	let i = 0;
	let shown = null;
	let rd = $rightDocked;

	function isVirtual(s) {
		return (s && s.virtual && s.virtual.isVirtual);
	}

	function setShown() {
		let json = JSON.stringify(sessions);
		if (sessionJson != json) {
			let newShown = sessions[0];
			if ($agenda.tracks.length > 1) {
				for (const [si, s] of sessions.entries()) {
					if (s.track == $agendaTrack) {
						newShown = s;
						i = si;
						break;
					}
				}
			}
			shown = newShown;
			sessionJson = json;
		}
	}

	function joinSession(s) {
		$session = s;
	}

	function getTrackName(st) {
		for (const t of $agenda.tracks) {
			if (t.ref == st) {
				return t.name;
			}
		}
	}

	function prevCard() {
		if (i == 0) {
			i = sessions.length - 1;
		} else {
			i = i - 1;
		}
		shown = sessions[i];
	}

	function nextCard() {
		if (i == (sessions.length - 1)) {
			i = 0;
		} else {
			i = i + 1;
		}
		shown = sessions[i];
	}

	function setRightDocked(d) {
		$rightDocked = d;
	}

	onMount(() => {
		stageInteractions = false;
	});

	onDestroy(() => {
		$rightDocked = rd;
		// console.log('trailer $rightDocked reset', rd);
	});

	$: if (sessions) {
		setShown();
	}

	$: setRightDocked($leftDocked);

</script>

<style>
	.outer {
		grid-area: c;
		position: relative;
	}

	.inner {
		position: relative;
		flex: 1 1 auto;
		border-radius: 4px;
		box-shadow: 0 2px 10px var(--shadow);
		margin: 0 auto;
		max-width: calc((100vh - 16rem) * 2.1);
		max-width: calc((100dvh - 16rem) * 2.1);
		overflow: hidden;
		z-index: 0;
	}

	@media (max-width: 1100px) and (min-height: 500px) {
		.inner {
			max-width: calc(100vh - 18rem);
			max-width: calc(100dvh - 18rem);
		}
	}

	@media (max-width: 1100px) and (max-height: 500px) {
		.inner {
			max-width: calc((100vh - 10rem) * 2.1);
			max-width: calc((100dvh - 10rem) * 2.1);
		}
	}

	.inner:before {
		content: '';
		display: block;
		padding-top: 56.25%;
		/*background: var(--textColor);*/
		background: linear-gradient(to bottom, var(--textColor) 0%, var(--blend-120) 100%);
	}

	.card {
		position: absolute;
		inset: 2rem;
	}

	.panel {
		grid-area: r;
		width: calc((100vw - 2rem) * 0.22);
		transition: margin-right 0.4s cubic-bezier(.24,.06,.23,.9);
	}

	.panel.docked {
		margin-right: calc(0px - ((100vw - 2rem) * 0.22));
		transform: translateX(1rem);
	}

	@media (max-width: 1100px) {
		.panel {
			display: none;
		}
	}

	.badge {
		background: var(--panelColor);
		text-transform: uppercase;
		line-height: 0.8rem;
		padding: 0.2rem 0.6rem;
		border-radius: 3px;
		font-size: 0.8rem;
		font-weight: 600;
		position: absolute;
		top: 0;
		left: 0;
		box-shadow: 0 1px 4px var(--shadow);
	}

	.badge.current {
		background: var(--red);
		color: var(--panelColor);
	}

	.details {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
	}

	.details > div {
		transform-origin: bottom left;
		transition: transform 0.4s cubic-bezier(.24,.06,.23,.9);
	}

	@media (min-width: 1100px) {
		:global(.leftDocked) .details > div {
			transform: scale(1.4);
			width: calc(100% / 1.4);
		}
	}

	.session {
		color: var(--panelColor);
		font-size: 1.2rem;
		font-weight: 600;
	}

	.date {
		color: var(--panelColor);
		font-size: 0.6875rem;
		margin: 0.25rem 0;
	}

	.join {
		margin-top: 0.8rem;
	}

	.track {
		font-size: 0.6875rem;
		color: var(--panelColor);
		font-weight: 500;
		margin: 0.25rem 0;
	}

	.arrows {
		position: absolute;
		right: 0;
		bottom: 0;
		display: inline-flex;
		gap: 0.6rem;
	}

	.arrows button {
		position: relative;
		border: 1px solid var(--panelColor);
		background: transparent;
		border-radius: 100px;
		color: var(--panelColor);
		font-size: 0.8rem;
		padding: 0;
		width: 1.5rem;
		height: 1.5rem;
		transition: transform 0.2s ease;
		cursor: pointer;
	}
	.arrows button svg {
		position: absolute;
		inset: 0;
	}
	.arrows button.forward svg {
		transform: rotate(180deg);
	}
	.arrows button:hover {
		transform: scale(1.1);
	}

</style>

<div class="outer">
	<div class="inner" in:fly={{ y: 20, duration: 400 }}>
		<div class="card">

			{#if isCurrent}
				<p class="badge current">On now</p>
			{:else}
				<p class="badge">Coming up</p>
			{/if}

			{#if shown}
				{#key shown}
					<div class="details" in:fly|local={{ y: 20, duration: 400, delay: 200 }} out:fly|local={{ y: 20, duration: 200 }}>
						<div>
							{#if ($agenda.tracks.length > 1) && shown.track}
								<p class="track">{getTrackName(shown.track)}</p>
							{/if}
							<p class="session">{shown.name}</p>
							{#if isToday(shown.starts, $epoch)}
								<p class="date">{showDate(shown.starts, 'HH:mm')}–{showDate(shown.ends, 'HH:mm')}</p>
							{:else}
								<p class="date">{showDate(shown.starts)}–{showDate(shown.ends, 'HH:mm')}</p>
							{/if}
						</div>
						{#if isToday(shown.starts, $epoch) && isVirtual(shown)}
							<p class="join"><Button mini={true} wide={false} grow={false} label="Join session" on:click={joinSession(shown)}/></p>
						{/if}
					</div>
				{/key}
			{/if}

			{#if sessions.length > 1}
				<div class="arrows">
					<button type="button" class="back" on:click={prevCard}>
						<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
					</button>
					<button type="button" class="forward" on:click={nextCard}>
						<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
					</button>
				</div>
			{/if}

		</div>
	</div>
</div>

<div class="panel" class:docked={$rightDocked}></div>