<script>

	import { createEventDispatcher } from 'svelte';

	import Avatar from "../../../ui/Avatar.svelte";
	import Menu from "../../../ui/Menu.svelte";
	import Button from "../../../ui/Button.svelte";

	import { attendee, displayInEventTz, attendeeLikes, attendees, muted, shadowbans, session } from '../../../lib/stores.js';
	import { text_to_html } from '../../../lib/html.js';
	import { showDate } from '../../../lib/dt.js';
	import { postServerData } from '../../../lib/prelude.js';
	import { autoblur } from '../../../lib/autoblur.js';

	const dispatch = createEventDispatcher();

	export let q;
	export let selectedMsg;
	export let questions;
	export let mod;
	export let studio = false;
	export let studioHighlight = null;

	export const closeMenu = () => { avatar.closeMenu() };

	let avatar;
	let avatarMenu = false;

	async function toggleLike() {
		// Update immediately; Sync revision will update again...
		if (q.liked) {
			q.likes--;
			q.liked = false;
		} else {
			q.likes++;
			q.liked = true;
		}
		selectedMsg = null;
		const d = new Date;
		q.revision = q.index + '/' + d.getTime();
		questions = questions;
		// console.log(reply);
		await postServerData('virtual/likes', {
			channelRef: $session.syncChannel,
			messageIndex: q.index
		});
	}

	async function actionDelete() {
		selectedMsg = null;
		await postServerData('virtual/qa/delete', {
			sessionRef: $session.ref,
			index: q.index
		});
	}

	async function actionAnswered() {
		selectedMsg = null;
		await postServerData('virtual/qa/answered', {
			sessionRef: $session.ref,
			index: q.index
		});
	}

	async function actionApproved() {
		selectedMsg = null;
		await postServerData('virtual/qa/approved', {
			sessionRef: $session.ref,
			index: q.index
		});
	}

	function handleClick() {
		selectedMsg = (selectedMsg == q.index) ? null : q.index;
	}

	function openMenu() {
		avatarMenu = true;
		dispatch('openMenu', q.index);
	}

	function toggleHighlight() {
		if (studioHighlight && (studioHighlight.t == 'qa') && (studioHighlight.r == q.index)) {
			dispatch('highlight', null);
		} else {
			dispatch('highlight', { t: 'qa', r: q.index });
		}
	}

</script>

<style>
	div {
		position: relative;
	}
	.msg {
		position: relative;
		background: var(--panelColor, #fff);
		border-radius: 4px;
		padding: 0.4rem 0.4rem 0.5rem 2.4rem;
		word-wrap: break-word;
	}
	/*.msg + .msg {
		margin-top: 0.4rem;
	}*/
	.msg:hover {
		background: var(--blend-05);
	}
	.msg :global(.avatar) {
		position: absolute;
		top: 0.4rem;
		left: 0.4rem;
	}
	.msg :global(p) {
		font-size: 0.6875rem;
		line-height: 1.4;
	}

	.person {
		margin-bottom: 0.2rem;
	}
	.person strong {
		font-weight: 700;
	}
	.person span {
		display: inline-block;
		margin-left: 0.3rem;
		opacity: 0.6;
		font-size: 0.625rem;;
	}

	.likes {
		display: flex;
		margin-top: 0.2rem;
		line-height: 1;
		cursor: pointer;
		align-items: center;
		justify-content: flex-start;
		gap: 0.2em;
		margin-bottom: -0.2em;
	}
	.likes span {
		vertical-align: middle;
		font-size: 0.625rem;
		font-weight: 600;
	}
	.likes svg {
		display: inline-block;
		width: 1.6em;
		height: 1.6em;
		vertical-align: middle;
	}

	.likes:hover {
		color: var(--accentColor);
	}

	.msg .actions {
		display: none;
		position: absolute;
		top: -0.3rem;
		right: -0.2rem;
	}

	.msg:hover .actions,
	.msg.selected .actions {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
	}

	.msg.studio:hover :global(.avatar),
	.msg.studio:hover .person,
	.msg.studio:hover .message,
	.msg.studio:hover .likes,
	.msg.studio.selected :global(.avatar),
	.msg.studio.selected .person,
	.msg.studio.selected .message,
	.msg.studio.selected .likes {
		opacity: 0.6;
	}

	.msg.studio .actions {
		top: 50%;
		right: auto;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
	}

	.msg .actions > div {
		background: var(--panelColor);
		border-radius: 3px;
		height: 1.2rem;
		padding: 0.1rem 0.4rem;
		box-shadow: 0 1px 4px var(--shadow), 0 0 0 1px var(--blend-05);
		display: flex;
		gap: 0.5rem;
	}

	.msg.studio .actions > div {
		border-radius: 4px;
		box-shadow: 0 1px 4px var(--shadow);
	}

	.msg .actions button {
		position: relative;
		box-sizing: border-box;
		border: none;
		background: transparent;
		color: var(--textColor);
		width: 1.2rem;
		height: 1.2rem;
	}

	.msg .actions button:hover {
		color: var(--accentColor);
		cursor: pointer;
	}

	.msg .actions button svg {
		position: absolute;
		/*inset: 0;*/
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.msg .actions :global(.wrap) {
		flex: 0 0 auto;
	}

	.msg .actions :global(.wrap button) {
		background: var(--panelColor);
		color: var(--textColor);
		border: none;
		box-shadow: 0 1px 4px var(--shadow);
		height: 1.4rem;
		padding-top: 0;
		padding-bottom: 0;
		min-width: 7rem;
	}

	.msg .actions :global(.wrap button:hover),
	.msg .actions :global(.wrap button:active),
	.msg .actions :global(.wrap button:focus) {
		background: var(--blend-10);
	}

	.msg .actions :global(.wrap button.onstage) {
		background: var(--accentColor);
		color: var(--panelColor);
	}

	.msg .actions :global(.wrap button.onstage:hover),
	.msg .actions :global(.wrap button.onstage:active),
	.msg .actions :global(.wrap button.onstage:focus) {
		background: var(--accentBlend-120);
	}

	.msg.openMenu .actions {
		display: none !important;
	}

	.msg.muted {
		display: none !important;
	}

	.msg span.answered,
	.msg span.pending {
		display: inline-block;
		font-size: 0.5rem;
		color: var(--blend-80);
		font-weight: 600;
		box-shadow: 0 0 0 1px var(--blend-80);
		padding: 0 3px;
    	letter-spacing: 0.05em;
    	border-radius: 2px;
		opacity: 1;
		text-transform: uppercase;
		position: relative;
		top: -0.075rem;
	}

	.msg span.pending {
		color: var(--red);
		box-shadow: 0 0 0 1px var(--red);
	}

</style>

<!-- TODO: nest a button instead -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
	class="msg"
	class:selected={selectedMsg == q.index}
	class:muted={($muted.includes(q.sender) || ($shadowbans.includes(q.sender) && (q.sender != $attendee.ref)))}
	class:openMenu={avatarMenu}
	class:studio
	on:click={handleClick}
>
	<Avatar
		identity={$attendees[q.sender]}
		hasMenu={($attendees[q.sender].type != 'login')}
		anon={q.anon}
		on:open={openMenu}
		on:close={closeMenu}
		bind:this={avatar}
	/>
	<p class="person">
		{#if q.anon}
			<strong>Anonymous</strong>
		{:else}
			{#if $attendees[q.sender]}
				<strong>{$attendees[q.sender].f} {$attendees[q.sender].l}</strong>
			{/if}
		{/if}
		{#if q.answered}
			<span class="answered">Answered</span>
		{:else if (mod && !q.approved)}
			<span class="pending">Pending</span>
		{/if}
		<span>{showDate(q.sent, 'HH:mm')}</span>
	</p>
	<div class="message">
		{@html q.message}
	</div>
	{#if q.likes}
		{#key q.revision}
			<!-- TODO: nest a button instead -->
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<p class="likes" on:click|stopPropagation={toggleLike}>
				<svg viewBox="0 0 20 20">
					{#if q.liked}
						<path d="M8.26 14.73c.56.48 1.28.48 1.31.48h4.55c.7-.04 1.24-.62 1.24-1.31 0-.11-.01-.21-.04-.31.48-.2.8-.67.8-1.21 0-.18-.04-.35-.1-.51.39-.23.63-.66.63-1.12 0-.36-.15-.7-.4-.94.18-.23.28-.51.28-.8 0-.34-.13-.67-.38-.92-.26-.26-.61-.41-.98-.41h-2.84l.18-1.18c.02-.15.03-.3.03-.45 0-1.17-.79-2.53-1.81-2.53-.29 0-.58.1-.81.29l-.09.07.15 1.95c.01.08 0 .17-.02.24l-.09.23-1.56 2.89-.05.05v5.49zM6.93 15.5c.3 0 .55-.24.55-.55v-5.7c0-.3-.24-.55-.55-.55h-3.03c-.3 0-.55.24-.55.55v5.7c0 .3.24.55.55.55h3.03z"/>
					{:else}
						<path d="M10.1 15.15c-.09 0-.87-.02-1.5-.55l-.18-.15v-4.97c0-.25.07-.51.19-.73l1.36-2.52-.14-2.02.29-.24c.29-.25.67-.38 1.05-.38 1.22 0 2.13 1.47 2.13 2.79 0 .16-.01.33-.04.48l-.08.49h1.98c.46 0 .91.19 1.24.52.32.32.49.74.49 1.18 0 .24-.05.47-.15.68.17.27.26.58.26.89 0 .46-.18.89-.5 1.2l.02.28c0 .55-.26 1.05-.69 1.36v.01c0 .9-.7 1.63-1.59 1.68h-4.14zm-.68-1.19c.33.19.68.19.68.19h4.1c.35-.02.63-.32.63-.69l-.02-.16-.1-.41.39-.17c.25-.11.42-.36.42-.63 0-.09-.02-.18-.05-.27l-.17-.4.37-.22c.21-.12.33-.34.33-.59 0-.18-.08-.36-.21-.49l-.32-.31.28-.35c.09-.12.14-.26.15-.42 0-.17-.07-.34-.2-.47-.14-.14-.33-.22-.53-.22h-3.14l.25-1.64.03-.33c0-.87-.58-1.79-1.13-1.79-.11 0-.21.03-.31.08l.11 1.48c.01.13 0 .25-.03.38-.03.12-.08.24-.14.35l-1.32 2.35c-.04.08-.07.16-.07.25v4.48zM6.44 15.42h-2.41c-.55 0-.99-.44-.99-.99v-5.15c0-.55.44-.99.99-.99h2.41c.55 0 .99.44.99.99v5.14c0 .55-.44 1-.99 1zm-.51-6.14h-1.4c-.28 0-.5.22-.5.5l.01 4.14c0 .28.23.5.5.5h1.4c.28 0 .5-.22.5-.5v-4.14c-.01-.27-.23-.5-.51-.5z"/>
					{/if}
				</svg>
				<span>{q.likes}</span>
			</p>
		{/key}
	{/if}
	<div class="actions">
		{#if studio}
			<Button
				mini={true}
				customClass={(studioHighlight && (studioHighlight.t == 'qa') && (studioHighlight.r == q.index)) ? 'onstage' : ''}
				label={(studioHighlight && (studioHighlight.t == 'qa') && (studioHighlight.r == q.index)) ? 'Remove' : 'Add to stage'}
				on:click={toggleHighlight(q.index)}
			/>
		{/if}
		<div>
			{#if (mod || (q.sender == $attendee.ref))}
				<button type="button" on:click|stopPropagation={actionDelete} use:autoblur>
					<svg viewBox="0 0 20 20"><title>Delete</title><path d="M12 14.5c-.28 0-.5-.22-.5-.5v-5.01c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .28-.22.5-.5.5zM10 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM8 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM15.5 5.5c0-.55-.45-1-1-1h-2.49c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1h-2.51c-.55 0-1 .45-1 1v2h1v8c0 .55.45 1 1 1h7c.55 0 1-.45 1-1v-8h1v-2zm-2 9.5c0 .28-.22.5-.5.5h-6c-.28 0-.5-.22-.5-.5v-7.5h7v7.5zm1-8.5h-9v-.49c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5v.49z"/></svg>
				</button>
			{/if}
			{#if mod && !q.approved}
				<button type="button" on:click|stopPropagation={actionApproved} use:autoblur>
					<svg viewBox="0 0 20 20"><title>Mark as approved</title><path d="M10 2.5c4.14 0 7.5 3.36 7.5 7.5s-3.36 7.5-7.5 7.5c-4.14 0-7.5-3.36-7.5-7.5s3.36-7.5 7.5-7.5zm0 14c3.58 0 6.5-2.92 6.5-6.5s-2.92-6.5-6.5-6.5-6.5 2.92-6.5 6.5 2.92 6.5 6.5 6.5zM8.83 13.31l-3.05-3.06 1.06-1.06 1.99 1.99 4.3-4.29 1.06 1.06z"/></svg>
				</button>
			{:else if mod && !q.answered}
				<button type="button" on:click|stopPropagation={actionAnswered} use:autoblur>
					<svg viewBox="0 0 20 20"><title>Mark as answered</title><path d="M12.28 13.43l-4.82 3.11v-3.11h-2.88c-.87-.01-1.58-.73-1.58-1.63v-6.71c0-.9.71-1.62 1.58-1.62h10.89c.84 0 1.53.71 1.53 1.58v6.76c0 .9-.71 1.62-1.58 1.62h-3.14zm3.08-1.01c.42 0 .64-.31.64-.69v-6.62c0-.35-.27-.64-.6-.64h-10.76c-.35 0-.64.3-.64.68v6.58c0 .38.29.69.58.69h3.88v2.27l3.53-2.27h3.37zM8.73 11.11l-2.09-2.09.71-.7 1.38 1.37 3.92-3.92.71.71z"/></svg>
				</button>
			{/if}
			<button type="button" on:click|stopPropagation={toggleLike} use:autoblur>
				<svg viewBox="0 0 20 20"><title>Like</title><path d="M8.26 14.73c.56.48 1.28.48 1.31.48h4.55c.7-.04 1.24-.62 1.24-1.31 0-.11-.01-.21-.04-.31.48-.2.8-.67.8-1.21 0-.18-.04-.35-.1-.51.39-.23.63-.66.63-1.12 0-.36-.15-.7-.4-.94.18-.23.28-.51.28-.8 0-.34-.13-.67-.38-.92-.26-.26-.61-.41-.98-.41h-2.84l.18-1.18c.02-.15.03-.3.03-.45 0-1.17-.79-2.53-1.81-2.53-.29 0-.58.1-.81.29l-.09.07.15 1.95c.01.08 0 .17-.02.24l-.09.23-1.56 2.89-.05.05v5.49zM6.93 15.5c.3 0 .55-.24.55-.55v-5.7c0-.3-.24-.55-.55-.55h-3.03c-.3 0-.55.24-.55.55v5.7c0 .3.24.55.55.55h3.03z"/></svg>
			</button>
		</div>
	</div>
</div>
