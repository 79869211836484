<script>

	import { onMount, onDestroy, tick, setContext } from 'svelte';
	import { writable } from 'svelte/store';

	import { v4 as uuid } from 'uuid';

	import Video from "./Video.svelte";
	import Interactions from "./Interactions.svelte";

	import { busy, bigError, agenda, session, syncClient, syncChannels, epoch } from '../lib/stores.js';
	import { getServerData } from '../lib/prelude.js';

	export let stageInteractions = true;

	let rated = writable(null);
   setContext('rated', rated);

	let mounted = false;
	let attendeeNotifications;

	let playback;
	let livestreamStatus;
	let token;
	let ll;
	let captions;
	let rtmp;

	let sessionRef;
	let videoKey;

	onMount(async () => {

		// console.log('mounting session', $session.ref);

		await getSessionData(true);
		await tick();
		mounted = true;

		stageInteractions = ($session && $session.virtual && $session.virtual.interactions && $session.virtual.interactions.length) ? true : false;

		try {
			attendeeNotifications = await $syncClient.list($syncChannels.attendee);
			attendeeNotifications.on('itemAdded', syncNotificationAdded);
		} catch (e) {
			console.log("Sync error", e, $syncChannels.attendee);
		}

	});

	onDestroy(() => {

		// console.log('dismounting session', sessionRef);
		if (attendeeNotifications) attendeeNotifications.close();

		if ($session && ($epoch > $session.epochEnds)) {
			$session = null;
		}

	});

	function syncNotificationAdded(message) {
		// console.log("(session) attendee notification", message);
		if (message && message.item && message.item.data && message.item.data.type) {
			if (message.item.data.sessionRef && (message.item.data.sessionRef == $session.ref)) {
				if (message.item.data.type == 'rated') {
					// console.log("Marking session as rated (sync)");
					$rated = true;
				} else if (message.item.data.type == 'poll') {
					// console.log("Setting pollResponses (message.item.data.poll // message.item.data.response)");
					$session.pollResponses[message.item.data.poll] = message.item.data.response;
					$session = $session;
				} else if (message.item.data.type == 'reload') {
					// Signal to reload the video
					console.log('Reloading video...');
					videoKey = uuid();
				}
			}
		}
	}

	async function getSessionData(m) {
		if (m || mounted) {

			$busy = true;

			let playbackId = '';
			let livestream = '';

			// console.log({$session});

			if (($session.virtual.source == 'simulive') && $session.virtual.asset) {
				playbackId = $session.virtual.asset.playback;
			} else if (($session.virtual.source == 'live') && $session.virtual.livestream) {
				livestream = $session.virtual.livestream;
			}

			const sessionData = await getServerData('virtual/session', {
				session: $session.ref,
				playback: playbackId,
				livestream: livestream
			});

			if (!$bigError) {

				sessionRef = $session.ref;
				videoKey = sessionRef;

				$busy = false;

				$session.syncChannel = sessionData.syncChannel;
				$session.controlChannel = sessionData.controlChannel;
				if (sessionData.privateChannel) $session.privateChannel = sessionData.privateChannel;
				// console.log('getSessionData', $session);

				// // for Vime
				// playback = sessionData.playbackUrl;

				playback = sessionData.playback;
				token = sessionData.token;
				livestreamStatus = sessionData.livestreamStatus;
				ll = sessionData.ll;
				captions = sessionData.captions;
				rtmp = sessionData.rtmp;

				$rated = sessionData.rated ? true : false;

				$session.pollResponses = sessionData.pollResponses ? sessionData.pollResponses : {};

			}

		}
	}

	$: if ($agenda) {
		getSessionData();
	}

	$: if ($session && ($session.ref != sessionRef)) {
		getSessionData();
	}

</script>

<style>
	@media (max-height: 599px) {
		:global(body) {
			height: 280vh;
		}
	}
</style>

{#if mounted}
	<Video {playback} {token} {livestreamStatus} {ll} {captions} {rtmp} {videoKey}/>
	{#if stageInteractions}
		<Interactions/>
	{/if}
{/if}
