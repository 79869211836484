<script>

	import { onMount, onDestroy } from 'svelte';

	import { setDriftlessInterval, clearDriftless } from 'driftless';

	export let duration;

	let remaining;
	let countdown;

	let days, hours, totalHours, minutes, totalMinutes, seconds;

	onMount(() => {
		remaining = duration;
		countdown = setDriftlessInterval(() => {
			if (remaining > 0) {
				remaining--;
			} else {
				remaining = 0;
				clearDriftless(countdown);
			}
		}, 1000);
	});

	onDestroy(() => {
		clearDriftless(countdown);
	});

	$: days = String(Math.floor(remaining / 60 / 60 / 24));

	$: hours = String(Math.floor((remaining / 60 / 60) - (days * 24))).padStart(2, '0');
	$: totalHours = String(Math.floor(remaining / 60 / 60)).padStart(2, '0');

	$: minutes = String(Math.floor((remaining - (totalHours * 60 * 60)) / 60)).padStart(2, '0');
	$: totalMinutes = String(Math.floor(remaining / 60)).padStart(2, '0');

	$: seconds = String(Math.floor(remaining - totalMinutes * 60)).padStart(2, '0');

</script>

<style>
	span.timer {
		font-family: system-ui;
		font-variant-numeric: tabular-nums;
	}
</style>

{#if remaining > 0}
	<span class="timer">
		{#if days > 1}{days} days, {:else if days > 0}{days} day, {/if}
		{#if hours > 0}{hours}:{/if}{minutes}:{seconds}
	</span>
{:else}
	<span class="timer">00:00</span>
{/if}
