<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import { crossfade, fly } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import Poll from "./Polls/Poll.svelte";
	import NewPoll from "./Polls/NewPoll.svelte";

	import Button from "../../ui/Button.svelte";

	import {	bigError, attendee, session, syncClient, backstage } from '../../lib/stores.js';
	import { randomIntFromInterval } from '../../lib/utils.js';
	import {	autoblur } from '../../lib/autoblur.js';

	const dispatch = createEventDispatcher();

	const [send, receive] = crossfade({
		duration: 0,
		fallback(node, params) {
			return {
				duration: 300,
				easing: quintOut,
				css: t => `
					transform: scale(${1 - ((1 - t) / 32)});
					opacity: ${t}
				`
			};
		}
	});

	export let studio = false;
	export let studioHighlight = null;

	let mounted = false;
	let createOverlay = false;

	let channel;
	let polls = [];

	onMount(async () => {

		try {

			// console.log("opening session channel (polls)");
			channel = await $syncClient.list($session.syncChannel);

			channel.on('itemAdded', function(i) {
				if (i.item.data.type == 'poll') {
					addPoll(i.item);
				}
			});

			channel.on('itemUpdated', function(i) {
				if (i.item.data.type == 'poll') {
					updatePoll(i.item);
				}
			});

			channel.on('itemRemoved', function(i) {
				if (i.previousItemData.type == 'poll') {
					deletePoll(i.index);
				}
			});

			await getPolls();
			
		} catch (e) {

			console.log("Sync error", e, $session.syncChannel);
			
		}

		mounted = true;

	});

	onDestroy(() => {
		// console.log("closing session channel (polls)");
		if (channel) channel.close();
	});

	async function pollPageHandler(paginator) {
		paginator.items.forEach(function(item) {
			if (item.data.type == 'poll') {
				addPoll(item);
			}
		});
		return paginator.hasNextPage ? paginator.nextPage().then(pollPageHandler) : null;
	};

	async function getPolls() {
		// channel.getItems({
		// 	from: 0,
		// 	order: 'asc',
		// 	pageSize: 100
		// }).then(
		// 	pollPageHandler
		// ).catch(function(error) {
		// 	if (error.message.match('Maximum attempt')) {
		// 		// back off and try again later...
		// 		const smear = 1000 * randomIntFromInterval(5,10);
		// 		setTimeout(() => {
		// 			getPolls();
		// 		}, smear);
		// 	} else {
		// 		console.error('getPolls failed', error);
		// 	}
		// });
		let paginator;
		try {
			paginator = await channel.getItems({
				from: 0,
				order: 'asc',
				pageSize: 100
			});
		} catch (error) {
			if (error.message.match('Maximum attempt')) {
				// back off and try again later...
				const smear = 1000 * randomIntFromInterval(5,10);
				setTimeout(async () => {
					await getPolls();
				}, smear);
			} else {
				console.error('getPolls failed', error);
			}
		}
		if (paginator) await pollPageHandler(paginator);
	}

	function addPoll(item) {
		// console.log('addPoll', item.data);
		let poll = item.data.poll;
		poll.index = item.index;
		polls.unshift(poll);
		polls = polls;
	}

	async function updatePoll(item) {
		// console.log('updatePoll', item.data);
		const idx = item.index;
		for (let [i,r] of polls.entries()) {
			if (r.index == idx) {
				let poll = item.data.poll;
				poll.index = r.index;
				polls[i] = poll;
				break;
			}
		}
		polls = polls;
	}

	function deletePoll(idx) {
		// console.log('deletePoll', idx);
		polls = polls.filter(m => {
			if (m.index != idx) return m;
		});
	}

	// console.log('backstage?', $session.ref, $backstage);

</script>

<style>
	.wrap {
		position: absolute;
		inset:  0;
		display: flex;
		flex-direction: column;
	}
	.wrap .polls {
		flex: 1 1 auto;
		overflow-y: auto;
		padding: 0 1rem 1rem 1rem;
	}
	.wrap .actions {
		flex: 0 0 auto;
		border-top: 2px solid var(--blend-20);
		padding: 1rem 0;
		margin: 0 1rem;
	}
	.polls > div + div {
		margin-top: 3rem;
		border-top: 1px solid var(--blend-40);
	}

	.polls .slate {
		padding-top: 2rem;
	}
</style>

{#if mounted}
	<div class="wrap" in:fly={{ duration: 300, y: 20 }}>
		<div class="polls">
			{#each polls as poll (poll.index)}
				<div
					in:receive|local="{{ key: poll.index }}"
					out:send|local="{{ key: poll.index }}"
					animate:flip="{{ duration: 400 }}"
				>
					<Poll {poll} {studio} {studioHighlight} on:highlight/>
				</div>
			{:else}
				<div class="slate">
					<p><svg viewBox="0 0 32 32"><path d="M24.85 30h-21.7c-1.74 0-3.15-1.46-3.15-3.25v-21.5c0-1.79 1.41-3.25 3.15-3.25h21.78c1.7 0 3.07 1.41 3.07 3.15v21.6c0 1.79-1.41 3.25-3.15 3.25zm-21.56-26c-.71 0-1.29.62-1.29 1.38v21.24c0 .76.58 1.38 1.15 1.38h21.56c.84 0 1.29-.62 1.29-1.38v-21.33c0-.71-.54-1.29-1.2-1.29h-21.51zM8.41 25.3v-17.09c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25v17.09h2.5zM15.5 25.3v-12.29c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25v12.29h2.5zM22.59 25.3v-5.38c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25v5.38h2.5z"/></svg></p>
					<p><strong>No polls yet.</strong></p>
					{#if $attendee.type == 'login'}
						<p>Add a poll to this session and ask your attendees to respond.</p>
					{:else}
						<p>If the session organiser adds a poll, it will appear here.</p>
					{/if}
				</div>
			{/each}
		</div>
		{#if ($attendee.type == 'login') || ($backstage.includes($session.ref))}
			<div class="actions">
				<Button label="Create poll" type="button" ghost={false} wide={true} on:click={() => { createOverlay = true }} />
			</div>
		{/if}
	</div>
{/if}

{#if createOverlay}
	<NewPoll bind:createOverlay/>
{/if}