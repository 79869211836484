<script>

	import SubNav from "./Graphics/SubNav.svelte";
	import Banners from "./Graphics/Banners.svelte";
	import Backgrounds from "./Graphics/Backgrounds.svelte";
	import Overlays from "./Graphics/Overlays.svelte";
	import Clips from "./Graphics/Clips.svelte";

	let subtab = 'banners';

</script>

<style>
	div {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}
</style>

<div>
	<SubNav bind:subtab/>
	{#if subtab == 'banners'}
		<Banners/>
	{:else if subtab == 'backgrounds'}
		<Backgrounds/>
	{:else if subtab == 'overlays'}
		<Overlays/>
	{:else if subtab == 'clips'}
		<Clips/>
	{/if}
</div>