<script>

	import { onMount, onDestroy, getContext, tick, createEventDispatcher } from 'svelte';
	import { fade } from "svelte/transition";

	import { ParticipantEvent } from 'livekit-client';

	const dispatch = createEventDispatcher();

	import { draggable } from '@neodrag/svelte';

	import Avatar from "../../../ui/Avatar.svelte";
	import Button from "../../../ui/Button.svelte";

	import { attendees, attendee } from '../../../lib/stores.js';
	import { autoblur } from '../../../lib/autoblur.js';

	const rtcProvider = getContext('rtcProvider');
	const participants = getContext('participants');
	const participantCount = getContext('participantCount');
	const layout = getContext('layout');
	const room = getContext('room');
	const feeds = getContext('feeds');
	const host = getContext('host');
	const localVideoTrack = getContext('localVideoTrack');
	const localAudioTrack = getContext('localAudioTrack');

	export let participant = {};
	export let offsets = {};
	export let i;
	export let dragging = null;

	let attendeeId = participant.id;

	let over = false;
	let mounted = false;

	let feed = null;
	let local = false;
	let screenShare = false;
	let videoElement;
	let videoTrack = null;
	let audioTrack = null;
	let invisible = true;
	let muted = false;
	let selfMuted = false;

	let elem;
	let transform = { x: 0, y: 0 };
	let handle;
	let dragStartIdx;

	let sid = '';

	onMount(() => {
		mounted = true;
	});

	onDestroy(() => {
		if ($rtcProvider == 'livekit') {
			if (local) {
				if ($room) {
					$room.localParticipant.removeListener(ParticipantEvent.TrackMuted, trackDisabled);
					$room.localParticipant.removeListener(ParticipantEvent.TrackUnmuted, trackEnabled);
					$room.localParticipant.removeListener(ParticipantEvent.LocalTrackPublished, trackInit);
					$room.localParticipant.removeListener(ParticipantEvent.LocalTrackUnpublished, trackUnsubscribed);
				}
			} else if (feed) {
				feed.removeListener(ParticipantEvent.TrackMuted, trackDisabled);
				feed.removeListener(ParticipantEvent.TrackUnmuted, trackEnabled);
				feed.removeListener(ParticipantEvent.TrackSubscribed, trackInit);
				feed.removeListener(ParticipantEvent.TrackUnsubscribed, trackUnsubscribed);
			}
		} else {
			if (feed) {
				feed.removeAllListeners();
			}
			if (videoTrack) {
				videoTrack.detach();
				videoTrack = null;
			}
		}
	});

	const trackInit = (track) => {
		if ($rtcProvider == 'livekit') {
			// console.log('trackInit', attendeeId, local, screenShare, track.kind, track.source, track);
			if (local && (track.kind === 'video') && !screenShare) {
				videoTrack = $localVideoTrack;
				invisible = (!$localVideoTrack || $localVideoTrack.isMuted) ? true : false;
				$localVideoTrack.attach(videoElement);
			} else if (local && (track.kind === 'audio') && !screenShare) {
				audioTrack = $localAudioTrack;
			} else if ((track.kind === "video") && ((screenShare && (track.source == 'screen_share')) || (!screenShare && (track.source == 'camera')))) {
				videoTrack = track;
				invisible = (!videoTrack || videoTrack.isMuted) ? true : false;
				if (videoTrack.isSubscribed) {
					trackSubscribed(videoTrack.track);
				}
			} else if ((track.kind === "audio") && ((screenShare && (track.source == 'screen_share_audio')) || (!screenShare && (track.source == 'microphone')))) {
				audioTrack = track;
			}
		} else {
			if ((track.kind === "video") && ((screenShare && (track.name == 'screen')) || (!screenShare && (track.name != 'screen')))) {
				videoTrack = track;
				invisible = (videoTrack && videoTrack.isEnabled) ? false : true;
				track.attach(videoElement);
			} else if ((track.kind === "audio") && ((screenShare && (track.name == 'screen')) || (!screenShare && (track.name != 'screen')))) {
				audioTrack = track;
			}
		}
	};

	const trackSubscribed = async (track) => {
		// Livekit only
		if ((track.kind === "video") && ((screenShare && (track.source == 'screen_share')) || (!screenShare && (track.source == 'camera')))) {
			// console.log('trackSubscribed', track);
			invisible = track.isMuted ? true : false;
			track.attach(videoElement);
		}
	};

	const trackUnsubscribed = (track) => {
		if ($rtcProvider == 'livekit') {
			if ((track.kind === "video") && ((screenShare && (track.source == 'screen_share')) || (!screenShare && (track.source == 'camera')))) {
				// console.log('trackUnsubscribed', track);
				if (!local) track.detach(videoElement);
			}
		} else {
			if ((track.kind === "video") && ((screenShare && (track.name == 'screen')) || (!screenShare && (track.name != 'screen')))) {
				if (videoTrack) {
					videoTrack.detach();
				}
				videoTrack = null;
				invisible = true;
			} else if ((track.kind === "audio") && ((screenShare && (track.name == 'screen')) || (!screenShare && (track.name != 'screen')))) {
				audioTrack = null;
			}
		}
	};

	const trackEnabled = (track) => {
		if ($rtcProvider == 'livekit') {
			if ((track.kind === "video") && ((screenShare && (track.source == 'screen_share')) || (!screenShare && (track.source == 'camera')))) {
				invisible = false;
			}
		} else {
			if ((track.kind === "video") && ((screenShare && (track.name == 'screen')) || (!screenShare && (track.name != 'screen')))) {
				invisible = false;
			}
		}
		checkMuted();
	};

	const trackDisabled = (track) => {
		if ($rtcProvider == 'livekit') {
			if ((track.kind === "video") && ((screenShare && (track.source == 'screen_share')) || (!screenShare && (track.source == 'camera')))) {
				invisible = true;
			}
		} else {
			if ((track.kind === "video") && ((screenShare && (track.name == 'screen')) || (!screenShare && (track.name != 'screen')))) {
				invisible = true;
			}
		}
		checkMuted();
	};

	async function rtcTracks() {

		await tick();

		// console.log('rtcTracks (left)', participant.id);

		if ($rtcProvider == 'livekit') {

			if (participant.id == $attendee.ref) {
				local = true;
				attendeeId = participant.id;
			} else if (participant.id.substring(2) == $attendee.ref) {
				local = true;
				screenShare = true;
				attendeeId = participant.id.substring(2);
			} else {
				if (participant.id.startsWith('s_')) {
					screenShare = true;
					attendeeId = participant.id.substring(2);
				} else {
					attendeeId = participant.id;
				}
			}

			if (local) {

				$room.localParticipant.on(ParticipantEvent.TrackMuted, trackDisabled);
				$room.localParticipant.on(ParticipantEvent.TrackUnmuted, trackEnabled);

				$room.localParticipant.on(ParticipantEvent.LocalTrackPublished, trackInit);
				$room.localParticipant.on(ParticipantEvent.LocalTrackUnpublished, trackUnsubscribed);

				let publications = Array.from($room.localParticipant.trackPublications.values());

				publications.forEach(t => {
					trackInit(t);
				});

			} else {

				feed = $room.getParticipantByIdentity(attendeeId);

				if (feed) {

					feed.on(ParticipantEvent.TrackMuted, trackDisabled);
					feed.on(ParticipantEvent.TrackUnmuted, trackEnabled);

					feed.on(ParticipantEvent.TrackSubscribed, trackSubscribed);
					feed.on(ParticipantEvent.TrackUnsubscribed, trackUnsubscribed);

					let publications = Array.from(feed.trackPublications.values());

					publications.forEach(t => {
						trackInit(t);
					});

				} else {

					console.log('(Livekit) participant without feed...');

				}

			}

		} else {

			if (participant.id == $attendee.ref) {
				feed = $room.localParticipant;
				local = true;
				attendeeId = participant.id;
			} else if (participant.id.substring(2) == $attendee.ref) {
				feed = $room.localParticipant;
				local = true;
				screenShare = true;
				attendeeId = participant.id.substring(2);
				// console.log('screenShare',attendeeId);
			} else {
				if ($feeds) {
					for (const f of $feeds) {
						if (participant.id == f.identity) {
							feed = f;
							attendeeId = participant.id;
							break;
						} else if (participant.id.substring(2) == f.identity) {
							feed = f;
							screenShare = true;
							attendeeId = participant.id.substring(2);
							break;
						}
					}
				}
			}

			if (feed) {

				feed.on("trackInit", (t) => {
					// console.log('(left) trackInit event', t);
					trackInit(t);
				});

				feed.on("trackUnsubscribed", (t) => {
					// console.log('(left) trackUnsubscribed event', t);
					trackUnsubscribed(t);
				});

				feed.on("trackDisabled", trackDisabled);
				feed.on("trackEnabled", trackEnabled);

				feed.on("trackPublished", (publication) => {
					// console.log('(left) trackPublished event', publication);
					if (publication.track) trackInit(publication.track);
				});

				feed.tracks.forEach(publication => {
					// 07 Mar 2024 -- what happens if publication.isSubscribed is false??
					// console.log(publication);
					if (local || publication.isSubscribed) {
						console.log('(left) init', local, publication.isSubscribed, publication.track);
						if (publication.track) trackInit(publication.track);
					}
				});

			} else {

				console.log("!!! (Twilio) Feed not found", participant.id);

				let found = false;

				if ($room.participants.values()) {
					for (const f of $room.participants.values()) {
						if ((participant.id == f.identity) || (participant.id.substring(2) == f.identity)) {
							found = true;
							break;
						}
					}
				}

				if (found) {
					console.log('!!! reconstructing $feeds from $room');
					$feeds = Array.from($room.participants.values());
				} else {
					console.log('!!! participant appears to be missing from room, removing from list');
					let filtered = $participants.filter(f => f.id != participant.id);
					$participants = filtered;
				}

			}

		}

	}

	function dragStart() {		
		dragging = participant.id;
		over = false;
		dragStartIdx = i;
	}

	function drag({ offsetX, offsetY }) {
		const h = elem.offsetHeight;
		dispatch('consider', {
			id: participant.id,
			i: i,
			y: offsetY,
			s: dragStartIdx,
			h: h
		});
	}

	function dragEnd() {		
		dragging = null;
		for (const [k,v] of Object.entries(offsets)) {
			offsets[k] = 0;
		}
		dispatch('finalise');
	}

	function toggleMute() {
		if ($rtcProvider == 'livekit') {
			if (audioTrack && !audioTrack.isMuted) {
				participant.m = !participant.m;
			}
		} else {
			if (audioTrack && audioTrack.isEnabled) {
				participant.m = !participant.m;
			}
		}
	}

	function toggleSpotlight() {
		if (participant.s) {
			participant.s = false;
		} else {
			if (!(participant.o)) {
				toggleStatus();
			}
			for (const p of $participants) {
				if (p.id != participant.id) {
					p.s = false;
				}
			}
			participant.s = true;
		}
	}

	function toggleStatus() {
		if (participant.o) {
			participant.o = false;
			participant.s = false;
		} else {
			if (($layout == 'side') || ($layout == 'side2') || ($layout == 'cinema') || ($layout == 'inset')) {
				if ($participantCount == 6) {
					$layout = 'gap';
				}
			}
			participant.o = true;
		}
	}

	function checkMuted() {
		let m = false;
		if (audioTrack) {
			if ($rtcProvider == 'livekit') {
				// console.log('checkMuted', {audioTrack});
				if (audioTrack.isMuted) {
					m = true;
					selfMuted = true;
				} else {
					m = false;
					selfMuted = false;
				}
			} else {
				if (audioTrack.isEnabled) {
					m = false;
					selfMuted = false;
				} else {
					m = true;
					selfMuted = true;
				}
			}
		}
		if (participant.m) m = true;
		muted = m;
	}

	function checkSid() {
		if (participant.t != sid) {
			sid = participant.t;
			if (mounted && $room) {
				rtcTracks();
			}
		}
	}

	$: if (mounted && $room && $feeds) {
		rtcTracks();
	}

	// $: if ($room) {
	// 	console.log('room updated', $room);
	// }

	// $: if ($feeds) {
	// 	console.log('feeds updated', $feeds);
	// }

	$: transform = {
		x: 0,
		y: offsets[participant.id]
	}

	$: checkMuted(participant);

	$: checkSid(participant);

</script>

<style>

	.participant {
		position: relative;
		font-size: 0.75rem;
		line-height: 1;
		aspect-ratio: 16/9;
		overflow: hidden;
		border-radius: 4px;
		box-shadow: 0 0 2px var(--shadow);
		width: 70%;
	}

	:global(.list.p0) .participant,
	:global(.list.p1) .participant {
		width: 100%;
	}

	:global(.list.p2) .participant {
		width: 90%;
	}

	:global(.list.p3) .participant {
		width: 80%;
	}

	.participant:not(.dragging) {
		transition: width 0.3s ease, transform 0.2s ease;
	}

	.participant.dragging {
		z-index: 100;
		box-shadow: 0 1px 10px var(--shadow);
	}

	.video {
		position: absolute;
		inset: 0;
		background: var(--blend-80);
		box-shadow: 0 2px 8px -2px var(--shadow);
	}

	.name {
		position: absolute;
		z-index: 60;
		left: 0;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		overflow: hidden;
		text-overflow: ellipsis;
		background: var(--textColor);
		color: var(--panelColor);
		font-size: 0.6875rem;
		padding: 0.2rem 0.3rem;
		white-space: nowrap;
		display: flex;
		gap: 0.1rem;
		align-items: center;
	}

	.host .name {
		cursor: grab;
	}

	.onstage .name {
		background: var(--accentColor);
	}

	.name svg {
		width: 1.2rem;
	}

	.video video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 20;
		object-fit: cover;
	}

	.video video.blur {
		top: -2%;
		left: -2%;
		width: 104%;
		height: 104%;
	}

	.video video.mirror {
		transform: scale(-1,1);
	}

	.video :global(.avatar) {
		z-index: 10;
		top: calc((100% - 0.6rem - 0.6875rem) / 2);
		left: 50%;
		width: 2.4rem;
		height: 2.4rem;
		transform: translate(-50%,-50%);
	}

	.video :global(abbr) {
		font-size: 1rem;
	}

	.actions {
		position: absolute;
		z-index: 70;
		top: 0.4rem;
		right: 0.4rem;
		display: flex;
		gap: 0.2rem;		
	}

	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	.actions button[disabled],
	.actions button[disabled]:hover,
	.actions button[disabled]:active,
	.actions button[disabled]:focus {
		background: var(--panelColor);
		cursor: not-allowed;
	}
	.actions button svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
	.actions button.dots svg {
		width: 0.9rem;
		height: 0.9rem;
	}
	.actions button.muted {
		color: var(--red);
	}
	.actions button.lit {
		background: var(--accentColor);
		color: var(--panelColor);
		border-color: var(--blend-20);
	}
	.actions button.lit:hover,
	.actions button.lit:active,
	.actions button.lit:focus {
		background: var(--accentBlend-80);
	}

	.control {
		position: absolute;
		z-index: 70;
		top: 50%;
		left: 50%;
		max-width: 100%;
		width: max-content;
		box-sizing: border-box;
		padding: 0.4rem;
		transform: translate(-50%,-50%);
	}

	.control :global(button.mini),
	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--panelColor);
		border-color: var(--panelColor);
		color: var(--textColor);
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		box-shadow: 0 2px 10px var(--shadow);
		min-width: 7rem;
	}

	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--blend-10);
		border-color: var(--blend-10);
	}

	.onstage .control :global(button.mini),
	.onstage .control :global(button.mini:hover),
	.onstage .control :global(button.mini:active),
	.onstage .control :global(button.mini:focus) {
		background: var(--accentColor);
		border-color: var(--accentColor);
		color: var(--panelColor);
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.onstage .control :global(button.mini:hover),
	.onstage .control :global(button.mini:active),
	.onstage .control :global(button.mini:focus) {
		background: var(--accentBlend-120);
		border-color: var(--accentBlend-120);
	}

	.participant.invisible video {
		opacity: 0;
	}

</style>

{#if mounted}
	<div
		bind:this={elem}
		class="participant"
		class:onstage={participant.o}
		class:over
		class:invisible
		class:dragging={dragging == participant.id}
		class:host
		on:mouseenter={() => { over = true }}
		on:mouseleave={() => { over = false }}
		on:focus={() => { over = true }}
		on:blur={() => { over = false }}
		use:draggable={{
			axis: 'y',
			handle: handle,
			position: transform,
			onDragStart: dragStart,
			onDrag: drag,
			onDragEnd: dragEnd,
			disabled: !host
		}}
	>

		<div class="video">
			{#key sid}
				<!-- svelte-ignore a11y-media-has-caption -->
				<video id="lpv-{participant.id}" bind:this={videoElement} class:invisible class:mirror={participant.f} class:blur={participant.b} />
			{/key}
			<Avatar identity={$attendees[attendeeId]} hasMenu={false} />
		</div>

		{#if $attendees[attendeeId]}
			<div bind:this={handle} class="name" aria-label="drag handle">
				{#if screenShare}
					<svg viewBox="0 0 20 20"><path d="M3 5c0-.55.42-1 .93-1h12.13c.52 0 .94.45.94 1v7.64c0 .55-.42 1-.93 1h-12.14c-.52 0-.93-.45-.93-1v-7.64zm12.6 7.65c.26 0 .47-.22.47-.5v-6.65c0-.28-.21-.5-.47-.5h-11.2c-.26 0-.47.22-.47.5v6.65c0 .28.21.5.47.5h11.2zM12.98 16h-6c-.28 0-.5-.22-.5-.5s.23-.5.5-.5h6c.28 0 .5.22.5.5s-.22.5-.5.5z"/></svg>
				{:else}
					<svg viewBox="0 0 20 20"><path d="M10 11.64c-2.11 0-3.82-1.71-3.82-3.82s1.71-3.82 3.82-3.82 3.82 1.71 3.82 3.82-1.71 3.82-3.82 3.82zm0-6.64c-1.56 0-2.82 1.27-2.82 2.82 0 1.56 1.27 2.82 2.82 2.82 1.56 0 2.82-1.27 2.82-2.82 0-1.55-1.26-2.82-2.82-2.82zM7.68 13.65h4.4c1.94 0 3.1 1.46 3.49 2.38h1.07c-.37-1.31-1.94-3.38-4.56-3.38h-4.4c-2.62 0-4.18 2.08-4.56 3.38h1.07c.39-.92 1.55-2.38 3.49-2.38z"/></svg>
				{/if}
				<span>{$attendees[attendeeId].f} {$attendees[attendeeId].l}</span>
			</div>
		{/if}

		{#if !dragging && host}
			<div class="actions">
				{#if audioTrack}
					<button
						transition:fade|local={{ duration: 100 }}
						type="button"
						class:muted={muted}
						use:autoblur
						on:click|stopPropagation={toggleMute}
						disabled={selfMuted}
					>
						<svg viewBox="0 0 20 20">
							{#if muted}
								<path d="M15 9.94v-1.61h-1v1.61c0 .66-.19 1.27-.51 1.8l.72.73c.51-.73.79-1.6.79-2.53zM3.8 3.46l-.7.7 3.7 3.7v1.36c0 1.77 1.44 3.2 3.2 3.2.4 0 .78-.08 1.15-.22l.88.88c-.46.22-.97.35-1.51.35h-1.03c-1.93 0-3.49-1.57-3.49-3.49v-1.62h-1v1.61c0 2.47 2.02 4.49 4.49 4.49h.01v1.8h-1.3v1h3.61v-1h-1.31v-1.8h.02c.82 0 1.59-.23 2.24-.61l3.08 3.08.7-.7-12.74-12.73zm6.2 7.96c-1.22 0-2.2-.99-2.2-2.2v-.36l2.53 2.53-.33.03zM9.99 2.79c-1.48 0-2.71 1.01-3.08 2.37l.89.88v-.02l-.01-.04c0-1.22.99-2.2 2.21-2.2 1.09 0 2 .8 2.16 1.86l.04 3.61c0 .34-.1.67-.24.96l.73.74c.32-.49.5-1.09.5-1.72v-3.24c.01-1.77-1.42-3.2-3.2-3.2z"/>
							{:else}
								<path d="M10 12.42c1.77 0 3.2-1.43 3.2-3.2v-3.24c0-1.76-1.43-3.2-3.2-3.2-1.77 0-3.2 1.43-3.2 3.2v3.24c0 1.76 1.43 3.2 3.2 3.2zm2.2-3.18c-.01 1.2-.99 2.19-2.2 2.19-1.22 0-2.2-.99-2.2-2.2v-3.25c0-1.22.99-2.2 2.2-2.2 1.1 0 2 .8 2.16 1.86l.04 3.6zm-2.2-2 0-1.8"/><path d="M14 8.32v1.61c0 1.93-1.57 3.49-3.49 3.49h-1.03c-1.93 0-3.49-1.57-3.49-3.49v-1.61h-1v1.61c0 2.48 2.02 4.5 4.49 4.5h.02v1.8h-1.3v1h3.61v-1h-1.31v-1.8h.02c2.47 0 4.49-2.02 4.49-4.49v-1.62h-1.01z"/>
							{/if}
						</svg>
					</button>
				{/if}
				<button transition:fade|local={{ duration: 100 }} type="button" class:lit={participant.s} use:autoblur on:click|stopPropagation={toggleSpotlight}>
					<svg viewBox="0 0 20 20">
						{#if participant.s}
							<path d="M17.01 14.77l-.04-.06-11.52-11.94c-.19-.2-.51-.2-.71-.01-.2.19-.2.51-.01.71l9.78 10.14c-1.04-.24-2.31-.38-3.68-.38-2.39 0-4.47.42-5.57 1.05l-1.49-9.9c-.04-.28-.29-.46-.56-.42-.28.04-.47.29-.42.56l1.62 10.84.05.16c.35 1.04 3.06 1.85 6.36 1.85 3.53 0 6.4-.93 6.4-2.07 0-.18-.07-.36-.21-.53z"/>
						{:else}
							<path d="M17.01 14.77l-.04-.06-11.52-11.94c-.19-.2-.51-.2-.71-.01-.2.19-.2.51-.01.71l9.78 10.14c-1.04-.24-2.31-.38-3.68-.38-2.39 0-4.47.42-5.57 1.05l-1.49-9.9c-.04-.28-.29-.46-.56-.42-.28.04-.47.29-.42.56l1.62 10.84.06.16c.35 1.04 3.06 1.85 6.36 1.85 3.53 0 6.4-.93 6.4-2.07-.01-.18-.08-.36-.22-.53zm-6.19 1.61c-3.03 0-4.83-.68-5.31-1.07.48-.39 2.29-1.07 5.31-1.07 3.03 0 4.83.68 5.31 1.07-.48.39-2.28 1.07-5.31 1.07z"/>
						{/if}
					</svg>
				</button>
			</div>

			{#if over && host}
				<div class="control" transition:fade|local={{ duration: 100 }}>
					<Button
						mini={true}
						customClass={participant.o ? 'onstage' : ''}
						label={participant.o ? 'Remove' : 'Add to stage'}
						on:click={toggleStatus}
					/>
				</div>
			{/if}

		{/if}
	</div>
{/if}