<script>

	import { onMount } from 'svelte';

	// import Brand from "./Brand.svelte";

	import Avatar from "../../ui/Avatar.svelte";

	import { attendees, attendee, exhibitors, event } from '../../lib/stores.js';

	export let conversation;
	export let alignTop = false;

	let mounted = false;
	let exhibitor;
	let displayParticipants = [];
	let originator;
	let help = false;
	let mask = null;

	onMount(() => {

		help = conversation.help;

		conversation.participants.forEach(p => {
			if ((p != $attendee.ref) && $attendees[p]) {
				displayParticipants.push($attendees[p]);
			}
		});

		if (conversation.exhibitor) {
			originator = conversation.participants[0];
			for (const e of $exhibitors) {
				if (e.ref == conversation.exhibitor) {
					exhibitor = e;
					break;
				}
			}
		} else if (help) {
			originator = conversation.participants[0];
			if ($event.theme.virtual.logo && ($event.theme.virtual.logoStyle == 'tinted')) {
				if ($event.theme.virtual.logo.format == 'svg') {
					const regex = /\.svg\Z/;
					mask = 'https://cdn.attendzen.io/' + $event.accountRef + '/' + $event.theme.virtual.logo.filename.replace(regex, '.png');
				} else {
					const regex = /\.jpe?g\Z/;
					mask = 'https://cdn.attendzen.io/' + $event.accountRef + '/' + $event.theme.virtual.logo.filename.replace(regex, '.png');
				}
			}
		}

		mounted = true;

	});

	// console.log({conversation});

</script>

<style>
	.participants {
		position: relative;
		display: flex;
		gap: 0.5rem;
		line-height: 1.4;
		font-size: 0.8rem;
		align-items: center;
	}

	.participants.alignTop {
		align-items: flex-start;
	}

	.participants :global(.avatar),
	.participants :global(.badge) {
		position: relative;
		top: 0;
		left: 0;
		width: 3.2rem;
		height: 3.2rem;
	}

	.participants :global(.avatar abbr) {
		font-size: 1.28rem;
	}

	.participants.alignTop p {
		padding-top: 0.25rem;
	}

	.participants :global(.badge) {
		padding: 0;
		background: #fff;
		border-radius: 100%;
		box-shadow: 0 0 0 1px var(--blend-10);
	}
	.participants :global(.badge img) {
		position: absolute;
		/*inset: 0.6rem;*/
		top: 0.6rem;
		left: 0.6rem;
		width: calc(100% - 1.2rem);
    	height: calc(100% - 1.2rem);
		object-fit: contain;
		object-position: 50% 50%;
	}
	.participants :global(.minibadge) {
		position: absolute;
		top: 2rem;
		left: 2rem;
		width: 1.4rem;
		height: 1.4rem;
		background: #fff;
		border-radius: 100%;
		z-index: 4;
		box-shadow: 0 1px 6px -2px var(--shadow);
	}
	.participants :global(.minibadge img) {
		position: absolute;
		/*inset: 0.25rem;*/
		top: 0.25rem;
		left: 0.25rem;
		width: calc(100% - 0.5rem);
    	height: calc(100% - 0.5rem);
		object-fit: contain;
		object-position: 50% 50%;
	}

	.participants abbr {
		display: grid;
		place-content: center;
		background: var(--textColor);
		color: var(--panelColor);
		text-decoration: none;
		border: none;
		font-size: 2rem;
	}
	.participants abbr.minibadge {
		font-size: 0.9rem;
	}

</style>

{#if mounted}
	<div class="participants" class:alignTop>
		{#if exhibitor}
			{#if originator == $attendee.ref}
				{#if exhibitor.logo}
					{#if exhibitor.logo.format == 'svg'}
						<div class="badge">
							<img src="https://cdn.attendzen.io/{$event.accountRef}/{exhibitor.logo.filename}" alt="{exhibitor.name}"/>
						</div>
					{:else}		
						<div class="badge">
							<img src="https://cdn.attendzen.io/{$event.accountRef}/trim_{exhibitor.logo.filename}" alt="{exhibitor.name}"/>
						</div>
					{/if}
				{:else}
					<abbr	class="badge" title="{exhibitor.name}">
						{exhibitor.name.charAt(0).toUpperCase()}
					</abbr>
				{/if}
				<p>	
					<strong>{exhibitor.name}</strong>
				</p>
			{:else}
				<div class="avatar">
					<Avatar identity={displayParticipants[0]} on:click/>
					{#if exhibitor.logo}
						{#if exhibitor.logo.format == 'svg'}
							<div class="minibadge">
								<img src="https://cdn.attendzen.io/{$event.accountRef}/{exhibitor.logo.filename}" alt="{exhibitor.name}"/>
							</div>
						{:else}		
							<div class="minibadge">
								<img src="https://cdn.attendzen.io/{$event.accountRef}/trim_{exhibitor.logo.filename}" alt="{exhibitor.name}"/>
							</div>
						{/if}
					{:else}
						<abbr	class="minibadge" title="{exhibitor.name}">
							{exhibitor.name.charAt(0).toUpperCase()}
						</abbr>
					{/if}
				</div>
				<p>	
					<strong>{displayParticipants[0].f} {displayParticipants[0].l}</strong>
					{#if displayParticipants[0].j}<br/>{displayParticipants[0].j}{/if}
					{#if displayParticipants[0].o}<br/>{displayParticipants[0].o}{/if}
				</p>
			{/if}
		{:else if help}
			{#if originator == $attendee.ref}
				<!-- <Brand/> -->
				<div class="badge"><svg viewBox="0 0 80 80"><title>Help</title><path d="M57.3 27.9c-1.4-8.5-7.2-12.9-17.3-12.9s-15.9 4.3-17.3 12.9c-1.4.2-2.5 1.5-2.5 3v5.4c0 1.5 1.1 2.8 2.6 3 .6 3.1 1.8 5.8 3.6 7.9-.1.3-.2.7-.2 1.1 0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7-.4 0-.7.1-1.1.2-1.5-1.9-2.6-4.2-3.1-6.9.9-.5 1.5-1.5 1.5-2.5v-.3c1.1 6.6 6.9 11.6 13.8 11.6s12.7-5 13.8-11.6v.3c0 1.7 1.3 3 3 3s3-1.3 3-3v-5.4c0-1.6-1.1-2.9-2.5-3.1zm-17.3 15.7c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm13.8-12.7v.3c-1.1-6.6-6.9-11.6-13.8-11.6s-12.7 5-13.8 11.6v-.3c0-1.1-.6-2.1-1.5-2.6 1.2-7.6 6.3-11.3 15.3-11.3s14.1 3.7 15.3 11.2c-.9.6-1.5 1.5-1.5 2.7zM32.5 57.5h15c6.4 0 10.6 4 12.8 7.4h4.5c-2.3-5.1-8.2-11.4-17.3-11.4h-15c-9.1 0-15 6.4-17.3 11.4h4.5c2.2-3.4 6.4-7.4 12.8-7.4z"/></svg></div>
				<p>	
					<strong>{$event.name}</strong>
				</p>
			{:else}
				<div class="avatar">
					<Avatar identity={displayParticipants[0]} on:click/>
					<!-- <Brand minibadge={true}/> -->
					<div class="minibadge"><svg viewBox="0 0 80 80"><title>Help</title><path d="M57.3 27.9c-1.4-8.5-7.2-12.9-17.3-12.9s-15.9 4.3-17.3 12.9c-1.4.2-2.5 1.5-2.5 3v5.4c0 1.5 1.1 2.8 2.6 3 .6 3.1 1.8 5.8 3.6 7.9-.1.3-.2.7-.2 1.1 0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7-.4 0-.7.1-1.1.2-1.5-1.9-2.6-4.2-3.1-6.9.9-.5 1.5-1.5 1.5-2.5v-.3c1.1 6.6 6.9 11.6 13.8 11.6s12.7-5 13.8-11.6v.3c0 1.7 1.3 3 3 3s3-1.3 3-3v-5.4c0-1.6-1.1-2.9-2.5-3.1zm-17.3 15.7c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm13.8-12.7v.3c-1.1-6.6-6.9-11.6-13.8-11.6s-12.7 5-13.8 11.6v-.3c0-1.1-.6-2.1-1.5-2.6 1.2-7.6 6.3-11.3 15.3-11.3s14.1 3.7 15.3 11.2c-.9.6-1.5 1.5-1.5 2.7zM32.5 57.5h15c6.4 0 10.6 4 12.8 7.4h4.5c-2.3-5.1-8.2-11.4-17.3-11.4h-15c-9.1 0-15 6.4-17.3 11.4h4.5c2.2-3.4 6.4-7.4 12.8-7.4z"/></svg></div>
				</div>
				<p>	
					<strong>{displayParticipants[0].f} {displayParticipants[0].l}</strong>
					{#if displayParticipants[0].j}<br/>{displayParticipants[0].j}{/if}
					{#if displayParticipants[0].o}<br/>{displayParticipants[0].o}{/if}
				</p>
			{/if}
		{:else}
			<Avatar identity={displayParticipants[0]} on:click/>	
			<p>	
				<strong>{displayParticipants[0].f} {displayParticipants[0].l}</strong>
				{#if displayParticipants[0].j}<br/>{displayParticipants[0].j}{/if}
				{#if displayParticipants[0].o}<br/>{displayParticipants[0].o}{/if}
			</p>
		{/if}
	</div>
{/if}