<script>

	import { onMount, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

	import { v4 as uuid } from 'uuid';

	import { postServerData } from '../../../../lib/prelude.js';

	const dispatch = createEventDispatcher();

	const accept = ['video/mp4','video/quicktime','video/x-msvideo','video/avi','video/mpeg'];

	export let id = 'uploader_' + uuid();

	let uploading = false;

	async function handleUpload(event) {

		if (event.target && event.target.files && event.target.files[0]) {

			let file = event.target.files[0];

			// getVideoDuration doesn't work from localhost
			const duration = (window.location.hostname == 'localhost') ? await getVideoDuration(file) : 0;

			uploading = true;

			dispatch('uploading', 0);

			let ok = true;

			if (file.type && !(accept.includes(file.type))) {
				dispatch('uploaded', {
					error: "Please upload only files in MP4, AVI, MOV or MPG formats."
				});
				ok = false;
			}

			if (ok) {

				const uploadData = await postServerData('virtual/upload/video', {
					filename: file.name,
					duration: duration
				});

				if (uploadData) {

					const url = uploadData.upload.url;

					let completed = false;
					let progress = 0;
					let displayProgress = 0;

					const uploader = UpChunk.createUpload({
						endpoint: url,
						file: file
					});

					const completeUpload = async () => {
						if (!completed) {

							completed = true;

							setTimeout(async () => {

								const assetData = await postServerData('virtual/upload/video', {
									upload: uploadData.upload.ref
								});

								if (assetData.asset) {
									uploader.abort();
									console.log('assetData', assetData.asset);
									dispatch('uploaded', assetData.asset);
								}

							}, 500);

						}
					};

					uploader.on('error', err => {
						console.log({err});
						if (progress == 100) {
							// Actually, it might be OK...
							completeUpload();
						} else {
							dispatch('uploaded', {
								error: "Sorry, this upload failed."
							});
						}
					});

					uploader.on('progress', (p) => {
						// console.log({p});
						if (!completed) {
							progress = p.detail;
							if (progress == 100) {
								// Actually, it might be OK...
								completeUpload();
							}
							displayProgress = (displayProgress >= 100) ? 100 : progress;
							dispatch('uploading', displayProgress);
						}
					});

					uploader.on('success', () => {
						console.log('success');
						completeUpload();
					});

				}

			}

			uploading = false;
			event.target.value = '';

		}

	}

	async function getVideoDuration(f) {

		const fileCallbackToPromise = (fileObj) => {
			return Promise.race([
				new Promise((resolve) => {
					if (fileObj instanceof HTMLImageElement) {
						fileObj.onload = resolve;
					} else {
						fileObj.onloadedmetadata = resolve;
					}
				}),
				new Promise((_, reject) => {
					setTimeout(reject, 1000);
				})
			]);
		};

		const objectUrl = URL.createObjectURL(f);
		const video = document.createElement("video");
		video.src = objectUrl;

		await fileCallbackToPromise(video);
		return video.duration;

	}

</script>

<style>
	.uploader {
		position: absolute;
		inset: 0;
		z-index: 1000;
	}
	input {
		position: absolute;
		inset: 0;
		cursor: pointer;
		opacity: 0;
	}
</style>

<svelte:head>
	<script src="https://unpkg.com/@mux/upchunk@3"></script>
</svelte:head>

<div class="uploader">
	<input
		id={id}
		type="file"
		on:change={handleUpload}
		accept={accept}
	/>
</div>