<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';

	import { IdleSessionTimeout } from "idle-session-timeout";

	const dispatch = createEventDispatcher();

	export let minutes = 30;

	let sessionTimeout;

	onMount(() => {

		const milliseconds = minutes * 60 * 1000;

		sessionTimeout = new IdleSessionTimeout(milliseconds);

		sessionTimeout.onTimeOut = () => {
			dispatch('timeout');
		};

		sessionTimeout.start();

	});

	onDestroy(() => {
		sessionTimeout.dispose();
	});

</script>