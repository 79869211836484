<script>

	export let ref;
	export let value;
	export let options;
	export let disabled;

	// console.log(options)

</script>

<style>
	.radiobuttons p {
		text-align: left;
		margin: 0;
		display: flex;
		align-items: flex-start;
		gap: 0.4rem;
	}
	.radiobuttons p + p {
		margin-top: 0.4rem;
	}
	.radiobuttons span {
		width: 1em;
		height: 1em;
		position: relative;
		overflow: visible;
		top: -0.1em;
	}
	.radiobuttons span input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		opacity: 0;
		z-index: 2;
	}
	.radiobuttons span svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		z-index: 1;
	}
	/*.radiobuttons span input:focus + svg .off {
		fill: var(--accentColor);
	}*/
	.radiobuttons span path,
	.radiobuttons span circle {
		fill: currentColor;
		transition: opacity 0.2s ease-in-out;
	}
	.radiobuttons span circle.on-bg {
		fill: transparent;
	}
	.radiobuttons span input + svg .on {
		opacity: 0;
	}
	.radiobuttons span input:checked + svg .on {
		opacity: 1;
	}

	.radiobuttons label {
		display: inline-block;
		font-size: 0.8rem;
		line-height: 1;
	}
</style>

<div class="radiobuttons">
	{#each options as o, i}
		<p class:selected={value == o.value} class:disabled>
			<span>
				<input
					type="radio"
					id="{ref}_o{i}"
					bind:group={value}
					value={o.value}
					{disabled}
				>
				<svg viewBox="0 0 60 60"><g class="on"><circle class="on-bg" cx="30" cy="30" r="27.42"/><circle class="on-disc" cx="29.94" cy="29.99" r="15"/></g><path class="off" d="M30 0c16.54 0 30 13.46 30 30s-13.46 30-30 30-30-13.46-30-30 13.46-30 30-30zm0 56c14.34 0 26-11.66 26-26s-11.66-26-26-26-26 11.66-26 26 11.66 26 26 26z"/></svg>
			</span>
			<label for="{ref}_o{i}">{o.label}</label>
		</p>
	{/each}
</div>