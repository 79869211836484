<script>

	import { getContext, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

	import Uploader from './Uploader.svelte';

	import Button from "../../../../ui/Button.svelte";
	import Spinner from "../../../../ui/Spinner.svelte";

	import { session, event } from "../../../../lib/stores.js";
	import { postServerData } from '../../../../lib/prelude.js';

	const dispatch = createEventDispatcher();

	const clips = getContext('clips');
	const clipTokens = getContext('clipTokens');

	let uploading = false;
	let progress = 0;

	let asset;

	async function save() {
		$clipTokens[asset.ref] = {};
		$clipTokens[asset.ref].thumbnail = asset.jwt;
		let a = asset;
		delete a.jwt;
		let c = JSON.parse(JSON.stringify($clips));
		c.push(a);
		const json = JSON.stringify(c);
		await postServerData('virtual/studio/clips', {
			json: json,
			sessionRef: $session.ref
		});
		$clips = c;
		dispatch('saved');
	}

	function handleUploading(e) {
		uploading = true;
		progress = e.detail;
	}

	function handleUploaded(e) {
		if (e && e.detail) {
			// Pause to allow Mux time to generate the thumbnail
			// One second should be okay...
			setTimeout(() => {
				asset = e.detail;
				uploading = false;
			}, 1000);
		} else {
			uploading = false;
		}
	}

</script>

<style>
	.uploader {
		position: relative;
		padding: 1rem 0;
		display: flex;
		justify-content: flex-start;
		gap: 2rem;
		align-items: center;
		cursor: pointer;
	}
	.uploader .image {
		display: block;
		width: 12rem;
		aspect-ratio: 16 / 9;
		position: relative;
		border-radius: 3px;
		flex: 0 0 auto;
		background: var(--blend-05);
		overflow: hidden;
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
	}
	.uploader .image img,
	.uploader .image svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	path, circle {
		fill: var(--blend-80);
		opacity: 0.4;
	}
	.st1 {
		fill: var(--blend-40);
	}
	.uploader span {
		display: block;
		font-size: 0.6875rem;
		color: var(--blend-80);
		line-height: 1.4;
	}
	.uploader .uploading {
		position: absolute;
		inset: -2rem;
		display: grid;
		place-content: center;
		background: var(--blend-10);
		z-index: 100;
	}
	.uploader .progress {
		font-weight: 600;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 0.6875rem;
		font-family: system-ui;
		font-variant-numeric: tabular-nums;
		color: var(--accentColor);
	}
</style>

<div class="overlay-title">
	<h2>Add a new video clip</h2>
</div>
<div class="overlay-main">
	<form id="uploader" on:submit|preventDefault={save}>
		<div class="uploader">
			{#if uploading}
				<div class="uploading">
					<Spinner size="100" speed="1200" color={$event.theme.colors[$event.theme.virtual['accentColor']]} thickness="1" gap="30" />
					{#if progress}
						<span class="progress" transition:fade|local={{ duration:600 }}>{#if progress == 100}100{:else}{progress.toFixed(1)}{/if}%</span>
					{/if}
				</div>
			{/if}
			<p class="image">
				{#if asset}
					<img src="https://image.mux.com/{asset.playback}/thumbnail.jpg?token={asset.jwt}" alt=""/>
				{:else}
					<svg viewBox="0 0 640 360"><circle cx="407.51" cy="78.55" r="27.21"/><path class="st1" d="M0 360v-100.06c17.49 6.25 34.53 14.59 49.15 25.73l5.07-6.66c-5.19-3.95-10.7-7.56-16.43-10.86 35.34-26.86 74.27-42.05 113.03-43.97 46.95-2.32 92.28 14.28 134.75 49.36l5.33-6.45c-7.92-6.54-15.95-12.44-24.06-17.74 32.3-17.17 69.83-25.86 106.44-24.46 41.84 1.57 79.99 15.89 110.33 41.42l5.39-6.41c-13.85-11.65-29.25-21.07-45.83-28.13l112.6-114.27 49.36 49.37 34.87-34.88v228.01h-640z"/><path d="M605.13 155.01l-49.4-49.41-121.01 122.82c-19.12-7.05-39.65-11.1-61.12-11.9-39.62-1.48-80.37 8.37-114.89 27.74-34.66-20.72-70.95-30.28-108.29-28.44-41.56 2.07-83.19 18.66-120.65 47.98-9.63-4.92-19.66-9.06-29.77-12.54v8.68c17.49 6.25 34.53 14.59 49.15 25.73l5.07-6.66c-5.19-3.95-10.7-7.56-16.43-10.86 35.34-26.86 74.27-42.05 113.03-43.97 46.95-2.32 92.28 14.28 134.75 49.36l5.33-6.45c-7.92-6.54-15.95-12.44-24.06-17.74 32.3-17.17 69.83-25.86 106.44-24.46 41.84 1.57 79.99 15.89 110.33 41.42l5.39-6.41c-13.85-11.65-29.25-21.07-45.83-28.13l112.6-114.27 49.36 49.37 34.87-34.88v-11.84l-34.87 34.86z"/></svg>
				{/if}
			</p>
			<span>Click to upload a new video clip<br/>(MP4, AVI, MOV or MPG)</span>
			<Uploader
				on:uploading={handleUploading}
				on:uploaded={handleUploaded}
			/>
		</div>
	</form>
</div>
<div class="overlay-actions">
	<Button label="Save" type="submit" form="uploader" wide={false} grow={false} disabled={!asset} />
</div>