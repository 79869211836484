<script>

	import { onMount, onDestroy } from 'svelte';
	import { fade } from 'svelte/transition';

	import Portal from "svelte-portal";

	import List from "./Messages/List.svelte";
	import Start from "./Messages/Start.svelte";
	import Conversation from "./Messages/Conversation.svelte";

	import Overlay from "../ui/Overlay.svelte";
	// import Button from "../ui/Button.svelte";

	import { conversations, conversation, syncChannels, syncClient, muted } from '../lib/stores.js';
	import { isMuted } from '../lib/conversations.js';
	import { randomIntFromInterval } from '../lib/utils.js';

	let openOverlay = false;
	let tracker;
	// let conversations = {};
	let channels = {};
	let datestamps = {};
	let spot = false;
	let start = false;

	onMount(async () => {

		try {

			// console.log("opening conversations tracker channel", $syncChannels.conversations);
			tracker = await $syncClient.map($syncChannels.conversations);

			tracker.on('itemAdded', function(i) {
				$conversations[i.item.key] = i.item.data;
				openChannel(i.item.key);
			});

			tracker.on('itemUpdated', function(i) {
				const c = i.item.key;
				$conversations[c] = i.item.data;
				if ($conversation && ($conversation.ref == c)) {
					$conversation = {
						ref: c,
						sid: $conversations[c].sid,
						participants: $conversations[c].participants,
						encryptionKey: $conversations[c].encryptionKey,
						exhibitor: $conversations[c].exhibitor,
						help: $conversations[c].help,
						videoCalls: $conversations[c].videoCalls
					}
				}
			});

			tracker.on('itemRemoved', function(i) {
				delete $conversations[i.key];
				closeChannel(i.key);
			});

			getConversations();

			// console.log('conversations', $conversations);
			
		} catch (e) {

			console.log("Sync error", e, $syncChannels.conversations);
			
		}

	});

	onDestroy(() => {
		if (tracker) tracker.close();
		Object.keys(channels).forEach(c => {
			channels[c].close();
		});
	});

	function pageHandler(paginator) {
		paginator.items.forEach((item) => {
			$conversations[item.key] = item.data;
			openChannel(item.key);
		});
		return paginator.hasNextPage
			? paginator.nextPage().then(pageHandler)
			: null;
	}

	function getConversations() {
		tracker.getItems({ pageSize: 100 })
			.then(pageHandler)
			.catch((error) => {
				if (error.message.match('Maximum attempt')) {
					// back off and try again later...
					const smear = 1000 * randomIntFromInterval(5,10);
					setTimeout(() => {
						getConversations();
					}, smear);
				} else {
					console.error('getConversations failed', error);
				}
			});
	}

	async function openChannel(c) {
		let m = isMuted($conversations[c].participants);
		if (!m) {

			// console.log('openChannel', c, $conversations[c].sid);

			channels[c] = await $syncClient.list($conversations[c].sid);

			channels[c].on('itemAdded', function(i) {
				if (i.item.data.dt) {
					datestamps[c] = i.item.data.dt;
				}
			});

			// Get 3 to guarantee we avoid typing items
			await channels[c].getItems({
				order: 'desc',
				pageSize: 3
			}).then(p => {
				for (const i of p.items) {
					if (i.data && ((i.data.type == 'message') || (i.data.type == 'videocall')) && i.data.dt) {
						datestamps[c] = i.data.dt;
						break;
					}
				}
			});

		}
	}

	function closeChannel(c) {
		channels[c].close();
	}

	function checkDatestamps() {
		let s = false;
		for (const c of Object.keys($conversations)) {
			// console.log(conversations[c].viewed, datestamps[c]);
			if (datestamps[c] && (datestamps[c] > $conversations[c].viewed)) {
				if (!isMuted($conversations[c].participants)) {
					s = true;
					break;
				}
			}
		}
		spot = s;
	}

	function checkChannels() {
		for (const c of Object.keys($conversations)) {
			const m = isMuted($conversations[c].participants);
			if (channels[c] && m) {
				channels[c].close();
				delete channels[c];
			} else if (!channels[c] && !m) {
				openChannel(c);
			}
		}
		checkDatestamps();
	}

	function checkConversation(c) {
		if (c) {
			openOverlay = true;
		} else {
			start = false;
		}
	}

	$: checkConversation($conversation);

	$: if (datestamps) {
		checkDatestamps();
	}

	$: if ($conversations) {
		checkDatestamps();
	}

	$: if ($muted) {
		checkChannels();
	}

	// function tmpCon() {
	// 	let tmp = Object.keys(conversations)[2];
	// 	$conversation = {
	// 		ref: tmp,
	// 		sid: conversations[tmp].sid,
	// 		participants: conversations[tmp].participants,
	// 		encryptionKey: conversations[tmp].encryptionKey
	// 	};
	// }

</script>

<style>

	.messages {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		color: var(--textColor, #333);
		position: relative;
		padding: 0;
		border: 0;
		background: transparent;
	}

	.messages:hover {
		cursor: pointer;
		color: var(--accentColor, #e6007e);
	}

	.messages:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0.4rem;
		height: 0.4rem;
		background: var(--red);
		border: 2px solid var(--panelColor);
		border-radius: 100%;
		opacity: 0;
	}

	.messages.spot:after {
		opacity: 1;
		transition: opacity 0.2s ease;
		transition-delay: 0.4s;
	}

	.inner {
		position: absolute;
		inset: 0;
		overflow: hidden;
	}

	.inner > div {
		display: flex;
		/* setting explicit width (and compensating with overflow) needed for firefox */
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		top: 0;
		overflow: visible;
		transition: left 0.25s ease-out;
	}

	.inner > div.start {
		left: -100%;
	}

	.inner > div.conversation {
		left: -100%;
	}

	.inner > div.start.conversation {
		left: -200%;
	}

	.inner > div > div {
		flex: 0 0 100%;
		height: 90vh;
		height: 90dvh;
		position: relative;
	}

	.inner :global(.slate) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-60%);
	}
	.inner :global(.slate p) {
		margin-top: 0.5rem;
		font-size: 0.8rem;
		max-width: 14rem;
		line-height: 1.4;
		text-align: center;
	}
	.inner :global(.slate svg) {
		width: 2rem;
		height: 2rem;
	}
	.inner :global(.slate strong) {
		font-size: 1rem;
	}

</style>

<li>
	<button type="button" class="messages" on:click={() => {openOverlay = true}} class:spot>
		<svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg>
	</button>
</li>

{#if openOverlay}
	<Portal target="#breakout">
		<Overlay
			on:escape={() => { openOverlay = false; $conversation = null; start = false; }}
			tall={true}
			narrow={true}
			modal={true}
		>
			<div class="inner">
				<div class:start class:conversation={$conversation}>
					<div>
						<List {datestamps} on:start={() => { start = true }}/>
					</div>
					{#if start}
						<div out:fade={{ duration: 0, delay: 400 }}>
							<!-- <Button label="Go" on:click={tmpCon}/> -->
							<Start on:back={() => { start = false }}/>
						</div>
					{/if}
					<div>
						{#if $conversation}
							<div out:fade={{ duration: 0, delay: 400 }}>
								<Conversation
									{tracker}
									on:escape={() => { openOverlay = false; $conversation = null; start = false; }}
								/>
							</div>
						{/if}
					</div>
				</div>
			</div>
		</Overlay>
	</Portal>
{/if}