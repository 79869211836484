<script>

	import { tick } from 'svelte';
	import { fly } from 'svelte/transition';

	import Portal from "svelte-portal";

	import moment from 'moment-timezone';

	import { v4 as uuid } from 'uuid';

	import Overlay from "../ui/Overlay.svelte";
	import Button from "../ui/Button.svelte";
	import Field from '../ui/Field.svelte';
	import Announcement from '../ui/Announcement.svelte';
	import Switch from "../ui/fields/Switch.svelte";

	import { bigError, event, sessions, session, epoch, room, announcements } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';
	import { postServerData } from '../lib/prelude.js';

	let openOverlay = false;
	let messageText = '';
	let announced = {};
	let showPreview = false;
	let sharedAudience = false;

	async function announce() {
		if ((messageText != '') && (messageText != null)) {
			openOverlay = false;
			let dt = moment().unix();
			let audience = {
				'app': true,
				'virtual': sharedAudience
			};
			let announcement = {
				ref: uuid(),
				type: 'announcement',
				message: messageText,
				audience: audience,
				dt: dt
			};
			let json = JSON.stringify(announcement);
			postServerData('virtual/announcements', {
				json: json
			});
		}
	}

	async function setFocus() {
		await tick();
		document.getElementById('field-announcement').focus();
	}

	function checkSessions() {

		// Show alert for sessions about to begin
		// (28 Jul 2023) If we sign in after this, and a session is in progress, there's a function in App.svelte to handle that

		const e = $epoch;
		const imminent = e + 30;
		const veryImminent = e + 15;

		let alert = $sessions[imminent] ? imminent : null;
		if (!alert) alert = $sessions[veryImminent] ? veryImminent : null;
		if (!alert) alert = $sessions[e] ? e : null;

		if (alert && !announced[alert]) {

			if ((Object.keys($sessions).length == 1) && ($sessions[Object.keys($sessions)[0]].length == 1) && ($room == 'lobby')) {

				// Single-session event, and we're in the lobby...				
				$room = 'auditorium';
				$session = $sessions[Object.keys($sessions)[0]][0];
				announced[alert] = true;

			} else if ((($room != 'auditorium') && ($room != 'studio')) || ($session && $session.ref && ($session.ref != alert) && $session.epochEnds && ($session.epochEnds <= e))) {

				const autoCancel = alert + 60;

				$announcements.push({
					ref: uuid(),
					type: 'session',
					sessions: $sessions[alert],
					dt: $epoch,
					autoCancel: autoCancel
				});

				$announcements = $announcements;

				announced[alert] = true;

			}

		}

		if ($announcements.length > 0) {
			$announcements.forEach(a => {
				// if ((a.type == 'session') && (a.session && a.session.epochStarts) && (a.session.epochStarts < (e - 60))) {
				if (a.autoCancel && (a.autoCancel < e)) {
					// console.log('autoCancel', a.autoCancel, e);
					let filtered = $announcements.filter(r => r.ref != a.ref);
					$announcements = filtered;
				}
			});
		}

	}

	$: if (openOverlay) {
		setFocus();
		messageText = '';
		showPreview = false;
	}

	$: if ($epoch) {
		checkSessions();
	}

</script>

<style>
	li {
		display: inline-block;
		line-height: 1;
		width: 1.6rem;
		height: 1.6rem;
		position: relative;
	}
	.announcements {
		padding: 0;
		border: 0;
		background: transparent;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: var(--textColor);
	}
	.announcements:hover {
		cursor: pointer;
		color: var(--accentColor, #e6007e);
	}
	.announcements svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.send > form {
		display: grid;
	}
	.send > form > div {
		grid-area: 1/1/2/2;
	}

	.preview {
		width: 450px;
		max-width: 100%;
		margin: 0 auto;
		position: relative;
		z-index: 100;
		padding: 1.2rem 0;
	}
	.send .preview :global(.announcement) {
		border: 1px solid var(--blend-40);
		background: var(--blend-05);
	}
	.send .preview :global(.announcement:before) {
		box-shadow: 0 2px 6px 2px var(--shadow);
		opacity: 0.3;
	}
	.preview :global(.announcement:after) {
		display: none;
	}
	.preview :global(.close) {
		pointer-events: none;
		cursor: default;
		opacity: 0.2;
	}

	.switch {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		margin: 1.5rem 0;
	}
	.switch label {
		font-size: 0.8rem;
	}
</style>

<li>
	<button type="button" class="announcements" on:click={() => {openOverlay = true}}>
		<svg viewBox="0 0 80 80"><title>Announcements</title><path d="M58.18 28.35c-1.11 0-2-.9-2-2 0-1.35-.53-2.63-1.48-3.58-.96-.96-2.23-1.49-3.59-1.49-1.1 0-2-.9-2-2s.9-2 2-2c2.42 0 4.7.94 6.41 2.66 1.71 1.71 2.66 3.99 2.66 6.41 0 1.1-.89 2-2 2zM66.03 24.81c-1.1 0-1.99-.89-2-1.99-.01-2.46-.98-4.78-2.73-6.52-1.76-1.76-4.1-2.73-6.59-2.73h-.07c-1.1 0-1.99-.89-2-1.99-.01-1.1.88-2 1.99-2.01h.08c3.56 0 6.9 1.38 9.42 3.9 2.49 2.49 3.88 5.81 3.9 9.33.01 1.1-.88 2.01-1.99 2.01h-.01zM65.74 41.34l-.19-.22-29.43-29.43-.22-.19c-1.03-.77-2.39-.9-3.54-.34-1.09.53-1.79 1.6-1.81 2.8-.24 10.33-4.07 19.61-11.08 26.98l-4.88 4.87c-2.34 2.34-2.34 6.16 0 8.5l8.31 8.31c1.17 1.17 2.71 1.76 4.25 1.76s3.08-.58 4.25-1.76l3.08-3.08.19-.16c.29-.31.59-.6.88-.89l10.37 10.37c.38.38.89.59 1.41.59.26 0 .52-.05.77-.15l8.05-3.33c.61-.25 1.06-.79 1.19-1.44s-.06-1.32-.51-1.79l-11.16-11.66c5.34-2.74 11.27-4.24 17.6-4.39 1.2-.03 2.27-.72 2.8-1.81.56-1.15.43-2.51-.33-3.54zm-44.17 3.17l11.15 11.15-4.14 4.15c-.78.78-2.06.78-2.84 0l-8.31-8.31c-.78-.78-.78-2.06 0-2.84l4.14-4.15zm30.35 18.88l-4.11 1.7-9.29-9.29c1.16-.96 2.35-1.84 3.59-2.65l9.81 10.24zm-16.24-10.42l-11.41-11.4c6.14-7.31 9.64-16.13 10.2-25.85l27.06 27.05c-9.72.55-18.54 4.05-25.85 10.2z"/></svg>
	</button>
</li>

{#if openOverlay}
	<Portal target="#breakout">
		<Overlay
			on:escape={() => { openOverlay = false; }}
			wide={true}
			modal={true}
		>
			<div class="overlay-title">
				<h2>Make an announcement</h2>
				{#if showPreview}
					<p>Please check the preview of your announcement</p>
				{:else}
					<p>Enter your message below to send an alert to all attendees</p>
				{/if}
			</div>
			<div class="overlay-main send">
				<form id="form-announcement" on:submit|preventDefault={announce}>
					{#if showPreview}
						<div class="preview" transition:fly|local={{ duration:200, y: 10 }}>
							<Announcement announcement={{
								type: 'announcement',
								message: messageText,
								dt: $epoch
							}}/>
						</div>
					{:else}
						<div transition:fly|local={{ duration:200, y: 10 }}>
							<Field
								ref="field-announcement"
								bind:value={messageText}
								component="TextArea"
								label="Your announcement"
								maxlength={150}
							/>
							<!-- TODO: confirm that the below matches the way we store app use in the event settings -->
							{#if $event.app && $event.app.active}
								<p class="switch">
									<Switch ref="audience" bind:value={sharedAudience}/>
									<label for="audience">Send also to users of the attendee app</label>
								</p>
							{/if}
						</div>
					{/if}
				</form>
			</div>
			<div class="overlay-actions">
				{#if showPreview}
					<Button ghost={true} wide={false} on:click={() => {showPreview = false}} label="Back"/>
					<Button type="submit" form="form-announcement" label="Send now" wide={false} disabled={messageText == ''} />
				{:else}
					<Button ghost={true} red={true} wide={false} on:click={() => { openOverlay = false; }} label="Cancel"/>
					<Button label="Next" wide={false} disabled={messageText == ''} on:click={() => {showPreview = true}} />
				{/if}
			</div>
		</Overlay>
	</Portal>
{/if}