<script>

	import { onMount } from 'svelte';
	import { crossfade, fade, fly } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import { v4 as uuid } from 'uuid';

	import latinize from 'latinize';
	import { byValue, byString } from 'sort-es';

	import Booth from "./Exhibition/Booth.svelte";

	import Filter from "./ui/Filter.svelte";

	import {	event, exhibitors, booth, socialMediaServiceOpts } from './lib/stores.js';
	import { getServerData } from './lib/prelude.js';

	const [send, receive] = crossfade({
		duration: 0,
		fallback(node, params) {
			return {
				duration: 300,
				easing: quintOut,
				css: t => `
					transform: scale(${1 - ((1 - t) / 32)});
					opacity: ${t}
				`
			};
		}
	});

	let mounted = false;
	let filterText = '';

	let boothIndex = null;

	let viewMode = 'tiles';

	let displayExhibitors = [];

	$: if (filterText || viewMode) {
		setDisplayExhibitors();
	}

	$: filterText = filterText && latinize(filterText).toLowerCase();

	// let rowTimeout;

	function setDisplayExhibitors() {
		if (mounted) {
			// clearTimeout(rowTimeout);
			// rowTimeout = seftTimeout(function(){
				let f = $exhibitors.filter(filterByValue);
				let d = [];
				f.forEach((e, i) => {
					if (viewMode == 'tiles') {
						if (i && (e.tier != f[i-1].tier)) {
							d.push({
								ref: e.ref + '-x',
								tier: 'x',
							});
						}
					}
					d.push(e);
				});
				displayExhibitors = (viewMode == 'list') ? d.sort(byValue(e => e.name, byString({ lowercase: true }))) : d;
			// }, 100);
		}
	}

	function filterByValue(o) {
		if (!filterText) return true;
		return Object.keys(o).some(f => (f == 'name' || f == 'headline' || f == 'tags') && filterValue(o[f]).includes(filterText));
	}

	function filterValue(v) {
		return v ? latinize(v).toString().toLowerCase() : '';
	}

	function goForward() {
		if (displayExhibitors[boothIndex + 1] && (displayExhibitors[boothIndex + 1].tier == 'x')) {
			boothIndex += 2;
			$booth = displayExhibitors[boothIndex];
		} else if (displayExhibitors[boothIndex + 1]) {
			boothIndex += 1;
			$booth = displayExhibitors[boothIndex];
		} else {
			boothIndex = 0;
			$booth = displayExhibitors[boothIndex];
		}
	}

	function goBack() {
		if (boothIndex && (displayExhibitors[boothIndex - 1].tier == 'x')) {
			// displayExhibitors[0].tier can't be "x" so this is safe
			boothIndex -= 2;
			$booth = displayExhibitors[boothIndex];
		} else if (boothIndex) {
			boothIndex -= 1;
			$booth = displayExhibitors[boothIndex];
		} else {
			boothIndex = displayExhibitors.length - 1;
			$booth = displayExhibitors[boothIndex];
		}
	}

	onMount(async () => {
				
		// if (!$socialMediaServiceOpts.length) {
		// 	const data = await getServerData('platform/socialmediaservices');
		// 	socialMediaServiceOpts.set(data.socialmediaservices);
		// }

		mounted = true;
		setDisplayExhibitors();

	});

</script>

<style>
	.hall {
		grid-area: c;
		position: relative;
		transition: margin-right 0.4s ease;
	}
	/*@media (min-width: 940px) {*/
	@media (min-width: 1100px) {
		:global(main:not(.leftDocked)) .hall {
			margin-right: -2rem;		
		}
	}
	.hall > :global(div) {
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		border-radius: 4px;
		display: flex;
		flex-direction: column;
	}
	.utils {
		flex: 0 0 auto;
		padding: 1rem;
	}
	.exhibitors {
		flex: 1 0 auto;
		position: relative;
	}
	.exhibitors > div {
		position: absolute;
		inset: 0;
		overflow-y: auto;
		scrollbar-width: thin;
		/*border-top: 1px solid var(--blend-20);*/
		/*background: var(--blend-10);*/
	}
	.exhibitors > div > div {
		display: flex;
		flex-wrap: wrap;
		gap: 0 1rem;
		padding: 0 1rem 1rem 1rem;
		justify-content: flex-start;
		align-items: flex-start;
	}

	/*.exhibitors hr {
    	content: '';
    	width: 100%;
    	border: 0;
    	height: 0;
    	padding: 0;
    	margin: 0;
	}*/

	.tile {
		position: relative;
		margin: 0;
		border-radius: 4px;
		background: var(--dominantColor, var(--textColor));
		transition: transform 0.2s ease;
		margin-top: 1rem;
	}
	/*.tile:first-child {
		margin-top: 0;
	}*/
	.tiles .tile:hover {
		transform: scale(1.01);
	}
	.tiles .tile.tier-x {
		background: transparent;
    	width: 100%;
    	border: 0;
    	height: 0;
    	padding: 0;
    	margin: 0;
	}
	.tiles .tier-l {
		flex: 0 0 100%;
		aspect-ratio: 4.2 / 1;
	}
	.tiles .tier-m {
		flex: 0 0 calc((100% - 1rem) / 2);
		aspect-ratio: 2.1 / 1;
	}
	.tiles .tier-s {
		flex: 0 0 calc((100% - 2rem) / 3);
		aspect-ratio: 1.5 / 1;
	}

	@media (max-width: 1100px) {
		.tiles .tier-s {
			aspect-ratio: 1.25 / 1;
		}
	}
	@media (max-width: 1000px) {
		.tiles .tier-l {
			flex: 0 0 100%;
			aspect-ratio: 2.5 / 1;
		}
		.tiles .tier-m {
			flex: 0 0 100%;
			aspect-ratio: 3 / 1;
		}
		.tiles .tier-s {
			flex: 0 0 100%;
			aspect-ratio: 4 / 1;
		}
	}

	@media (max-width: 600px) {
		.tiles .tier-l {
			aspect-ratio: 1.8 / 1;
		}
		.tiles .tier-m {
			aspect-ratio: 2.1 / 1;
		}
		.tiles .tier-s {
			aspect-ratio: 2.5 / 1;
		}
	}

	/*@media (max-width: 940px) {*/
	@media (max-width: 1100px) {
		.hall {
			margin-right: 0;
		}
	}

	.tiles .tile .bg {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: var(--focalPoint, 50% 50%);
		border-radius: 4px;
		z-index: 1;
	}

	.tiles .tile:before {
		content: '';
		position: absolute;
		inset: 0;
		border-radius: 4px;
		/*background: linear-gradient(to bottom, rgba(0,0,0,0) 30%, rgba(0,0,0,0.6) 90%);*/
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		z-index: 2;
		mix-blend-mode: multiply;
	}

	.tile > button {
		position: absolute;
		/*inset: 0;*/
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 10;
		border: 0;
		background: transparent;
		padding: 0;
	}

	.tiles .tile > div {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		right: 1rem;
		top: auto;
		z-index: 3;
	}

	.tiles .tile h2 {
		font-size: 1.25rem;
		color: #fff;
	}
	.tiles .tile p {
		margin: 0.2rem 0 0 0;
		/*font-size: 0.6875rem;*/
		font-size: 0.8rem;
		color: #fff;
	}

	.tiles .tile .logo {
		position: absolute;
		top: 1rem;
		left: 1rem;
		width: 3rem;
		height: 3rem;
		padding: 0.5rem;
		background: #fff;
		border-radius: 4px;
		object-fit: contain;
		object-position: 50% 50%;
		z-index: 4;
	}

	.exhibitors.list > div > div {
		/*align-items: stretch;*/
		display: block;
		columns: 3;
		padding: 1rem 0.5rem 1rem 0.5rem;
		/*gap: 0.5rem;*/
	}
	@media (max-width: 900px) {
		.exhibitors.list > div > div {
			columns: 2;
		}
	}
	@media (max-width: 600px) {
		.exhibitors.list > div > div {
			columns: 1;
		}
	}
	.list .tile {
		position: relative;
		background: transparent;
		display: inline-flex;
		/*flex: 0 0 calc((100% - 1rem) / 3);*/
		width: 100%;
		padding: 0.5rem;
		gap: 1rem;
		box-sizing: border-box;
		margin: 0 0 0.5rem 0;
	}
	.list .tile:hover {
		background: var(--blend-05);
	}
	.list .tile.tier-x,
	.list .tile .bg {
		display: none;
	}
	.list .tile .logo {
		width: 2rem;
		height: 2rem;
		padding: 0.5rem;
		background: #fff;
		border-radius: 4px;
		object-fit: contain;
		object-position: 50% 50%;
		border: 1px solid var(--blend-20);
	}
	.list .tile h2 {
		font-size: 0.8rem;
		margin: 0.25rem 0 0.25rem 0;
	}
	.list .tile p {
		font-size: 0.6875rem;
	}

	@media (max-width: 700px) {
		.list .tile {
			flex: 0 0 calc((100% - 0.5rem) / 2);
		}
	}
	@media (max-width: 500px) {
		.list .tile {
			flex: 0 0 100%;
		}
	}

	.utils {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		/*box-shadow: 0 1px 8px -4px rgba(0, 0, 0, 0.3);*/
		/*background: var(--blend-05);*/
	}
	.filter {
		flex: 1 1 auto;
		max-width: 24rem;
	}
	.filter + div {
		flex: 0 0 auto;
		height: 1.4rem;
		display: flex;
		gap: 1rem;
	}
	.utils button {
		width: 1.3rem;
		height: 1.3rem;
		border: 0;
		background: transparent;
		padding: 0;
		cursor: pointer;
		color: var(--blend-40);
	}
	.utils button.current {
		color: var(--textColor);
	}
	.utils button:hover {
		color: var(--blend-80);
	}
	.utils button.current:hover {
		color: var(--textColor);
	}

</style>

{#if mounted}
	<div class="hall">
		{#if $booth}
			<Booth on:forward={goForward} on:back={goBack}/>
		{:else}
			<div in:fly={{ y: 30, duration: 400 }}>
				<div class="utils">
					<div class="filter">
						<Filter bind:filterText icon={false} placeholder="Search for an exhibitor …"/>
					</div>
					<div>
						<button class:current={viewMode == 'list'} type="button" on:click={() => { viewMode = 'list' }}>
							<svg viewBox="0 0 26 26"><title>{#if viewMode != 'list'}Switch to {/if}A–Z view</title><path d="M0 1c0-.55.45-1 1-1h24c.55 0 1 .45 1 1v24c0 .55-.45 1-1 1h-24c-.55 0-1-.45-1-1v-24zm24.5 24c.28 0 .5-.22.5-.5v-23c0-.28-.22-.5-.5-.5h-23c-.28 0-.5.22-.5.5v23c0 .28.22.5.5.5h23zM3.39 17.62l4.5-12.37h1.21l4.5 12.37h-1.18l-1.32-3.72h-5.2l-1.32 3.73h-1.19zm7.34-4.74l-2.19-6.16h-.08l-2.19 6.16h4.46zM15.2 16.77l5.37-7.34v-.07h-5.14v-1.02h6.52v.89l-5.27 7.3v.08h5.46v1.01h-6.94v-.85z"/></svg>
						</button>
						<button class:current={viewMode == 'tiles'} type="button" on:click={() => { viewMode = 'tiles' }}>
							<svg viewBox="0 0 26 26"><title>{#if viewMode != 'tiles'}Switch to {/if}Tiled view</title><path d="M25 8h-24c-.55 0-1-.45-1-1v-6c0-.55.45-1 1-1h24c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1zM11 17h-10c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1zM25 17h-10c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1zM6.33 26h-5.33c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1h5.33c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1zM25 26h-5.33c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1h5.33c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1zM15.66 26h-5.33c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1h5.33c.55 0 1 .45 1 1v5c.01.55-.44 1-1 1z"/></svg>
						</button>
					</div>
				</div>
				<div class="exhibitors {viewMode}">
					<div>
						<div>

							{#each displayExhibitors as e, i (e.ref)}
								<!-- {#if (i && (e.tier != displayExhibitors[i-1].tier))}
									<hr/>
								{/if} -->
								<article
									class="tile tier-{e.tier}"
									style={e.tier == "x" ? "" : "--dominantColor:" + (e.banner ? e.banner.dominantColor : $event.theme.colors[$event.theme.virtual.textColor]) + ";--focalPoint:" + (e.banner ? e.banner.focalPointX : '50') + "% " + (e.banner ? e.banner.focalPointY : '50') + "%;"}
									in:receive|local="{{ key: e.ref }}"
									out:send|local="{{ key: e.ref }}"
									animate:flip="{{ duration: 200 }}"
								>
									{#if e.tier != "x"}
										{#if e.banner}			
											<img class="bg" src="https://cdn.attendzen.io/{$event.accountRef}/lg_{e.banner.filename}" alt=""/>
										{/if}
										{#if e.logo}
											{#if e.logo.format == 'svg'}	
												<img class="logo" src="https://cdn.attendzen.io/{$event.accountRef}/{e.logo.filename}" alt=""/>
											{:else}		
												<img class="logo" src="https://cdn.attendzen.io/{$event.accountRef}/trim_{e.logo.filename}" alt=""/>
											{/if}
										{/if}
										<div>
											<h2>{e.name}</h2>
											{#if e.headline}<p>{e.headline}</p>{/if}
										</div>
										<button 
											type="button" 
											on:click={() => { $booth = e; boothIndex = i; }}
										></button>
									{/if}
								</article>
							{/each}

						</div>
					</div>
				</div>
			</div>
		{/if}
	</div>
{/if}
