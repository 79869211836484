<script>

	import { onMount, onDestroy } from 'svelte';
	import { fade } from 'svelte/transition';

	import { session, epoch } from '../lib/stores.js';
	import { getServerData } from '../lib/prelude.js';

	let presence = 0;
	let poller;

	onMount(() => {
		// pollPresence();
	});

	onDestroy(() => {
		// clearInterval(poller);
		clearTimeout(poller);
	});

	async function pollPresence() {
		if ($session && $session.ref) {
			let pollData = await getServerData('virtual/presence', {
				session: $session.ref
			});
			if (pollData) {
				presence = pollData.presence;
				// presence = Math.floor(Math.random() * 200);
			}
			// Set up the next interval
			// We're using setTimeout rather than setInterval because we want to adjust the interval
			// (every 5 seconds for the first 2 minutes; every 10 seconds for the next 5 minutes; every 30 seconds thereafter)
			let interval = 5000;
			if (($epoch - $session.epochStarts) > 420) {
				interval = 30000;
			} else if (($epoch - $session.epochStarts) > 120) {
				interval = 10000;
			}
			poller = setTimeout(() => {
				pollPresence();
			}, interval);
		}
	}

</script>

<style>
	p {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.6em;
		min-width: 5.5ex;
	}
	svg {
		width: 0.75rem;
	}
	span {
		font-variant-numeric: tabular-nums;
	}
</style>

<p class="presence" in:fade={{ duration: 300 }}>
	<svg viewBox="0 0 58 49.88"><path d="M12.25 44.78h11.15c4.22 0 6.88 2.96 7.96 5.1h4.29c-1.01-3.52-5.23-9.1-12.25-9.1h-11.15c-7.03 0-11.24 5.58-12.25 9.1h4.29c1.09-2.14 3.74-5.1 7.96-5.1zM40.48 20.82c-5.74 0-10.42-4.67-10.42-10.41s4.68-10.41 10.42-10.41 10.41 4.67 10.41 10.41-4.67 10.41-10.41 10.41zm0-16.82c-3.54 0-6.42 2.88-6.42 6.41 0 3.54 2.88 6.41 6.42 6.41 3.53 0 6.41-2.88 6.41-6.41 0-3.53-2.88-6.41-6.41-6.41zM45.75 24.84h-11.15c-.75 0-1.45.07-2.14.19.04.43.07.87.07 1.32 0 .97-.1 1.91-.28 2.83.72-.21 1.5-.33 2.35-.33h11.15c4.22 0 6.88 2.96 7.96 5.1h4.29c-1.01-3.52-5.22-9.11-12.25-9.11zM18.13 36.76c-5.74 0-10.42-4.67-10.42-10.41s4.67-10.41 10.42-10.41c5.74 0 10.41 4.67 10.41 10.41-.01 5.74-4.67 10.41-10.41 10.41zm0-16.82c-3.54 0-6.42 2.88-6.42 6.41 0 3.54 2.88 6.41 6.42 6.41 3.53 0 6.41-2.88 6.41-6.41-.01-3.54-2.88-6.41-6.41-6.41z"/></svg>
	<span>{presence}</span>
</p>