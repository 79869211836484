<script>

	// NB: To close already-open avatar menus when a new one is opened, we use the technique here:
	// https://www.arzidava.com/blog/svelte-functions-on-children/

	import { onMount, onDestroy, tick, createEventDispatcher } from 'svelte';
	import { crossfade, fly, slide } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import moment from 'moment-timezone';
	import { byValue, byNumber } from 'sort-es';

	import Question from "./QA/Question.svelte";

	import Button from "../../ui/Button.svelte";
	import Switch from "../../ui/fields/Switch.svelte";
	import Menu from "../../ui/Menu.svelte";

	import {	event, bigError, attendees, attendee, attendeeLikes, session, syncClient, backstage } from '../../lib/stores.js';
	import { text_to_html } from '../../lib/html.js';
	import { postServerData } from '../../lib/prelude.js';
	import { randomIntFromInterval } from '../../lib/utils.js';
	import {	autoblur } from '../../lib/autoblur.js';

	const dispatch = createEventDispatcher();

	const utilsThreshold = 1;

	const [send, receive] = crossfade({
		duration: 0,
		fallback(node, params) {
			return {
				duration: 300,
				easing: quintOut,
				css: t => `
					transform: scale(${1 - ((1 - t) / 32)});
					opacity: ${t}
				`
			};
		}
	});

	export let studio = false;
	export let studioHighlight = null;

	let mounted = false;

	let channel;

	let questions = [];
	let displayQuestions = [];
	let bound = {};

	let approvedCount = 0;

	let filter = 'all';
	let sort = 'latest';

	let filterMenu = false;
	let sortMenu = false;

	let flipDuration = 400;

	let selectedMsg = null;

	let remaining = 400;
	let messageText = '';
	let anon = false;

	let ta;

	let rowTimeout;

	let mod = ($attendee.type == 'login') || ($backstage.includes($session.ref));

	const menuLabels = {
		'all': 'All questions',
		'pending': 'Pending',
		'answered': 'Answered',
		'unanswered': 'Unanswered',
		'latest': 'Latest',
		'likes': 'Most liked'
	};

	let filterOptions = [
		{ ref: 'all', label: menuLabels['all'] },
		{ ref: 'answered', label: menuLabels['answered'] },
		{ ref: 'unanswered', label: menuLabels['unanswered'] },
	];

	if (mod && $event.setup.virtual.questionModeration) {
		filterOptions.push({ ref: 'pending', label: menuLabels['pending'] });
	}

	onMount(async () => {

		try {

			// console.log("opening session channel (Q+A)");
			channel = await $syncClient.list($session.syncChannel);

			channel.on('itemAdded', function(i) {
				if (i.item.data.type == 'qa') {
					addQuestion(i.item);
				}
			});

			channel.on('itemUpdated', function(i) {
				if (i.item.data.type == 'qa') {
					updateQuestion(i.item);
				}
			});

			channel.on('itemRemoved', function(i) {
				if (i.previousItemData.type == 'qa') {
					deleteQuestion(i.index);
				}
			});

			await getQA();
			
		} catch (e) {

			console.log("Sync error", e, $session.syncChannel);
			
		}

		mounted = true;

	});

	function qaPageHandler(paginator) {
		if (paginator.items && paginator.items.length) {
			paginator.items.forEach(function(item) {
				if (item.data.type == 'qa') {
					addQuestion(item);
				}
			});
			return paginator.hasNextPage ? paginator.nextPage().then(qaPageHandler) : null;
		}
	};

	async function getQA() {
		let paginator;
		try {
			paginator = await channel.getItems({
				from: 0,
				order: 'asc',
				pageSize: 100
			});
		} catch (error) {
			if (error.message.match('Maximum attempt')) {
				// back off and try again later...
				const smear = 1000 * randomIntFromInterval(5,10);
				setTimeout(async () => {
					await getQA();
				}, smear);
			} else {
				console.error('getQA failed', error);
			}
		}
		if (paginator) await qaPageHandler(paginator);
	}

	function addQuestion(item) {
		// console.log('addQuestion', item);
		let question = processQuestion(item);
		question.index = item.index;
		questions.unshift(question);
		questions = questions;
	}

	async function updateQuestion(item) {
		// console.log('updateQuestion', item.data);
		const idx = item.index;
		for (let [i,r] of questions.entries()) {
			if (r.index == idx) {
				let question = processQuestion(item);
				question.index = r.index;
				questions[i] = question;
				break;
			}
		}
		questions = questions;
	}

	function deleteQuestion(idx) {
		// console.log('deleteQuestion', idx);
		questions = questions.filter(m => {
			if (m.index != idx) return m;
		});
	}

	function processQuestion(item) {
		let html = text_to_html(item.data.message);
		// Is item.data.approved also an epoch time??
		let sent = item.data.approved ? moment(item.data.approved) : moment.unix(item.data.dt);
		let q = {
			revision: item.index + '/' + item.revision,
			index: item.index,
			message: html,
			sent: sent,
			sender: item.data.sender,
			likes: item.data.likes ? item.data.likes : 0,
			liked: $attendeeLikes.includes($session.syncChannel + '/' + item.index) ? true : false,
			answered: item.data.answered ? true : false,
			approved: item.data.approved ? true : false,	
			anon: item.data.anon ? true : false,			
		};
		return q;
	}

	// console.log('backstage?', $session.ref, $backstage);

	async function sendQuestion() {
		if ((messageText != '') && (remaining >= 0)) {
			await postServerData('virtual/qa', {
				channelRef: $session.syncChannel,
				message: messageText,
				anon: anon
			});
			if (!$bigError) {
				messageText = '';
				await tick();
				resize({ target: ta });
				ta.focus();
			}
		}
	}

	function checkMessage(e) {
		if (e.code == 'Enter') {
			e.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
			e.preventDefault();
		}
	}

	function resize({ target }) {
		target.style.height = "1px";
		target.style.height = (+target.scrollHeight)+"px";
	}

	function autoresize(el) {
		resize({ target: el });
		el.style.overflow = 'hidden';
		el.addEventListener('input', resize);

		return {
			destroy: () => el.removeEventListener('input', resize)
		}
	}

	function setDisplayQuestions() {
		clearTimeout(rowTimeout);
		rowTimeout = setTimeout(function(){
			if (sort == 'likes') {
				displayQuestions = questions.sort(byValue(a => a.likes, byNumber({ desc: true })));
			} else {
				displayQuestions = questions.sort(byValue(a => a.index, byNumber({ desc: true })));
			}
			displayQuestions = (displayQuestions && displayQuestions.length) ? displayQuestions.filter(filterByValue) : [];
			// console.log(questions);
			approvedCount = questions.filter(q => q.approved).length;
			// console.log('setDisplayQuestions', sort, filter, approvedCount);
		}, 100);
	}

	function filterByValue(q) {
		if (!q.approved && !mod && !(q.sender == $attendee.ref)) return false;
		if (filter == 'answered') {
			return q.answered;
		} else if (filter == 'unanswered') {
			return !q.answered;
		} else if (filter == 'pending') {
			return !q.approved;
		} else {
			return true;
		}
	}

	function setFilter(e) {
		filter = e.detail.ref;
		filterMenu = false;
		flipDuration = 200;
		setTimeout(() => {
			flipDuration = 400;
		}, 400);
	}

	function setSort(e) {
		sort = e.detail.ref;
		sortMenu = false;
		flipDuration = 200;
		setTimeout(() => {
			flipDuration = 400;
		}, 400);
	}

	function closeMenus(e) {
		let except = e.detail;
		for (const [k,v] of Object.entries(bound)) {
			if (k != except) {
				v.closeMenu();
			}
		}
	}

	function closeSortMenu() {
		sortMenu = false;
	}

	function closeFilterMenu() {
		filterMenu = false;
	}

	$: remaining = 400 - messageText.length;

	$: if (questions || sort || filter) {
		setDisplayQuestions();
	}

	$: if (filterMenu) {
		closeSortMenu();
	}

	$: if (sortMenu) {
		closeFilterMenu();
	}

	onDestroy(() => {
		// console.log("closing session channel (Q+A)");
		if (channel) channel.close();
	});

</script>

<style>
	.wrap {
		position: absolute;
		inset:  0;
		display: flex;
		flex-direction: column;
	}
	.qa {
		flex: 1 1 auto;
		overflow-y: auto;
		padding: 0 0.5rem 1rem 0.5rem;
	}

	.qa .slate {
		padding-top: 2rem;
	}

	.qa > div {
		margin-top: 0.5rem;
	}


	.utils {
		position: sticky;
		top: 0;
		margin: 0 0.4rem;
		padding: 0 0 0.3rem 0;
		border-bottom: 1px solid var(--blend-80, #888);
		background: var(--panelColor);
		font-size: 0.625rem;;
		z-index: 100;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}

	.util {
		display: flex;
		gap: 0.2rem;
	}
	.util span {
		position: relative;
		display: block;
		margin: 0;
		padding: 0.25rem 1.2em 0.2rem 0;
		font-weight: 400;
		font-size: 0.6875rem;
	}
	.util svg {
		display: inline-block;
		width: 0.5em;
		transform: rotate(90deg);
		position: relative;
		left: -0.25rem;
		transition: transform 0.2s ease;
	}
	.util.open svg {
		transform: rotate(-90deg);
	}
	.util:hover {
		cursor: pointer;
		color: var(--accentColor, #e6007e);
	}

	.util :global(.menu) {
		position: absolute;
		top: calc(100% - 2px);
		left: 0;
		width: 100%;
	}

	.send {
		flex: 0 0 auto;
	}
	.send > form {
		margin: 0 0.4rem;
		border-top: 1px solid var(--blend-20);
		padding: 0.5rem;
		font-size: 0.6875rem;
	}
	.send > form > div {
		margin: 0.5rem 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.send label {
		font-weight: 600;
		line-height: 1;
	}
	.counter {
		font-size: 0.625rem;
		line-height: 1;
		color: var(--blend-60);
	}
	.counter.over {
		color: var(--red);
	}
	.send textarea {
		border: 1px solid var(--blend-20);
		border-radius: 3px;
		padding: 0.5rem;
		width: 100%;
		min-height: 2.4rem;
		max-height: 6rem;
		background: none;
		resize: none;
		font-family: Inter;
		font-size: 0.6875rem;;
		color: var(--textColor);
		text-align: left;
	}
	.send textarea:focus {
		outline: none;
		border-color: var(--blend-60);
	}
	.send :global(button) {
		margin-left: auto;
		padding: 0.1rem 1rem;
	}
	.send .switch {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
	.send .switch label {
		font-weight: 400;
		color: var(--blend-60);
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: var(--blend-40);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: var(--blend-40);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: var(--blend-40);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: var(--blend-40);
	}

</style>

{#if mounted && Object.keys($attendees).length}
	<div class="wrap" in:fly={{ duration: 300, y: 20 }}>
		<div class="qa">

			{#if ((mod && questions.length > utilsThreshold) || (approvedCount.length > utilsThreshold))}
				<div class="utils" transition:slide|local={{ duration: 400, delay: 400 }}>
					<!-- TODO: nest a button instead -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<div
						class="util"
						class:open={filterMenu}
						on:click|stopPropagation={() => { filterMenu = !filterMenu }}
					>
						<span>{menuLabels[filter]}</span>
						<svg viewBox="0 0 16.7 29.5"><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"/></svg>
						{#if filterMenu}
							<Menu
								options={filterOptions}
								on:selected={setFilter}
								on:escape={closeFilterMenu}
								arrow={false}
								tick={filter}
							/>
						{/if}
					</div>
					<!-- TODO: nest a button instead -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<div class="util" class:open={sortMenu} on:click|stopPropagation={() => { sortMenu = !sortMenu }}>
						<span>{menuLabels[sort]}</span>
						<svg viewBox="0 0 16.7 29.5"><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"/></svg>
						{#if sortMenu}
							<Menu
								options={[
									{ ref: 'latest', label: menuLabels['latest'] },
									{ ref: 'likes', label: menuLabels['likes'] },
								]}
								on:selected={setSort}
								on:escape={closeSortMenu}
								arrow={false}
								tick={sort}
							/>
						{/if}
					</div>
				</div>
			{/if}

			{#each displayQuestions as q (q.index)}
				<div
					in:receive|local="{{ key: q.index }}"
					out:send|local="{{ key: q.index }}"
					animate:flip="{{ duration: flipDuration }}"
				>
					<Question
						bind:this={bound[q.index]}
						bind:q
						bind:selectedMsg
						bind:questions
						{mod}
						{studio}
						{studioHighlight}
						on:highlight
						on:openMenu={closeMenus}
					/>
				</div>
			{:else}
				{#if (filter == 'all')}
					<div class="slate">
						<p><svg viewBox="0 0 32 32"><path d="M18.55 21.92l-9.65 6.21v-6.21l-5.75-.01c-1.74.01-3.15-1.45-3.15-3.24v-13.42c0-1.79 1.41-3.25 3.15-3.25h21.78c1.7 0 3.07 1.41 3.07 3.15v13.52c0 1.79-1.41 3.25-3.15 3.25h-6.3zm6.16-2c.84 0 1.29-.62 1.29-1.38v-13.25c0-.71-.54-1.29-1.2-1.29h-21.51c-.71 0-1.29.62-1.29 1.38v13.16c0 .76.58 1.38 1.15 1.38l7.75.01v4.55l7.06-4.55 6.75-.01zM12.94 14.14c.01-1.79.52-2.34 1.4-2.89.6-.38 1.06-.86 1.06-1.55 0-.78-.61-1.29-1.37-1.29-.7 0-1.38.45-1.42 1.38h-1.77c.05-1.89 1.46-2.85 3.2-2.85 1.9 0 3.21 1.05 3.21 2.74 0 1.14-.57 1.89-1.49 2.43-.81.5-1.15.98-1.16 2.03v.13h-1.66v-.13zm-.23 2.14c0-.6.49-1.08 1.09-1.08.59 0 1.09.49 1.09 1.08 0 .61-.51 1.09-1.09 1.09-.6 0-1.09-.48-1.09-1.09z"/></svg></p>
						<p><strong>No questions yet.</strong></p>
						<p>Be the first to ask a question.</p>
					</div>
				{/if}
			{/each}
		</div>

		<div class="send">
			<form on:submit|preventDefault={sendQuestion}>
				<div class="label">
					<label for="question">Your question</label>
					<span class="counter" class:over={remaining < 0}>{remaining}</span>
				</div>
				<div>
					<textarea
						id="question"
						bind:this={ta}
						bind:value={messageText}
						use:autoresize
						placeholder="Type your question"
						on:keypress={checkMessage}
					></textarea>
				</div>
				<div>
					{#if $event.setup.virtual.questionAnonymity}
						<p class="switch">
							<Switch ref="anon" bind:value={anon}/>
							<label for="anon">Hide my name</label>
						</p>
					{/if}
					<Button type="submit" label="Ask" disabled={!messageText} grow={false}/>	
				</div>
			</form>
		</div>
	</div>
{/if}