<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from 'svelte/transition';

	import Upload from "./Clips/Upload.svelte";
	import ListItem from "./Clips/ListItem.svelte";

	import Overlay from "../../../ui/Overlay.svelte";
	import Button from "../../../ui/Button.svelte";

	import { event } from '../../../lib/stores.js';

	const clips = getContext('clips');
	const clipTokens = getContext('clipTokens');

	let mounted = false;
	let overlay = false;

	let clipsList = [];

	onMount(() => {
		setClipsList(true);
		mounted = true;
	});

	function setClipsList(force) {
		if (force || mounted) {	
			let items = [];
			if ($event.setup.virtual.studio && $event.setup.virtual.studio.clips) {
				for (const b of $event.setup.virtual.studio.clips) {
					if ($clipTokens[b.content.ref]) {
						b.content.allowDelete = false;
						items.push(b.content);
					}
				}
			}
			for (const b of $clips) {
				if ($clipTokens[b.ref]) {
					b.allowDelete = true;
					items.push(b);
				}
			}
			clipsList = items;
		}
	}

	$: if ($clips || $clipTokens) {
		setClipsList();
	}

</script>

<style>
	.list {
		flex: 1 1 auto;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		overflow: scroll;
	}
	/*.actions {
		flex: 0 0 auto;
		padding: 0.75rem;
	}*/
	.actions :global(.label) {
		font-size: 0.75rem;
	}
</style>

{#if mounted}
	<div class="list p{clipsList.length}" in:fly={{ duration: 300, y: 20 }}>
		{#each clipsList as video}
			<ListItem {video}/>
		{/each}
		<div class="actions">
			<Button
				subtle={true}
				on:click={() => { overlay = true }}
				label="Upload a new video clip"
			>
				<svg viewBox="0 0 80 80"><path d="M43.24 43.21v15.55h-6.36v-15.55h-15.55v-6.36h15.55v-15.55h6.36v15.55h15.56v6.36h-15.56z"/></svg>
			</Button>
		</div>
	</div>

	<!-- <div class="actions" in:fly={{ duration: 300, y: 20 }}>
		<Button ghost={false} wide={true} on:click={() => { overlay = true }} label="Add a new video clip"/>
	</div> -->

	{#if overlay}
		<Overlay on:escape={() => { overlay = false }} modal={true}>
			<Upload on:saved={() => { overlay = false }} />
		</Overlay>
	{/if}
{/if}