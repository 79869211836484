<script>

	import { onMount, onDestroy } from 'svelte';
	import { fly } from "svelte/transition";

	import moment from 'moment-timezone';

	import Chat from '../../Auditorium/Interactions/Chat.svelte';

	import Button from "../../ui/Button.svelte";
	import Avatar from "../../ui/Avatar.svelte";
	import Dialog from "../../ui/Dialog.svelte";
	import Overlay from "../../ui/Overlay.svelte";

	import Eject from "../../ui/Reports/Eject.svelte";

	import { event, attendee, attendees, shadowbans, bigError, epoch } from '../../lib/stores.js';
	import {	autoblur } from '../../lib/autoblur.js';
	import { getServerData, postServerData } from '../../lib/prelude.js';
	import { text_to_html } from '../../lib/html.js';
	import { openConversation } from '../../lib/conversations.js';
	import { showDate } from '../../lib/dt.js';

	export let report;
	export let channel;

	let mounted = false;
	let tooltip = null;

	let details = {};
	let reportee = {};
	let reporter = {};

	let shadowbanDialog = null;

	let ejectOverlay = false;
	let ejectMessage = '';

	// function deleteReport() {
	// 	channel.remove(report.index);
	// 	report = null;
	// }

	onMount(async () => {

		await loadReport(true);

		if (!$bigError) {

			if (channel) channel.mutate(report.index, markAsRead);

			mounted = true;

		}

	});

	onDestroy(() => {
		closeReport();
	});

	const markAsRead = (currentData) => {
		currentData.read = moment().unix();
		return currentData;
	};

	function closeReport() {
		if (channel && report) channel.mutate(report.index, markAsRead);
		report = null;
	}

	async function loadReport(m) {
		if (mounted || m) {

			const reportData = await getServerData('virtual/reports', {
				report: report.report.ref
			});

			if (!$bigError) {
				details = reportData.report;
				reportee = reportData.reportee;
				reporter = reportData.reporter;
			}

		}
	}

	function getName(r) {
		if ($attendees[report.report.reporter].f == $attendees[report.report.reportee].f) {
			return $attendees[report.report[r]].f + ' ' + $attendees[report.report[r]].l;
		} else {
			return $attendees[report.report[r]].f;
		}
	}

	async function openConversationWithReportee() {
		await openConversation([report.report.reportee], null, true);
		postServerData('virtual/chat', {
			channelRef: report.report.channel,
			message: "I’ve opened a conversation with " + getName('reportee') + '.'
		});
	}

	async function openConversationWithReporter() {
		await openConversation([report.report.reporter], null, true);
		postServerData('virtual/chat', {
			channelRef: report.report.channel,
			message: "I’ve opened a conversation with " + getName('reporter') + '.'
		});
	}

	async function shadowbanReportee() {
		shadowbanDialog = {
			text: "Are you sure you want to shadow ban this attendee?",
			info: [
				"No content they post will be visible to other attendees.",
				"The attendee will be unaware of this."
			],
			actions: [
				{
					label: "Confirm",
					dispatch: "confirm"
				},
				{
					label: "Cancel",
					ghost: true,
					dispatch: "escape"
				}
			]
		};
	}

	async function ejectReportee() {
		ejectOverlay = true;
	}

	async function doShadowban() {

		shadowbanDialog = null;

		await postServerData('virtual/shadowban', {
			attendee: report.report.reportee
		});

		if (reportee && !$bigError) {

			reportee.shadowban = !reportee.shadowban;

			postServerData('virtual/chat', {
				channelRef: report.report.channel,
				message: "I’ve shadow banned " + getName('reportee') + '.'
			});

		}

	}

	async function doEject() {

		ejectOverlay = false;

		await postServerData('virtual/eject', {
			attendee: report.report.reportee,
			message: ejectMessage
		});

		if (reportee && !$bigError) {

			reportee.ejected = !reportee.ejected;

			postServerData('virtual/chat', {
				channelRef: report.report.channel,
				message: "I’ve ejected " + getName('reportee') + '.'
			});

		}

	}

	$: if ($shadowbans) {
		loadReport();
	}

</script>

<style>
	.report {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}

	.overlay-title {
		display: flex;
		justify-content: center;
		padding-top: 1.4rem;
		padding-bottom: 1.4rem;
		text-align: center;
		color: var(--blend-60);
	}

	.overlay-title h2 {
		color: var(--textColor);
		margin-bottom: 0.25rem;
	}

	.overlay-title h2 + p {
		margin-top: 0.25rem;
	}

	button.back {
		position: absolute;
		top: 0;
		left: 0;
		width: 3rem;
		height: 3rem;
		border: 0;
		background:	transparent;
		color: var(--textColor);
		padding: 0;
	}
	button.back svg {
		position: absolute;
		/*inset: 0.7rem;*/
		top: 0.7rem;
		left: 0.7rem;
		width: calc(100% - 1.4rem);
		height: calc(100% - 1.4rem);
	}
	button.back:hover {
		color: var(--accentBlend-120);
	}

	/*.reportee {
		position: relative;
		display: flex;
		gap: 0.5rem;
		line-height: 1.4;
		font-size: 0.8rem;
		align-items: center;
	}
	.reportee :global(.avatar) {
		position: relative;
		top: 0;
		left: 0;
		width: 3.2rem;
		height: 3.2rem;
	}
	.reportee :global(.avatar abbr) {
		font-size: 1.28rem;
	}*/

	.overlay-main {
		box-sizing: border-box;
		padding: 1rem 2rem;
	}

	.split {
		display: flex;
		width: 100%;
		height: 100%;
	}

	.split > div {
		flex: 0 0 calc(50% - 1rem);
		padding-right: 2rem;
		position: relative;
	}

	.split > div + div {
		padding-right: 0;
		overflow: visible;
	}

	.split > div + div:before {
		content: '';
		position: absolute;
		top: 0;
		left: -1px;
		width: 2px;
		bottom: 0.5rem;
		background: var(--panelColor);
	}

	@media (max-width: 700px) {

		.overlay-main {
			overflow-y: auto;
		}

		.split {
			flex-wrap: wrap;
			overflow: hidden;
			height: auto;
		}

		.split > div {
			flex: 0 0 100%;
			padding-right: 0;	
		}

		/*.split > div + div {
			flex: 0 0 calc(100% + 6rem);			
		}*/

		.split > div + div:before {
			display: none;
		}

		.split .panel > div {
			position: static;
			padding: 0;
			margin-bottom: 2rem;
		}

		.split .chat > div {
			position: static;
			margin-top: 2rem;
		}

	}

	.chat > div {
		position: absolute;
		left: 1rem;
		top: 0;
		right: 0;
		bottom: 0.5rem;
		display: flex;
		flex-direction: column;
	}

	.chat > div > div {
		flex: 0 0 auto;
		padding: 0 2.8rem;
		position: relative;
		/*color: var(--blend-80);*/
	}

	.chat > div > div > svg {
		position: absolute;
		left: 0.8rem;
		top: 0;
		width: 1.6rem;
		height: 1.6rem;
	}

	.chat > div > div > h3 {
		font-size: 0.8rem;
		margin: 0 0 0.25rem 0;
		/*color: var(--blend-80);*/
	}

	.chat > div > div > p {
		font-size: 0.625rem;
		margin-bottom: 1rem;
		color: var(--blend-60);
	}

	/*@media (min-width: 700px) and (max-width: 950px) {
		.chat > div > div > p {
			font-size: 0.55rem;
			margin-right: -1rem;
			margin-bottom: 1.2rem;
		}
	}*/

	@media (max-width: 700px) {
		.chat > div {
			left: 0;
			min-height: 400px;
		}
	}

	.chat > div > div + div {
		flex: 1 0 auto;
		padding: 0;
	}

	.lozenge {
		display: inline-block;
		background: transparent;
		border: 1px solid var(--red);
		color: var(--red);
		padding: 0 0.2rem;
		border-radius: 4px;
		vertical-align: middle;
		line-height: 0;
		position: relative;
	}

	.lozenge.red {
		background: var(--red);
		color: var(--panelColor);
	}

	.lozenge svg {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		vertical-align: middle;
		pointer-events: none;
	}

	.lozenge span {
		display: inline-block;
		vertical-align: middle;
		font-size: 0.625rem;
		font-weight: 400;
		position: relative;
		left: -0.2rem;
		/*line-height: 1;*/
		pointer-events: none;
	}

	.lozenge .tooltip {
		position: absolute;
		top: 1.6rem;
		left: 0;
		/*transform: translate(100%, -50%);*/
		border-radius: 4px;
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.4rem 0.8rem;
		line-height: 1.2;
		font-size: 0.6875rem;
		font-weight: 400;
		width: 7rem;
		z-index: 1000;
	}
	.tooltip:before {
		content: "";
		position: absolute;
		top: -0.1rem;
		left: 0.5rem;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translateY(-50%);
		background: var(--panelColor);
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}

	.details {
		font-size: 0.6875rem;
	}

	.details + .details {
		margin-top: 1rem;
		border-top: 1px solid var(--blend-20);
		padding-top: 0.5rem;
	}

	.reportee {
		position: relative;
		padding-left: 4rem;
		min-height: 3rem;
		font-size: 0.8rem;
	}
	.reportee :global(.avatar) {
		left: 0rem;
		top: 0rem;
		width: 3rem;
		height: 3rem;
	}

	.reporter {
		margin-top: 0.75rem;
		position: relative;
		padding-left: 2rem;
		min-height: 1.6rem;
	}
	.reporter :global(.avatar) {
		left: 0rem;
		top: 0rem;
	}

	.lozenges {
		margin-top: 0.75rem;
	}

	.value {
		/*font-size: 0.8rem;*/
		margin-top: 0.25rem;
	}

	.value :global(p + p) {
		margin-top: 0.5rem;
	}

	.further {
		margin-top: 0.5rem;
		color: var(--blend-80);
	}

	.further + .further {
		margin-top: 0.25rem;
	}

	.actions :global(button + button) {
		margin-left: 0;
	}

	.control {
		display: flex;
		flex-direction: column;
	}

	.panel {
		position: relative;
		flex: 1 0 auto;
	}

	.panel > div {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -2rem;
		right: -2rem;
		overflow-y: auto;
		padding: 0 2rem 1rem 2rem;
	}

	.actions {
		flex: 0 0 auto;
		padding: 1rem 0;
		border-top: 1px solid var(--blend-20);
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
</style>

{#if report && mounted}
	<div class="report">
		<div class="overlay-title">
			
			<button class="back" type="button" on:click={closeReport}>
				<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
			</button>
			
			<!-- <div class="reportee">
				<Avatar identity={$attendees[report.report.reportee]}/>	
				<p>	
					<strong>{$attendees[report.report.reportee].f} {$attendees[report.report.reportee].l}</strong>
					{#if $attendees[report.report.reportee].j}<br/>{$attendees[report.report.reportee].j}{/if}
					{#if $attendees[report.report.reportee].o}<br/>{$attendees[report.report.reportee].o}{/if}
				</p>
			</div> -->

			<div>
				<h2>Reported: {$attendees[report.report.reportee].f} {$attendees[report.report.reportee].l}</h2>
				<p>{showDate(moment.unix(report.dt))}</p>
			</div>

		</div>
		<div class="overlay-main">
			<div class="split">
				<div class="control">

					<div class="panel">
						<div>

							<div class="details">
								<div class="reportee">
									<Avatar identity={$attendees[report.report.reportee]}/>	
									<p>	
										<strong>{$attendees[report.report.reportee].f} {$attendees[report.report.reportee].l}</strong>
										{#if $attendees[report.report.reportee].j}<br/>{$attendees[report.report.reportee].j}{/if}
										{#if $attendees[report.report.reportee].o}<br/>{$attendees[report.report.reportee].o}{/if}
									</p>

									<div class="lozenges">
										{#if reportee.muted}
											<div
												class="lozenge"
												on:mouseover={() => { tooltip = 'muted' }}
												on:mouseout={() => { tooltip = null }}
												on:focus={() => { tooltip = 'muted' }}
												on:blur={() => { tooltip = null }}
											>
												<svg viewBox="0 0 17 17"><path d="M4.59 10.22c-.13-.01-.22-.11-.22-.24v-2.96c0-.13.09-.23.22-.24.35-.03.71-.08 1.08-.15l-.75-.9c-.37.05-.74.08-1.07.08-.13 0-.25.05-.34.14-.09.09-.14.21-.14.34v4.43c0 .13.05.25.14.34.09.09.21.14.34.14 2.36 0 5.85 1.18 7.97 3.16.09.09.22.14.34.14l.1-.02-2.11-2.52c-1.84-1.01-3.89-1.6-5.56-1.74zM14.2 13.67l-1.57-1.87v-8.82c0-.19-.12-.36-.29-.44l-.18-.04c-.12 0-.24.05-.34.14-1.27 1.19-3.01 2.06-4.71 2.59l-2.13-2.54c-.18-.21-.49-.24-.7-.06-.21.18-.24.49-.06.7l9.21 10.98c.1.12.24.18.38.18.11 0 .23-.04.32-.12.22-.17.24-.48.07-.7zm-2.57-9.56v6.51l-3.82-4.56c1.33-.45 2.67-1.09 3.82-1.95z"/></svg>
												<span>{reportee.muted}</span>
												{#if tooltip == 'muted'}
													<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>{reportee.muted} {#if reportee.muted > 1}people have{:else}person has{/if} muted this attendee</span>
												{/if}
											</div>
										{/if}
										{#if reportee.reports}
											<div
												class="lozenge"
												on:mouseover={() => { tooltip = 'reported' }}
												on:mouseout={() => { tooltip = null }}
												on:focus={() => { tooltip = 'reported' }}
												on:blur={() => { tooltip = null }}
											>
												<svg viewBox="0 0 17 17"><path d="M6.01 14.41l-1 .09-1.03-11.77.99-.09zM6.36 9.12c.49-.19 1.62-.27 2.45.2.79.45 1.46.61 2.01.61 1.02 0 1.66-.54 2.04-.86.23-.2.35-.47.33-.75l-.48-5.39c-.01-.15-.1-.28-.23-.34-.13-.07-.29-.06-.41.02l-.4.26c-.92.6-1.38.9-2.95.08-.95-.5-2.15-.53-2.92-.33l.56 6.5zm5.52-.76c-.5.41-1.22.82-2.62.02-.58-.33-1.28-.43-1.89-.4l-.38-4.38c.45-.01.99.07 1.45.31 1.51.79 2.25.59 2.99.17.12-.07.27.01.28.15l.31 3.81c.01.12-.04.24-.14.32z"/></svg>
												<span>{reportee.reports}</span>
												{#if tooltip == 'reported'}
													<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>{reportee.reports} {#if reportee.reports > 1}people have{:else}person has{/if} reported this attendee</span>
												{/if}
											</div>
										{/if}
										{#if reportee.shadowban}
											<div
												class="lozenge red"
												on:mouseover={() => { tooltip = 'shadowban' }}
												on:mouseout={() => { tooltip = null }}
												on:focus={() => { tooltip = 'shadowban' }}
												on:blur={() => { tooltip = null }}
											>
												<svg viewBox="0 0 17 17"><ellipse cx="7.51" cy="6.99" rx=".63" ry="1.09"/><ellipse cx="9.65" cy="6.99" rx=".63" ry="1.09"/><path d="M8.54 13.27c-.26 0-.45.09-.66.25-.29.23-.65.51-1.14.51-.6 0-.91-.31-1.26-.72-.29-.35-.41-.45-.53-.45-.09 0-.23.06-.36.16-.27.2-.66.42-1.05.37-.19-.02-.36-.13-.46-.29-.25-.37-.12-.96-.06-1.19.07-.25.3-.89.54-1.52l.27-.74c.2-.54.31-.81.38-1.45.1-.89.38-2.18.87-3.1.74-1.4 1.93-2.13 3.46-2.13s2.73.73 3.46 2.13c.49.92.77 2.21.87 3.1.07.65.18.91.38 1.45l.27.74c.23.63.47 1.27.54 1.52.07.23.19.82-.06 1.19-.11.16-.27.27-.46.29-.39.05-.79-.17-1.05-.37-.13-.1-.26-.16-.36-.16-.12 0-.24.1-.53.45-.35.41-.66.72-1.26.72-.49 0-.85-.28-1.14-.51-.21-.16-.39-.25-.66-.25zm0-.98c.61 0 .99.27 1.26.48.17.13.34.27.51.27.15 0 .21 0 .55-.39.3-.35.64-.75 1.21-.76.29 0 .5.1.66.18.13.07.47.27.26-.32l-.39-1.09-.27-.71c-.21-.54-.34-.9-.43-1.65-.09-.76-.33-1.93-.75-2.73-.56-1.07-1.44-1.61-2.6-1.61s-2.05.55-2.61 1.62c-.42.8-.67 1.97-.75 2.73-.08.74-.22 1.1-.43 1.64l-.26.72-.39 1.09c-.22.59.13.38.26.32.16-.08.37-.19.66-.18.57.01.91.4 1.21.76.33.39.4.39.55.39.17 0 .34-.14.51-.27.25-.22.63-.49 1.24-.49z"/></svg>
												{#if tooltip == 'shadowban'}
													<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>This attendee is currently subject to a shadow ban</span>
												{/if}
											</div>
										{/if}
										{#if reportee.ejected}
											<div
												class="lozenge red"
												on:mouseover={() => { tooltip = 'ejected' }}
												on:mouseout={() => { tooltip = null }}
												on:focus={() => { tooltip = 'ejected' }}
												on:blur={() => { tooltip = null }}
											>
												<svg viewBox="0 0 17 17"><path d="M11.53 4.96c-.13 0-.26.03-.38.07v-.61c0-.62-.51-1.13-1.13-1.13-.15 0-.29.03-.42.08-.12-.5-.57-.87-1.1-.87-.54 0-1 .39-1.11.9-.12-.05-.26-.08-.4-.08-.62 0-1.13.51-1.13 1.13v3.51l-.1-.17c-.28-.56-.96-.78-1.52-.49-.56.28-.78.96-.49 1.52l1.76 3.45.04.07c.33.59 1.21 2.16 3.37 2.16 1.13 0 2.22-.42 2.9-1.12.57-.58.86-1.33.84-2.16v-5.13c0-.63-.51-1.13-1.13-1.13zm.2 6.13c.01.61-.19 1.13-.6 1.55-.52.53-1.36.85-2.25.85-1.63 0-2.3-1.18-2.58-1.69l-1.7-3.32c-.09-.18-.02-.4.16-.49.09-.04.18-.05.28-.02.08.03.16.09.2.18l.74 1.42.06.11.42.81c.09.18.31.24.48.15.18-.09.24-.31.15-.48l-.41-.8v-4.71c0-.2.16-.36.36-.36.2 0 .36.16.36.36v3.47c0 .2.16.36.36.36.2 0 .36-.16.36-.36v-4.25c0-.2.16-.36.36-.36s.36.16.36.36v4.48c0 .2.16.36.36.36.2 0 .36-.16.36-.36v-3.73c0-.2.16-.36.36-.36.2 0 .36.16.36.36v3.87c0 .2.16.36.36.36.2 0 .36-.16.36-.36v-2.29c0-.2.16-.36.36-.36s.36.16.36.36v4.89z"/></svg>
												{#if tooltip == 'ejected'}
													<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>This attendee has been ejected from the event</span>
												{/if}
											</div>
										{/if}
									</div>

								</div>
							</div>

							<div class="details">
								<p class="label"><strong>Category:</strong></p>
								<p class="value">{ details.category }</p>
							</div>

							<div class="details">
								<p class="label"><strong>Further details:</strong></p>
								<div class="value">{ @html text_to_html(details.details) }</div>
							</div>

							<div class="details">
								<p class="label"><strong>Reported by:</strong></p>
								<div class="reporter">
									<Avatar identity={$attendees[report.report.reporter]}/>	
									<p>	
										<strong>{$attendees[report.report.reporter].f} {$attendees[report.report.reporter].l}</strong>
										{#if $attendees[report.report.reporter].j}<br/>{$attendees[report.report.reporter].j}{/if}
										{#if $attendees[report.report.reporter].o}<br/>{$attendees[report.report.reporter].o}{:else}<br/>AZN{/if}
									</p>
								</div>

								<p class="further">{$attendees[report.report.reporter].f} has reported a total of {reporter.people_reported} {#if reporter.people_reported > 1}attendees{:else}attendee{/if}.</p>

								{#if reporter.reportee_reported > 1}
									<p class="further">{$attendees[report.report.reporter].f} has reported {$attendees[report.report.reportee].f} {reporter.reportee_reported} times.</p>
								{/if}

							</div>

						</div>
					</div>

					<div class="actions">
						{#if !reportee.ejected}
							<Button
								wide={true}
								mini={true}
								ghost={true}
								label={"Send message to " + getName('reportee')}
								on:click={openConversationWithReportee}
							/>
						{/if}
						{#if !(report.report.reporter == $attendee.ref)}
							<Button
								wide={true}
								mini={true}
								ghost={true}
								label={"Send message to " + getName('reporter')}
								on:click={openConversationWithReporter}
							/>
						{/if}
						{#if !reportee.shadowban && !reportee.ejected}
							<Button
								wide={true}
								mini={true}
								red={true}
								label={"Shadow ban " + getName('reportee')}
								on:click={shadowbanReportee}
							/>
						{/if}
						{#if !reportee.ejected}
							<Button
								wide={true}
								mini={true}
								red={true}
								label={"Eject " + getName('reportee') + " from event"}
								on:click={ejectReportee}
							/>
						{/if}
					</div>

				</div>
				<div class="chat">
					<div>
						<div class="chat-heading">
							<svg viewBox="0 0 32 32"><path d="M24.94 2h-21.79c-1.74 0-3.15 1.46-3.15 3.25v21.51c0 1.78 1.41 3.24 3.15 3.24h17.26c.28 0 .53-.11.71-.29l6.58-6.58c.18-.18.29-.43.29-.71v-17.27c.01-1.74-1.36-3.15-3.05-3.15zm-4.93 26.01v-6.01h5.99l-5.99 6.01zm-2-8.01v8.01l-14.86-.01c-.57 0-1.15-.62-1.15-1.38v-21.24c0-.76.58-1.38 1.29-1.38h21.51c.66 0 1.2.58 1.2 1.29v14.71h-7.99z"/></svg>
							<h3>Notes</h3>
							<p>You can leave comments for your colleagues here.</p>
						</div>
						<div>
							<Chat
								isPrivate={true}
								channelRef={report.report.channel}
								slateText=""
								emojis={false}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}

{#if shadowbanDialog}
	<Dialog
		{...shadowbanDialog}
		on:escape={() => { shadowbanDialog = null}}
		on:confirm={doShadowban}
	/>
{/if}

{#if ejectOverlay}
	<Overlay on:escape={() => { ejectOverlay = false }}>
		<Eject
			attendee={$attendees[report.report.reportee]}
			bind:ejectMessage
			on:escape={() => { ejectOverlay = false }}
			on:confirm={doEject}
		/>
	</Overlay>
{/if}