<script>

	import Back from "./Back.svelte";

	import {	event, displayLang, socialMediaServiceOpts } from '../../lib/stores.js';
	import { text_to_html } from '../../lib/html.js';

	export let speaker;

	let socialMedia = [];

	// console.log({speaker});

	if ($event.setup.virtual.socialMedia) {
		for (const sm of $event.setup.virtual.socialMedia) {
			if (speaker[sm.service.toLowerCase()]) {
				socialMedia.push({
					icon: getIcon(sm.service),
					href: speaker[sm.service]
				});
			}
		}
	}

	function getIcon(sm) {
		for (const s of $socialMediaServiceOpts) {
			if (s.name == sm) {
				return s.icon;
			}
		}
	}

</script>

<style>
	.speaker {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.speaker .details {
		flex: 1 0 auto;
		position: relative;
	}
	.speaker .details > div {
		position: absolute;
		inset: 0;
		overflow-y: auto;
		padding: 0 1rem 2rem 1rem;
		box-sizing: border-box;
	}
	p {
		margin: 2rem 0 0 0;
	}
	.avatar {
		display: block;
		width: 4rem;
		height: 4rem;
		position: relative;
		margin-bottom: 1rem;
	}
	.avatar img,
	.avatar abbr {
		border-radius: 100%;
	}
	.avatar abbr {
		position: absolute;
		inset: 0;
		display: grid;
		place-content: center;
		border-radius: 100px;
		background: var(--textColor);
		color: var(--panelColor);
		line-height: 0;
		text-decoration: none;
		border: 0;
		font-size: 1.6rem;
		font-weight: 200;
		user-select: none;
	}
	.bio {
		margin: 1rem 0 0 0;
		font-size: 0.6875rem;
	}
	.bio :global(p) {
		margin: 0.5rem 0 0 0;
	}

	.links {
		margin: 1.4rem 0 0 0;
		display: flex;
		gap: 1rem;
	}

	.links a {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		position: relative;
	}

	.links a {
		color: var(--accentColor);
	}

	.links a:hover,
	.links a:active,
	.links a:focus {
		color: var(--accentBlend-120);
	}

	.links :global(svg) {
		position: absolute;
		inset: 0;
	}

</style>

<div class="speaker">
	<Back on:back/>
	<div class="details">
		<div>

			<p>
				<span class="avatar">
					{#if speaker.a}
						<img src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{speaker.a}" alt=""/>
					{:else}
						<abbr title="{speaker.f} {speaker.l}">{#if speaker.f && speaker.l}{speaker.f.charAt(0).toUpperCase()}{speaker.l.charAt(0).toUpperCase()}{:else}–{/if}</abbr>
					{/if}
				</span>
				<strong>{speaker.n}</strong>
				{#if speaker.j}<br/>{speaker.j}{/if}
				{#if speaker.o}<br/>{speaker.o}{/if}
			</p>

			{#if speaker.bio && speaker.bio[$displayLang] && speaker.bio[$displayLang].html}
				<div class="bio">
					{@html speaker.bio[$displayLang].html}
				</div>
			{/if}

			{#if socialMedia.length}
				<div class="links">
					{#each socialMedia as sm}
						<a href={sm.href} target="_blank" rel="noreferrer">
							{@html sm.icon}
						</a>
					{/each}
				</div>
			{/if}

		</div>
	</div>
</div>