<script>

	import { displayLang } from '../../../lib/stores.js';

	export let stc;
	export let details;

</script>

<div class="component">
	{#if details[$displayLang]}
		<p class="label">{stc.label[$displayLang]}</p>
		<p>{details[$displayLang]}</p>
	{/if}
</div>