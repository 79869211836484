<script>

	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let gif;

	function handleKeydown(event){
		if (event.key === 'Escape') {
			dispatch('cancel');
		} else if (event.key === 'Backspace') {
			dispatch('cancel');
		} else if (event.key === 'Enter') {
			dispatch('send');
		}
	}

</script>

<style>
	.gif {
		border: 0;
		padding: 0;
		width: 100%;
		flex: 1 1 auto;
		min-height: 2.4rem;
		max-height: 6rem;
		color: var(--textColor);
		text-align: left;
	}
	.gif span {
		display: inline-block;
		position: relative;
	}
	.gif video {
		max-width: 100%;
		max-height: 4rem;
	}
	.gif button {
		position: absolute;
		top: -0.2rem;
		right: -0.4rem;
		width: 1rem;
		height: 1rem;
		border-radius: 100px;
		background: var(--panelColor);
		box-shadow: 1px 1px 4px var(--shadow);
		color: var(--textColor);
		transition: transform 0.2s ease;
	}
	.gif button svg {
		position: absolute;
		inset: 0;
	}
	.gif button:hover,
	.gif button:active,
	.gif button:focus {
		color: var(--textColor);
		transform: scale(1.2);
	}
</style>

<svelte:window on:keydown={handleKeydown}/>

<p class="gif">
	<span>				
		<video autoplay loop muted inline playsinline>
			<source src={gif} type="video/mp4">
		</video>
		<button type="button" on:click={() => {dispatch('cancel')}}>
			<svg viewBox="0 0 80 80">
				<title>Cancel</title>
				<path d="M40.06 44.53l-11 11-4.5-4.49 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.49-11 11 11 11-4.5 4.49-11-10.99z"/>
			</svg>
		</button>
	</span>
</p>