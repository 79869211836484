<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import { fade, fly } from "svelte/transition";

	// import 'media-chrome';
	// import '@mux-elements/mux-video';
	// import 'playerx';

	import { colord } from "colord";

	import Spinner from "./Spinner.svelte";
	import LiveIndicator from './Player/LiveIndicator.svelte';
	
	import {	epoch, spinnerColor, session, event, isPhone, videoCall } from '../lib/stores.js';
	import { postServerData } from '../lib/prelude.js';
	import { randomIntFromInterval } from '../lib/utils.js';

	const dispatch = createEventDispatcher();

	export let provider;
	export let playback;
	export let token = null;
	export let playbackType = 'vod';
	export let livestreamStatus = null;
	export let ll = false;
	export let captions = false;
	export let poster = null;
	export let autoplay = true;
	export let muted = true;
	export let control = {};
	export let useAutoCut = false;
	export let isControlPanel = false;
	export let showControls = true;
	export let loop = false;
	export let startAt = 0;
	export let sendHeartbeat = true;

	// NB: unpause on a live stream or simulive **should** restore us to the current time (cf. TMI 2022)

	let player;
	let loading = true;
	let contentAvailable = false;
	let controls = !muted;
	let playing = false;
	let playDuration = 0;
	let playInterval;
	let heartbeat = randomIntFromInterval(60,120);
	let startTime = 0;
	let streamType = 'on-demand';
	let muxPlayback = token ? playback + '?token=' + token : playback;

	// console.log({muxPlayback});

	if (livestreamStatus) {
		streamType = ll ? 'll-live' : 'live';
	}

	// function randomIntFromInterval(min, max) {
	// 	return Math.floor(Math.random() * (max - min + 1) + min)
	// }

	let tc = $event.theme.colors[$event.theme.virtual.textColor];
	let hsl = colord(tc).toHsl();
	hsl.l = '15';
	let bg = colord(hsl).alpha(0.7).toRgbString();
	let bgh = colord(hsl).alpha(0.95).toRgbString();

	onMount(() => {
		// Initialise presence
		logPlayDuration(0);
	});

	function play() {
		// console.log('PLAY');
		// Do we need to do this for live too?
		if (startAt) {
			player.media.currentTime = startAt;
		} else if ((playbackType == 'simulive') && $session) {
			player.media.currentTime = $epoch - $session.epochStarts;
		}
		playing = true;
		playInterval = setInterval(() => {
			playDuration = playDuration + 1;
			if (playDuration == heartbeat) {
				resetPlayDuration();
			}
		}, 1000);
		dispatch('play');
	}

	function pause() {
		// console.log('PAUSE');
		playing = false;
		clearInterval(playInterval);
		resetPlayDuration();
		dispatch('pause');
	}

	function resetPlayDuration() {
		logPlayDuration(playDuration);
		playDuration = 0;
		heartbeat = randomIntFromInterval(60,120);
	}

	async function logPlayDuration(d, ended) {
		if ($session && sendHeartbeat) {
			// console.log('duration', d);
			ended = ended ? true : false;
			await postServerData('virtual/playback', {
				vod: (playbackType == 'vod') ? 1 : 0,
				session: $session.ref,
				duration: d,
				provider: provider,
				ended: ended
			});
		}
	}

	function checkContentAvailable() {
		if ((provider == 'mux') && (playbackType == 'vod')) {
			// Eg studio clips
			contentAvailable = true;
		} else if ($session && ($epoch < $session.epochStarts)) {
			contentAvailable = false;
		} else {
			if ($session && (provider == 'mux') && (playbackType == 'live')) {
				contentAvailable = (livestreamStatus == 'active') ? true : false;
				console.log(provider, playbackType, livestreamStatus, contentAvailable);
			} else {
				if ((playbackType == 'simulive') && $session) {
					startTime = $epoch - $session.epochStarts;
				}
				contentAvailable = true;
			}
		}
	}

	function livestreamStatusUpdated() {
		if (playInterval && (livestreamStatus != 'active')) {
			// Stop logging duration if the livestream cuts
			// Smear just a bit, though, to help avoid a potential thundering herd
			clearInterval(playInterval);
			let smear = randomIntFromInterval(100,6000);
			setTimeout(() => {
				resetPlayDuration();
			}, smear);
		} else if (!playInterval && (livestreamStatus == 'active')) {
			// Start logging (again) if the livestream starts
			playInterval = setInterval(() => {
				playDuration = playDuration + 1;
				if (playDuration == heartbeat) {
					resetPlayDuration();
				}
			}, 1000);
		}
	}

	// function showCaptions(e) {
	// 	const visible = e.detail;
	// 	if (visible) {
	// 		player.shouldRenderNativeTextTracks = false;
	// 		const cur = player.currentTextTrack;
	// 		if (cur == -1) {
	// 			player.setCurrentTextTrack(0);
	// 		}
	// 	}
	// }

	onDestroy(() => {
		clearInterval(playInterval);
		logPlayDuration(playDuration, true);
		playDuration = 0;
	});

	// $: console.log({heartbeat});

	$: if ($epoch || livestreamStatus) {
		checkContentAvailable();
	}

	$: if (livestreamStatus) {
		livestreamStatusUpdated();
	}

</script>

<style>

	/* transparent backgrounds get rid of the thin lines to the sides, */
	/* but introduce visible gaps on media with a different aspect ratio */

	div.player,
	media-controller,
	mux-video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		/*background: transparent;*/
	}
	div.player :global(iframe) {
		border-radius: 4px;
	}

	/*player-x {
		background: transparent;
	}*/

	/*media-play-button {
		border-bottom-left-radius: 4px;
	}
	media-volume-range {
		border-bottom-right-radius: 4px;
	}*/

	#muted {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
		border: 0;
		background: var(--accentColor);
		border-radius: 4px;
		color: var(--panelColor);
		border: 1px solid var(--panelColor);
		/*padding: 0.5rem 1rem;
		box-shadow: 0 2px 10px -2px var(--shadow);*/
		font-size: 0.9rem;
		font-weight: 700;
		padding: 1rem 1.5rem;
		box-shadow: 0 2px 10px var(--shadow);
	}
	#muted:hover,
	#muted:active,
	#muted:focus {
		background: var(--textColor);
		outline: none;
	}
	.idle {
		position: absolute;
		inset: 0;
		z-index: 1;
		pointer-events: none;
		display: grid;
		place-content: center;
		width: 100%;
		height: 100%;
		background: var(--textColor);
	}
	/*.idle span {
		font-size: 14px;
		position: absolute;
		bottom: 3rem;
		left: 50%;
		transform: translateX(-50%);
		color: var(--captionColor);
		font-family: system-ui;
	}*/

	div.poster {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0;
		border: 0;
		background: transparent;
	}

	div.poster img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 4px;
	}

	div.poster svg {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 15%;
		max-height: 30%;
		opacity: 0.1;
		transform: translate(-50%, -44%) scale(0.9);
		filter: blur(4px);
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
		mix-blend-mode: multiply;
	}

	div.poster svg + svg {
		opacity: 0.8;
		transform: translate(-50%, -50%) scale(1);
		filter: none;
		mix-blend-mode: normal;
	}

	div.poster svg + svg path {
		fill: #fff;
	}

	div.poster button {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		border: 0;
		background: transparent;
		padding: 0;
		cursor: pointer;
	}

	div.poster button:hover ~ svg,
	div.poster button:focus ~ svg {
		opacity: 0.4;
	}

	div.poster button:hover ~ svg + svg,
	div.poster button:focus ~ svg + svg {
		transform: translate(-50%, -50%) scale(1.1);
		opacity: 0.95;
	}

	.shim {
		background: var(--media-control-background, rgba(20,20,30, 0.7));
		flex: 1 0 auto;
	}

	.status {
		color: var(--panelColor);
		text-align: center;
	}

</style>

{#if !autoplay && poster}

	<div class="poster">
		<button type="button" on:click={() => { autoplay = true; muted = false; }}></button>
		<img src="{poster}" alt=""/>
		<svg viewBox="0 0 57 57"><path d="M28.5 0c-15.7 0-28.5 12.8-28.5 28.5s12.8 28.5 28.5 28.5 28.5-12.8 28.5-28.5-12.8-28.5-28.5-28.5zm13 29.1l-21.9 12.6c-.5.3-1.1-.1-1.1-.6v-25.2c0-.6.6-.9 1.1-.6l21.8 12.6c.6.2.6 1 .1 1.2z"></path></svg>
		<svg viewBox="0 0 57 57"><path d="M28.5 0c-15.7 0-28.5 12.8-28.5 28.5s12.8 28.5 28.5 28.5 28.5-12.8 28.5-28.5-12.8-28.5-28.5-28.5zm13 29.1l-21.9 12.6c-.5.3-1.1-.1-1.1-.6v-25.2c0-.6.6-.9 1.1-.6l21.8 12.6c.6.2.6 1 .1 1.2z"></path></svg>
	</div>

{:else if contentAvailable}

	{#if control.cut || (useAutoCut && (($session.epochEnds + control.autoCut) < $epoch))}

		<div class="idle" transition:fade|local={{duration: 400}}>
			<p class="status">This session has ended.</p>
		</div>

	{:else}

		<div class="player" transition:fade|local={{duration: 400}}>

			<media-controller autohide="2" bind:this={player}>
				{#if provider == 'youtube'}
					<player-x
						slot="media"
						src="https://www.youtube.com/watch?v={playback}"
						{autoplay}
						muted={muted || $videoCall}
						on:loadedsrc={() => { loading = false }}
						on:playing={play}
						on:pause={pause}
						playsinline
					></player-x>
				{:else if provider == 'vimeo'}
					<player-x
						slot="media"
						src="https://vimeo.com/{playback}"
						{autoplay}
						muted={muted || $videoCall}
						on:loadedsrc={() => { loading = false }}
						on:playing={play}
						on:pause={pause}
						playsinline
					></player-x>
				{:else}
					<mux-video
						slot="media"
						preload="auto"
						stream-type={streamType}
						start-time={startTime}
						playback-id="{muxPlayback}"
						muted={muted || $videoCall}
						{autoplay}
						{loop}
						on:loadedmetadata={() => { loading = false }}
						on:play={play}
						on:pause={pause}
						on:ended
						prefer-mse
						playsinline
					></mux-video>
				{/if}
				{#if showControls && controls}
					<media-control-bar style="--media-control-background:{bg};--media-control-hover-background:{bgh};">
						{#if isControlPanel}
							<div class="shim"></div>
							<media-mute-button></media-mute-button>
							<media-volume-range></media-volume-range>
						{:else}
							<media-play-button></media-play-button>
							{#if playbackType == 'vod'}
								{#if !$isPhone}
									<media-time-range></media-time-range>
								{/if}
								<media-time-display/>
							{:else}
								<div class="shim"></div>
							{/if}
							{#if captions}
								<media-captions-button/>
							{/if}
							<media-mute-button></media-mute-button>
							<media-volume-range></media-volume-range>
							<media-fullscreen-button/>
						{/if}
					</media-control-bar>
				{/if}
			</media-controller>

			{#if (provider == 'mux') && playing && !isControlPanel}
				{#if (playbackType == 'live') || ((playbackType == 'simulive') && ($session.virtual.liveBadge))}
					<LiveIndicator/>
				{/if}
			{/if}

		</div>

		{#if !loading && muted && playing && !isControlPanel}
			<button type="button" id="muted" on:click={() => { muted = false; controls = true; }} transition:fly|local={{ duration: 400, y: 5 }}>
				Click to unmute
			</button>
		{/if}

	{/if}

{:else}
	
	<!-- We're in a session, the session has started, but livestream is still idle... -->
	<div in:fade={{delay: 800, duration: 600}} out:fade|local={{duration: 400}} class="idle">
		{#if isControlPanel}
			<p class="status">Live stream<br/>not broadcasting</p>
		{:else}
			<Spinner size="100" speed="1200" color={$spinnerColor} thickness="1" gap="30" />
		{/if}
	</div>

{/if}

