<script>

	import Portal from "svelte-portal";

	import Profile from "./Avatar/Profile.svelte";
	import Admin from "./Avatar/Admin.svelte";

	import Avatar from "../ui/Avatar.svelte";
	import Overlay from "../ui/Overlay.svelte";

	// import { event, attendee, displayInEventTz } from '../lib/stores.js';
	import { event, attendee } from '../lib/stores.js';

	let profileOverlay = false;
	let adminOverlay = false;

	function openProfileOverlay(e) {
		if (($attendee.type == 'login') && e && e.detail && e.detail.altKey) {
			adminOverlay = true;
		} else {
			profileOverlay = true;
		}
	}

</script>

<style>

	li :global(.avatar) {
		width: 1.5625rem;
		height: 1.5625rem;
		border-radius: 100%;
		position: relative;
		top: -0.0625rem;
		left: 0;
		transition: transform 0.2s ease;
	}

	li.attendee :global(.avatar:hover) {
		cursor: pointer;
		transform: scale(1.1);
	}

</style>

<!-- <li on:click={() => {$displayInEventTz = !$displayInEventTz}}> -->
<li class={$attendee.type}>
	<Avatar identity={$attendee} on:click={openProfileOverlay}/>
</li>

{#if profileOverlay}
	<Portal target="#breakout">
		<Overlay on:escape={() => { profileOverlay = false }}>
			<Profile on:saved={() => { profileOverlay = false }}/>
		</Overlay>
	</Portal>
{/if}

{#if adminOverlay}
	<Portal target="#breakout">
		<Overlay on:escape={() => { adminOverlay = false }}>
			<Admin/>
		</Overlay>
	</Portal>
{/if}