<script>

	import { tick } from 'svelte';
	import { fade } from "svelte/transition";

	import Back from "./Back.svelte";
	import Button from "../../ui/Button.svelte";

	import Moderators from './Session/Moderators.svelte';
	import Speakers from './Session/Speakers.svelte';
	import Sponsors from './Session/Sponsors.svelte';
	import Location from './Session/Location.svelte';
	import SelectList from './Session/SelectList.svelte';
	import TextArea from './Session/TextArea.svelte';
	import TextField from './Session/TextField.svelte';
	import UrlField from './Session/UrlField.svelte';

	import { agenda, epoch, session, room, epochInterval } from '../../lib/stores.js';
	import { showDate, isToday } from '../../lib/dt.js';

	export let selectedSession;

	let sessionType = null;

	const components = {
		Moderators: Moderators,
		SelectList: SelectList,
		Location: Location,
		Speakers: Speakers,
		Sponsors: Sponsors,
		TextArea: TextArea,
		TextField: TextField,
		UrlField: UrlField
	};

	if (selectedSession.sessionType && $agenda.sessionTypes) {
		for (const s of $agenda.sessionTypes) {
			if (s.ref == selectedSession.sessionType) {
				sessionType = s;
				// console.log(s);
				break;
			}
		}
	}

	function joinSession() {
		$session = selectedSession;
		$room = 'auditorium';
	}

</script>

<style>
	.session {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.session .details {
		flex: 1 0 auto;
		position: relative;
		margin: 1rem 0 0 0;
	}
	.session .details > div {
		position: absolute;
		inset: 0;
		overflow-y: auto;
		padding: 0 1rem 2rem 1rem;
		box-sizing: border-box;
	}
	.session .actions {
		flex: 0 0 auto;
		border-top: 2px solid var(--blend-20);
		padding: 1rem 0;
		margin: 0 1rem;
	}
	.session :global(p) {
		margin: 0.5rem 0 0 0;
		font-size: 0.6875rem;
	}
	.session :global(.label) {
		margin-top: 0;
		color: var(--blend-60);
		font-size: 0.625rem;
	}
	.session :global(.component + .component) {
		border-top: 1px solid var(--blend-20);
		padding-top: 0.5rem;
	}
	.session :global(.label + p) {
		margin-top: 0.25rem;
	}
	h2 {
		font-size: 1rem;
		margin: 0.5rem 0 1rem 0;
	}

	.live {
		display: inline-block;
		background: var(--red, #d73531);
		color: var(--redText, #fff);
		text-transform: uppercase;
		font-size: 0.5rem;
		border-radius: 2px;
		line-height: 1;
		padding: 2px 3px;
		letter-spacing: 0.05em;
		margin-left: 0.5em;
		position: relative;
		top: -0.075rem;
	}

	.details :global(.component) {
		margin-top: 1.5rem;
	}

	.details :global(div.speaker + div.speaker) {
		margin-top: 1.5rem;
	}

	.details :global(.moderator + .moderator) {
		margin-top: 1rem;
	}

</style>

<div class="session">
	<Back on:back/>
	<div class="details">
		<div>
			<p>
				{showDate(selectedSession.starts, 'HH:mm')}–{showDate(selectedSession.ends, 'HH:mm')}
				{#if (selectedSession.virtual && selectedSession.virtual.isVirtual) && ($epoch > selectedSession.epochStarts) && ($epoch < selectedSession.epochEnds)}<span class="live">Now</span>{/if}
			</p>
			<h2>{selectedSession.name}</h2>
			{#if sessionType && sessionType.components}
				{#each sessionType.components as stc}
					<!-- The check on visibility here shouldn't be needed (hidden components are edited out on the server), but keep it just in case... -->
					{#if (stc.visibility != 'hidden') && selectedSession.details[stc.ref]}
						<svelte:component this={components[stc.component]} {stc} details={selectedSession.details[stc.ref]}/>
					{/if}
				{/each}
			{/if}
		</div>
	</div>
	{#if (selectedSession.virtual && selectedSession.virtual.isVirtual)}
		{#if !($session == selectedSession) || !($room == 'auditorium')}
			{#if (isToday(selectedSession.starts, $epoch)) && ($epoch < selectedSession.epochEnds)}
				<div class="actions" out:fade|local={{ duration: 200, delay: 400 }}>
					<Button wide={true} label="Join session" on:click={joinSession}/>
				</div>
			{/if}
		{/if}
	{/if}
</div>