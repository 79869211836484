<script>

	import { fade } from 'svelte/transition';

	import moment from 'moment-timezone';

	import Avatar from "../../ui/Avatar.svelte";

	import { attendees } from '../../lib/stores.js'; // tmp
	import { showDate } from '../../lib/dt.js';

	export let reports;
	export let report;
	export let spot;

	let displayReports;

	$: displayReports = reports.sort((a,b) => b.dt - a.dt);

</script>

<style>
	.wrapper {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}

	.report {
		position: relative;
		/*display: flex;*/
		flex-direction: row-reverse;
		/*justify-content: space-between;*/
		cursor: pointer;
		padding: 0.5rem 0.5rem 0.5rem 4rem;
		margin: 0.5rem -0.5rem;
		border-radius: 4px;
		/*gap: 0.5rem;*/
	}
	.report:hover {
		background: var(--blend-05);
	}

	.report + .report {
		margin-top: 1.6rem;
	}
	.reportee {
		font-size: 0.8rem;
		display: flex;
	}
	.reportee :global(.avatar) {
		width: 3rem;
		height: 3rem;
		left: 0.5rem;
		top: 0.5rem;
	}
	.category {
		font-size: 0.6875rem;
		margin-top: 0.5rem;
	}
	.meta {
		margin-top: 0.25rem;
		font-size: 0.625rem;
		color: var(--blend-60);
		/*flex: 0 0 auto;*/
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
	}
	/*.reporter {
		margin-top: 0.75rem;
		position: relative;
		font-size: 0.6875rem;
	}*/
	/*.reporter :global(.avatar) {
		left: 0rem;
		top: 0rem;
	}*/
	.spot .meta:after {
		content: '';
		display: inline-block;
		width: 0.4rem;
		height: 0.4rem;
		background: var(--red);
		border-radius: 100%;
		margin-left: 0.4rem;
		position: relative;
		top: -0.05rem;
	}

	.slate {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-60%);
	}
	.slate p {
		margin-top: 0.5rem;
		font-size: 0.8rem;
		max-width: 10rem;
		line-height: 1.4;
		text-align: center;
	}
	.slate svg {
		width: 2rem;
		height: 2rem;
	}
	.slate strong {
		font-size: 1rem;
	}
</style>

<div class="wrapper">
	<div class="overlay-title">
		<h2>Reported issues</h2>
	</div>
	<div class="overlay-main">
		{#if !report}
			<div transition:fade|local={{ duration: 250, delay: 250 }}>
				{#each displayReports as r}
					<!-- TODO: nest a button instead -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<div
						class="report"
						on:click={() => { report = r }}
						class:spot={spot.includes(r.index)}
					>
						<p class="meta">{showDate(moment.unix(r.dt), 'HH:mm')}</p>
						<div>
							<div class="reportee">
								<Avatar identity={$attendees[r.report.reportee]}/>	
								<p>	
									<strong>{$attendees[r.report.reportee].f} {$attendees[r.report.reportee].l}</strong>
									{#if $attendees[r.report.reportee].j}<br/>{$attendees[r.report.reportee].j}{/if}
									{#if $attendees[r.report.reportee].o}<br/>{$attendees[r.report.reportee].o}{/if}
								</p>
							</div>
							<p class="category">
								<strong>Reported by:</strong>
								{$attendees[r.report.reporter].f} {$attendees[r.report.reporter].l}{#if $attendees[r.report.reporter].j}, {$attendees[r.report.reporter].j}{/if}{#if $attendees[r.report.reporter].o}, {$attendees[r.report.reporter].o}{:else}, AttendZen{/if}
							</p>
							<p class="category"><strong>Issue:</strong> {r.report.category}</p>
						</div>
					</div>
				{:else}
					<div class="slate">
						<p><svg viewBox="0 0 80 80"><path d="M18.86 68.13l-4.69-53.58c-.05-.55.36-1.04.91-1.08l1.99-.17c.55-.05 1.04.36 1.08.91l4.69 53.93h-3.98zM31.46 16.51c2.97 0 6.14.57 9.11 1.18 3.26.67 7.08 1.36 10.68 1.36.78 0 1.54-.03 2.27-.1 1.03-.09 2.12-.21 3.22-.42.58-.11 1.12.31 1.18.89l1.76 20.28c.04.48-.26.91-.73 1.05-.82.24-1.93.44-3.49.57-.5.04-1.03.07-1.58.07-2.88 0-6.2-.6-9.07-1.2-3.39-.7-7.03-1.35-10.73-1.35-.84 0-1.67.03-2.46.1-1.37.12-2.54.34-3.56.63l-1.91-22.06c.89-.46 1.92-.8 3.53-.94.57-.04 1.17-.06 1.78-.06m0-4c-.71 0-1.41.03-2.11.09-3.95.34-5.63 1.72-7.39 2.8l2.63 30.34c1.76-1.08 3.44-2.46 7.39-2.8.7-.06 1.4-.09 2.11-.09 3.47 0 6.98.66 9.92 1.26 3.63.75 6.89 1.28 9.87 1.28.66 0 1.3-.03 1.93-.08 3.57-.31 5.9-.95 7.75-2.59.23-.21.35-.52.32-.83l-2.43-28c-.05-.55-.5-.91-.99-.91-.19 0-.38.05-.55.16-1.69 1.08-3.78 1.56-6.73 1.81-.63.05-1.27.08-1.93.08-2.98 0-6.25-.53-9.87-1.28-2.93-.58-6.45-1.24-9.92-1.24z"/></svg></p>
						<p><strong>No reported issues</strong></p>
						<p>Reports will be listed here if any are made</p>
					</div>
				{/each}
			</div>
		{/if}
	</div>
</div>