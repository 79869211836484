<script>

	import { onMount } from 'svelte';
	import { slide } from 'svelte/transition';

	import { v4 as uuid } from 'uuid';

	import Button from "../../../ui/Button.svelte";
	import Overlay from "../../../ui/Overlay.svelte";
	import Field from '../../../ui/Field.svelte';

	import { bigError, session } from '../../../lib/stores.js';
	import { postServerData } from '../../../lib/prelude.js';
	import {	autoblur } from '../../../lib/autoblur.js';

	export let createOverlay = true;

	const placeholders = ['Eg: Miniature Schnauzer', 'Eg: Chihuahua'];

	let mounted = false;
	let workingPoll = {};
	let dataOK = false;

	onMount(() => {
		let ref = uuid();
		workingPoll = {
			ref: ref,
			closed: false,
			results: true,
			question: null,
			options: []
		};
		addOption();
		addOption();
		mounted = true;
	});

	async function createPoll() {
		let json = JSON.stringify(workingPoll);
		await postServerData('virtual/polls', {
			sessionRef: $session.ref,
			json: json
		});
		if (!$bigError) {
			createOverlay = false;
		}
	}

	function addOption() {
		let ref = uuid();
		workingPoll.options.push({
			value: ref,
			label: undefined
		});
		workingPoll = workingPoll;
	}

	function deleteOption(ref) {
		workingPoll.options = workingPoll.options.filter(o => {
			if (o.value != ref) return o;
		});
	}

	$: if (workingPoll) {
		checkData();
	}

	function checkData() {
		if (mounted) {
			let err = false;
			if (!workingPoll.question) err = true;
			for (const o of workingPoll.options) {
				if (!o.label) err = true;
			}
			if (workingPoll.options.length < 2) err = true;
			dataOK = !err;
		}
	}

</script>

<style>
	.add {
		margin-top: 1.5rem;
	}
	.option {
		position: relative;
		overflow: visible;
		margin: 0;
		padding: 0.5rem 0;
	}
	.option :global(.field) {
		margin: 0;
	}
	.question + .option {
		margin-top: -0.5rem;
	}
	.option button {
		position: absolute;
		right: -1.5rem;
		top: 50%;
		width: 1.2rem;
		height: 1.2rem;
		border: 0;
		padding: 0;
		color: var(--blend-60);
		cursor: pointer;
	}
	.option button svg {
		position: absolute;
		inset: 0;
	}
	.option button:hover,
	.option button:active,
	.option button:focus {
		color: var(--red);
	}
	form :global(.field:first-child) {
		margin-top: 0;
	}
</style>

{#if mounted}
	<Overlay on:escape={() => { createOverlay = false }} modal={true}>
		<div class="overlay-title">
			<h2>Create a poll</h2>
			<p>Add a poll to this session that attendees can respond to.</p>
		</div>
		<div class="overlay-main">
			<form id="pollCreate" on:submit|preventDefault={createPoll}>
				<Field
					ref="pollCreate-results"
					bind:value={workingPoll.results}
					component="RadioButtons"
					label="Results"
					options="{[
						{ value: true, label: 'Visible' },
						{ value: false, label: 'Hidden' }
					]}",
				/>
				<div class="question">
					<Field
						ref="pollCreate-question"
						bind:value={workingPoll.question}
						component="TextField"
						label="Question"
						placeholder="What’s your favourite dog?"
						maxlength={100}
					/>
				</div>
				{#each workingPoll.options as opt, i (opt.value)}
					<div
						class="option"
						transition:slide|local={{ duration: 200 }}
					>
						<Field
							ref="pollCreate-options-{i}"
							bind:value={opt.label}
							component="TextField"
							label="Option {i + 1}"
							placeholder={placeholders[i]}
							maxlength={50}
						/>
						<button type="button" on:click={deleteOption(opt.value)} tabindex="-1">
							<svg viewBox="0 0 20 20"><path d="M12 14.5c-.28 0-.5-.22-.5-.5v-5.01c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .28-.22.5-.5.5zM10 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM8 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM15.5 5.5c0-.55-.45-1-1-1h-2.49c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1h-2.51c-.55 0-1 .45-1 1v2h1v8c0 .55.45 1 1 1h7c.55 0 1-.45 1-1v-8h1v-2zm-2 9.5c0 .28-.22.5-.5.5h-6c-.28 0-.5-.22-.5-.5v-7.5h7v7.5zm1-8.5h-9v-.49c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5v.49z"/></svg>
						</button>
					</div>
				{/each}
				<p class="add">
					<Button
						label="Add option"
						type="button"
						ghost={true}
						wide={true}
						on:click={addOption} disabled={workingPoll.options.length > 5}
					>
						<svg viewBox="0 0 80 80"><path d="M43.24 43.21v15.55h-6.36v-15.55h-15.55v-6.36h15.55v-15.55h6.36v15.55h15.56v6.36h-15.56z"/></svg>
					</Button>
				</p>
			</form>
		</div>
		<div class="overlay-actions">
			<Button label="Launch poll" type="submit" form="pollCreate" wide={true} disabled={!dataOK} />
		</div>
	</Overlay>
{/if}