<script>

	import { getContext } from 'svelte';

	import SubNav from "./Interactions/SubNav.svelte";
	import Chat from '../../Auditorium/Interactions/Chat.svelte';
	import QA from '../../Auditorium/Interactions/QA.svelte';
	import Polls from '../../Auditorium/Interactions/Polls.svelte';

	import { session } from '../../lib/stores.js';

	const banner = getContext('banner');
	const highlight = getContext('highlight');
	const host = getContext('host');

	export let spots = {};
	export let subtab = 'chat';

	function highlightContent(e) {
		$banner = null;
		$highlight = e.detail;
	}

</script>

<style>
	.wrap {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}
	.panel {
/*		border-top: 2px solid var(--blend-20);*/
		flex: 1 0 auto;
		position: relative;
	}
</style>

{#if $session}
	<div class="wrap">
		<SubNav bind:subtab bind:spots/>
		{#if $session.syncChannel}
			<div class="panel">
				{#if subtab == 'chat'}
					<Chat studio={host} studioHighlight={$highlight} on:highlight={highlightContent} />
				{:else if subtab == 'qa'}
					<QA studio={host} studioHighlight={$highlight} on:highlight={highlightContent} />
				{:else if subtab == 'poll'}
					<Polls studio={host} studioHighlight={$highlight} on:highlight={highlightContent} />
				{/if}
			</div>
		{/if}
	</div>
{/if}