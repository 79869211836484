<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from 'svelte/transition';

	import Upload from "./Overlays/Upload.svelte";
	import ListItem from "./Overlays/ListItem.svelte";

	import Overlay from "../../../ui/Overlay.svelte";
	import Button from "../../../ui/Button.svelte";

	import { event } from '../../../lib/stores.js';

	const overlays = getContext('overlays');
	const background = getContext('background');
	const backgrounds = getContext('backgrounds');

	let mounted = false;
	let overlay = false;
	let dominantColor;

	let overlaysList = [];

	onMount(() => {
		setOverlaysList(true);
		mounted = true;
	});

	function setOverlaysList(force) {
		if (force || mounted) {	
			let items = [];
			if ($event.setup.virtual.studio && $event.setup.virtual.studio.overlays) {
				for (const o of $event.setup.virtual.studio.overlays) {
					o.content.allowDelete = false;
					items.push(o.content);
				}
			}
			for (const o of $overlays) {
				o.allowDelete = true;
				items.push(o);
			}
			overlaysList = items;
		}
	}

	function getDominantColor() {
		let color = null;
		if ($event.setup.virtual.studio && $event.setup.virtual.studio.backgrounds) {
			for (const b of $event.setup.virtual.studio.backgrounds) {
				if (b.content.ref == $background) {
					if (b.content.dominantColor) {
						color = b.content.dominantColor;
					}
					break;
				}
			}
		}
		if (!color) {
			for (const b of $backgrounds) {
				if (b.ref == $background) {
					if (b.dominantColor) {
						color = b.dominantColor;
					}
					break;
				}
			}
		}
		dominantColor = color ? color : '#101820';
	}

	$: getDominantColor($background);

	$: if ($overlays) {
		setOverlaysList();
	}

</script>

<style>
	.list {
		flex: 1 1 auto;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		overflow: scroll;
	}
	/*.actions {
		flex: 0 0 auto;
		padding: 0.75rem;
	}*/
	.actions :global(.label) {
		font-size: 0.75rem;
	}
</style>

<div class="list p{overlaysList.length}" in:fly={{ duration: 300, y: 20 }} style="--dominantColor:{dominantColor};">
	{#each overlaysList as image}
		<ListItem {image}/>
	{/each}
	<div class="actions">
		<Button
			subtle={true}
			on:click={() => { overlay = true }}
			label="Upload a new overlay"
		>
			<svg viewBox="0 0 80 80"><path d="M43.24 43.21v15.55h-6.36v-15.55h-15.55v-6.36h15.55v-15.55h6.36v15.55h15.56v6.36h-15.56z"/></svg>
		</Button>
	</div>
</div>

<!-- <div class="actions" in:fly={{ duration: 300, y: 20 }}>
	<Button ghost={false} wide={true} on:click={() => { overlay = true }} label="Add a new overlay"/>
</div> -->

{#if overlay}
	<Overlay on:escape={() => { overlay = false }} modal={true}>
		<Upload on:saved={() => { overlay = false }} />
	</Overlay>
{/if}