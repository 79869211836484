<script>
	import { fade } from 'svelte/transition';
</script>

<style>
	div {
		position: absolute;
		top: 1rem;
		left: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		padding: 4px 5px 3px 5px;
		z-index: 1000;
		pointer-events: none;
		background: var(--red);
		color: var(--panelColor);
		border-radius: 3px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
	}
	div :global(span:after) {
		content: '';
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 20px;
		background: var(--panelColor);
		margin-left: 4px;
		transform: translate(1px, -1px);
		animation: pulse 2s infinite;
	}
	@keyframes pulse {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 100%;
		}
		100% {
			opacity: 0;
		}
	}
</style>

<div class="liveindicator" in:fade={{duration:400}}>
	<span>Live</span>
</div>