<script>

	import { onMount, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

	import Portal from "svelte-portal";

	const dispatch = createEventDispatcher();

	import Button from './Button.svelte';

	export let text = '';
	export let info = [];
	export let actions = [];

	let mounted = false;

	onMount(() => {
		setTimeout(() => {
			mounted = true;
		}, 200);
	});

	function handleWindowClick() {
		if (mounted) {
			dispatch('escape');
		}
	}

	function handleKeydown(event){
		if (event.key === 'Escape') {
			dispatch('escape');
		}
	}

</script>

<style>

	.dialogBg {
		position: fixed;
		inset: 0;
		/*backdrop-filter: blur(2px);*/
		z-index: 13021;
	}

	.dialogBg:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor);
		/*opacity: 0.6;*/
		opacity: 0.7;
	}

	.dialog {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 13022;
		font-size: 1rem;
	}

	.dialog > div {
		max-width: 80vw;
		max-height: 80dvh;
		overflow: auto;
		width: 560px;
		box-sizing: border-box;
		padding: 2rem 2rem 2rem 5rem;
		background: var(--panelColor);
		color: var(--textColor);
		box-shadow: 0 20px 80px var(--shadow);
		position: relative;
		border-radius: 4px;
	}

	svg {
		position: absolute;
		top: 1.7rem;
		left: 1.7rem;
		width: 2rem;
	}

	.triangle {
		fill: var(--red);
	}

	.pling {
		fill: currentColor;
	}

	.dialog p {
		margin: 0;
		line-height: 1.4;
		font-size: 1rem;
	}

	.dialog .info {
		margin-top: 1rem;
		font-size: 0.8rem;
	}

	.dialog .info + .info {
		margin-top: 0.5rem;
	}

	.dialog .actions {
		margin-top: 1.4rem;
		display: flex;
		gap: 1rem;
	}

</style>

<svelte:window on:keydown|stopPropagation={handleKeydown} on:click={handleWindowClick}/>

<Portal target="#dialog">
	<div class="dialogBg" in:fade={{ duration: 200 }}></div>
	<div class="dialog" in:fade={{ duration: 200, delay: 100 }}>
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div on:click|stopPropagation>
			<svg viewBox="0 0 56 56">
				<path class="triangle" d="M3.7 52.7c-1.2 0-2.5-.7-3.1-1.9-.7-1.2-.6-2.5 0-3.6l24.3-42c.7-1.1 1.8-1.8 3.2-1.8 1.3 0 2.5.7 3.2 1.8l24.3 42c.6 1.1.6 2.5 0 3.6-.7 1.2-1.9 1.8-3.1 1.9h-48.8zm24.3-43.1l-22.1 38.2h44.1l-22-38.2z"/>
				<path class="pling" d="M28 40.3c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7zm-2.3-18.9c0-1.5.9-2.5 2.3-2.5s2.3 1 2.3 2.5v14.4c0 1.5-.9 2.5-2.3 2.5s-2.3-1-2.3-2.5v-14.4z"/>
			</svg>

			<p><strong>{text}</strong></p>

			{#each info as i}
				<p class="info">{i}</p>
			{/each}

			{#if actions && actions.length}
				<div class="actions">
					{#each actions as action}
						<Button label={action.label} ghost={action.ghost} red={action.red} grow={false} on:click={() => { dispatch(action.dispatch) }} />
					{/each}
				</div>
			{/if}
			
		</div>
	</div>
</Portal>