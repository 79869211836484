<script>

	import { onMount, tick, getContext } from 'svelte';
	import { fade, fly } from 'svelte/transition';

	import moment from 'moment-timezone';

	import Avatar from "../../ui/Avatar.svelte";

	import { attendee, displayInEventTz, attendeeLikes, attendees, bigError } from '../../lib/stores.js';
	import { text_to_html } from '../../lib/html.js';
	import { showDate } from '../../lib/dt.js';
	import { postServerData } from '../../lib/prelude.js';

	const session = getContext('session');

	// export let channel;

	let remaining = 100;
	let message = '';

	let mounted = false;

	let replies = [];

	let msgs;
	let ta;

	onMount(async () => {

		// let pageHandler = function(paginator) {
		// 	paginator.items.forEach(function(item) {
		// 		if (item.data.type == 'chat') {
		// 			addMessage(item);
		// 		}
		// 	});
		// 	return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
		// };

		// await channel.getItems({
		// 	from: 0,
		// 	order: 'asc',
		// 	pageSize: 100
		// }).then(
		// 	pageHandler
		// ).catch(function(error) {
		// 	console.error('chat: List getItems() failed', error);
		// });

		// channel.on('itemAdded', function(i) {
		// 	if (i.item.data.type == 'chat') {
		// 		addMessage(i.item);
		// 	}
		// });

		// channel.on('itemUpdated', function(i) {
		// 	if (i.item.data.type == 'chat') {
		// 		updateMessage(i.item);
		// 	}
		// });

		// channel.on('itemRemoved', function(i) {
		// 	if (i.previousItemData.type == 'chat') {
		// 		deleteMessage(i.index);
		// 	}
		// });

		mounted = true;

		tick().then(() => scrollToEnd());

	});

	function addMessage(item) {
		// console.log('addMessage', item.data);
		let reply = processMessage(item);
		// console.log(reply);
		replies.push(reply);
		replies = replies;
		scrollToEnd();
	}

	function updateMessage(item) {
		// console.log('updateMessage', item.data);
		const idx = item.index;
		let reply = processMessage(item);
		for (let [i,r] of replies.entries()) {
			if (r.index == idx) {
				// console.log('replacing', idx, reply);
				replies[i] = reply;
				break;
			}
		}
		// console.log({replies});
		replies = replies;
	}

	function deleteMessage(idx) {
		// console.log('deleteMessage', idx);
		replies = replies.filter(r => { r.index != idx });
	}

	function processMessage(item) {
		let html = text_to_html(item.data.message);
		let sent = moment(item.dateUpdated);
		let reply = {
			revision: item.index + '/' + item.revision,
			index: item.index,
			message: html,
			sent: sent,
			sender: item.data.sender,
			likes: item.data.likes ? item.data.likes : 0,
			liked: $attendeeLikes.includes($session.syncChannel + '/' + item.index) ? true : false
		};
		return reply;
	}

	async function sendMessage() {

		// if (message != '') {
		// 	channel.push({
		// 		type: 'chat',
		// 		sender: $attendee.ref,
		// 		message: message
		// 	}, { ttl: 604800 }).then(function(i) {
		// 		message = '';
		// 		tick().then(() => resize({ target: ta }));
		// 		ta.focus();
		// 	}).catch(function(error) {
		// 		console.error('List Item push() failed', error);
		// 	});
		// }

		// console.log({$session});

		if (message != '') {
			await postServerData('virtual/chat', {
				channelRef: $session.syncChannel,
				message: message
			});
			if (!$bigError) {
				message = '';
				tick().then(() => resize({ target: ta }));
				ta.focus();
			}
		}

	}

	function checkMessage(e) {
		if (e.code == 'Enter') {
			e.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
			e.preventDefault();
		}
	}

	function scrollToEnd() {
		if (mounted) {
			// check that we haven't scrolled up by more than a message or so...
			let scrolledBack = false;
			if (msgs && msgs.lastElementChild) {
				let scrollDiff = msgs.scrollHeight - (msgs.parentNode.scrollHeight + msgs.scrollTop);
				// console.log(scrollDiff, msgs.lastElementChild.scrollHeight);
				if (scrollDiff > (msgs.lastElementChild.scrollHeight > 200 ? msgs.lastElementChild.scrollHeight : 200)) {
					scrolledBack = true;
				}
				if (!scrolledBack) {
					tick().then(() => {
						msgs.lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
						// console.log(msgs.parentNode.scrollHeight, msgs.scrollHeight, msgs.scrollTop)
					});
				}
			}
		}
	}

	// async function toggleLike(reply) {
	// 	// Update immediately; Sync revision will update again...
	// 	if (reply.liked) {
	// 		reply.likes--;
	// 		reply.liked = false;
	// 	} else {
	// 		reply.likes++;
	// 		reply.liked = true;
	// 	}
	// 	const d = new Date;
	// 	reply.revision = reply.index + '/' + d.getTime();
	// 	replies = replies;
	// 	// console.log(reply);
	// 	await postServerData('virtual/likes', {
	// 		channelRef: $session.syncChannel,
	// 		messageIndex: reply.index
	// 	});
	// }

	function resize({ target }) {
		target.style.height = "1px";
		target.style.height = (+target.scrollHeight)+"px";
		scrollToEnd();
	}

	function autoresize(el) {
		resize({ target: el });
		el.style.overflow = 'hidden';
		el.addEventListener('input', resize);

		return {
			destroy: () => el.removeEventListener('input', resize)
		}
	}

	$: remaining = 100 - message.length;

</script>

<style>
	.wrap {
		flex: 1 0 auto;
		position: relative;
	}
	.wrap > div {
		position: absolute;
		inset:  0;
		display: flex;
		flex-direction: column;
	}
	.chat {
		flex: 1 1 auto;
		overflow: auto;
		scrollbar-width: thin;
		padding-bottom: 0.8rem;
	}
	.chat > div {
		display: flex;
		flex-direction: column;
	}
	.chat > div > div {
		position: relative;
		padding: 0.8rem 0.8rem 0 3rem;
	}
	.chat :global(.avatar) {
		position: absolute;
		top: 0.8rem;
		left: 0.9rem;
	}
	.chat p {
		font-size: 0.6875rem;;
		line-height: 1.4;
	}
	.person {
		margin-bottom: 0.2rem;
	}
	.person strong {
		font-weight: 700;
	}
	.person span {
		display: inline-block;
		margin-left: 0.3rem;
		opacity: 0.6;
		font-size: 0.625rem;;
	}

	.send > form {
		margin: 0 0.8rem;
		border-top: 1px solid var(--blend-60);
		padding: 0.5rem 0;
		display: flex;
		align-items: flex-start;
		position: relative;
	}
	.counter {
		font-size: 0.625rem;;
		line-height: 1;
		position: absolute;
		bottom: 0.6rem;
		right: 0;
		color: var(--blend-40);
	}
	.counter.over {
		color: var(--red);
	}
	.send textarea {
		border: 0;
		padding: 0;
		width: 100%;
		flex: 1 1 auto;
		min-height: 2.4rem;
		max-height: 6rem;
		background: none;
		resize: none;
		font-family: Inter;
		font-size: 0.6875rem;;
		border-radius: 0;
		color: var(--captionColor);
	}
	.send textarea:focus {
		outline: none;
	}
	.send button {
		margin-left: 1rem;
		padding: 0;
		border: 0;
		background: transparent;
		color: var(--captionColor);
		width: 1.5rem;
	}
	.send button:hover,
	.send button:active,
	.send button:focus {
		cursor: pointer;
		color: var(--accentColor);
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: var(--blend-40);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: var(--blend-40);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: var(--blend-40);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: var(--blend-40);
	}
</style>


{#if mounted}
	<div class="wrap" transition:fly={{ duration: 300, y: 20 }}>
		<div>

			{#key $displayInEventTz}
				<div class="chat">
					<div bind:this={msgs}>

						{#each replies as reply (reply.index)}
							<div in:fade|local={{ duration: 250 }}>
								<Avatar identity={$attendees[reply.sender]}/>
								<p class="person"><strong>{$attendees[reply.sender].f} {$attendees[reply.sender].l}</strong> <span>{showDate(reply.sent, 'HH:mm')}</span></p>
								<div class="message" class:emoji={(reply.message.length == 9) && (/\p{Extended_Pictographic}/u.test(reply.message))}>
									{@html reply.message}
								</div>
							</div>
						{/each}

					</div>
				</div>
			{/key}

			<div class="send">
				<form on:submit|preventDefault={sendMessage}>
					<textarea bind:this={ta} bind:value={message} use:autoresize placeholder="Say something …" on:keypress={checkMessage}></textarea>
					<button type="submit">
						<svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg>
					</button>
					<span class="counter" class:over={remaining < 0}>{remaining}</span>
				</form>
			</div>

		</div>
	</div>
{/if}
