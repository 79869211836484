<script>

	import { displayLang } from '../../../lib/stores.js';
	import Sponsor from "./Sponsors/Sponsor.svelte";

	export let stc;
	export let details;

</script>

<style>
	.component > div {
		display: flex;
		gap: 1.5rem;
		flex-wrap: wrap;
		align-items: center;
	}
</style>

{#if details && details[0].crm}
	<div class="component">
		<p class="label">{stc.label[$displayLang]}</p>
		<div>
			{#each details as s}
				{#if s.crm}
					<Sponsor {s}/>
				{/if}
			{/each}
		</div>
	</div>
{/if}