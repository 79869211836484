<script>

	import { onMount, createEventDispatcher } from 'svelte';
	import { fade, fly } from "svelte/transition";

	// import { colord } from "colord";

	import Player from "../ui/Player.svelte";
	import Chat from "./Chat.svelte";

	import {	event, booth, socialMediaServiceOpts } from '../lib/stores.js';

	const dispatch = createEventDispatcher();

	let mounted = false;
	let poster = null;

	// let tc = $event.theme.colors[$event.theme.virtual.textColor];
	// let hsl = colord(tc).toHsl();
	// hsl.l = '15';
	// let bg1 = colord(hsl).alpha(0).toRgbString();
	// let bg2 = colord(hsl).alpha(0.6).toRgbString();

	onMount(() => {
		mounted = true;
	});

	$: if ($booth) {
		getPoster();
	}

	async function getPoster() {
		poster = null;
		if ($booth.videoID) {
			if ($booth.videoProvider == 'youtube') {
				poster = 'https://i.ytimg.com/vi/' + $booth.videoID + '/maxresdefault.jpg';
			} else if ($booth.videoProvider == 'vimeo') {
				const vimeoData = await fetch('https://vimeo.com/api/v2/video/' + $booth.videoID + '.json');
				const json = await vimeoData.json();
				poster = json[0].thumbnail_large;
			}
		}
	}

</script>

<style>

	.booth > div {
		position: absolute;
		inset: 0;
		padding: 1rem;
		overflow-y: auto;
		scrollbar-width: thin;
	}

	.content {
		display: flex;
		gap: 3rem;
		padding: 1rem;
	}

	@media (max-width: 600px) {
		.content {
			flex-direction: column;
			gap: 1rem;
		}
	}

	.content > div {
		flex: 1 1 auto;
	}

	.content .comms {
		flex: 0 0 calc((100vw - 2rem) * 0.22);
	}

	.description {
		font-size: 0.8rem;
		line-height: 1.4;
	}

	.description :global(p + p) {
		margin-top: 1rem;
	}

	.description :global(a:link),
	.description :global(a:visited) {
		color: var(--accentColor);
	}

	.description :global(a:hover),
	.description :global(a:active),
	.description :global(a:focus) {
		color: var(--accentBlend-120);
		text-decoration: none;
	}

	.description :global(ul),
	.description :global(ol) {
		padding-left: 1rem;
	}

	.description :global(li + li) {
		margin-top: 0.5rem;
	}

	.video {
		position: relative;
		height: 0;
		padding-top: 56.25%;
		margin-top: 2rem;
	}

	.masthead {
		flex: 0 0 auto;
		position: relative;
		margin: 0 0 1rem 0;
		border-radius: 4px;
		aspect-ratio: 5 / 1;
		background: var(--dominantColor, var(--textColor));
		width: 100%;
	}

	@media (max-width: 1100px) {
		.masthead {
			aspect-ratio: 4.5 / 1;
		}
	}

	@media (max-width: 600px) {
		.masthead {
			aspect-ratio: 2.2 / 1;
		}
	}

	.masthead .bg {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: var(--focalPoint, 50% 50%);
		border-radius: 4px;
		z-index: 1;
	}
	.masthead:before {
		content: '';
		position: absolute;
		inset: 0;
		border-radius: 4px;
		background: linear-gradient(to bottom, rgba(0,0,0,0) 30%, rgba(0,0,0,0.6) 90%);
		/*background: linear-gradient(to bottom, var(--bg1) 30%, var(--bg2) 90%);*/
		z-index: 2;
		mix-blend-mode: multiply;
	}
	.masthead > div {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		right: 1rem;
		top: auto;
		z-index: 3;
	}
	.ident {
		box-sizing: border-box;
		padding-right: 4rem;
	}
	.masthead h2 {
		font-size: 1.25rem;
		color: #fff;
	}
	.masthead p {
		margin: 0.2rem 0 0 0;
		/*font-size: 0.6875rem;*/
		font-size: 0.8rem;
		color: #fff;
	}
	.masthead .logo {
		position: absolute;
		top: 1rem;
		left: 1rem;
		width: 3rem;
		height: 3rem;
		padding: 0.5rem;
		background: #fff;
		border-radius: 4px;
		object-fit: contain;
		object-position: 50% 50%;
		z-index: 4;
	}

	.links {
		margin: 0.25rem 0 0 0;
		display: flex;
		gap: 1rem;
	}
	.links a {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		position: relative;
	}
	.links a:link,
	.links a:visited {
		color: var(--textColor);
	}
	.links a:hover,
	.links a:active,
	.links a:focus {
		color: var(--blend-120);
	}
	.links svg {
		position: absolute;
		inset: 0;
	}

	.messaging {
		position: sticky;
		top: 1rem;
		margin: 2rem 0 0 0;
	}

	.downloads {
		display: flex;
		flex-wrap: wrap;
		align-items: top;
		gap: 1rem;
		margin: 1.5rem 0 0.5rem 0;
		padding: 0;
	}
	.downloads > li {
		display: block;
		flex: 0 0 calc((100% - 1rem) / 2);
		padding: 0;
	}
	.downloads li a {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 0.5rem;
		font-size: 0.6875rem;
		padding: 0.5rem 0;
		border-radius: 3px;
		color: var(--textColor);
	}
	.downloads svg {
		width: 1.2rem;
		height: 1.2rem;
	}
	.downloads > li a:hover,
	.downloads > li a:active,
	.downloads > li a:focus {
		background: var(--blend-05);
		text-decoration: none;
	}

	.masthead .arrows {
		position: absolute;
		left: auto;
		bottom: 1rem;
		right: 1rem;
		display: inline-flex;
		gap: 0.6rem;
	}
	.arrows button {
		position: relative;
		border: 1px solid #fff;
		background: transparent;
		border-radius: 100px;
		color: #fff;
		font-size: 0.8rem;
		padding: 0;
		width: 1.5rem;
		height: 1.5rem;
		transition: transform 0.2s ease;
		cursor: pointer;
	}
	.arrows button svg {
		position: absolute;
		inset: 0;
	}
	.arrows button.forward svg {
		transform: rotate(180deg);
	}
	.arrows button:hover {
		transform: scale(1.1);
	}

</style>

{#if mounted}
	<div class="booth" in:fly={{ y: 30, duration: 400 }}>
		{#if $booth.ref}
			{#key $booth.ref}
				<div transition:fade|local={{ duration: 300 }}>

					<div
						class="masthead"
						style="--dominantColor:{$booth.banner ? $booth.banner.dominantColor : $event.theme.colors[$event.theme.virtual.textColor]};--focalPoint:{$booth.banner ? $booth.banner.focalPointX : 50}% {$booth.banner ? $booth.banner.focalPointY : 50}%"
					>
						{#if $booth.banner}						
							<img class="bg" src="https://cdn.attendzen.io/{$event.accountRef}/lg_{$booth.banner.filename}" alt=""/>
						{/if}
						{#if $booth.logo}
							{#if $booth.logo.format == 'svg'}	
								<img class="logo" src="https://cdn.attendzen.io/{$event.accountRef}/{$booth.logo.filename}" alt=""/>
							{:else}		
								<img class="logo" src="https://cdn.attendzen.io/{$event.accountRef}/trim_{$booth.logo.filename}" alt=""/>
							{/if}
						{/if}
						<div class="ident">
							<h2>{$booth.name}</h2>
							{#if $booth.headline}<p>{$booth.headline}</p>{/if}
						</div>
						<div class="arrows">
							<button type="button" class="back" on:click={() => { dispatch('back') }}>
								<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
							</button>
							<button type="button" class="forward" on:click={() => { dispatch('forward') }}>
								<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
							</button>
						</div>
					</div>

					<div class="content">

						<div>

							{#if $booth.description}
								<div class="description">
									{@html $booth.description.html}
								</div>
							{/if}

							{#if $booth.videoProvider && $booth.videoID}
								<div class="video">
									<!-- <Video/> -->
									<Player
										provider={$booth.videoProvider}
										playback={$booth.videoID}
										autoplay={false}
										muted={false}
										poster={poster}
									/>
								</div>
							{/if}

							{#if $booth.documents.length}
								<ul class="downloads">
									{#each $booth.documents as d}
										<li>
											<a href="https://cdn.attendzen.io/{$event.accountRef}/{d.file.filename}">
												<svg viewBox="0 0 80 80"><path d="M57.75 26.09l-10.93-9.94c-.76-.75-1.77-1.15-2.79-1.16h-19.03c-2.21 0-4 1.79-4 4v42c0 2.21 1.79 4 4 4h30c2.21 0 4-1.79 4-4v-31.98c0-1.06-.42-2.1-1.25-2.92zm-4.75 34.92h-26c-1.1 0-2-.9-2-2v-38.03c0-1.1.9-2 2-2l13.01.01v10c0 2.21 1.79 4 4 4h10.99v26.01c0 1.11-.89 2.01-2 2.01zm2-32.02h-10.99l-.01-10.01 11.03 10.01h-.03zM38 36.98v12.68l-3.85-3.84-2.67 2.68 8.52 8.51 8.52-8.51-2.67-2.68-3.85 3.84v-12.68z"/></svg>
												<span>{#if d.name}{d.name}{:else}{d.file.name}{/if}</span>
											</a>
										</li>
									{/each}
								</ul>
							{/if}

						</div>

						<div class="comms">

							{#if ($booth.socialMedia && $booth.socialMedia.length) || $booth.url || $booth.email}
								<div class="links">
									{#each $booth.socialMedia as sm}
										{#if sm.url}
											<a href="{sm.url}" target="_blank" rel="noreferrer">
												{#each $socialMediaServiceOpts as ss}
													{#if ss.name == sm.service}
														{@html ss.icon}
													{/if}
												{/each}
											</a>
										{/if}
									{/each}
									{#if $booth.url}
										<a href="{$booth.url}" target="_blank" rel="noreferrer">
											<svg role="img" viewBox="0 0 72 72"><title>Web site</title><path d="M25.71 71.87c-5.03 0-10.07-1.92-13.9-5.75l-5.89-5.89c-3.71-3.71-5.76-8.65-5.75-13.9 0-5.25 2.05-10.19 5.76-13.9l8.69-8.69 4.24 4.24-8.69 8.69c-2.58 2.58-4 6.01-4 9.66s1.42 7.08 4 9.66l5.89 5.89c5.33 5.32 13.99 5.32 19.31 0l8.69-8.69 4.24 4.24-8.69 8.69c-3.83 3.83-8.86 5.75-13.9 5.75zM57.15 48.49l-4.24-4.24 8.92-8.92c2.58-2.58 4-6.01 4-9.66s-1.42-7.08-4-9.66l-5.89-5.89c-5.32-5.32-13.99-5.32-19.31 0l-8.92 8.92-4.24-4.24 8.92-8.92c7.66-7.66 20.13-7.67 27.8 0l5.89 5.89c3.71 3.71 5.76 8.65 5.76 13.9s-2.04 10.19-5.76 13.9l-8.93 8.92zM21.646 46.05l24.321-24.328 4.243 4.242-24.321 24.328z"/></svg>
										</a>
									{/if}
									{#if $booth.email}
										<a href="mailto:{$booth.email}">
											<svg role="img" viewBox="0 0 80 80"><title>Email</title><path d="M73.63 12.62h-67.26c-2.21 0-4 1.79-4 4v46.72c0 2.21 1.79 4 4 4h67.27c2.21 0 4-1.79 4-4v-46.72c-.01-2.2-1.8-4-4.01-4zm-3.15 3.99l-30.5 28.48-30.5-28.48h61zm3.15 44.74c0 1.1-.9 2-2 2h-63.26c-1.1 0-2-.9-2-2v-42.18l33.62 31.39 33.65-31.42-.01 42.21z"/></svg>
										</a>
									{/if}
								</div>
							{/if}

							<div class="messaging">
								<Chat/>
							</div>

						</div>

					</div>

				</div>
			{/key}
		{/if}
	</div>
{/if}