<script>

	import { onMount, onDestroy, tick } from 'svelte';
	import { fade } from 'svelte/transition';

	import aes from 'crypto-js/aes';

	import moment from 'moment-timezone';
	import momentDurationFormatSetup from "moment-duration-format";

	import { Pulse } from 'svelte-loading-spinners'

	import Participants from "./Participants.svelte";

	import Avatar from "../../ui/Avatar.svelte";
	import Button from "../../ui/Button.svelte";

	import { event, conversation, attendees, attendee, syncClient, muted } from '../../lib/stores.js';
	import { text_to_html } from '../../lib/html.js';
	import { showDate } from '../../lib/dt.js';
	import { isMuted, videoCall } from '../../lib/conversations.js';
	import { autoblur } from '../../lib/autoblur.js';

	momentDurationFormatSetup(moment);

	export let tracker;

	let mounted = false;
	let remaining = 400;
	let messageText = '';
	let ta;
	let msgs;
	let channel;
	let messages = [];
	let participants = [];
	let avatarMenu = null;
	let avatars = {};
	let waitingIndex;
	let typingIndex;
	let typingTimeout;
	let staff = {};

	let conversationRef = $conversation.ref;

	$conversation.participants.forEach(p => {
		if (p != $attendee.ref) {
			participants.push($attendees[p]);
		}
	});

	onMount(async () => {

		try {

			// console.log("conversation", $conversation);

			// console.log("opening conversation channel", conversationRef, $conversation.sid);
			channel = await $syncClient.list($conversation.sid);

			channel.on('itemAdded', function(i) {
				addMessage(i.item);
			});

			channel.on('itemRemoved', function(i) {
				deleteMessage(i.index);
			});

			await getMessages();

			// Initial message, eg from exhibitor booth
			if ($conversation.message) {
				messageText = $conversation.message;
				delete $conversation.message;
				await sendMessage();
			}
			
		} catch (e) {

			console.log("Sync error", e, $conversation.sid);
			
		}

		mounted = true;

		await tick();

		if (ta) ta.focus();

		msgs.parentNode.scrollBy(0, 1000000);

		// tracker.get(conversationRef).then((item) => {
		// 	// console.log('Tracker:', item.data);
		// });

		if (tracker) tracker.mutate(conversationRef, updateTracker);

	});

	onDestroy(() => {
		// console.log("closing conversation channel", conversationRef);
		if (channel) channel.close();
		if (tracker) tracker.mutate(conversationRef, updateTracker);
	});

	function pageHandler(paginator) {
		paginator.items.forEach(function(item) {
			// console.log(item.data.type);
			if ((item.data.type == 'message') || (item.data.type == 'videocall')) {
				addMessage(item);
			} else {
				// we only want messages in any initial read
				channel.remove(item.index);
			}
		});
		// if (paginator.hasNextPage) {
		// 	await paginator.nextPage();
		// 	await pageHandler(paginator);
		// }
		return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
	};

	async function getMessages() {
		let paginator;
		try {
			paginator = await channel.getItems({
				from: 0,
				order: 'asc',
				pageSize: 100
			});
		} catch (error) {
			if (error.message.match('Maximum attempt')) {
				// back off and try again later...
				const smear = 1000 * randomIntFromInterval(5,10);
				setTimeout(async () => {
					await getMessages();
				}, smear);
			} else {
				console.error('getMessages failed', error);
			}
		}

		if (paginator) await pageHandler(paginator);

	}

	function addMessage(item) {
		// console.log('addMessage', item.data);
		if ((item.data.type == 'message') || (item.data.type == 'videocall')) {
			let msg = processMessage(item);
			messages.push(msg);
			messages = messages;
			if (tracker) tracker.mutate(conversationRef, updateTracker);
		} else if ((item.data.type == 'typing') && (item.data.sender != $attendee.ref)) {
			waitingIndex = item.index;
			// console.log('waitingIndex ' + waitingIndex + ' set');
		}
		scrollToEnd();
	}

	function deleteMessage(idx) {
		// console.log('deleteMessage', idx);		
		if (idx == waitingIndex) {
			// console.log('waitingIndex ' + waitingIndex + ' removed');
			waitingIndex = undefined;
		} else {
			messages = messages.filter(m => {
				if (m.index != idx) return m;
			});
		}
	}

	function processMessage(item) {
		// console.log(item.data);
		let html;
		if (item.data.message) {
			let decrypted = decrypt(item.data.message);
			html = text_to_html(decrypted);
		}
		let sent = item.data.dt ? moment.unix(item.data.dt) : moment(item.dateUpdated);
		let msg = {
			revision: item.index + '/' + item.revision,
			index: item.index,
			type: item.data.type,
			status: item.data.status,
			message: html,
			sent: sent,
			sender: item.data.sender,
			call: item.data.call
		};
		let day;
		if (messages[messages.length - 1]) {
			day = messages[messages.length - 1].sent;
		} else {
			day = moment(); // now
		}
		if (!day.isSame(msg.sent, 'day')) {
			msg.day = msg.sent.format('ddd D MMM');
		}
		return msg;
	}

	function encrypt(message){
		let encrypted = aes.encrypt(message, $conversation.encryptionKey);
		return encrypted.toString();
	}

	function decrypt(message){
		let decrypted = aes.decrypt(message, $conversation.encryptionKey);
		return decrypted.toString(CryptoJS.enc.Utf8);
	}

	function resize({ target }) {
		if (target) {
			target.style.height = "1px";
			target.style.height = (+target.scrollHeight)+"px";
			scrollToEnd();
		}
	}

	function autoresize(el) {
		resize({ target: el });
		el.style.overflow = 'hidden';
		el.addEventListener('input', resize);

		return {
			destroy: () => el.removeEventListener('input', resize)
		}
	}

	function scrollToEnd() {
		if (mounted) {
			// check that we haven't scrolled up by more than a message or so...
			let scrolledBack = false;
			if (msgs && msgs.lastElementChild) {
				let scrollDiff = msgs.scrollHeight - (msgs.parentNode.scrollHeight + msgs.scrollTop);
				if (scrollDiff > (msgs.lastElementChild.scrollHeight > 200 ? msgs.lastElementChild.scrollHeight : 200)) {
					scrolledBack = true;
				}
				if (!scrolledBack) {
					tick().then(() => {
						msgs.lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
					});
				}
			}
		}
	}

	function checkMessage(e) {
		if (e.code == 'Enter') {
			e.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
			e.preventDefault();
		}
	}

	async function sendMessage() {
		if ((messageText != '') && (messageText != null) && (remaining >= 0)) {
			if (typingIndex) {
				channel.remove(typingIndex);
				typingIndex = undefined;
			}
			let encrypted = encrypt(messageText);
			let dt = moment().unix();
			channel.push({
				sender: $attendee.ref,
				message: encrypted,
				dt: dt,
				type: 'message'
			}, { ttl: 1314000 }).then(function(i) {
				messageText = '';
				if (mounted) {
					tick().then(() => resize({ target: ta }));
					if (ta) ta.focus();
				}
			}).catch(function(error) {
				console.error('List Item push() failed', error);
			});
		}
	}

	function typing() {
		clearTimeout(typingTimeout);
		if (!typingIndex) {
			channel.push({
				sender: $attendee.ref,
				type: 'typing'
			}, { ttl: 3600 }).then(function(i) {
				// console.log(i);
				typingIndex = i.index;
				// console.log('typingIndex ' + typingIndex + ' set');
			}).catch(function(error) {
				console.error('List Item push() failed', error);
			});
		}
		typingTimeout = setTimeout(function(){
			if (typingIndex) {
				channel.remove(typingIndex);
				// console.log('typingIndex ' + typingIndex + ' removed');
				typingIndex = undefined;
			}
		}, 1200);
	}

	function goBack() {
		$conversation = null;
	}

	function closeAvatarMenus() {
		for (const [k,v] of Object.entries(avatars)) {
			if (k != avatarMenu) {
				if (v) v.closeMenu();
			}
		}
	}

	const updateTracker = (currentData) => {
		currentData.viewed = moment().unix();
		return currentData;
	};

	function checkMuted() {
		if ($conversation) {
			if (isMuted($conversation.participants)) {
				$conversation = null;
			}
		}
	}

	function setStaff() {
		if ($conversation && ($conversation.exhibitor || $conversation.help) && ($conversation.participants[0] == $attendee.ref)) {
			let s = {};
			messages.forEach(m => {
				if (!s[m.sender] && (m.sender != $attendee.ref)) {
					s[m.sender] = m.revision;
				}
			});
			staff = s;
		}
	}

	async function inviteToVideoCall() {
		const ok = await videoCall($conversation.ref);
		if (ok) {
			$conversation.videoCalls = 'sent';
		}
	}

	async function acceptVideoCall() {
		const ok = await videoCall($conversation.ref, 'accepted');
		if (ok) {
			$conversation.videoCalls = 'accepted';
		}
	}

	async function declineVideoCall() {
		const ok = await videoCall($conversation.ref, 'declined');
		if (ok) {
			$conversation.videoCalls = 'declined';
		}
	}

	async function startVideoCall() {
		const ok = await videoCall($conversation.ref);
	}

	function getOtherParticipantName() {
		let n = '';
		$conversation.participants.forEach(p => {
			if ((p != $attendee.ref) && $attendees[p]) {
				n = $attendees[p].f;
			}
		});
		return n;
	}

	function videoCallsAreBlocked() {
		if (!$event.setup.virtual.videoChat) return true;
		let blocked = false;
		$conversation.participants.forEach(p => {
			if ((p != $attendee.ref) && $attendees[p]) {
				if ($attendees[p].blockVideo) blocked = true;
			}
		});
		return blocked;
	}

	function getDuration(d) {
		return moment.duration(d, 'seconds').format();
	}

	$: remaining = 400 - messageText.length;

	// $: console.log(messages);

	$: if (messages) {
		setStaff();
	}

	$: if ($muted) {
		checkMuted();
	}

</script>

<style>

	.wrapper {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}

	.overlay-title {
		position: relative;
	}

	.overlay-main {
		padding: 1rem 3rem;
	}

	.send {
		position: relative;
	}
	.send > form {
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: flex-start;
		position: relative;
	}
	.counter {
		font-size: 0.625rem;;
		line-height: 1;
		position: absolute;
		bottom: 0;
		right: 0;
		color: var(--blend-60);
		width: 1.5rem;
		text-align: center;
	}
	.counter.over {
		color: var(--red);
	}
	.send textarea {
		border: 0;
		padding: 0;
		width: 100%;
		flex: 1 1 auto;
		min-height: 2.4rem;
		max-height: 6rem;
		background: none;
		resize: none;
		font-family: Inter;
		font-size: 0.6875rem;;
		border-radius: 0;
		color: var(--textColor);
		text-align: left;
	}
	.send textarea:focus {
		outline: none;
	}
	.send :global(button) {
		flex: 0 0 auto;
		margin-left: 0.5rem;
		padding: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		box-sizing: border-box;
		border-radius: 3px;
	}
	.send button {
		background: var(--blend-05);
	}
	.send :global(button > svg) {
		position: absolute;
		/*inset: 3px;*/
		top: 0.15rem;
		left: 0.15rem;
		width: calc(100% - 0.3rem);
		height: calc(100% - 0.3rem);
	}
	.send button:hover,
	.send button:active,
	.send button:focus {
		cursor: pointer;
		color: var(--panelColor);
		background: var(--textColor);
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: var(--blend-40);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: var(--blend-40);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: var(--blend-40);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: var(--blend-40);
	}

	/*.avatars {
		display: flex;
		gap: 0.2rem;
		flex-wrap: wrap;
		margin-top: 0.5rem;
	}
	.avatars :global(.avatar) {
		position: relative;
		left: 0;
		top: 0;
	}*/

	.msg {
		position: relative;
		list-style: none;
		margin: 0 -2rem;
		padding: 0 25% 0.8rem 2rem;
	}
	.msg.me {
		padding: 0 2rem 0.8rem 25%;
		text-align: right;
	}
	.message :global(p) {
		margin: 0;
		color: inherit;
	}
	.message {
		text-align: left;
		font-size: 0.6875rem;
		line-height: 1.3;
		background: var(--panelColor);
		color: var(--textColor);
		padding: 8px;
		border-radius: 8px;
		border-bottom-left-radius: 0;
	}
	/*.videoCall .message {
		background: var(--blend-05);
		color: var(--blend-80);
		border: 1px solid var(--blend-20);
		border-radius: 4px;
	}*/
	.me .message {
		background: var(--blend-90);
		color: var(--panelColor);
		border-radius: 8px;
		border-bottom-right-radius: 0;
	}
	/*.me.videoCall .message {
		position: relative;
		background: transparent;
		color: var(--blend-80);
		border: 1px solid var(--blend-40);
		border-radius: 4px;
	}
	.me.videoCall .message:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor);
		opacity: 0.05;
		z-index: 0;
	}*/
	.message :global(button) {
		position: relative;
		z-index: 10;
	}
	/*.me.videoCall .message :global(button.subtle) {
		color: var(--blend-80);
	}
	.me.videoCall .message :global(button.subtle:hover),
	.me.videoCall .message :global(button.subtle:active),
	.me.videoCall .message :global(button.subtle:focus) {
		color: var(--blend-120);
	}*/
	/*.me .message :global(button.subtle) {
		color: var(--blend-20);
	}
	.me .message :global(button.subtle:hover),
	.me .message :global(button.subtle:active),
	.me .message :global(button.subtle:focus) {
		color: var(--panelColor);
	}*/
	.me .message :global(button.mini) {
		background: var(--panelColor);
		color: var(--textColor);
		border-color: var(--panelColor);
	}
	.me .message :global(button.mini:hover),
	.me .message :global(button.mini:active),
	.me .message :global(button.mini:focus) {
		background: var(--textColor);
		color: var(--panelColor);
	}
	.msg :global(.avatar) {
		position: absolute;
		left: 0;
		top: auto;
		bottom: 1.75rem;
	}
	.msg.me :global(.avatar) {
		left: auto;
		right: 0;
	}
	.meta {
		margin-top: 3px;
		font-size: 0.625rem;
		color: var(--blend-60);
	}

	.message .opts {
		margin: 0.5rem 0 0.25rem 0;
		display: flex;
		gap: 0.5rem;
	}

	.message :global(button) {
		font-size: 0.6875rem;
		/*font-weight: 600;*/
	}

	/*.back {
		border: 0;
		color: var(--accentColor);
		background: transparent;
		padding: 0;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-weight: 600;
		position: relative;
		top: -0.85rem;
		left: -1rem;
	}

	.back svg {
		transform: rotate(180deg);
		width: 0.5rem;
	}*/

	button.back {
		position: absolute;
		top: 0;
		/*top: 50%;*/
		/*transform: translateY(-50%);*/
		left: 0;
		width: 3rem;
		height: 3rem;
		border: 0;
		background:	transparent;
		/*background: var(--blend-10);*/
		/*border-radius: 100px;*/
		color: var(--textColor);
		/*font-size: 0.8rem;*/
		padding: 0;
		/*width: 1.6rem;*/
		/*height: 1.6rem;*/
	}
	button.back svg {
		position: absolute;
		/*width: 0.5rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) rotate(180deg);*/
		/*inset: 0.7rem;*/
		top: 0.7rem;
		left: 0.7rem;
		width: calc(100% - 1.4rem);
		height: calc(100% - 1.4rem);
	}
	button.back:hover {
		/*background: var(--accentBlend-20);*/
		/*color: var(--accentBlend-120);*/
		color: var(--accentBlend-120);
	}

	/*.participants {
		position: relative;
		top: 0.75rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	.participants :global(.avatar) {
		position: relative;
		top: 0;
		left: 0;
		width: 4rem;
		height: 4rem;
	}

	.participants :global(.avatar abbr) {
		font-size: 1.6rem;
	}*/

	/*.wrapper :global(.participants) {
		top: 0.75rem;
	}*/

	.day, .staff, .log {
		padding: 0;
		margin: 1rem 0;
		font-size: 10px;
		color: var(--blend-60);
		text-align: center;
		width: 100%;
	}

	.day {
		font-weight: 600;
		color: var(--blend-80);
	}

	.day:first-child {
		margin-top: 0;
	}

	.typing {
		padding: 0 0 1rem 0;
	}
	.typing > div {
		padding: 10px;
		background: var(--blend-05);
		border-radius: 8px;
		border-bottom-left-radius: 0;
		width: 60px;
		line-height: 1;
	}
	.typing :global(.wrapper) {
		margin: 0 auto;
	}
	.typing :global(.wrapper .cube) {
		width: calc(var(--size) / 3);
		height: calc(var(--size) / 3);
		border-radius: 100px;
	}

	.overlay-title {
		display: flex;
		justify-content: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.overlay-title :global(.participants) {
		flex-direction: column;
		font-size: 0.6875rem;
		text-align: center;
	}

</style>

<svelte:head>
	<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.2/rollups/aes.js"></script>
</svelte:head>

<!-- TODO: nest a button instead -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="wrapper" on:click={() => { avatarMenu = null; closeAvatarMenus(); }}>
	<div class="overlay-title">
		<!-- <h2>
			{#if displayParticipants.length > 3}
				{displayParticipants[0].f} {displayParticipants[0].l},
				{displayParticipants[1].f} {displayParticipants[1].l}
				and {displayParticipants.length - 2} others
			{:else}
				{#each displayParticipants as p, i}
					{p.f} {p.l}{#if displayParticipants[i+1]},{/if}
				{/each}
			{/if}
		</h2>
		<div class="avatars">
			{#each displayParticipants as p}
				<Avatar identity={p}/>
			{/each}
		</div> -->
		
		<button class="back" type="button" on:click={goBack}>
			<!-- <svg viewBox="0 0 16.7 29.5"><title>Back</title><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"></path></svg> -->
			<!-- <span>Direct messages</span> -->
			<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
		</button>

		<!-- <div class="participants">
			<Avatar identity={displayParticipants[0]}/>	
			<p>	
				<strong>{displayParticipants[0].f} {displayParticipants[0].l}</strong>
				{#if displayParticipants[0].j}<br/>{displayParticipants[0].j}{/if}
				{#if displayParticipants[0].o}<br/>{displayParticipants[0].o}{/if}
			</p>
		</div> -->

		<div>
			<Participants conversation={$conversation}/>
			{#if $conversation}
				{#if $conversation.videoCalls == 'accepted'}
					<Button subtle={true} label="Start video call" on:click={startVideoCall}>
						<svg viewBox="0 0 80 80"><path d="M25.82 22.33l6.22 7.59-2.38 2.43c-1.09 1.11-1.43 2.75-.88 4.21 2.43 6.46 8.19 12.22 14.67 14.67.46.17.94.26 1.42.26 1.03 0 2.04-.4 2.8-1.14l2.43-2.38 7.59 6.22-4.22 4.22c-.96.85-2 1.23-3.33 1.23-13.07 0-29.75-16.68-29.75-29.75 0-1.34.38-2.37 1.23-3.33l4.2-4.23m0-4c-1.02 0-2.04.39-2.83 1.17l-4.29 4.29c-1.77 1.95-2.32 4.08-2.32 6.08 0 15.27 18.4 33.75 33.75 33.75 2 0 4.13-.55 6.08-2.32l4.29-4.29c1.67-1.67 1.54-4.42-.29-5.92l-10.37-8.49-4.99 4.89c-5.15-1.95-10.2-6.66-12.34-12.34l4.89-4.99-8.49-10.36c-.79-.98-1.94-1.47-3.09-1.47zM63.62 38.62c-1.1 0-2-.9-2-2 0-10.06-8.19-18.25-18.25-18.25-1.1 0-2-.9-2-2s.9-2 2-2c12.27 0 22.25 9.98 22.25 22.25 0 1.11-.89 2-2 2zM50.12 38.62c-1.1 0-2-.9-2-2 0-2.62-2.13-4.75-4.75-4.75-1.1 0-2-.9-2-2s.9-2 2-2c4.83 0 8.75 3.93 8.75 8.75 0 1.11-.89 2-2 2zM56.88 38.62c-1.1 0-2-.9-2-2 0-6.34-5.16-11.5-11.5-11.5-1.1 0-2-.9-2-2s.9-2 2-2c8.55 0 15.5 6.95 15.5 15.5 0 1.11-.9 2-2 2z"/></svg>
					</Button>
				{:else if !$conversation.videoCalls}
					{#if !videoCallsAreBlocked()}
						<Button subtle={true} label="Invite to video call" on:click={inviteToVideoCall}>
							<svg viewBox="0 0 80 80"><path d="M25.82 22.33l6.22 7.59-2.38 2.43c-1.09 1.11-1.43 2.75-.88 4.21 2.43 6.46 8.19 12.22 14.67 14.67.46.17.94.26 1.42.26 1.03 0 2.04-.4 2.8-1.14l2.43-2.38 7.59 6.22-4.22 4.22c-.96.85-2 1.23-3.33 1.23-13.07 0-29.75-16.68-29.75-29.75 0-1.34.38-2.37 1.23-3.33l4.2-4.23m0-4c-1.02 0-2.04.39-2.83 1.17l-4.29 4.29c-1.77 1.95-2.32 4.08-2.32 6.08 0 15.27 18.4 33.75 33.75 33.75 2 0 4.13-.55 6.08-2.32l4.29-4.29c1.67-1.67 1.54-4.42-.29-5.92l-10.37-8.49-4.99 4.89c-5.15-1.95-10.2-6.66-12.34-12.34l4.89-4.99-8.49-10.36c-.79-.98-1.94-1.47-3.09-1.47zM63.62 38.62c-1.1 0-2-.9-2-2 0-10.06-8.19-18.25-18.25-18.25-1.1 0-2-.9-2-2s.9-2 2-2c12.27 0 22.25 9.98 22.25 22.25 0 1.11-.89 2-2 2zM50.12 38.62c-1.1 0-2-.9-2-2 0-2.62-2.13-4.75-4.75-4.75-1.1 0-2-.9-2-2s.9-2 2-2c4.83 0 8.75 3.93 8.75 8.75 0 1.11-.89 2-2 2zM56.88 38.62c-1.1 0-2-.9-2-2 0-6.34-5.16-11.5-11.5-11.5-1.1 0-2-.9-2-2s.9-2 2-2c8.55 0 15.5 6.95 15.5 15.5 0 1.11-.9 2-2 2z"/></svg>
						</Button>
					{/if}
				{/if}
			{/if}
		</div>

	</div>

	<div class="overlay-main">
		<div bind:this={msgs}>

			{#if mounted && $conversation}
				{#each messages as msg, i (msg.revision)}

					{#if msg.day}
						<p class="day">{msg.day}</p>
					{/if}

					{#if (msg.type == 'videocall') && msg.call}

						<p class="log">
							{showDate(msg.sent, 'HH:mm')}
							<br/>Video call with
							{#if msg.call.originator == $attendee.ref}
								{$attendees[msg.call.participant].f}
							{:else}
								{$attendees[msg.call.originator].f}
							{/if}
							{#if msg.call.duration}
								({getDuration(msg.call.duration)})
							{:else}
								(unanswered)
							{/if}
						</p>

					{:else}

						{#if msg.sender && staff[msg.sender] && (staff[msg.sender] == msg.revision)}
							<p class="staff">You’re talking with {$attendees[msg.sender].f}</p>
						{/if}

						<div
							in:fade|local={{ duration: 200 }}
							class="msg"
							class:openMenu={avatarMenu == msg.revision}
							class:me={(msg.sender && (msg.sender == $attendee.ref))}
							class:videoCall={msg.type == 'videocall'}
						>
							<!-- fixed={!messages[i + 1]} -->
							{#if msg.sender}
								<Avatar
									bind:this={avatars[msg.revision]}
									identity={$attendees[msg.sender]}
									hasMenu={($attendees[msg.sender].type != 'login')}
									on:open={() => { avatarMenu = msg.revision; closeAvatarMenus(); }}
									on:close={() => { avatarMenu = null }}
								/>
							{/if}
							{#if msg.message}
								<div class="message">
									{@html msg.message}
								</div>
							{:else if (msg.type == 'videocall')}
								<div class="message">
									{#if (msg.status == 'sent')}
										{#if msg.sender == $attendee.ref}
											<!-- <p>You sent {#if $conversation.participants.length == 2}{getOtherParticipantName()}{/if} a video call invitation.</p> -->
											<p>I’d like to invite you to a video call.</p>
										{:else}
											<!-- <p>{$attendees[msg.sender].f} invited you to a video call.</p> -->
											<p>I’d like to invite you to a video call.</p>
											{#if ($conversation.videoCalls == 'sent')}
												<p class="opts">
													<Button mini={true} grow={false} label="Accept" on:click={acceptVideoCall}/>
													<Button mini={true} grow={false} ghost={true} red={true} label="Decline" on:click={declineVideoCall}/>
												</p>
											{/if}
										{/if}
									{:else if (msg.status == 'declined')}
										{#if msg.sender == $attendee.ref}
											<!-- <p>You declined the video call invitation.</p> -->
											<p>Sorry, I’m unavailable for a video call.</p>
											{#if ($conversation.videoCalls == 'declined')}
												<p class="opts">
													<Button mini={true} label="Undo and accept" on:click={acceptVideoCall}/>
												</p>
											{/if}
										{:else}
											<!-- <p>{$attendees[msg.sender].f} declined your video call invitation.</p> -->
											<p>Sorry, I’m unavailable for a video call.</p>
										{/if}
									{:else if (msg.status == 'accepted')}
										{#if msg.sender == $attendee.ref}
											<!-- <p>You accepted the video call invitation.</p> -->
											<p>I’m happy to have a video call with you.</p>
											{#if ($conversation.videoCalls == 'accepted')}
												<p class="opts">
													<Button mini={true} label="Start video call" on:click={startVideoCall}/>
												</p>
											{/if}
										{:else}
											<!-- <p>{$attendees[msg.sender].f} accepted your video call invitation.</p> -->
											<p>I’m happy to have a video call with you.</p>
											{#if ($conversation.videoCalls == 'accepted')}
												<p class="opts">
													<Button mini={true} label="Start video call" on:click={startVideoCall}/>
												</p>
											{/if}
										{/if}
									{/if}
								</div>
							{/if}
							<p class="meta">{showDate(msg.sent, 'HH:mm')}</p>
						</div>

					{/if}

				{:else}

					{#if participants && participants[0]}
						<div class="slate">
							<p><svg viewBox="0 0 32 32"><path d="M17.13 13.22c-.62 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12 0 .61-.49 1.12-1.12 1.12zm-3.23 0c-.62 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12.01.61-.49 1.12-1.12 1.12zm-3.22 0c-.61 0-1.12-.51-1.12-1.12 0-.63.51-1.12 1.12-1.12.63 0 1.12.5 1.12 1.12 0 .61-.49 1.12-1.12 1.12zM3.15 21.92c-1.74 0-3.15-1.46-3.15-3.25v-13.52c0-1.74 1.37-3.15 3.06-3.15h21.78c1.75 0 3.16 1.46 3.16 3.25v13.43c0 1.79-1.41 3.25-3.15 3.25l-5.75.01v6.21l-9.65-6.21-6.3-.02zm6.88-2l7.06 4.55v-4.55l7.75-.01c.58 0 1.15-.62 1.15-1.38v-13.15c.01-.76-.57-1.38-1.28-1.38h-21.51c-.66 0-1.2.58-1.2 1.29v13.26c0 .76.44 1.38 1.29 1.38l6.74-.01z"/></svg></p>
							<p><strong>Start chatting</strong></p>
							<p>Say hi to {participants[0].f} and start the conversation</p>
						</div>
					{/if}

				{/each}

				{#if messages.length && waitingIndex}
					<div class="typing" in:fade|local={{ duration: 300 }}>
						<div><Pulse size={20} color={'var(--blend-40)'}/></div>
					</div>
				{/if}

			{/if}

		</div>
	</div>

	<div class="overlay-actions send">
		{#if mounted}
			<form on:submit|preventDefault={sendMessage}>
				<textarea
					bind:this={ta}
					bind:value={messageText}
					use:autoresize
					placeholder="Say something …"
					on:input={typing}
					on:keypress={checkMessage}
				></textarea>
				<button type="submit" use:autoblur>
					<svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg>
				</button>
				{#if messageText}
					<span class="counter" class:over={remaining < 0}>{remaining}</span>
				{/if}
			</form>
		{/if}
	</div>
</div>