<script>

	import { onMount } from 'svelte';
	import { fade } from "svelte/transition";
	import { event } from "../../../../lib/stores.js";

	let svg = false;
	let mask = null;
	let badgeColor;
	let mounted = false;

	onMount(() => {

		svg = ($event.setup.virtual.studio.banners.badge.format == 'svg') ? true : false;

		if ($event.setup.virtual.studio.banners.badgeStyle == 'tinted') {
			if (svg) {
				const regex = /\.svg$/;
				mask = 'https://cdn.attendzen.io/' + $event.accountRef + '/mask_' + $event.setup.virtual.studio.banners.badge.filename.replace(regex, '.png');
			} else {
				const regex = /\.jpe?g$/;
				mask = 'https://cdn.attendzen.io/' + $event.accountRef + '/mask_' + $event.setup.virtual.studio.banners.badge.filename.replace(regex, '.png');
			}
			badgeColor = $event.theme.colors[$event.setup.virtual.studio.banners.badgeColor];
		} else {
			mask = null;
		}

		mounted = true;

	});

</script>

<style>
	.badge {
		position: absolute;
		top: calc(var(--sw) * 0.035);
		bottom: calc(var(--sw) * 0.035);
		left: calc(var(--sw) * 0.04);
		width: clamp(calc(var(--sw) * 0.06), calc(var(--sw) * 0.06 / (var(--badgeAspectRatio) / 100)), calc(var(--sw) * 0.15));
/*		width: calc(var(--sw) * 0.06);*/
/*		width: calc(var(--sw) * 0.06 / (var(--badgeAspectRatio) / 100));*/
/*		width: calc(var(--sw) * 0.205);*/
		display: flex;
		justify-content: center;
		align-items: flex-start;
/*		outline: 1px solid red;*/
	}
	.badge img {
		width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	.mask {
		position: absolute;
		inset: 0;
	}

	@supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) {
		.mask:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			-webkit-mask-image: var(--mask);
			-webkit-mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 50% 0%;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: 50% 0%;
			background: var(--badgeColor, #000);
			pointer-events: none;
			max-width: 10rem;
		}
		.mask > img {
			visibility: hidden;
		}
	}
</style>

{#if mounted}
	<div class="badge" in:fade={{ duration: 200, delay: 400 }}>
		{#if mask}
			<span class="mask" style="--mask:url('{mask}');--badgeColor:{badgeColor};">
				<img src="{mask}" alt=""/>
			</span>
		{:else}
			{#if svg}
				<img src="https://cdn.attendzen.io/{$event.accountRef}/{$event.setup.virtual.studio.banners.badge.filename}" alt=""/>
			{:else}
				<img src="https://cdn.attendzen.io/{$event.accountRef}/small_{$event.setup.virtual.studio.banners.badge.filename}" alt=""/>
			{/if}
		{/if}
	</div>
{/if}