<script>

	import { displayLang } from '../../../lib/stores.js';
	import { event } from '../../../lib/stores.js';

	export let details;

</script>

<style>
	p.speaker {
		position: relative;
		padding-left: 3rem;
		/*margin: 1rem 0;*/
		min-height: 2.5rem;
	}
	p.speaker img {
		position: absolute;
		top: 0;
		left: 0;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 100px;
	}
	p + p {
		margin-top: 0.25rem;
	}
	p + p.speaker {
		margin-top: 0.75rem;
	}
	p.speaker strong {
		/*font-size: 0.75rem;*/
		/*font-weight: 600;*/
	}
</style>

{#if details[0] && (details[0].crm || details[0].presentation || details[0].synopsis) }
	<div class="component">
		{#each details as speaker}
			<div class="speaker">
				{#if speaker.presentation && speaker.presentation[$displayLang]}
					<p class="title"><strong>{speaker.presentation[$displayLang]}</strong></p>
				{/if}
				{#if speaker.synopsis && speaker.synopsis[$displayLang]}
					<p>{speaker.synopsis[$displayLang]}</p>
				{/if}
				{#if speaker.crm}
					<p class="speaker">
						{#if speaker.crm.a}<img class="avatar" src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{speaker.crm.a}" alt=""/>{/if}
						<strong>{speaker.crm.n}</strong>
						{#if speaker.crm.j}<br/><span>{speaker.crm.j}</span>{/if}
						{#if speaker.crm.o}<br/><span>{speaker.crm.o}</span>{/if}
					</p>
				{/if}
			</div>
		{/each}
	</div>
{/if}