<script>

	import { displayLang } from '../../../lib/stores.js';
	import { text_to_html } from "../../../lib/html.js";

	export let stc;
	export let details;

</script>

<div class="component">
	{#if details[$displayLang]}
		<p class="label">{stc.label[$displayLang]}</p>
		{@html text_to_html(details[$displayLang])}
	{/if}
</div>