<script>

	import { getContext } from 'svelte';
	import { fade } from 'svelte/transition';
	import fadeScale from 'svelte-transitions-fade-scale';

	import Button from "../../../../ui/Button.svelte";
	import Dialog from "../../../../ui/Dialog.svelte";

	import { event, session, epoch } from "../../../../lib/stores.js";
	import { postServerData } from '../../../../lib/prelude.js';
	import { autoblur } from '../../../../lib/autoblur.js';

	const clip = getContext('clip');
	const clips = getContext('clips');
	const clipTokens = getContext('clipTokens');

	export let video;

	let over = false;
	let deleteItemDialog = null;

	function toggleStatus() {
		if ($clip && $clip.r && ($clip.r == video.ref)) {
			$clip = null;
		} else {
			$clip = {
				r: video.ref,
				l: false,
				e: $epoch
			};
		}
	}

	function openDeleteItemDialog() {
		deleteItemDialog = {
			text: "Are you sure you want to delete this video clip?",
			actions: [
				{
					label: "Confirm",
					dispatch: "confirm"
				},
				{
					label: "Cancel",
					ghost: true,
					dispatch: "escape"
				}
			]
		};
	}

	async function deleteItem() {
		if ($clip && $clip.r && ($clip.r == video.ref)) {
			$clip = null;
		}
		let filtered = $clips.filter(a => video.ref != a.ref);
		const json = JSON.stringify(filtered);
		await postServerData('virtual/studio/clips', {
			json: json,
			sessionRef: $session.ref
		});
		deleteItemDialog = null;
	}

	function toggleLoop() {
		if ($clip && $clip.r && ($clip.r == video.ref)) {
			$clip.l = !$clip.l;
		}
	}

</script>

<style>
	.item {
		position: relative;
		width: 70%;
		background: var(--dark, #101820);
		border-radius: 4px;
		display: flex;
		transform: width 0.3s ease;
	}

	:global(.list.p0) .item,
	:global(.list.p1) .item {
		width: 100%;
	}

	:global(.list.p2) .item {
		width: 90%;
	}

	:global(.list.p3) .item {
		width: 80%;
	}

	img {
		width: 100%;
		aspect-ratio: 16/9;
		object-fit: cover;
		border-radius: 4px;
		box-shadow: 0 0 2px var(--shadow);
		background: #101820;
	}

	.control {
		position: absolute;
		z-index: 70;
		top: 50%;
		left: 50%;
		max-width: 100%;
		width: max-content;
		box-sizing: border-box;
		padding: 0.4rem;
		transform: translate(-50%,-50%);
	}

	.control :global(button.mini),
	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--panelColor);
		border-color: var(--panelColor);
		color: var(--textColor);
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		box-shadow: 0 2px 10px var(--shadow);
		min-width: 7rem;
	}

	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--blend-10);
		border-color: var(--blend-10);
	}

	.actions {
		position: absolute;
		z-index: 70;
		top: 0.4rem;
		right: 0.4rem;
		display: flex;
		gap: 0.2rem;		
	}
	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	.actions button.lit {
		background: var(--accentColor);
		color: var(--panelColor);
		border-color: var(--blend-20);
	}
	.actions button.lit:hover,
	.actions button.lit:active,
	.actions button.lit:focus {
		background: var(--accentBlend-80);
	}
	.actions button span {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
	.actions button svg {
		position: absolute;
		inset: 0;
	}
	@keyframes spin {
		from { transform: rotate(360deg); }
		to { transform: rotate(0deg); }
	}
	.actions button.lit svg {
		will-change: transform;
		transition-property: transform;
		animation-name: spin;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-duration: 10s;
	}
</style>

<div
	class="item"
	in:fadeScale={{ duration: 200, baseScale: 0.9 }}
	on:mouseenter={() => { over = true }}
	on:mouseleave={() => { over = false }}
	on:focus={() => { over = true }}
	on:blur={() => { over = false }}
>
	<img src="https://image.mux.com/{video.playback}/thumbnail.jpg?token={$clipTokens[video.ref].thumbnail}" alt=""/>
	{#if over}
		<div class="control" transition:fade|local={{ duration: 100 }}>
			<Button
				mini={true}
				customClass={($clip && $clip.r && ($clip.r == video.ref)) ? 'onstage' : ''}
				label={($clip && $clip.r && ($clip.r == video.ref)) ? 'Remove' : 'Add to stage'}
				on:click={toggleStatus}
			/>
		</div>
	{/if}
	<div class="actions">
		{#if over && video.allowDelete}
			<button
				type="button"
				use:autoblur
				on:click|stopPropagation={openDeleteItemDialog}
			>
				<span>
					<svg viewBox="0 0 80 80">
						<path d="M48.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM40.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM32.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM62 21.27c0-2.21-1.79-4-4-4h-9.94c0-2.21-1.79-4-4-4h-8c-2.21 0-4 1.79-4 4h-10.06c-2.21 0-4 1.79-4 4v7.99h4.01v32.51c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-32.51h3.99v-7.99zm-8 38.51c0 1.1-.9 2-2 2h-24c-1.1 0-2-.9-2-2v-30.52h28v30.52zm4-34.52h-36v-1.96c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2v1.96z"/>
					</svg>
				</span>
			</button>
		{/if}
		{#if $clip && $clip.r && ($clip.r == video.ref)}
			<button
				type="button"
				use:autoblur
				on:click|stopPropagation={toggleLoop}
				class:lit={$clip.l}
			>
				<span>
					<svg viewBox="0 0 80 80">
						<path d="M9.2 34.6l5 13.5 9.3-11-5.1-.9c2.1-12 13.5-20 25.5-17.9 5.8 1 10.8 4.2 14.2 9.1l3.3-2.3c-4-5.7-9.9-9.5-16.8-10.7-14.1-2.5-27.6 7-30.1 21.1l-5.3-.9zM70.8 45.4l-4.9-13.5-9.3 11 5.2.9c-1 5.8-4.2 10.8-9 14.2-4.8 3.4-10.6 4.7-16.4 3.6-5.8-1-10.8-4.2-14.2-9l-3.3 2.3c2.2 3.2 5 5.7 8.3 7.6 2.6 1.5 5.5 2.5 8.5 3.1 6.8 1.2 13.7-.3 19.4-4.3 5.7-4 9.5-9.9 10.7-16.8l5 .9z"/>
					</svg>
				</span>
			</button>
		{/if}
	</div>
</div>

{#if deleteItemDialog}
	<Dialog
		{...deleteItemDialog}
		on:escape={() => { deleteItemDialog = null}}
		on:confirm={deleteItem}
	/>
{/if}