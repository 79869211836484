<script>

	import { createEventDispatcher } from 'svelte';

	import Brand from './ui/Brand.svelte';
	import Timer from './ui/Timer.svelte';

	import { event, epoch } from './lib/stores.js';

	const dispatch = createEventDispatcher();

	export let checkinThreshold;

	let duration = $event.startsEpoch - $epoch;

	$: if ($epoch > checkinThreshold) {
		dispatch('showtime');
	}

	// clearInterval($epochInterval);

	// $epochInterval = setInterval(() => {
	// 	$epoch = $epoch + 1;
	// }, 1000);

</script>

<style>
	.hold {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 11002;
	}

	.hold > div {
		max-width: 80vw;
		max-height: 80dvh;
		overflow: auto;
		width: 600px;
		box-sizing: border-box;
		padding: 2rem;
		background: var(--panelColor);
		color: var(--textColor);
		box-shadow: 0 0 20px -8px rgba(0,0,0,0.2);
		position: relative;
	}

	p {
		font-size: 0.875rem;
		margin-top: 2rem;
	}

	.timer {
		margin-top: 0.5rem;
		font-size: 2rem;
	}

</style>

<div class="hold">
	<div>
		<Brand/>
		<p>This event starts in …</p>
		<div class="timer">
			<Timer {duration}/>
		</div>
	</div>
</div>
