<script>

	import { onMount, getContext } from 'svelte';
	import { fade } from "svelte/transition";

	import Player from '../../../ui/Player.svelte';

	import { event, room, session, epoch } from "../../../lib/stores.js";

	const clip = getContext('clip');
	const clips = getContext('clips');
	const clipTokens = getContext('clipTokens');

	let video = null;
	let fadeDuration = 0;
	let playing = false;
	let startAt = 0;

	onMount(() => {
		let vid = null;
		if ($clip && $clip.r) {
			if ($event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.clips) {
				for (const b of $event.setup.virtual.studio.clips) {
					if (b.content.ref == $clip.r) {
						vid = b.content;
						break;
					}
				}
			}
			if (!vid) {
				for (const o of $clips) {
					if (o.ref == $clip.r) {
						vid = o;
						break;
					}
				}
			}
		}
		if ($clip.e && (($epoch - $clip.e) > 1)) {
			let overspill = $epoch - $clip.e;
			let overspillAfterLoops = overspill % vid.duration;
			// console.log({overspill}, {overspillAfterLoops});
			startAt = overspillAfterLoops;
		}
		video = vid;
	});

	$: fadeDuration = ($room && $session) ? 200 : 0;

</script>

<style>
	.clip {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		object-fit: cover;
		pointer-events: none;
		opacity: 1;
		transition: opacity 0.6s ease;
	}
	.clip.playing {
		opacity: 1;
	}
</style>

{#if video}
	<div class="clip" class:playing out:fade={{ duration: fadeDuration }}>
		<Player
			provider="mux"
			playback={video.playback}
			token={$clipTokens[video.ref].playback}
			autoplay={true}
			muted={false}
			showControls={false}
			loop={$clip && $clip.l}
			startAt={startAt}
			on:ended={() => { $clip = null }}
			on:play={() => { playing = true }}
		/>
	</div>
{/if}