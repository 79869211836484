<script>

	import { onMount } from 'svelte';

	import Nav from './Masthead/Nav.svelte';
	import Messages from './Masthead/Messages.svelte';
	import Reports from './Masthead/Reports.svelte';
	import Announcements from './Masthead/Announcements.svelte';
	import Help from './Masthead/Help.svelte';
	import Avatar from './Masthead/Avatar.svelte';

	import Brand from './ui/Brand.svelte';

	import { attendee, event } from './lib/stores.js';

	// let accountRef = $event.accountRef;
	// let logo = $event.theme.virtual.logo;
	// let logoStyle = $event.theme.virtual.logoStyle;
	// let eventName = $event.name;

	// let logoSvg = false;
	// let mask = null;
	// let wide = false;
	// let extrawide = false;

	let mounted = false;
	let ready = false;

	// if (logo) {

	// 	// TODO: unsplash, unless we prohibit that

	// 	logoSvg = (logo.format == 'svg') ? true : false;

	// 	const aspectRatio = logo.aspectRatio;

	// 	wide = false;
	// 	extrawide = false;

	// 	if (aspectRatio) {
	// 		if (aspectRatio < 15) {
	// 			extrawide = true;
	// 		} else if (aspectRatio < 60) {
	// 			wide = true;
	// 		}
	// 	}

	// 	if (logoStyle == 'tinted') {
	// 		if (logoSvg) {
	// 			const regex = /\.svg\Z/;
	// 			mask = 'https://cdn.attendzen.io/' + accountRef + '/' + logo.filename.replace(regex, '.png');
	// 		} else {
	// 			const regex = /\.jpe?g\Z/;
	// 			mask = 'https://cdn.attendzen.io/' + accountRef + '/' + logo.filename.replace(regex, '.png');
	// 		}
	// 	} else {
	// 		mask = null;
	// 	}

	// }

	onMount(() => {

		mounted = true;

		setTimeout(function(){
			ready = true;
		}, 400);

	});

</script>

<style>
	header {
		/*position: relative;*/
		z-index: 10000;
		background: var(--panelColor, #fff);
		box-shadow: 0 2px 10px var(--shadow);
		transform: translateY(-100%);
		transition: opacity 0.3s ease, transform 0.8s ease-out;
		padding: 0.8rem 1rem 0.5rem 1rem;
		box-sizing: border-box;
		height: 4.5rem;
		opacity: 0;
		position: sticky;
		top: 0;
	}
	/*@media (max-width: 940px) {
		header {
			position: sticky;
			top: 0;
		}
	}*/
	header.ready {
		opacity: 1;
		transform: translateY(0);
	}

	header > div {
		display: flex;
	}

	@media (max-width: 600px) {
		header {
			height: 6rem;
		}
		header > div {
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	@media (max-height: 599px) {
		header {
			position: relative;
		}
	}

	header :global(.brand) {
		flex: 0 0 25%;
		/*box-sizing: border-box;*/
		/*position: relative;*/
	}

	/*.brand img {
		max-width: 10rem;
		max-height: 3rem;
	}

	.brand.wide img {
		max-height: 2.5rem;
	}

	.brand.extrawide img {
		max-height: 2rem;
	}

	.brand img.svg {
		height: 3rem;
	}

	.brand.wide img.svg {
		height: 2.5rem;
	}*/

	ul {
		display: flex;
		align-items: flex-start;
		margin: 0 auto;
		padding: 0;
	}

	.utils {
		flex: 0 0 25%;
	}

	.utils ul {
		/*width: auto;*/
		justify-content: flex-end;
	}

	.utils :global(li) {
		display: block;
		margin: 0 0 0 1.4rem;
	}

	:global(#notifications) {
		position: relative;
		left: -2px;
	}

	/*@supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) {
		.mask:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			-webkit-mask-image: var(--mask);
			-webkit-mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 0 0;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: 0 0;
			background: var(--logoColor, #000);
			pointer-events: none;
		}
		.mask > img {
			visibility: hidden;
		}
	}*/

</style>

{#if mounted}
	<header class:ready>
		<div>

			<!-- {#if mask}
				<p class="brand mask"  class:wide class:extrawide style="--mask:url('{mask}');">
					<img src="{mask}" alt="{eventName}"/>
				</p>
			{:else}
				<p class="brand" class:wide class:extrawide>
					{#if logo && logoSvg}
						<img class="svg" src="https://cdn.attendzen.io/{accountRef}/{logo}" alt="{eventName}"/>
					{:else if logo}
						<img src="https://cdn.attendzen.io/{accountRef}/small_{logo}" alt="{eventName}"/>
					{:else}
						<span>{eventName}</span>
					{/if}
				</p>
			{/if} -->
			<Brand/>

			<Nav/>

			<div class="utils">
				<ul>
					{#if $event && $event.setup && $event.setup.virtual && $event.setup.virtual.textChat}
						<Messages/>
					{/if}
					{#if $attendee && ($attendee.type == 'login')}
						<Reports/>
						<Announcements/>
					{:else if ($attendee)}
						{#if $event && $event.setup && $event.setup.virtual && $event.setup.virtual.textChat}
							<Help/>
						{/if}
					{/if}
					<Avatar/>
				</ul>
			</div>

		</div>
	</header>
{/if}
