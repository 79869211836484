<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';

	import Results from "./Results.svelte";

	import Field from "../../../ui/Field.svelte";
	import Button from "../../../ui/Button.svelte";
	import Menu from "../../../ui/Menu.svelte";
	import Dialog from "../../../ui/Dialog.svelte";

	import { getServerData, postServerData } from '../../../lib/prelude.js';
	import {	bigError, session, attendee, backstage } from '../../../lib/stores.js';
	import { autoblur } from '../../../lib/autoblur.js';

	const dispatch = createEventDispatcher();

	export let poll;
	export let studio = false;
	export let studioHighlight = null;

	const resultsInterval = 30000; // 30 seconds; maybe 1 min for prod?

	// console.log({$session});

	let mounted = false;
	let results = null;
	let resultsTimer;
	let showResults = true;

	let dotsMenu = false;
	let dotsOptions = [];

	let closePollDialog = null;
	let deletePollDialog = null;

	let response = ($session.pollResponses && $session.pollResponses[poll.ref]) ? $session.pollResponses[poll.ref] : null;

	onMount(async () => {

		showResults = poll.results;

		if (poll.closed || $session.pollResponses[poll.ref]) {
			results = {};
			checkResults(true);
		}

		mounted = true;

	});

	async function respond() {
		await postServerData('virtual/polls/respond', {
			poll: poll.ref,
			response: response
		});
		if (!$bigError) {
			results = {};
		}
	}

	$: if ($session.pollResponses[poll.ref]) {
		checkResults();
	}

	async function checkResults(m) {
		if ((mounted || m) && showResults) {
			// console.log("fetching results", resultsTimer);
			const pollData = await getServerData('virtual/polls', {
				poll: poll.ref
			});
			if (!$bigError) {
				results = pollData.results;
				if (!poll.closed) {
					clearTimeout(resultsTimer);
					resultsTimer = setTimeout(checkResults, resultsInterval);
				}
			}
		}
	}

	function dotsAction(e) {
		dotsMenu = false;
		const action = e.detail.value;
		if (action == "close") {
			closePollDialog = {
				text: "Are you sure you want to close this poll?",
				actions: [
					{
						label: "Confirm",
						dispatch: "confirm"
					},
					{
						label: "Cancel",
						ghost: true,
						dispatch: "escape"
					}
				]
			};
		} else if (action == "results") {
			showResults = true;
			checkResults(true);
		} else if (action == "highlight") {
			toggleHighlight();
		} else if (action == "delete") {
			deletePollDialog = {
				text: "Are you sure you want to delete this poll?",
				actions: [
					{
						label: "Confirm",
						dispatch: "confirm"
					},
					{
						label: "Cancel",
						ghost: true,
						dispatch: "escape"
					}
				]
			};
		}
	}

	function checkPoll() {
		setDotsOptions();
		if (mounted) {
			if (poll.closed) {
				results = {};
				checkResults();
			}
		}
	}

	function setDotsOptions() {
		dotsOptions = [];
		if (!poll.closed) dotsOptions.push({
			value: 'close',
			label: 'Close poll',
			icon: '<svg viewBox="0 0 80 80"><path d="M21.57 50.69v-30.52c0-1.23-1-2.23-2.23-2.23s-2.23 1-2.23 2.23v30.52h4.46zM13 55.09c-1.1 0-2-.9-2-2v-38.01c0-1.1.9-2 2-2h38c1.1 0 2 .9 2 2v8.46c1.36.04 2.7.17 4 .4v-10.85c0-2.21-1.79-4-4-4h-42c-2.21 0-4 1.79-4 4v41.99c0 2.21 1.79 4 4 4h16.13c-.45-1.29-.81-2.62-1.06-3.99h-13.07zM34.23 30.59v-1.83c0-1.23-1-2.23-2.23-2.23s-2.23 1-2.23 2.23v7.17c1.25-1.97 2.75-3.76 4.46-5.34zM52.31 29.52c-11.41 0-20.7 9.28-20.7 20.7 0 11.41 9.28 20.7 20.7 20.7s20.69-9.3 20.69-20.71-9.28-20.69-20.69-20.69zm0 4c3.88 0 7.45 1.34 10.29 3.58l-23.41 23.41c-2.23-2.84-3.58-6.41-3.58-10.29 0-9.21 7.49-16.7 16.7-16.7zm0 33.39c-3.88 0-7.45-1.34-10.29-3.58l23.41-23.41c2.23 2.84 3.57 6.41 3.57 10.29 0 9.21-7.49 16.7-16.69 16.7z"/></svg>'
		});
		if (poll.closed && !showResults) {
			dotsOptions.push({
				value: 'results',
				label: 'View results',
				icon: '<svg viewBox="0 0 20 20"><path d="M13.48 15.53v-2.95c0-.55.45-1 1-1s1 .45 1 1v2.95h-2zM4.52 15.53v-9.91c0-.55.45-1 1-1s1 .45 1 1v9.92h-2zM9 15.53v-7.05c0-.55.45-1 1-1s1 .45 1 1v7.05h-2z"/></svg>'
			});
		}
		if (poll.closed && showResults && studio) {
			if (studioHighlight && (studioHighlight.t == 'poll') && (studioHighlight.r == poll.ref)) {
				dotsOptions.push({
					value: 'highlight',
					label: 'Remove from stage',
					icon: '<svg viewBox="0 0 20 20"><path d="M10 3.5c3.58 0 6.5 2.92 6.5 6.5s-2.92 6.5-6.5 6.5-6.5-2.92-6.5-6.5 2.92-6.5 6.5-6.5m0-1c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5zM4.95 10.61v-1.21h10.08v1.22h-10.08z"/></svg>'
				});
			} else {
				dotsOptions.push({
					value: 'highlight',
					label: 'Add to stage',
					icon: '<svg viewBox="0 0 20 20"><path d="M10 3.5c3.58 0 6.5 2.92 6.5 6.5s-2.92 6.5-6.5 6.5-6.5-2.92-6.5-6.5 2.92-6.5 6.5-6.5m0-1c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5zM10.6 4.96v4.44h4.44v1.22h-4.44l-.01 4.45h-1.19v-4.44l-4.45-.01v-1.22h4.44v-4.44h1.21z"/></svg>'
				});
			}
		}
		dotsOptions.push({
			value: 'delete',
			label: 'Delete poll',
			icon: '<svg viewBox="0 0 20 20"><path d="M12 14.5c-.28 0-.5-.22-.5-.5v-5.01c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .28-.22.5-.5.5zM10 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM8 14.5c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5.01c0 .27-.22.49-.5.49zM15.5 5.5c0-.55-.45-1-1-1h-2.49c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1h-2.51c-.55 0-1 .45-1 1v2h1v8c0 .55.45 1 1 1h7c.55 0 1-.45 1-1v-8h1v-2zm-2 9.5c0 .28-.22.5-.5.5h-6c-.28 0-.5-.22-.5-.5v-7.5h7v7.5zm1-8.5h-9v-.49c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5v.49z"/></svg>'
		});
		dotsOptions = dotsOptions;
	}

	async function closePoll() {
		closePollDialog = null;
		postServerData('virtual/polls/close', {
			poll: poll.ref,
			index: poll.index
		});
	}

	function deletePoll() {
		deletePollDialog = null;
		postServerData('virtual/polls/delete', {
			poll: poll.ref,
			index: poll.index
		});
	}

	function toggleHighlight() {
		if (studioHighlight && (studioHighlight.t == 'poll') && (studioHighlight.r == poll.ref)) {
			dispatch('highlight', null);
		} else {
			dispatch('highlight', { t: 'poll', r: poll.ref });
		}
	}

	onDestroy(() => {
		// console.log("clearTimeout", resultsTimer);
		clearTimeout(resultsTimer);
	});

	$: if (mounted && poll) {
		checkPoll();
	}

	$: setDotsOptions(studioHighlight);

	// console.log('pollResponses', $session.pollResponses);

</script>

<style>
	.poll {
		position: relative;
		padding-top: 1rem;
	}

	.poll :global(.field) {
		margin-top: 0;
	}
	.poll :global(.labelgroup) {
		font-size: 0.8rem;
		margin-bottom: 0.25rem;
	}
	.poll form :global(.radiobuttons p) {
		border: 1px solid var(--blend-20);
		padding: 0.5rem;
		border-radius: 4px;
		position: relative;
		gap: 0.5rem;
		font-size: 0.7rem;
	}
	.poll form :global(.radiobuttons p span) {
		flex: 0 0 auto;
		top: 0.2em;
	}
	.poll form :global(.radiobuttons p label) {
		cursor: pointer;
		line-height: 1.4;
		font-size: 0.6875rem;
	}
	.poll form :global(.radiobuttons p.disabled label) {
		cursor: default;
	}
	.poll form :global(.radiobuttons p label:after) {
		content: '';
		position: absolute;
		inset: 0;
	}
	.poll :global(.radiobuttons p:hover) {
		border-color: var(--blend-60);
	}
	.poll :global(.radiobuttons p span) {
		color: var(--blend-40);
	}
	.poll :global(.radiobuttons p.selected span) {
		color: var(--accentColor);
	}
	.poll :global(.radiobuttons p.selected) {
		border-color: var(--accentColor);
		background: var(--accentBlend-10);
	}

	.poll.withDots :global(.labelgroup),
	.poll.withDots :global(.question) {
		padding-right: 3rem;
	}
	
	.dots {
		position: absolute;
		top: 1rem;
		right: 0;
		width: 1rem;
		height: 1rem;
		padding: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		/*transform: rotate(90deg);*/
	}
	.dots:hover,
	.dots:active,
	.dots:focus {
		color: var(--accentColor);
	}
	
	.poll :global(.menu) {
		position: absolute;
		width: 200px;
		top: 2.4rem;
		right: -0.5rem;
	}
	.poll :global(.menu.arrow:before) {
		left: auto !important;
		right: 0.9rem !important;
	}

	.question {
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
	}
	.question + p {
		margin-top: 0;
		font-size: 0.6875rem;
	}

</style>

{#if mounted}
	<div class="poll" class:withDots={($attendee.type == 'login') || ($backstage.includes($session.ref))}>
		{#if results}
			{#if showResults}
				<Results {poll} {results}/>
			{:else}
				{#if $session.pollResponses[poll.ref]}
					<form>
						<Field
							ref="poll-{poll.ref}-response"
							bind:value={response}
							component="RadioButtons"
							label={poll.question}
							options={poll.options}
							disabled={true}
						/>
					</form>
				{:else}
					<p class="question"><strong>{poll.question}</strong></p>
					<p>This poll has closed.</p>
				{/if}
			{/if}
		{:else}
			<form on:submit|preventDefault={respond}>
				<Field
					ref="poll-{poll.ref}-response"
					bind:value={response}
					component="RadioButtons"
					label={poll.question}
					options={poll.options}
				/>
				<Button label="Submit response" type="submit" wide={true} disabled={!response} />
			</form>
		{/if}
		{#if ($attendee.type == 'login') || ($backstage.includes($session.ref))}
			<button type="button" class="dots" use:autoblur on:click|stopPropagation={() => { dotsMenu = !dotsMenu }}>
				<svg viewBox="0 0 142 142"><title>Menu</title><path d="M.8 70.9c-.1-8.9 7.3-16.1 16.3-16.1 8.8 0 16.3 7.3 16.3 16.2-.1 9-7.6 16.3-16.3 16.3-9-.1-16.4-7.3-16.3-16.4zm53.9 0c-.1-8.9 7.3-16.1 16.3-16.1 8.8 0 16.2 7.3 16.3 16.2-.1 9-7.6 16.3-16.3 16.3-9-.1-16.4-7.3-16.3-16.4zm53.9 0c-.1-8.9 7.3-16.2 16.3-16.2 8.7 0 16.2 7.3 16.3 16.2-.1 9-7.6 16.3-16.3 16.3-9.1 0-16.4-7.2-16.3-16.3z"/></svg>
			</button>
			{#if dotsMenu}
				<Menu
					options={dotsOptions}
					on:selected={dotsAction}
					on:escape={() => { dotsMenu = false }}
					arrow={true}
				/>
			{/if}
		{/if}
	</div>
{/if}

{#if closePollDialog}
	<Dialog
		{...closePollDialog}
		on:escape={() => { closePollDialog = null}}
		on:confirm={closePoll}
	/>
{/if}

{#if deletePollDialog}
	<Dialog
		{...deletePollDialog}
		on:escape={() => { deletePollDialog = null}}
		on:confirm={deletePoll}
	/>
{/if}