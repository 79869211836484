<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from "svelte/transition";

	import Nav from './Panel/Nav.svelte';
	import Chat from './Panel/Chat.svelte';

	// import { rightDocked, room } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';

	const session = getContext('session');

	let mounted = false;

	onMount(() => {

		mounted = true;

	});

</script>

<style>
	.panel {
		position: relative;
		grid-area: cr;
		box-sizing: border-box;
		width: calc((100vw - 2rem) * 0.22);
	}
	.panel > div {
		position: absolute;
		inset: 0;
		border-radius: 4px;
		box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.4);
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	/*.panel > div > div {
		flex: 1 1 auto;
		position: relative;
		overflow: hidden;
	}*/
	.panel > div:before {
		content: '';
		background: var(--textColor, #ddd);
		position: absolute;
		inset: 0;
		border-radius: 4px;
		z-index: 0;
		opacity: 0.4;
	}
</style>

{#if mounted}
	<div class="panel" transition:fly|local={{ delay: 900, duration: 800, x: 20, y: 20 }}>
		<div>
			<Nav/>
			<Chat/>
		</div>
	</div>
{/if}