<script>

	import { event } from '../../lib/stores.js';

	export let s;
	export let g;

	if (!g.logoStyle) g.logoStyle = 'normal';
	if (!g.logoColor) g.logoColor = 'textColor';

	let color = $event.theme.colors[g.logoColor]

	let basis = '0.08em';
	if (g.size == 'l') basis = '0.09em';
	if (g.size == 's') basis = '0.06em';

	let mask = "https://cdn.attendzen.io/" + $event.accountRef + "/mask_" + s.crm.logo.replace(/\.jpe?g$/,'.png');

	if (s.crm.logo_format == 'svg') {
		mask = "https://cdn.attendzen.io/" + $event.accountRef + "/mask_" + s.crm.logo.replace(/\.svg$/,'.png');
	}

</script>

<style>
	.sponsor {
		position: relative;
	}

	:global(.boxed) .sponsor {
		box-shadow: 0 0 0 1px var(--blend-20);
		border-radius: 4px;
		background: #fff;
	}
	:global(.boxed.l) .sponsor {
		flex: 0 0 70%;
	}
	:global(.boxed.m) .sponsor {
		flex: 0 0 calc(50% - 0.5rem);
	}
	:global(.boxed.s) .sponsor {
		flex: 0 0 calc(33% - 0.66rem);
	}
	:global(.boxed.l) .sponsor > div {
		aspect-ratio: 2.1 / 1;
	}
	:global(.boxed.m) .sponsor > div {
		aspect-ratio: 1.8 / 1;
	}
	:global(.boxed.s) .sponsor > div {
		aspect-ratio: 1.5 / 1;
	}
	:global(.boxed) .sponsor img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		max-width: 70%;
		max-height: 50%;
	}

	.sponsor.masked img {
		display: block;
		margin: 0 auto 0 0;
		width: clamp(calc(var(--basis, 0.1em) * 22), calc(var(--basis, 0.1em) * ((((100 / var(--aspectRatio)) * 100) / 3) - 3)), calc(var(--basis, 0.1em) * 90));
		height: calc((clamp(calc(var(--basis, 0.1em) * 22), calc(var(--basis, 0.1em) * ((((100 / var(--aspectRatio)) * 100) / 3) - 3)), calc(var(--basis, 0.1em) * 90)) / 100) * var(--aspectRatio));
		visibility: hidden;
	}
	.sponsor.masked:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		-webkit-mask-image: var(--mask);
		-webkit-mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: 0% 50%;
		mask-image: var(--mask);
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-position: 0% 50%;
		background: var(--tint);
		pointer-events: none;
	}
</style>

<div class="sponsor" class:masked={g.logoStyle == 'tinted'} style="--aspectRatio:{s.crm.logo_aspectRatio};--mask:url('{mask}');--basis:{basis};--tint:{color}">
	<div>
		{#if s.crm.logo_format == 'svg'}
			<img src="https://cdn.attendzen.io/{ $event.accountRef }/{ s.crm.logo }" alt="{ s.crm.n }"/>
		{:else}
			<img src="https://cdn.attendzen.io/{ $event.accountRef }/trim_{ s.crm.logo }" alt="{ s.crm.n }"/>
		{/if}
	</div>
</div>