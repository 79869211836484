<script>

	import { event } from '../../../../lib/stores.js';

	export let s;

	let mask = "https://cdn.attendzen.io/" + $event.accountRef + "/mask_" + s.crm.logo.replace(/\.jpe?g$/,'.png');

	if (s.crm.logo_format == 'svg') {
		mask = "https://cdn.attendzen.io/" + $event.accountRef + "/mask_" + s.crm.logo.replace(/\.svg$/,'.png');
	}

</script>

<style>
	.sponsor {
		padding-top: 0.25rem;
		position: relative;
	}
	img {
		display: block;
		margin: 0 auto 0 0;
		width: clamp(2.2em, calc(0.1em * ((((100 / var(--aspectRatio)) * 100) / 3) - 3)), 10em);
		height: calc((clamp(2.2em, calc(0.1em * ((((100 / var(--aspectRatio)) * 100) / 3) - 3)), 12em) / 100) * var(--aspectRatio));
		visibility: hidden;
	}
	.sponsor:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		-webkit-mask-image: var(--mask);
		-webkit-mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: 0% 50%;
		mask-image: var(--mask);
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-position: 0% 50%;
		background: var(--textColor);
		pointer-events: none;
	}
</style>

<p class="sponsor" style="--aspectRatio:{s.crm.logo_aspectRatio};--mask:url('{mask}');">
	{#if s.crm.logo_format == 'svg'}
		<img src="https://cdn.attendzen.io/{ $event.accountRef }/{ s.crm.logo }" alt="{ s.crm.n }"/>
	{:else}
		<img src="https://cdn.attendzen.io/{ $event.accountRef }/trim_{ s.crm.logo }" alt="{ s.crm.n }"/>
	{/if}
</p>