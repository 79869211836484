<script>

	import { onMount, onDestroy, getContext } from 'svelte';
	import { slide, fly } from 'svelte/transition';
	import { cubicInOut } from 'svelte/easing';

	import Player from '../../ui/Player.svelte';
	import Button from '../../ui/Button.svelte';

	import { session, attendee } from '../../lib/stores.js';
	import { autoblur } from '../../lib/autoblur.js';

	const livestreamStatus = getContext('livestreamStatus');
	const broadcastStarted = getContext('broadcastStarted');
	const audienceViewMuted = getContext('audienceViewMuted');

	let show = false;
	let tooltip = false;

	onMount(() => {
		$audienceViewMuted = true;
	});

	onDestroy(() => {
		$audienceViewMuted = true;
	});

	function toggleShow() {
		show = !show;
	}

	function toggleMute() {
		$audienceViewMuted = !$audienceViewMuted;
	}

</script>

<style>
	.audience {
		position: relative;
		padding: 0.3rem 1rem 0 1rem;
		background: var(--blend-10);
		box-shadow: inset 0 -2px 10px -6px var(--shadow);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	.audience.show {
		padding: 0.3rem 1rem 0 1rem;
	}
	.audience :global(button.subtle) {
		width: 100%;
		text-align: center;
		color: var(--blend-60);
		margin-bottom: 0.3rem;
	}
	.audience :global(button.subtle:hover),
	.audience :global(button.subtle:active),
	.audience :global(button.subtle:focus) {
		color: var(--textColor);
	}
	.audience :global(button.subtle svg) {
		width: 0.33rem;
		transform: rotate(-90deg);
		transition: transform 0.2s ease;
		margin-right: 0.3rem;
	}
	.audience.show :global(button.subtle svg) {
		transform: rotate(90deg);
	}
	.player {
		position: relative;
		overflow: visible;
		width: min(280px,100%);
		aspect-ratio: 16/9;
		margin: 0 auto;
		padding-bottom: 0.8rem;
	}
	@media (max-height: 780px) {
		.player {
			max-width: 30vh;
		}
	}
	@media (max-height: 650px) {
		.player {
			max-width: 22vh;
		}
	}
	.player > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		aspect-ratio: 16/9;
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0 2px 8px -2px var(--shadow);
	}


	.actions {
		position: absolute;
		z-index: 70;
		top: 0.4rem;
		right: 0.4rem;
		display: flex;
		gap: 0.2rem;		
	}
	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	/*.actions button[disabled],
	.actions button[disabled]:hover,
	.actions button[disabled]:active,
	.actions button[disabled]:focus {
		background: var(--panelColor);
		cursor: not-allowed;
	}*/
	.actions button svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
	.actions button.muted {
		color: var(--red);
	}
	.info {
		position: absolute;
		top:  0;
		right: 0;
	}
	.info button {
		border: 0;
		background: transparent;
		position: relative;
		width: 0.8rem;
		height: 0.8rem;
		padding: 0.66rem 0.6rem;
		color: var(--blend-60);
		cursor: pointer;
		box-sizing: content-box;
	}
	.info button:hover {
		color: var(--textColor);
	}

	.tooltip {
		position: absolute;
		bottom: 0.36rem;
		left: 0rem;
		transform: translate(-100%, 0);
		border-radius: 4px;
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.4rem 0.8rem;
		line-height: 1.2;
		font-size: 0.6875rem;
		font-weight: 400;
		width: 11rem;
		z-index: 1000;
	}
	.tooltip:before {
		content: "";
		position: absolute;
		bottom: 0.3rem;
		right: -0.05rem;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translateY(-50%);
		background: var(--panelColor);
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}
</style>

{#if $broadcastStarted}
	<div class="audience" class:show in:slide|local={{ duration: 300, easing: cubicInOut }}>
		<Button subtle={true} wide={true} label={show ? 'Hide audience view' : 'Show audience view'} on:click={toggleShow}>
			<svg viewBox="0 0 16.7 29.5"><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"/></svg>
		</Button>
		<div class="info">
			<button
				type="button"
				on:mouseover={() => { tooltip = true }}
				on:mouseout={() => { tooltip = false }}
				on:focus={() => { tooltip = true }}
				on:blur={() => { tooltip = false }}
			>
				<svg viewBox="0 0 56 56"><path d="M56 28c0 15.46-12.54 28-28 28s-28-12.54-28-28 12.54-28 28-28 28 12.54 28 28zm-28-24c-13.25 0-24 10.75-24 24s10.75 24 24 24 24-10.75 24-24-10.75-24-24-24z"/><path d="M31.61 9.61c3.05 0 3.95 1.77 3.95 3.79 0 2.52-2.02 4.86-5.47 4.86-2.89 0-4.26-1.45-4.18-3.85 0-2.02 1.69-4.8 5.7-4.8zM24.01 46.39c-2.09 0-3.61-1.27-2.15-6.82l2.39-9.86c.41-1.58.48-2.21 0-2.21-.62 0-3.33 1.09-4.93 2.16l-1.04-1.7c5.07-4.23 10.89-6.71 13.38-6.71 2.09 0 2.43 2.46 1.39 6.26l-2.74 10.36c-.49 1.83-.28 2.46.21 2.46.63 0 2.67-.76 4.69-2.34l1.18 1.58c-4.92 4.93-10.3 6.82-12.38 6.82z"/></svg>
			</button>
			{#if tooltip}
				<span class="tooltip" in:fly|local={{ duration: 400, delay: 200, y: 5 }}>
					This shows you what your audience can currently see and hear. Note that there is a few seconds of latency between the Studio and the Stage.
				</span>
			{/if}
		</div>
		{#if show}
			<div class="player" transition:slide|local={{ duration: 300, easing: cubicInOut }}>
				<div>
					<Player
						provider="mux"
						playbackType="studio"
						playback={$session.playback}
						token={$session.token}
						livestreamStatus={$livestreamStatus}
						ll={true}
						isControlPanel={true}
						muted={$audienceViewMuted}
						sendHeartbeat={false}
					/>
					<div class="actions">
						<button
							type="button"
							class:muted={$audienceViewMuted}
							use:autoblur
							on:click|stopPropagation={toggleMute}
						>
							<svg viewBox="0 0 20 20">
								{#if $audienceViewMuted}
									<path d="M5.91 11.82c-.12-.01-.22-.11-.22-.24v-3.16c0-.13.09-.23.22-.24l.59-.07-.79-.93-.52.03c-.13 0-.26.05-.35.14-.1.09-.15.22-.15.35v4.6c0 .13.05.26.15.35.09.09.22.14.35.14 2.14 0 5.17.93 7.37 2.54l-1.86-2.22c-1.64-.74-3.36-1.17-4.79-1.29zM15.88 16.18l-1.58-1.88v-10.03c0-.2-.12-.38-.3-.46l-.19-.04c-.13 0-.25.05-.35.14-1.45 1.37-3.52 2.35-5.46 2.87l-3.02-3.59c-.18-.21-.49-.24-.7-.06-.22.17-.24.49-.07.7l10.9 13c.1.12.24.18.38.18.11 0 .23-.04.32-.12.22-.18.25-.5.07-.71zm-2.57-10.82v7.76l-4.61-5.5c1.59-.47 3.23-1.22 4.61-2.26z"/>
								{:else}
									<path d="M13.31 5.36v9.29c-2.22-1.69-5.16-2.65-7.4-2.83-.12-.01-.22-.11-.22-.24v-3.16c0-.13.09-.23.22-.24 2.24-.18 5.18-1.14 7.4-2.82m.51-1.58c-.13 0-.25.05-.35.14-2.21 2.06-5.84 3.29-8.28 3.29-.13 0-.26.05-.35.14-.1.09-.15.22-.15.35v4.6c0 .13.05.26.15.35.09.09.22.14.35.14 2.45 0 6.07 1.22 8.28 3.29.1.09.22.14.35.14l.19-.04c.18-.08.3-.26.3-.46v-11.45c0-.2-.12-.38-.3-.46l-.19-.03z"/>
								{/if}
							</svg>
						</button>
					</div>
				</div>
			</div>
		{/if}
	</div>
{/if}