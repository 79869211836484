<script>

	import { fade } from 'svelte/transition';

	import Avatar from "../ui/Avatar.svelte";
	import Button from "../ui/Button.svelte";

	import {	event, attendees, booth, attendee, muted, conversations, conversation } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';
	import { openConversation, videoCall } from '../lib/conversations.js';

	let staff = [];
	let remaining = 400;
	let messageText = '';
	let attendeeIsStaff = false;

	let instaCall = null;
	let canInvite = true;

	let whitebg = ($event.theme.colors[$event.theme.virtual['panelColor']] == '#ffffff') ? true : false;

	function setStaff() {
		let people = [];
		$booth.staff.forEach(s => {
			if ($attendees[s.attendee] && !$muted.includes(s.attendee)) {
				people.push(s.attendee);
				if (s.attendee == $attendee.ref) attendeeIsStaff = true;
			}
		});
		staff = people;
	}

	function resize({ target }) {
		target.style.height = "1px";
		target.style.height = (+target.scrollHeight)+"px";
	}

	function autoresize(el) {
		resize({ target: el });
		el.style.overflow = 'hidden';
		el.addEventListener('input', resize);

		return {
			destroy: () => el.removeEventListener('input', resize)
		}
	}

	function checkMessage(e) {
		if (e.code == 'Enter') {
			e.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
			e.preventDefault();
		}
	}

	async function sendMessage() {
		if (!attendeeIsStaff && (messageText != '') && (messageText != null) && (remaining >= 0)) {
			openConversation(staff, $booth.ref, null, messageText);
			messageText = '';
		}
	}

	async function inviteToVideoCall() {
		await openConversation(staff, $booth.ref);
		if ($conversation) {
			const ok = await videoCall($conversation.ref);
			if (ok) {
				$conversation.videoCalls = 'sent';
			}
		}
	}

	async function startVideoCall() {
		const ok = await videoCall(instaCall);
	}

	function checkConversations() {
		instaCall = null;
		canInvite = true;
		if (!$event.setup.virtual.videoChat) {
			canInvite = false;
		}
		for (const c of Object.keys($conversations)) {
			if ($conversations[c].exhibitor == $booth.ref) {
				if ($conversations[c].videoCalls && ($conversations[c].videoCalls == 'accepted')) {
					instaCall = c;
				} else if ($conversations[c].videoCalls && ($conversations[c].videoCalls == 'declined')) {
					canInvite = false;
				} else if ($conversations[c].videoCalls && ($conversations[c].videoCalls == 'sent')) {
					canInvite = false;
				}
				for (const p of $conversations[c].participants) {
					if (p != $attendee.ref) {
						if ($attendees[p].blockVideo) {
							canInvite = false;
						}
					}
				}
			}
		}
	}

	$: remaining = 400 - messageText.length;

	$: if ($attendees) {
		setStaff();
	}

	$: if ($conversations) {
		checkConversations();
	}

</script>

<style>
	h2 {
		font-size: 0.6875rem;
		margin: 0;
	}
	.staff {
		display: flex;
		gap: 0.25rem 0.5rem;
		flex-wrap: wrap;
	}
	.staff :global(.avatar) {
		position: relative;
		left: 0;
	}

	.send {
		margin: 1rem 0;
	}
	.send > form {
		position: relative;
		background: var(--blend-05);
		display: flex;
		/*align-items: flex-end;*/
		align-items: flex-start;
		padding: 0.5rem;
		border-radius: 3px;
	}
	.send > form.bordered {
		box-shadow: 0 0 0 1px var(--blend-20);
	}
	.send textarea {
		border: 0;
		padding: 0;
		width: 100%;
		flex: 1 1 auto;
		min-height: 6rem;
		max-height: 12rem;
		background: none;
		resize: none;
		font-family: Inter;
		font-size: 0.6875rem;;
		border-radius: 0;
		color: var(--textColor);
		text-align: left;
	}
	.send textarea:focus {
		outline: none;
	}
	.send button {
		flex: 0 0 auto;
		margin-left: 0.5rem;
		padding: 0;
		border: 0;
		color: var(--textColor);
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		box-sizing: border-box;
		border-radius: 3px;
		background: var(--blend-20);
		box-shadow: 0 1px 3px var(--shadow);
	}
	.send :global(button > svg) {
		position: absolute;
		/*inset: 3px;*/
		top: 0.15rem;
		left: 0.15rem;
		width: calc(100% - 0.3rem);
		height: calc(100% - 0.3rem);
	}
	.send button:hover,
	.send button:active,
	.send button:focus {
		cursor: pointer;
		color: var(--panelColor);
		background: var(--blend-120);
	}
	.send button[disabled]:hover,
	.send button[disabled]:active,
	.send button[disabled]:focus {
		cursor: not-allowed;
		color: var(--textColor);
		background: var(--blend-20);
	}

	.send textarea[disabled] {
		cursor: not-allowed;
	}

	.counter {
		font-size: 0.625rem;;
		line-height: 1;
		position: absolute;
		bottom: 0.5rem;
		/*top: 0.5rem;*/
		right: 0.5rem;
		color: var(--blend-60);
		width: 1.5rem;
		text-align: center;
	}
	.counter.over {
		color: var(--red);
	}

	.actions :global(button) {
		padding: 0.3rem 1rem;
	}

</style>

{#if staff.length}
	<h2>Talk to us</h2>

	<div class="staff">
		{#each staff as s}
			<Avatar identity={$attendees[s]}/>
		{/each}
	</div>

	<div class="send">
		<form on:submit|preventDefault={sendMessage} class:bordered={!whitebg}>
			<textarea
				bind:value={messageText}
				use:autoresize
				placeholder="Type your message to {$booth.name}"
				on:keypress={checkMessage}
				disabled={attendeeIsStaff}
			></textarea>
			{#if messageText}
				<button type="submit" use:autoblur disabled={attendeeIsStaff} in:fade|local={{ duration: 200 }}>
					<svg viewBox="0 0 80 80"><path d="M74.9 15.18l-.06-.29c-.07-.26-.18-.51-.31-.74l-.16-.38-.28-.21c-.18-.19-.38-.35-.6-.49l-.29-.16c-.22-.11-.44-.19-.68-.24-.13-.03-.26-.05-.34-.05-.24-.03-.48-.03-.7 0l-.26.04-.14.02-63.81 19.11c-1.26.38-2.15 1.51-2.22 2.82-.07 1.31.7 2.53 1.82 3.01l21.96 10.49-.45 15.95-.01.23.01.09c.01.19.03.37.06.54l.09.35c.07.21.17.42.3.63l.15.24.15.22.16.13.27.24.51.34.1.03c.14.06.29.12.43.16l.26.07c.22.04.42.06.63.06h.14l.26-.02c.75-.08 1.43-.44 1.95-1.01l11.42-10.4 13.23 6.32c.44.22.92.34 1.41.34l.27-.01c.34-.03.67-.11.97-.25.88-.38 1.52-1.12 1.77-2.05l11.92-43.79.07-.31.02-.19v-.01l.02-.3c.01-.21-.01-.4-.04-.53zm-9.93 3.5l-34.3 25.88-20.07-9.59 54.37-16.29zm-32.54 43.58l.34-12.26 8.6 4.11-8.94 8.15zm26.91-4l-24.6-11.75 34.98-26.4-10.38 38.15z"/></svg>
				</button>
				<span class="counter" class:over={remaining < 0} in:fade|local={{ duration: 200 }}>{remaining}</span>
			{/if}
		</form>
	</div>

	{#if !attendeeIsStaff && (instaCall || canInvite)}
		<p class="actions">
			{#if instaCall}
				<Button label="Start video call" subtle={true} on:click={startVideoCall}>
					<svg viewBox="0 0 80 80"><path d="M25.82 22.33l6.22 7.59-2.38 2.43c-1.09 1.11-1.43 2.75-.88 4.21 2.43 6.46 8.19 12.22 14.67 14.67.46.17.94.26 1.42.26 1.03 0 2.04-.4 2.8-1.14l2.43-2.38 7.59 6.22-4.22 4.22c-.96.85-2 1.23-3.33 1.23-13.07 0-29.75-16.68-29.75-29.75 0-1.34.38-2.37 1.23-3.33l4.2-4.23m0-4c-1.02 0-2.04.39-2.83 1.17l-4.29 4.29c-1.77 1.95-2.32 4.08-2.32 6.08 0 15.27 18.4 33.75 33.75 33.75 2 0 4.13-.55 6.08-2.32l4.29-4.29c1.67-1.67 1.54-4.42-.29-5.92l-10.37-8.49-4.99 4.89c-5.15-1.95-10.2-6.66-12.34-12.34l4.89-4.99-8.49-10.36c-.79-.98-1.94-1.47-3.09-1.47zM63.62 38.62c-1.1 0-2-.9-2-2 0-10.06-8.19-18.25-18.25-18.25-1.1 0-2-.9-2-2s.9-2 2-2c12.27 0 22.25 9.98 22.25 22.25 0 1.11-.89 2-2 2zM50.12 38.62c-1.1 0-2-.9-2-2 0-2.62-2.13-4.75-4.75-4.75-1.1 0-2-.9-2-2s.9-2 2-2c4.83 0 8.75 3.93 8.75 8.75 0 1.11-.89 2-2 2zM56.88 38.62c-1.1 0-2-.9-2-2 0-6.34-5.16-11.5-11.5-11.5-1.1 0-2-.9-2-2s.9-2 2-2c8.55 0 15.5 6.95 15.5 15.5 0 1.11-.9 2-2 2z"/></svg>
				</Button>
			{:else}
				<Button label="Request video call" subtle={true} on:click={inviteToVideoCall}>
					<svg viewBox="0 0 80 80"><path d="M25.82 22.33l6.22 7.59-2.38 2.43c-1.09 1.11-1.43 2.75-.88 4.21 2.43 6.46 8.19 12.22 14.67 14.67.46.17.94.26 1.42.26 1.03 0 2.04-.4 2.8-1.14l2.43-2.38 7.59 6.22-4.22 4.22c-.96.85-2 1.23-3.33 1.23-13.07 0-29.75-16.68-29.75-29.75 0-1.34.38-2.37 1.23-3.33l4.2-4.23m0-4c-1.02 0-2.04.39-2.83 1.17l-4.29 4.29c-1.77 1.95-2.32 4.08-2.32 6.08 0 15.27 18.4 33.75 33.75 33.75 2 0 4.13-.55 6.08-2.32l4.29-4.29c1.67-1.67 1.54-4.42-.29-5.92l-10.37-8.49-4.99 4.89c-5.15-1.95-10.2-6.66-12.34-12.34l4.89-4.99-8.49-10.36c-.79-.98-1.94-1.47-3.09-1.47zM63.62 38.62c-1.1 0-2-.9-2-2 0-10.06-8.19-18.25-18.25-18.25-1.1 0-2-.9-2-2s.9-2 2-2c12.27 0 22.25 9.98 22.25 22.25 0 1.11-.89 2-2 2zM50.12 38.62c-1.1 0-2-.9-2-2 0-2.62-2.13-4.75-4.75-4.75-1.1 0-2-.9-2-2s.9-2 2-2c4.83 0 8.75 3.93 8.75 8.75 0 1.11-.89 2-2 2zM56.88 38.62c-1.1 0-2-.9-2-2 0-6.34-5.16-11.5-11.5-11.5-1.1 0-2-.9-2-2s.9-2 2-2c8.55 0 15.5 6.95 15.5 15.5 0 1.11-.9 2-2 2z"/></svg>
				</Button>
			{/if}
		</p>
	{/if}
{/if}
