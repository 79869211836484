<script>

	// import { onMount, onDestroy, tick, getContext } from 'svelte';
	import { onMount, onDestroy, tick } from 'svelte';

	import { session } from '../../lib/stores.js';
	import { autoblur } from '../../lib/autoblur.js';

	// const session = getContext('session');

	export let tab;
	export let spots;

	let lineLeft = 0;
	let lineWidth = 0;

	$: if (tab) {
		setLine();
	}

	async function setLine() {
		await tick();
		const current = document.getElementById("rightnav-" + tab);
		if (current) {
			lineLeft = current.offsetLeft + 'px';
			lineWidth = current.offsetWidth + 'px';
		}
	}

	onMount(() => {
		window.addEventListener('resize', setLine);
	});

	onDestroy(() => {
		window.removeEventListener('resize', setLine);
	});

</script>

<style>
	ul {
		position: relative;
		display: flex;
		justify-content: space-around;
		margin: 0;
		padding: 0 0.75rem;
		z-index: 1000;
		flex: 0 0 auto;
		background: var(--panelColor);
	}

	ul:after {
		content: "";
		position: absolute;
		left: var(--lineLeft, 0);
		bottom: 0.5rem;
		width: var(--lineWidth, 0);
		height: 2px;
		background: var(--accentColor);
		transition: left 0.15s ease-out, width 0.2s ease-out;
	}

	li {
		display: block;
		margin: 0;
		padding: 0 0.25rem;
		/*flex: 1 1 25%;*/
		color: var(--textColor);
		text-align: center;
	}

	button {
		padding: 0.75rem 0 1rem 0;
		margin: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		text-align: center;
		width: 100%;
		cursor: pointer;
	}

	li.current button {
		color: var(--accentColor);
	}

	button:focus {
		outline: none;
	}

	button span {
		display: inline-block;
		font-size: 0.6875rem;
		font-weight: 600;
		/*transition: transform 0.2s ease;*/
		position: relative;
	}

	button:hover span,
	button:active span,
	button:focus span {
		transform: scale(1.05);
	}

	li.current button:hover span,
	li.current button:active span,
	li.current button:focus span {
		transform: scale(1);
	}

	button span:after {
		content: '';
		width: 0.3rem;
		height: 0.3rem;
		background: var(--red);
		border-radius: 100%;
		opacity: 0;
		display: inline-block;
		position: absolute;
		/*top: 0.35rem;*/
		top: 0.05rem;
		right: -0.5rem;
	}

	button.spot span:after {
		opacity: 1;
		transition: opacity 0.2s ease;
		transition-delay: 0.4s;
	}
</style>

{#if $session}
	<ul style="--lineLeft:{lineLeft};--lineWidth:{lineWidth};">
		{#if $session.virtual.interactions.includes('chat')}
			<li class:current={tab == 'chat'}>
				<button type="button" use:autoblur on:click={() => {tab = 'chat'}} class:spot={spots.chat}>
					<!-- <svg viewBox="0 0 60 40"><path d="M51.2 12.5h-14.5v2h14.5c.5 0 .9.4.9.9v14.2c0 .5-.4.9-.9.9h-8v4.5l-7.1-4.5h-7.3c-.5 0-.9-.4-.9-.9v-5.3h-2v5.3c0 1.6 1.3 2.9 2.9 2.9h6.7l9.7 6.2v-6.2h6c1.6 0 2.9-1.3 2.9-2.9v-14.2c0-1.6-1.3-2.9-2.9-2.9zM15.3 11.4c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.3 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.2 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zM35.6 23.1c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.3 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.2 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zM14.5 27.5v-6.2h-6c-1.6 0-2.9-1.3-2.9-2.9v-14.2c0-1.6 1.3-2.9 2.9-2.9h22.4c1.6 0 2.9 1.3 2.9 2.9v14.2c0 1.6-1.3 2.9-2.9 2.9h-6.7l-9.7 6.2zm-6-24.2c-.5 0-.9.4-.9.9v14.2c0 .5.4.9.9.9h8v4.5l7.1-4.5h7.3c.5 0 .9-.4.9-.9v-14.2c0-.5-.4-.9-.9-.9h-22.4z"/></svg> -->
					<span id="rightnav-chat">Chat</span>
				</button>
			</li>
		{/if}
		{#if $session.virtual.interactions.includes('qa')}
			<li class:current={tab == 'qa'}>
				<button type="button" use:autoblur on:click={() => {tab = 'qa'}} class:spot={spots.qa}>
					<!-- <svg viewBox="0 0 60 40"><path d="M23 38.5v-8.4h-8.9c-2 0-3.6-1.6-3.6-3.6v-21.4c0-2 1.6-3.6 3.6-3.6h31.5c2 0 3.6 1.6 3.6 3.6v21.5c0 2-1.6 3.6-3.6 3.6h-9.6l-13 8.3zm-8.9-35c-.9 0-1.6.7-1.6 1.6v21.5c0 .9.7 1.6 1.6 1.6h10.9v6.7l10.4-6.7h10.1c.9 0 1.6-.7 1.6-1.6v-21.5c0-.9-.7-1.6-1.6-1.6h-31.4zM32.5 12c0-1.4-1-2.1-2.6-2.1-1.5 0-2.6.8-3.1 2.2l-2.4-1.3c.9-2.4 3.1-3.6 5.6-3.6 2.7 0 5.3 1.6 5.3 4.6 0 3.6-4.2 4.6-4.2 7.2h-2.8c0-3.5 4.2-4.4 4.2-7zm-4.6 10.8c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8z"/></svg> -->
					<span id="rightnav-qa">Q&amp;A</span>
				</button>
			</li>
		{/if}
		{#if $session.virtual.interactions.includes('polling')}
			<li class:current={tab == 'poll'}>
				<button type="button" use:autoblur on:click={() => {tab = 'poll'}} class:spot={spots.poll}>
					<!-- <svg viewBox="0 0 60 40"><path d="M42.2 36h-24.8c-2 0-3.6-1.7-3.6-3.7v-24.6c0-2 1.6-3.7 3.6-3.7h24.9c1.9 0 3.5 1.6 3.5 3.6v24.7c0 2-1.6 3.7-3.6 3.7zm-24.8-29.9c-.8 0-1.5.7-1.5 1.6v24.6c0 .9.7 1.6 1.5 1.6h24.8c.8 0 1.5-.7 1.5-1.6v-24.7c0-.8-.6-1.5-1.4-1.5h-24.9zM23.4 30.6v-19.5c0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4v19.5h2.8zM31.5 30.6v-14c0-.8-.6-1.4-1.4-1.4-.8 0-1.4.6-1.4 1.4v14h2.8zM39.6 30.6v-6.1c0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4v6.1h2.8z"/></svg> -->
					<span id="rightnav-poll">Polls</span>
				</button>
			</li>
		{/if}
		{#if $session.virtual.interactions.includes('ratings')}
			<li class:current={tab == 'ratings'}>
				<button type="button" use:autoblur on:click={() => {tab = 'ratings'}}>
					<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 40" enable-background="new 0 0 60 40"><path d="M47.54 10.65l3.22 6.86 7.17 1.09-5.19 5.32 1.23 7.53-6.43-3.55-6.43 3.55 1.23-7.53-5.19-5.32 7.17-1.09 3.22-6.86m0-2.35l-.91 1.93-2.99 6.38-6.64 1.01-1.92.29 1.36 1.39 4.83 4.95-1.15 7.03-.33 2.03 1.8-1 5.95-3.29 5.95 3.29 1.8 1-.33-2.03-1.15-7.03 4.83-4.95 1.36-1.39-1.92-.29-6.64-1.01-2.99-6.38-.91-1.93zM12.46 8.3l-.91 1.93-2.99 6.37-6.64 1.01-1.92.3 1.36 1.39 4.83 4.95-1.15 7.03-.33 2.03 1.8-1 5.95-3.29 5.95 3.29 1.8 1-.33-2.03-1.15-7.03 4.83-4.95 1.36-1.39-1.92-.29-6.64-1.01-2.99-6.38-.91-1.93z"/></svg> -->
					<span id="rightnav-ratings">Rate</span>
				</button>
			</li>
		{/if}
	</ul>
{/if}