<script>

	import { onMount, onDestroy } from 'svelte';
	import { fly } from "svelte/transition";

	import Portal from "svelte-portal";

	import Player from '../ui/Player.svelte';
	import Overlay from "../ui/Overlay.svelte";

	import SessionDetails from './Video/SessionDetails.svelte';
	import Holding from './Video/Holding.svelte';
	import ControlPanel from './Video/ControlPanel.svelte';

	import {	epoch, session, syncClient, syncChannels, event, backstage, attendee } from '../lib/stores.js';
	import { randomIntFromInterval } from '../lib/utils.js';

	export let playback;
	export let livestreamStatus;
	export let token = null;
	export let ll = false;
	export let captions = false;
	export let rtmp = null;
	export let videoKey = $session.ref;

	let mounted = false;
	let eventNotifications;

	let controlChannel;
	let control = {};

	let provider = 'mux';
	
	let portal;
	let controlPanel = false;

	let useAutoCut = false;

	let mod = ($attendee.type == 'login') || ($backstage.includes($session.ref));

	let spot = [];

	let sw = 0;

	if ($event.setup.virtual.provider == 'youtube') {
		provider = 'youtube';
	} else if ($event.setup.virtual.provider == 'vimeo') {
		provider = 'vimeo';
	}

	onMount(async () => {

		// console.log('source',$session.virtual.source);

		//// 14 Sep 2023 -- isn't this in Session.svelte? Why did I put it here too?
		//// Neither attendeeNotifications nor syncNotificationAdded are defined...
		//
		// if (($session.virtual.source == 'live') || ($session.virtual.source == 'studio')) {
		// 	try {
		// 		attendeeNotifications = await $syncClient.list($syncChannels.attendee);
		// 		attendeeNotifications.on('itemAdded', syncNotificationAdded);
		// 	} catch (e) {
		// 		console.log("Sync error", e, $syncChannels.attendee);
		// 	}
		// }

		try {

			controlChannel = await $syncClient.document($session.controlChannel);
			control = controlChannel.data;

			controlChannel.on('updated', (args) => {
				control = args.data;
			});

		} catch (e) {
			console.log("Sync error", e, $session.controlChannel);
		}

		if ($session.virtual.source == 'live') {
			useAutoCut = true;
		}

		// console.log($session.virtual.source, useAutoCut);

		if (mod) {
			initPrivateChannel();
		}

		mounted = true;

	});

	// 02 Nov 2023 -- does anything actually call this???
	function eventSyncItemAdded(message) {
		// console.log('video - syncItemAdded', message);
		const messageData = message.item.data;
		if (messageData && messageData.type && (messageData.type == 'livestream')) {
			if (messageData.stream && (messageData.stream == $session.virtual.livestream) && messageData.status) {
				// console.log("Updating livestream status", messageData.status);
				livestreamStatus = messageData.status;
			}
		}
	}

	async function initPrivateChannel() {

		try {

			// console.log("opening private channel (tracking)", $session.privateChannel);
			let privateChannel = await $syncClient.list($session.privateChannel);

			privateChannel.on('itemAdded', function(i) {
				if (!controlPanel) {
					spot.push(i.item.index);
					spot = spot;
				}
			});

			privateChannel.on('itemRemoved', function(i) {
				spot = spot.filter(m => {
					if (m != i.index) return m;
				});
			});
			
		} catch (e) {

			console.log("Sync error", e, $session.privateChannel);
			
		}

	}

	onDestroy(() => {
		// but they're never set??
		if (eventNotifications) eventNotifications.close();
	});

	$: portal = controlPanel ?  '#panelPlayer' : '#player';

	$: console.log('control', control);

</script>

<style>
	.outer {
		grid-area: c;
		position: relative;
		min-height: 400px;
	}
	@media (max-width: 675px) {
		.outer {
/*			min-height: calc(100vw * 0.56);*/
			min-height: calc(100vw * 0.75);
		}
	}
	@media (max-height: 599px) {
		.outer {
			min-height: 135vh;
		}
	}
	.wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}
	.video {
		flex: 0 1 auto;
		aspect-ratio: 16/9;
		position: relative;
		width: 100%;
	}

	.inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		aspect-ratio: 16/9;
		border-radius: 4px;
		box-shadow: 0 2px 10px var(--shadow);
		max-width: 100%;
		max-height: 100%;
		height: 100%;
    	overflow: hidden;
		z-index: 0;
	}

	.inner:before {
		content: '';
		display: block;
		padding-top: 56.25%;
		background: #000;
		opacity: 0.4;
	}
</style>

{#if mounted && $session}
	<div class="outer" in:fly|local={{ delay: 800, duration: 1200, y: 20 }}>
		{#key videoKey}
			<div class="wrap" style="--sw:{sw}px">
				<div class="video">
					<div class="inner" bind:clientWidth={sw}>
						{#if $epoch > $session.epochStarts}
							<div id="player"></div>
							<Portal target={portal}>
								{#if provider == 'mux'}
									<Player
										{provider}
										playbackType={$session.virtual.source}
										{playback}
										{livestreamStatus}
										{token}
										{ll}
										{captions}
										{useAutoCut}
										{control}
										isControlPanel={controlPanel}
									/>
								{:else}
									<Player
										{provider}
										playbackType="live"
										playback={$session.virtual.videoID}
									/>
								{/if}
							</Portal>
						{:else}
							<Holding/>
						{/if}
					</div>
				</div>
				{#if $epoch > $session.epochStarts}
					<SessionDetails {mod} {control} {livestreamStatus} {useAutoCut} bind:controlPanel {spot}/>
				{/if}
			</div>
		{/key}
	</div>
{/if}

{#if controlPanel}
	<Overlay on:escape={() => { controlPanel = false }} modal={true} extrawide={true}>
		<ControlPanel {control} {livestreamStatus} {useAutoCut} {rtmp} bind:spot />
	</Overlay>
{/if}