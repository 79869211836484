<script>

	import { onMount, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

	import { v4 as uuid } from 'uuid';

	import { postServerData } from '../lib/prelude.js';
	import { arrayToEnglishList } from '../lib/utils.js';

	const dispatch = createEventDispatcher();

	export let multiple = false;
	export let filetypes = ['jpg', 'png'];
	export let endpoint = 'virtual/upload/image';
	export let maxSize = 20971520; // 20MB in bytes
	export let id = 'uploader_' + uuid();
	export let fast = true;

	const mime = {
		jpg: 	'image/jpeg',
		png: 	'image/png',
		svg: 	'image/svg+xml',
		pdf:  'application/pdf',
		doc:  'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		xls:  'application/vnd.ms-excel',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		ppt:  'application/vnd.ms-powerpoint',
		pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
	};

	const accept = [];

	for (const filetype of filetypes) {
		accept.push(mime[filetype]);
	}

	async function handleUpload(event) {

		if (event.target && event.target.files && event.target.files.length) {

			dispatch('uploading', event.target.files.length);

			for (const file of event.target.files) {

				//// console.log(file.type + ' / ' + file.size);

				let ok = true;

				if (file.size && file.size > maxSize) {
					dispatch('uploaded', {
						error: "This file is too large."
					});
					ok = false;
				}

				if (ok && file.type && !(accept.includes(file.type))) {
					const list = arrayToEnglishList(filetypes, 'or');
					const noun = (accept.length > 1) ? 'formats' : 'format';
					dispatch('uploaded', {
						error: 'Please upload only files in ' + list + ' ' + noun + '.'
					});
					ok = false;
				}

				if (ok) {
					const fastParam = fast ? 'true' : '';
					const fileData = await postServerData(endpoint, {
						file: file,
						hide: 'true',
						fast: fastParam
					});
					dispatch('uploaded', fileData);
				}

			}

			event.target.value = '';

		}

	}

</script>

<style>
	.uploader {
		position: absolute;
		inset: 0;
		z-index: 1000;
	}
	input {
		position: absolute;
		inset: 0;
		cursor: pointer;
		opacity: 0;
	}
</style>

<div class="uploader">
	<input
		id={id}
		type="file"
		on:change={handleUpload}
		accept={accept}
		multiple={multiple}
	/>
</div>
