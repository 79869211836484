import { persist, createSessionStorage, createLocalStorage } from "@macfja/svelte-persistent-store";
import { writable } from 'svelte/store';

export const attendeeToken = persist(writable(''), createLocalStorage(), 'attendeeToken');
export const csrfToken = persist(writable(''), createLocalStorage(), 'csrfToken');

export const studioSessionToken = writable(undefined);

export const displayLang = writable('EN');

export const displayInEventTz = persist(writable(false), createLocalStorage(), 'displayInEventTz');
// export const appearInAttendeeList = persist(writable(true), createLocalStorage(), 'appearInAttendeeList');

export const room = persist(writable('lobby'), createSessionStorage(), 'room');
export const leftSection = persist(writable(null), createSessionStorage(), 'leftSection');
export const leftDocked = persist(writable(false), createSessionStorage(), 'leftDocked');
export const rightDocked = persist(writable(false), createSessionStorage(), 'rightDocked');

export const studioOptions = persist(writable({}), createLocalStorage(), 'studioOptions');

export const showLeft = writable(true);
export const isPhone = writable(false);

export const syncClient = writable(null);

// export const eventNotifications = writable(undefined);
// export const attendeeNotifications = writable(undefined);
export const syncChannels = writable({});

export const attendees = writable({});

export const attendeeLikes = writable([]);
export const muted = writable([]);
export const shadowbans = writable([]);

export const busy = writable(false);
export const bigError = writable(null);

export const spinnerColor = writable('#e5077e');
export const spinnerInDelay = writable(400);
export const spinnerInDuration = writable(300);
export const spinnerOutDelay = writable(0);
export const spinnerOutDuration = writable(100);

export const utcDiff = writable(0);
export const epoch = writable(0);
export const epochInterval = writable(undefined);

export const event = writable(null);
export const attendee = writable(null);

export const ejected = writable(null);

export const agenda = writable(null);
export const sessions = writable({});
export const backstage = writable([]);

export const currentSessions = writable([]);
export const upcomingSessions = writable([]);
export const studioSessions = writable([]);

export const session = writable(null);

export const agendaDay = writable(null);
export const agendaTrack = writable(null);

export const speakers = writable(null);
export const sponsors = writable(null);

export const exhibitors = writable([]);
export const booth = writable(null);

export const socialMediaServiceOpts = writable([]);

export const lobby = writable([]);

export const conversations = writable({});
export const conversation = writable(null);

export const videoCall = writable(null);
export const selectedReport = writable(null);

export const announcements = writable([]);
export const overlays = writable(0);

export const timeMachine = writable(0);
