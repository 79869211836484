<script>

	import { onMount, createEventDispatcher, tick } from 'svelte';
	import { fade, fly } from "svelte/transition";

	import { MasonryInfiniteGrid } from "@egjs/svelte-infinitegrid";

	import Spinner from '../../../ui/Spinner.svelte';

	const dispatch = createEventDispatcher();

	export let showPanel = false;

	let searchBox;

	function choose(g) {
		// console.log({g});
		showPanel = false;
		dispatch('chosen', {
			gif: g.use
		});
	}

	let search = "";
	let loading = false;
	let submitted = false;

	const API_URL = "https://api.giphy.com/v1/gifs/search?api_key=5nofCAHjZkBSu0nOB6zuiQKQ3rCMlM6w&rating=pg&limit=50&q=";

	let gifs = [];

	const doSearch = async (e,o) => {
		e.preventDefault();
		if (!o) o = 0;
		loading = true;
		submitted = true;
		const url = `${API_URL}${search}`;
		const response = await fetch(url);
		const json = await response.json();
		// console.log('giphy',json);
		let giphy = json.data.map(gif => ({
			groupKey: o,
			key: gif.id,
			preview: gif.images.preview.mp4,
			use: gif.images.looping.mp4,
			aspectRatio: gif.images.preview.width + "/" + gif.images.preview.height
		}));
		setTimeout(() => {
			gifs = giphy;
			loading = false;
		}, 300);
	}

	$: changeState(showPanel);

	function changeState() {
		if (showPanel) {
			tick().then(() => {searchBox.focus()});
		} else {
			setTimeout(() => {
				loading = false;
				search = "";
				gifs = [];
				submitted = false;
			}, 300);
		}
	}

	function handleKeydown(event){
		if (event.key === 'Escape') {
			showPanel = false;	
		}
	}

	function append(e) {
		// Why is this never called??
		// console.log('append',{e});
	}

</script>

<style>
	/*.toggle:before {
		content: '';
		position: absolute;
		inset: 2px;
		border-radius: 3px;
		border: 1px solid var(--textColor);
	}*/
	.toggle:hover {
		color: var(--accentColor);
	}
	.toggle:hover:before {
		border-color: var(--accentColor);
	}
	.panel {
		position: absolute;
		/*bottom: 0.4rem;*/
		width: 100%;
		/*top: 0;*/
		/*transform: translateY(-100%);*/
		top: -4.2rem;
		height: 4.2rem;
		left: 0;
		/*background: #000;*/
		background: transparent;
		border-radius: 4px;
		box-shadow: 0 2px 6px var(--shadow);
		/*border-top-left-radius: 4px;*/
		/*border-top-right-radius: 4px;*/
		/*padding: 0.5rem;*/
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		transition: height 0.3s ease-out, top 0.3s ease-out;
		padding: 0 0 32px 0;
	}
	.panel:before {
		content: "";
		position: absolute;
		inset: 0;
		background: #2e3130;
		border-radius: 4px;
		/*opacity: 0.8;*/
	}
	.panel:after {
		content: "";
		position: absolute;
		bottom: -5px;
		right: 0.5rem;
		width: 0;
		height: 0;
		border-top: 5px solid #2e3130;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		/*opacity: 0.8;*/
	}
	.panel.submitted {
		top: -40vh;
		height: 40vh;
	}
	@media (max-width: 600px) {
		.panel.submitted {
			top: -33vh;
			height: 33vh;
		}
	}
	form {
		padding: 0.5rem;
		margin: 0;
		position: relative;
	}
	input {
		width: 100%;
		height: 1.6rem;
		box-sizing: border-box;
		/*border: 1px solid var(--textColor);*/
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0 1.8rem 0 0.4rem;
		border-radius: 3px;
	}
	input:focus {
		outline: 1px solid var(--textColor);
		/*border-color: var(--accentColor);*/
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: var(--blend-40);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: var(--blend-40);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: var(--blend-40);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: var(--blend-40);
	}
	form > button {
		position: absolute;
		top: 0.5rem;
		right: 0.6rem;
		width: 1.6rem;
		height: 1.6rem;
		box-sizing: border-box;
		color: var(--textColor);
		border: 0;
	}
	form > button:hover {
		/*background-color: var(--textColor);*/
		color: var(--accentColor);
	}
	form > button > svg {
		position: absolute;
		/*inset: 0;*/
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.results {
		flex: 1 0 auto;
		position: relative;
	}
	.results > div {
		position: absolute;
		inset: 0;
		/*padding: 0.25rem;*/
		padding: 0 5px;
		overflow-y: auto;
	}
	/*.results > div > div {
		columns: 2;
		column-gap: 0.5rem;
	}*/
	.results p {
		/*margin: 0 0 0.5rem 0;*/
		/*position: relative;*/
		/*transition: transform 0.2s ease;
		transform: scale(1);*/
		cursor: pointer;
		width: 50%;
		padding: 0.25rem;
		box-sizing: border-box;
	}
	.results video {
		display: block;
		width: 100%;
		transition: transform 0.2s ease;
		transform: scale(1);
	}
	.results p:hover video {
		transform: scale(1.05);
	}
	/*.results p:before {
		opacity: 0;
		transition: opacity 0.3s ease;
		content: '';
		position: absolute;
		inset: -5px;
		box-sizing: border-box;
		border: 3px solid var(--accentColor);
	}
	.results p:hover:before {
		opacity: 1;
	}*/
	.attribution {
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		width: 100px;
	}
	.spinner {
		position: absolute;
		inset:  0;
		display: grid;
		place-content: center;
	}
</style>

<svelte:window on:keydown|stopPropagation={handleKeydown}/>

<button class="toggle" type="button" on:click|stopPropagation={() => {showPanel = !showPanel}}>
	<svg viewBox="0 0 32 32"><path d="M9.49 12.2c-1.7 0-2.86 1.37-2.86 3.79 0 2.43 1.11 3.81 2.88 3.81 1.56 0 2.5-.91 2.54-2.35h-2.39v-1.85h4.66v1.46c0 3.06-2 4.92-4.81 4.92-3.13 0-5.25-2.28-5.25-5.96 0-3.77 2.27-5.98 5.2-5.98 2.52 0 4.45 1.62 4.76 3.92h-2.39c-.31-1.1-1.13-1.76-2.34-1.76zM18.44 21.81h-2.35v-11.62h2.35v11.62zM20.38 10.19h7.37v2.03h-5.01v2.77h4.52v2.03h-4.52v4.8h-2.35v-11.63zM28 1.5c1.38 0 2.5 1.12 2.5 2.5v24c0 1.38-1.12 2.5-2.5 2.5h-24c-1.38 0-2.5-1.12-2.5-2.5v-24c0-1.38 1.12-2.5 2.5-2.5h24m0-1.5h-24c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4v-24c0-2.21-1.79-4-4-4z"/></svg>
</button>

{#if showPanel}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="panel" class:submitted transition:fly={{ duration: 300, y: 10 }} on:click|stopPropagation>

		<form on:submit={doSearch}>
			<input type="text" placeholder="Search for a GIF …" bind:value={search} id="giphysearch" name="search" bind:this={searchBox}>
			<button type="submit">
				<svg viewBox="0 0 80 80"><title>Search</title><path d="M62.05 56.1l-7.55-7.55c-.95-.95-2.26-1.32-3.5-1.11l1.03-1.03-3.14-3.14c1.63-2.64 2.59-5.73 2.59-9.06 0-9.54-7.76-17.3-17.3-17.3-9.54 0-17.3 7.76-17.3 17.3s7.76 17.3 17.3 17.3c3.32 0 6.42-.96 9.05-2.59l3.14 3.14 1.36-1.36c-.21 1.23.16 2.54 1.11 3.5l7.55 7.55c.78.78 1.8 1.17 2.83 1.17s2.05-.39 2.83-1.17c1.56-1.56 1.56-4.09 0-5.65zm-41.17-21.89c0-7.33 5.97-13.3 13.3-13.3s13.3 5.97 13.3 13.3c0 7.33-5.97 13.3-13.3 13.3s-13.3-5.96-13.3-13.3z"/></svg>
			</button>
		</form>

		<div class="results">
			{#if loading}
				<div class="spinner" in:fade={{ duration: 200, delay: 200 }} out:fade={{ duration: 200, delay: 600 }}>
					<Spinner size="60" speed="1200" color="#fff" thickness="1" gap="30"/>
				</div>
			{:else}
				<div>
					{#if gifs.length}
						<!-- <div>
							{#each gifs as gif}
								<p on:click|stopPropagation={choose(gif)}>
									<video autoplay loop muted inline>
										<source src={gif.preview} type="video/mp4">
									</video>
								</p>
							{/each}
						</div> -->
						<MasonryInfiniteGrid
							class="masonry"
							gap={1}
							column={2}
							items={gifs}
							on:requestAppend={append}
							let:visibleItems
						>
							{#each visibleItems as item}
								<!-- TODO: nest a button instead -->
								<!-- svelte-ignore a11y-click-events-have-key-events -->
								<p on:click|stopPropagation={choose(item.data)}>
									<video autoplay loop muted inline style="aspect-ratio: {item.data.aspectRatio}">
										<source src={item.data.preview} type="video/mp4">
									</video>
								</p>
							{/each}
						</MasonryInfiniteGrid>
					{/if}
				</div>
			{/if}
		</div>

		<p class="attribution"><img src="https://cdn.attendzen.io/giphy.png" alt="Powered by GIPHY"/></p>
	</div>
{/if}