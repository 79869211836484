<script>

	// import { onMount, getContext } from 'svelte';
	import { onMount } from 'svelte';
	import { fade } from "svelte/transition";

	// import SessionDetails from './SessionDetails.svelte';

	import {	event, epoch, spinnerColor, agenda, session } from '../../lib/stores.js';
	import {	showDate } from '../../lib/dt.js';

	import Spinner from "../../ui/Spinner.svelte";
	import Timer from '../../ui/Timer.svelte';

	// const session = getContext('session');

	let mounted = false;

	onMount(() => {

		// As per Agenda in info panel...
		$session.speakers = [];
		if ($session.details) {
			for (const st of $agenda.sessionTypes) {
				if ($session.sessionType == st.ref) {
					for (const c of st.components) {
						if (((c.component == 'Moderators') && ($session.details[c.ref])) || ((c.component == 'Speakers') && ($session.details[c.ref]))) {
							for (const d of $session.details[c.ref]) {
								$session.speakers.push({
									...d.crm,
									component: c.component
								});
							}
						}
					}
					break;
				}
			}
		}

		// console.log({$session});

		mounted = true;

	});

	function getTrackName(st) {
		for (const t of $agenda.tracks) {
			if (t.ref == st) {
				return t.name;
			}
		}
	}

</script>

<style>

	.holding {
		position: absolute;
		inset: 0;
		z-index: 1;
		/*pointer-events: none;*/
		/*background: var(--textColor);*/
		background: linear-gradient(to bottom, var(--textColor) 0%, var(--blend-120) 100%);
	}

	/*.card {
		width: max-content;
		margin: auto;
		padding-bottom: 4rem;
	}

	.card :global(h1) {
		max-width: 100%;
	}

	.speaker {
		position: relative;
		min-height: 1.5rem;
		padding-left: 2rem;
		font-size: 0.6875rem;
		margin-top: 0.5rem;
		color: var(--captionColor);
	}

	.speaker em {
		display: inline-block;
		margin-left: 0.1rem;
		font-style: normal;
		font-weight: 600;
		font-size: 0.625rem;
		opacity: 0.6;
	}

	:global(.session) + .speaker {
		margin-top: 2rem;
	}

	.avatar {
		position: absolute;
		top: 0;
		left: 0;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 100%;
	}*/

	.timer {
		background: var(--panelColor);
		text-transform: uppercase;
		line-height: 0.8rem;
		padding: 0.2rem 0.6rem;
		border-radius: 3px;
		font-size: 0.8rem;
		font-weight: 600;
		position: absolute;
		top: 2rem;
		right: 2rem;
		box-shadow: 0 1px 4px var(--shadow);
		font-family: system-ui;
	}

	.details {
		/*position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);*/
		padding: 5.6rem 2rem 0 2rem;
		transform-origin: top left;
		transition: transform 0.4s cubic-bezier(.24,.06,.23,.9);
	}

	@media (min-width: 1100px) {
		:global(.leftDocked) .details,
		:global(.rightDocked) .details {
			transform: scale(1.2);
		}
		:global(.leftDocked.rightDocked) .details {
			transform: scale(1.4);
		}
	}

	.session {
		color: var(--panelColor);
		font-size: 1.2rem;
		font-weight: 600;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}

	.session.long {
		font-size: 1rem;
	}

	.date {
		color: var(--panelColor);
		font-size: 0.6875rem;
		margin: 0.25rem 0;
	}

	.track {
		font-size: 0.6875rem;
		color: var(--panelColor);
		font-weight: 500;
		margin: 0.25rem 0;
	}

	.speakers {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		overflow-x: auto;
		display: flex;
		gap: 2rem;
		justify-content: flex-start;
		padding: 2rem 1.8rem 1.4rem 1.8rem;
		scroll-padding: 1.8rem;
		-ms-overflow-style: none;
		scrollbar-width: none;
		scroll-snap-type: x mandatory;
	}

	.speakers::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 600px) {
		.speakers {
			display: none;
		}
	}

	.speaker {
		flex: 0 0 26%;
		background: var(--blend-10);
		border-radius: 4px;
		position: relative;
		box-sizing: border-box;
		padding: 3rem 1rem 1rem 1rem;
		font-size: 0.6875rem;
		box-shadow: 0 1px 4px var(--shadow);
		line-height: 1.4;
		transition: flex 0.4s cubic-bezier(.24,.06,.23,.9);
		scroll-snap-align: start;
	}

	:global(.leftDocked) .speaker,
	:global(.rightDocked) .speaker {
		flex: 0 0 20%;
	}

	:global(.leftDocked.rightDocked) .speaker {
		flex: 0 0 15%;
	}

	.speaker strong {
		font-size: 0.8rem;
	}

	.speaker em {
		font-weight: 600;
		font-style: normal;
	}

	.avatar {
		position: absolute;
		top: -1rem;
		left: 0.85rem;
		width: 3rem;
		height: 3rem;
		border-radius: 100%;
		/*border: 1px solid var(--panelColor);*/
		/*box-shadow: 0 1px 4px var(--shadow);*/
	}

	/*.bg {
		position: absolute;
		inset: 0;
		z-index: -1;
	}

	.bg > img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0.4;
		filter: blur(var(--bgBlur, 0));
	}*/

</style>

{#if mounted}
	<div in:fade={{delay: 800, duration: 600}} class="holding">
		<span class="timer">
			<Timer duration={$session.epochStarts - $epoch} />
		</span>
		<div class="details">
			{#if ($agenda.tracks.length > 1) && $session.track}
				<p class="track">{getTrackName($session.track)}</p>
			{/if}
			<p class="session" class:long={$session.name.length > 100}>{$session.name}</p>
			<p class="date">{showDate($session.starts, 'HH:mm')}–{showDate($session.ends, 'HH:mm')}</p>
		</div>
		{#if $session.speakers && $session.speakers.length}
			<div class="speakers">
				{#each $session.speakers as speaker}
					{#if speaker.n}
						<p class="speaker">
							{#if speaker.a}<img class="avatar" src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{speaker.a}" alt="{speaker.n}"/>{/if}
							<strong>{speaker.n}</strong>
							{#if speaker.o}<br/><span>{speaker.o}</span>{/if}
							{#if speaker.component == 'Moderators'}<br/><em>Moderator</em>{/if}
						</p>
					{/if}
				{/each}
			</div>
		{/if}

		<!-- {#if $event.theme.virtual.bgImage}
			{#if $event.theme.virtual.bgImage.filename}
				<div class="bg">
					<img src="https://cdn.attendzen.io/{$event.accountRef}/lg_{$event.theme.virtual.bgImage.filename}" alt=""/>
				</div>
			{:else if $event.theme.virtual.bgImage.unsplash}
				<div class="bg">
					<img src="{$event.theme.virtual.bgImage.unsplash.url}&w=800" alt=""/>
				</div>
			{/if}
		{/if} -->

	</div>
{/if}