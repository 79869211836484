<script>

	import { fade, fly } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import Announcement from './ui/Announcement.svelte';

	import {	announcements, overlays } from './lib/stores.js';

	function dismiss(r) {
		let filtered = $announcements.filter(a => r != a.ref);
		$announcements = filtered;
	}

</script>

<style>
	.announcements {
		position: fixed;
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		width: 450px;
		max-width: 90rem;
		display: flex;
		flex-direction: column;
		max-height: calc(100vh - 12rem);
		max-height: calc(100dvh - 12rem);
		/*overflow-y: auto;*/
		z-index: 12011;
		padding: 0 1rem;
		margin-left: 0;
		transition: margin-left 0.4s cubic-bezier(.24,.06,.23,.9);
		/*outline: 1px solid red;*/
	}
	@media (min-width: 1100px) {
		:global(.room-lobby:not(.leftDocked)) .announcements:not(.overlays),
		:global(.room-exhibition:not(.leftDocked)) .announcements:not(.overlays) {
			margin-left: calc(((100vw - 2rem) * 0.22) / 2);
		}
	}
</style>

{#if $announcements.length}
	<div class="announcements" class:overlays={$overlays}>
		{#each $announcements as a (a.ref)}
			<div
				class="announcement"
				in:fly={{ duration: 600, y: 10 }}
				out:fade="{{ duration: 200 }}"
				animate:flip={{ duration: 400 }}
			>
				<Announcement announcement={a} on:dismiss={() => {dismiss(a.ref)}}/>
			</div>
		{/each}
	</div>
{/if}

