<script>

	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';

	import Button from './Button.svelte';

	import { busy, bigError, ejected } from '../lib/stores.js';
	import { text_to_html } from '../lib/html.js';

	onMount(() => {
		$busy = false;
	});

</script>

<style>

	.errBg {
		position: fixed;
		inset: 0;
		/*backdrop-filter: blur(2px);*/
		z-index: 32031;
	}

	.errBg:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor, #101820);
		/*opacity: 0.6;*/
		opacity: 0.7;
	}

	.err {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 32032;
	}

	.err > div {
		max-width: 80vw;
		max-height: 80vh;
		max-height: 80dvh;
		overflow: auto;
		width: 560px;
		box-sizing: border-box;
		padding: 2rem 2rem 2rem 5rem;
		background: var(--panelColor, #fff);
		color: var(--textColor, #101820);
		/*box-shadow: 0 0 20px -8px rgba(0,0,0,0.2);*/
		box-shadow: 0 20px 80px rgba(0,0,0,0.3);
		position: relative;
	}

	svg {
		position: absolute;
		top: 1.7rem;
		left: 1.7rem;
		width: 2rem;
	}

	.triangle {
		fill: var(--red, #e2261e);
	}

	.pling {
		fill: currentColor;
	}

	.err :global(p) {
		margin: 0;
		line-height: 1.4;
	}

	.err :global(p + p) {
		margin-top: 1rem;
	}

</style>

<div class="errBg" in:fade={{ duration: 400 }}></div>
<div class="err" in:fade={{ duration: 400, delay: 200 }}>
	<div>
		<svg viewBox="0 0 56 56">
			<path class="triangle" d="M3.7 52.7c-1.2 0-2.5-.7-3.1-1.9-.7-1.2-.6-2.5 0-3.6l24.3-42c.7-1.1 1.8-1.8 3.2-1.8 1.3 0 2.5.7 3.2 1.8l24.3 42c.6 1.1.6 2.5 0 3.6-.7 1.2-1.9 1.8-3.1 1.9h-48.8zm24.3-43.1l-22.1 38.2h44.1l-22-38.2z"/>
			<path class="pling" d="M28 40.3c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7zm-2.3-18.9c0-1.5.9-2.5 2.3-2.5s2.3 1 2.3 2.5v14.4c0 1.5-.9 2.5-2.3 2.5s-2.3-1-2.3-2.5v-14.4z"/>
		</svg>

		{#if ($ejected != null) && ($ejected != undefined)}
			{@html text_to_html($ejected)}

		{:else if $bigError == 'Already online'}
			<p><strong>Sorry, you already have this event open.</strong></p>
			<p>This event can only be viewed in one browser tab at a time.</p>
         <p>To view the event here, please make sure you have closed your other browser tab where it’s currently open.</p>

      {:else if $bigError == 'Link request throttled'}
			<p><strong>We’ve very recently sent your access link.</strong></p>
         <p>If necessary, you can try again in a few minutes.</p>
			<p><Button label="Dismiss" ghost={true} on:click={() => { $busy = false; $bigError = null }}/></p>

		{:else}
			<p>{$bigError}</p>
			<p><Button label="Dismiss" ghost={true} on:click={() => { $busy = false; $bigError = null }}/></p>
				
		{/if}
	</div>
</div>