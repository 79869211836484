<script>

	import { createEventDispatcher } from 'svelte';

	import latinize from 'latinize';
	import { byValue, byString, byNumber } from 'sort-es';

	import Avatar from "../../ui/Avatar.svelte";
	import Menu from "../../ui/Menu.svelte";
	import Filter from "../../ui/Filter.svelte";

	import {	attendees, attendee, conversation, muted } from '../../lib/stores.js';
	import { openConversation } from '../../lib/conversations.js';

	const dispatch = createEventDispatcher();

	let displayAttendees = [];
	let filterText = '';

	let sortField = 'l';

	$: if ($attendees || filterText) {
		setDisplayAttendees();
	}

	$: filterText = filterText && latinize(filterText).toLowerCase();

	let rowTimeout;

	function setDisplayAttendees() {
		clearTimeout(rowTimeout);
		rowTimeout = setTimeout(function(){
			displayAttendees = Object.keys($attendees).length ? Object.keys($attendees).sort(byValue(a => $attendees[a][sortField] ? $attendees[a][sortField] : '', byString({ lowercase: true }))) : [];
			displayAttendees = (displayAttendees && displayAttendees.length) ? displayAttendees.filter(filterByValue) : [];
		}, 100);
	}

	function filterByValue(o) {
		if ($muted.includes(o)) return false;
		if (!filterText) return true;
		return Object.keys($attendees[o]).some(f => (f == 'f' || f == 'l' || f == 'o') && filterValue($attendees[o][f]).includes(filterText));
	}

	function filterValue(v) {
		return v ? latinize(v).toString().toLowerCase() : '';
	}

	function goBack() {
		dispatch('back');
	}
	
</script>

<style>

	.wrapper {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}

	.overlay-title {
		position: relative;
	}

	.overlay-main {
		padding: 0 1.5rem;
	}

	.attendees {
		padding-bottom: 1rem;
	}

	.list > p {
		position: relative;
		padding: 0.3rem 0.4rem 0.3rem 3rem;
		font-size: 0.6875rem;
		line-height: 1.4;
		margin: 0.5rem;
		border-radius: 4px;
		cursor: pointer;
	}

	.list > p:hover {
		background: var(--blend-05);
	}

	.list > p + p {
		margin-top: 0.5rem;
	}

	.list :global(.avatar) {
		left: 0.8rem;
		top: 0.4rem;
	}

	.bar {
		position: sticky;
		top: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 1rem 1rem;
		background: var(--blend-10);
		z-index: 1000;
	}

	.bar :global(input) {
		background: var(--panelColor);
	}

	button.back {
		position: absolute;
		top: 0;
		/*top: 50%;*/
		/*transform: translateY(-50%);*/
		left: 0;
		width: 3rem;
		height: 3rem;
		border: 0;
		background:	transparent;
		/*background: var(--blend-10);*/
		/*border-radius: 100px;*/
		color: var(--textColor);
		/*font-size: 0.8rem;*/
		padding: 0;
		/*width: 1.6rem;*/
		/*height: 1.6rem;*/
	}
	button.back svg {
		position: absolute;
		/*inset: 0.7rem;*/
		top: 0.7rem;
		left: 0.7rem;
		width: calc(100% - 1.4rem);
		height: calc(100% - 1.4rem);
	}
	button.back:hover {
		color: var(--accentBlend-120);
	}

	.overlay-title {
		display: flex;
		justify-content: center;
	}

</style>

<div class="wrapper">

	<div class="overlay-title">
		<button class="back" type="button" on:click={goBack}>
			<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
		</button>
		<h2>Start a conversation</h2>
	</div>
	<div class="overlay-main">
		<div class="attendees">
			<div class="bar">
				<Filter bind:filterText icon={false}/>
			</div>
			<div class="list">
				{#each displayAttendees as a (a)}
					{#if (($attendees[a].type == 'attendee') && ($attendees[a].ref != $attendee.ref))}
						<!-- TODO: nest a button instead -->
						<!-- svelte-ignore a11y-click-events-have-key-events -->
						<p on:click={() => { openConversation([a]) }}>
							<Avatar identity={$attendees[a]}/>
							<strong>{$attendees[a].f} {$attendees[a].l}</strong>
							{#if $attendees[a].j}<br/>{$attendees[a].j}{/if}
							{#if $attendees[a].o}<br/>{$attendees[a].o}{/if}
						</p>
					{/if}
				{/each}
			</div>
		</div>
	</div>
</div>