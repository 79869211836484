<script>

	import { onMount, createEventDispatcher } from 'svelte';
	import { fly } from "svelte/transition";

	const dispatch = createEventDispatcher();

	export let options = [];
	export let arrow = false;
	export let tick = null;

	function handleKeydown(event){
		if (event.key === 'Escape') {
			dispatch('escape');
		}
	}

</script>

<style>

	.menu {
		background: var(--panelColor);
		border-radius: 4px;
		box-shadow: 0 3px 10px rgba(0,0,0,0.1), 0 0 0 1px var(--blend-05);
		z-index: 1000;
		/*padding: 0.75rem 0.5rem;*/
		padding: 0.8rem 0.5rem;
		box-sizing: border-box;
	}
	.menu:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}
	.menu ul {
		padding: 0;
		margin: 0;
	}
	.menu li {
		display: block;
		margin: 0;
		padding: 0.5rem;
		/*color: var(--textColor);*/
		color: var(--blend-80);
		font-weight: 400;
		font-size: 0.6875rem;
		line-height: 1;
		border-radius: 4px;
		position: relative;
	}
	.menu li:hover,
	.menu li:focus {
		color: var(--textColor);
		background: var(--blend-10);
	}
	.menu.icons li {
		padding-left: 1.75rem;
	}
	.menu li + li {
		margin-top: 0.25rem;
	}
	.menu.arrow:before {
		content: "";
		position: absolute;
		top: 0;
		left: 1rem;
		/*width: 0.4rem;*/
		/*height: 0.4rem;*/
		width: 0;
		height: 0;
		padding: 0.3rem;
		border-radius: 2px;
		transform: rotate(45deg) translateY(-50%);
		background: var(--panelColor);
		box-shadow: 0 0 4px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.menu.icons li :global(svg) {
		position: absolute;
		left: 0.25rem;
		top: 0.2rem;
		width: 1.2rem;
		height: 1.2rem;
	}

	.extra {
		padding: 0 0.5rem 0.5rem 0.5rem;		
	}

</style>

<svelte:window on:keydown|stopPropagation={handleKeydown} on:click={() => { dispatch('escape') }}/>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="menu" class:arrow class:icons={ tick || options[0].icon } transition:fly={{ duration: 200, y: 5 }} on:click|stopPropagation>
	{#if $$slots.default}
		<div class="extra">
			<slot/>
		</div>
	{/if}
	<ul>
		{#each options as opt}
			<!-- TODO: nest a button instead -->
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<li on:click|stopPropagation={() => { dispatch('selected', opt) }}>
				{#if opt.icon}
					{@html opt.icon}
				{:else if (tick && ((tick == opt.value) || (tick == opt.ref)))}
					<svg viewBox="0 0 80 80"><title>Selected</title><path d="M31.85 57.41l-12.21-12.21 4.24-4.24 7.97 7.97 24.35-24.35 4.24 4.24z"/></svg>
				{/if}
				{#if opt.label}
					<span>{opt.label}</span>
				{:else if opt.name}
					<span>{opt.name}</span>
				{/if}
			</li>
		{/each}
	</ul>
</div>