<script>

	import { onMount, getContext, tick } from 'svelte';
	import { flip } from 'svelte/animate';
	import fadeScale from 'svelte-transitions-fade-scale';
	import { quintOut } from 'svelte/easing';

	import Participant from "./Participants/Participant.svelte";

	const participants = getContext('participants');
	const flipDuration = getContext('flipDuration');

	let flipDurationMs = 200;
	let dragging = null;

	let participantList = [];
	// let participantListDebouncer;

	let mounted = false;
	// let recalcParticipants = true;

	let offsets = {};
	let listElem;
	let listHeight;

	// Participant key:
	// id = id
	// t = video track SID
	// o = onstage
	// s = spotlight
	// m = host-muted
	// f = flip (mirror camera)
	// n = show name

	onMount(() => {
		setParticipantList();
		mounted = true;
	});

	function handleConsider(e) {
		const id = e.detail.id; // participant id
		const i = e.detail.i; // current index
		const y = e.detail.y; // offset from start position, per neodrag
		const s = e.detail.s; // start index
		const h = e.detail.h; // height of box
		const c = h / 2; // midpoint of box
		let sd = (s * h) + y; // offset distance from top of list, based on start position
		if (sd < 0) {
			sd = 0;
		}
		let maxSd = (participantList.length - 1) * h;
		if (sd > maxSd) {
			sd = maxSd;
		}
		const m = (sd + c); // offset distance of the box midpoint from top of list, based on start position
		let j = Math.floor(m / h); // new index position
		if (j < 0) j = 0;
		if (j > (participantList.length - 1)) j = (participantList.length - 1);
		j = Math.abs(j);
		if (j != i) {
			// console.log('new index', sd, y, c, m, i, j);
			moveParticipant(id, j);
		}
		const d = j * h; // current (index position) distance from top of list
		offsets[id] = sd - d;
		const scr = listElem.scrollTop;
		if (scr > (sd - h)) {
			listElem.scrollTo({
				top: scr - 5
			});
		} else if ((sd + h + c) > (listHeight - scr)) {
			listElem.scrollTo({
				top: scr + 5
			});
		}
	}

	function moveParticipant(id, j) {

		let deleted = undefined;

		let filtered = participantList.filter(function(o){
			if (o.id == id) {
				deleted = o;
			} else {
				return o;
			}
		});

		filtered.splice(j, 0, deleted);

		participantList = filtered;

	}

	function setParticipants() {
		// console.log('setParticipants', participantList);
		if (mounted && !dragging) {
			// recalcParticipants = false;
			$participants = participantList;
			// recalcParticipants = true;
		}
	}

	function setParticipantList() {
		// console.log('setParticipantList', $participants);
		participantList = $participants;
	}

	$: setParticipants(participantList);
	$: setParticipantList($participants);

</script>

<style>
	.wrap {
		flex: 1 0 auto;
		position: relative;
	}
	.list {
		position: absolute;
		inset: 0;
		padding: 0.5rem 0.75rem;
		overflow-y: scroll;
	}
	.list.dragging :global(*) {
		cursor: grabbing;
	}
	.list > div {
		padding: 4px 0;
	}
</style>

{#if mounted}
	<div class="wrap">
		<div
			bind:this={listElem}
			bind:clientHeight={listHeight}
			class="list p{participantList.length}"
			class:dragging
		>
			{#each participantList as participant, i (participant.id)}
				<div
					in:fadeScale|local={{ duration: 200, baseScale: 0.8 }}
					out:fadeScale|local={{ duration: 100, baseScale: 0.8 }}
					animate:flip={{ delay: 0, duration: dragging ? 0 : 100 }}
				>
					<Participant bind:participant bind:dragging bind:offsets {i} on:consider={handleConsider} on:finalise={setParticipants} />
				</div>
			{/each}
		</div>
	</div>
{/if}