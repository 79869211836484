<script>

	import { onMount } from 'svelte';
	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';

	export let amount = 0;
	export let total = 0;
	export let delay = 0;

	let percentage = 0;

	let progress = tweened(0, {
		duration: 400,
		easing: cubicOut
	});

	function updateProgress(p) {
		setTimeout(() => {
			progress.set(p);
		}, delay);
	}

	$: if (amount && total) {
		percentage = amount ? (amount / total) * 100 : 0;
	}

	$: updateProgress(percentage);

</script>

<style>

	.progressOuter {
		margin: 0.4rem 0;
		background: var(--blend-10);
		width: 100%;
		height: 0.4rem;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
	}

	.progressInner {
		background: var(--accentColor);
		width: var(--w);
		height: 0.4rem;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
	}

</style>

<div class="progressOuter">
	<div class="progressInner" style="--w:{$progress}%;"/>
</div>