<script>

	import { getContext } from 'svelte';

	import { autoblur } from '../../lib/autoblur.js';

	const session = getContext('session');

	export let tab = 'chat';

</script>

<style>
	ul {
		position: relative;
		display: flex;
		margin: 0;
		padding: 0;
		z-index: 1000;
		flex: 0 0 auto;
		background: var(--textColor);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	ul:after {
		content: '';
		position: absolute;
		height: 100%;
		left: 0;
		bottom: 0;
		width: 100%;
		box-shadow: 0 1px 7px var(--textColor);
		opacity: 0.1;
		z-index: -1;
	}
	li {
		position: relative;
		display: block;
		margin: 0;
		padding: 0;
		flex: 1 0 25%;
		color: var(--captionColor);
		text-align: center;
	}
	li:first-child {
		border-top-left-radius: 4px;
	}
	li:last-child {
		border-top-right-radius: 4px;
	}

	li:hover,
	li:active,
	li:focus {
		/*background: var(--textColor-80);*/
	}
	li.current {
		/*background: var(--accent1);*/;
	}

	li.current:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 2px;
		background: var(--accentColor);
	}

	button {
		padding: 0.5rem 0 1.6rem 0;
		margin: 0;
		border: 0;
		background: transparent;
		color: var(--blend-05);
		text-align: center;
		max-width: 2.3rem;
	}

	li:hover button,
	li:active button,
	li:focus button {
		color: var(--textColor);
	}

	li:hover button:after,
	li:active button:after,
	li:focus button:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 2px;
		background: var(--blend-80);
	}

	li.current button {
		color: var(--accentColor);
	}

	button:focus {
		outline: none;
	}

	button svg {
		display: block;
		width: 100%;
	}

	button span {
		/*display: block;*/
		font-size: 0.625rem;
		/*margin-top: 0.5rem;*/
		position: absolute;
		bottom: 0.5rem;
		left: 50%;
		transform: translateX(-50%);
		font-weight: 400;
	}

	li.current button span {
		font-weight: 600;
	}
</style>

<ul>
	<li class:current={tab == 'chat'}>
		<button type="button" use:autoblur on:click={() => {tab = 'chat'}}>
			<svg viewBox="0 0 60 40"><path d="M51.2 12.5h-14.5v2h14.5c.5 0 .9.4.9.9v14.2c0 .5-.4.9-.9.9h-8v4.5l-7.1-4.5h-7.3c-.5 0-.9-.4-.9-.9v-5.3h-2v5.3c0 1.6 1.3 2.9 2.9 2.9h6.7l9.7 6.2v-6.2h6c1.6 0 2.9-1.3 2.9-2.9v-14.2c0-1.6-1.3-2.9-2.9-2.9zM15.3 11.4c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.3 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.2 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zM35.6 23.1c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.3 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zm3.2 0c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6.1-1.1-.4-1.1-1.1zM14.5 27.5v-6.2h-6c-1.6 0-2.9-1.3-2.9-2.9v-14.2c0-1.6 1.3-2.9 2.9-2.9h22.4c1.6 0 2.9 1.3 2.9 2.9v14.2c0 1.6-1.3 2.9-2.9 2.9h-6.7l-9.7 6.2zm-6-24.2c-.5 0-.9.4-.9.9v14.2c0 .5.4.9.9.9h8v4.5l7.1-4.5h7.3c.5 0 .9-.4.9-.9v-14.2c0-.5-.4-.9-.9-.9h-22.4z"/></svg>
			<span>Private chat</span>
		</button>
	</li>
</ul>