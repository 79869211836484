<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from "svelte/transition";

	import Portal from "svelte-portal";

	import latinize from 'latinize';
	import { byValue, byString, byNumber } from 'sort-es';

	import Attendee from "./Deep/Attendee.svelte";
	import Avatar from "../ui/Avatar.svelte";
	import Menu from "../ui/Menu.svelte";
	import Filter from "../ui/Filter.svelte";

	import {	attendees, event, shadowbans, attendee } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';

	const deep = getContext('deep');

	let mounted = false;
	let syncMounted = false;
	let displayAttendees = [];
	let selectedAttendee = null;
	let sortField = 'seq';
	let menu = false;
	let filterText = '';

	onMount(() => {
		// console.log({$attendees});
		mounted = true;
	});

	$: if ($attendees || sortField || filterText) {
		setDisplayAttendees();
	}

	$: filterText = filterText && latinize(filterText).toLowerCase();

	let rowTimeout;

	function setDisplayAttendees() {
		clearTimeout(rowTimeout);
		rowTimeout = setTimeout(function(){
			if (sortField == 'seq') {
				displayAttendees = Object.keys($attendees).length ? Object.keys($attendees).sort(byValue(a => $attendees[a].seq, byNumber({ desc: true }))) : [];
			} else {
				displayAttendees = Object.keys($attendees).length ? Object.keys($attendees).sort(byValue(a => $attendees[a][sortField] ? $attendees[a][sortField] : '', byString({ lowercase: true }))) : [];
			}
			displayAttendees = (displayAttendees && displayAttendees.length) ? displayAttendees.filter(filterByValue) : [];
		}, 100);
	}

	function filterByValue(o) {
		if (!filterText) return true;
		return Object.keys($attendees[o]).some(f => (f == 'f' || f == 'l' || f == 'o') && filterValue($attendees[o][f]).includes(filterText));
	}

	function filterValue(v) {
		return v ? latinize(v).toString().toLowerCase() : '';
	}

	function selectAttendee(a) {
		selectedAttendee = $attendees[a];
		$deep = true;
	}

	function setSort(e) {
		sortField = e.detail.value;
		menu = false;
	}
	
</script>

<style>
	.attendees {
		position: absolute;
		inset: 0;
		overflow: auto;
		scrollbar-width: thin;
		padding: 0.2rem 0 0.5rem 0;
	}

	/*.sort {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem;
		margin: 0 0.5rem;
		font-size: 0.6875rem;
		font-weight: 600;
		border-bottom: 1px solid var(--blend-20);
		cursor: pointer;
	}

	.sort:hover,
	.sort:focus {
		color: var(--accentColor);
	}

	.sort svg {
		height: 0.5rem;
		transform: rotate(90deg);
		transition: transform 0.2s ease;
	}

	.sort.open svg {
		transform: rotate(-90deg);		
	}

	.sort :global(.menu) {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
	}*/

	.list > p {
		position: relative;
		padding: 0.3rem 0.4rem 0.3rem 3rem;
		font-size: 0.6875rem;
		line-height: 1.4;
		margin: 0.5rem;
		border-radius: 4px;
		cursor: pointer;
	}

	.list > p:hover {
		background: var(--blend-05);
	}

	.list > p + p {
		margin-top: 0.5rem;
	}

	.list :global(.avatar) {
		left: 0.8rem;
		top: 0.4rem;
	}

	.bar {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		padding: 0 1rem;
		display: flex;
		align-items: stretch;
		gap: 0.5rem;
	}

	.bar > :global(span) {
		flex: 1 0 auto;
	}

	.bar button {
		position: relative;
		flex: 0 0 auto;
		width: 1.4rem;
		border: 0;
		padding: 0;
		background: transparent;
		color: var(--textColor);
	}

	.bar button:hover,
	.bar button:active,
	.bar button:focus {
		color: var(--accentColor);
	}

	.bar button svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.bar :global(.menu) {
		position: absolute;
		top: calc(100% + 0.5rem);
		right: 0.5rem;
		width: 240px;
	}

	.attendees :global(.menu.arrow:before) {
		left: auto !important;
		right: 1rem;
	}

</style>

{#if mounted}
	<div class="attendees" in:fly={{ duration: 300, y: 20 }}>
		<div class="bar">
			<Filter bind:filterText icon={false}/>
			<button type="button" class:open={menu} on:click|stopPropagation={() => { menu = !menu }} use:autoblur>
				<svg viewBox="0 0 27 27"><title>Sort</title><path d="M14.03 17.44c-.29-.29-.77-.29-1.06 0l-2.33 2.33v-7.83c0-.41-.34-.75-.75-.75s-.75.34-.75.75v7.83l-2.33-2.33c-.29-.29-.77-.29-1.06 0s-.29.77 0 1.06l3.61 3.61c.14.14.33.22.53.22s.39-.08.53-.22l3.61-3.61c.29-.29.29-.77 0-1.06zM21.24 8.5l-3.61-3.61c-.29-.29-.77-.29-1.06 0l-3.6 3.61c-.29.29-.29.77 0 1.06s.77.29 1.06 0l2.33-2.33v7.83c0 .41.34.75.75.75s.75-.34.75-.75v-7.82l2.33 2.33c.15.15.34.22.53.22s.38-.07.53-.22c.29-.3.29-.78-.01-1.07z"/></svg>
			</button>
			{#if menu}
				<Menu
					options={[
						{ value: 'seq', label: 'Chronological (sign-in)' },
						{ value: 'l',   label: 'Alphabetical (last name)' },
						{ value: 'o',   label: 'Alphabetical (organisation)' },
					]}
					on:selected={setSort}
					on:escape={() => { menu = false }}
					arrow={true}
					tick={sortField}
				/>
			{/if}
		</div>
		<div class="list">
			{#each displayAttendees as a (a)}
				{#if ($attendees[a].type == 'attendee') && (($attendee.type == 'login') || !$shadowbans.includes($attendees[a].ref) || ($attendee.ref == $attendees[a].ref))}
					<!-- TODO: nest a button instead -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<p on:click={selectAttendee(a)}>
						<Avatar identity={$attendees[a]}/>
						<strong>{$attendees[a].f} {$attendees[a].l}</strong>
						{#if $attendees[a].j}<br/>{$attendees[a].j}{/if}
						{#if $attendees[a].o}<br/>{$attendees[a].o}{/if}
					</p>
				{/if}
			{/each}
		</div>
	</div>
{/if}

{#if selectedAttendee}
	<Portal target="#deepInfo">
		<Attendee attendee={selectedAttendee} on:back={() => { selectedAttendee = null }}/>
	</Portal>
{/if}
