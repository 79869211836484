<script>

	import { onMount, onDestroy } from "svelte";
	import { fade } from 'svelte/transition';

	import { Room, RoomEvent } from 'livekit-client';

	import Participant from "./Participant.svelte";

	import Spinner from "../ui/Spinner.svelte";

	import { event, videoCall } from '../lib/stores.js';

	// export let vc;
	export let name;
	export let muted = false;
	export let invisible = false;
	
	let room = null;
	// let participants = [];
	let started = false;
	let remoteParticipant = null;

	let autoCloseTimer;

	// async function loadRoom() {
	// 	room = await Twilio.Video.connect(vc.jwt, {
	// 		name: vc.call
	// 	});
	// 	participants = Array.from(room.participants.values());
	// 	room.on("participantConnected", participant => {
	// 		participants = [...participants, participant];
	// 	});
	// 	room.on("participantDisconnected", participant => {
	// 		participants = participants.filter(p => p !== participant);
	// 	});
	// }

	onMount(async () => {
		
		room = new Room();

		room.on(RoomEvent.TrackSubscribed, handleTrackSubscribed);
		
		room.on(RoomEvent.ParticipantDisconnected, () => {
			remoteParticipant = null;
		});

		await room.connect('wss://' + $videoCall.host, $videoCall.jwt);
		await room.localParticipant.enableCameraAndMicrophone();

	});

	onDestroy(() => {
		clearTimeout(autoCloseTimer);
		// if (room) {
		// 	room.removeAllListeners();
		// 	room.disconnect();
		// 	room = null;
		// 	participants = [];
		// }
		if (room) {
			room.disconnect();
		}
	});

	function handleTrackSubscribed(track, publication, participant) {
		console.log('handleTrackSubscribed', track, participant);
		remoteParticipant = participant;
	}

	function toggleMute(m) {
		console.log('toggleMute', m);
		if (room) {
			// if (m) {
			// 	room.localParticipant.audioTracks.forEach(publication => {
			// 		publication.track.disable();
			// 	});
			// } else {
			// 	room.localParticipant.audioTracks.forEach(publication => {
			// 		publication.track.enable();
			// 	});
			// }
			room.localParticipant.setMicrophoneEnabled(!m);
		}
	}

	function toggleCamera(m) {
		console.log('toggleCamera', m);
		if (room) {
			// if (m) {
			// 	room.localParticipant.videoTracks.forEach(publication => {
			// 		publication.track.disable();
			// 	});
			// } else {
			// 	room.localParticipant.videoTracks.forEach(publication => {
			// 		publication.track.enable();
			// 	});
			// }
			room.localParticipant.setCameraEnabled(!m);
		}
	}

	function autoClose() {
		autoCloseTimer = setTimeout(() => {
			$videoCall = null;
		}, 240000); // 4 mins
	}

	// $: if (participants.length) started = true;
	$: if (remoteParticipant) started = true;

	$: toggleMute(muted);

	$: toggleCamera(invisible);

	$: if ($videoCall && ($videoCall.status == 'ended')) {
		autoClose();
	}

</script>

<style>
	.remote {
		/*width: 800px;
		max-width: 80vw;
		max-height: 60vh;
		aspect-ratio: 4 / 3;*/
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		/*width: calc((90vh - 14rem) * (4 / 3));*/
		width: calc((85vh - 6rem) * (4 / 3));
		max-width: 85vw;
		margin: 0 auto;
	}

	@media (max-height: 600px) {
		.remote {
			width: calc(90vh * (4 / 3));
		}
	}

	.slate {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		max-width: 14rem;
	}
	.slate p {
		margin-top: 0.5rem;
		font-size: 0.8rem;
		line-height: 1.4;
		text-align: center;
		color: var(--panelColor);
	}
	.slate svg {
		width: 2rem;
		height: 2rem;
	}
	.slate strong {
		font-size: 1rem;
	}

	.holding {
		flex: 1 1 auto;
		aspect-ratio: 4 / 3;
		display: grid;
		place-content: center;
	}

	.holding p {
		margin-top: 2rem;
		font-size: 0.8rem;
		color: var(--blend-10);
	}

</style>

<!-- <svelte:head>
	<script src="https://sdk.twilio.com/js/video/releases/2.27.0/twilio-video.min.js" on:load={loadRoom}></script>
</svelte:head> -->

{#if room}

	<div class="remote" in:fade>
		<!-- {#if participants.length}
			{#each participants as participant}
				<Participant {participant} />
			{/each} -->
		{#if remoteParticipant}
			{#key remoteParticipant.sid}
				<Participant participant={remoteParticipant} />
			{/key}
		{:else}
			<div class="holding">
				<div>
					{#if !started && (!$videoCall || ($videoCall.status == 'ended'))}
						<p><strong>{name} is currently unavailable</strong></p>
					{:else}
						<Spinner size="100" speed="1200" color={$event.theme.virtual['blend-10']} thickness="1" gap="30" />
						{#if started}
							<p>Call disconnected</p>
						{:else}
							<p>Waiting for {name} …</p>
						{/if}
					{/if}
				</div>
			</div>
		{/if}
	</div>

	<div class="local" in:fade>
		<Participant participant={room.localParticipant} local={true} />
	</div>

{:else}

	<div class="slate">
		<p><svg viewBox="0 0 80 80"><path d="M25.82 22.33l6.22 7.59-2.38 2.43c-1.09 1.11-1.43 2.75-.88 4.21 2.43 6.46 8.19 12.22 14.67 14.67.46.17.94.26 1.42.26 1.03 0 2.04-.4 2.8-1.14l2.43-2.38 7.59 6.22-4.22 4.22c-.96.85-2 1.23-3.33 1.23-13.07 0-29.75-16.68-29.75-29.75 0-1.34.38-2.37 1.23-3.33l4.2-4.23m0-4c-1.02 0-2.04.39-2.83 1.17l-4.29 4.29c-1.77 1.95-2.32 4.08-2.32 6.08 0 15.27 18.4 33.75 33.75 33.75 2 0 4.13-.55 6.08-2.32l4.29-4.29c1.67-1.67 1.54-4.42-.29-5.92l-10.37-8.49-4.99 4.89c-5.15-1.95-10.2-6.66-12.34-12.34l4.89-4.99-8.49-10.36c-.79-.98-1.94-1.47-3.09-1.47zM63.62 38.62c-1.1 0-2-.9-2-2 0-10.06-8.19-18.25-18.25-18.25-1.1 0-2-.9-2-2s.9-2 2-2c12.27 0 22.25 9.98 22.25 22.25 0 1.11-.89 2-2 2zM50.12 38.62c-1.1 0-2-.9-2-2 0-2.62-2.13-4.75-4.75-4.75-1.1 0-2-.9-2-2s.9-2 2-2c4.83 0 8.75 3.93 8.75 8.75 0 1.11-.89 2-2 2zM56.88 38.62c-1.1 0-2-.9-2-2 0-6.34-5.16-11.5-11.5-11.5-1.1 0-2-.9-2-2s.9-2 2-2c8.55 0 15.5 6.95 15.5 15.5 0 1.11-.9 2-2 2z"/></svg></p>
		<p><strong>Connecting…</strong></p>
		<p>Please allow camera and microphone access</p>
	</div>

{/if}
