<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from 'svelte/transition';

	import Button from "../../ui/Button.svelte";
	import Field from '../../ui/Field.svelte';
	import BigTick from '../../ui/BigTick.svelte';

	import { bigError, session } from '../../lib/stores.js';
	import { postServerData } from '../../lib/prelude.js';
	import { autoblur } from '../../lib/autoblur.js';

	const rated = getContext('rated');

	let mounted = false;

	let rating;
	let comments;

	let formOK = false;

	let animate = false;

	onMount(async () => {
		// console.log("Rated on mount?", $rated);
		mounted = true;
	});

	async function sendRating() {

		await postServerData('virtual/ratings', {
			session: $session.ref,
			rating: rating,
			comments: comments
		});

		if (!$bigError) {
			animate = true;
			$rated = true;
		}
		
	}

	$: formOK = rating ? true : false;

	$: if ($rated) {
		setAnimate();
	}

	function setAnimate() {
		if (mounted) {
			animate = true;
		}
	}

</script>

<style>
	.wrap {
		position: absolute;
		inset:  0;
		padding: 1rem;
	}

	.field {
		margin: 1rem 0;
	}

	legend {
		display: block;
		font-size: 0.6875rem;
		padding: 0 0 0.5rem 0;
		line-height: 1.4;
		/*font-weight: 600;*/
	}

	legend span {
		/*color: var(--blend-60);*/
	}

	.actions {
		margin: 2rem 0 0 0;
	}


	:root {
		--star-rating-size: 2.2rem;
		--star-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' enable-background='new 0 0 32 32'%3E%3Cpath d='M13 3.9c-.6 0-1.2.4-1.4 1l-2.3 7.1h-7.6c-.6 0-1.2.4-1.4 1-.2.6 0 1.2.5 1.6l6.2 4.5-2.3 7.2c-.2.6 0 1.2.5 1.6.2.1.5.2.8.2.3 0 .6-.1.8-.3l6.1-4.4 6.1 4.4c.3.2.6.3.8.3s.6-.1.8-.3c.5-.4.7-1 .5-1.6l-2.3-7.2 6.1-4.4c.4-.3.6-.7.6-1.2 0-.8-.6-1.4-1.4-1.4h-7.5l-2.3-7.2c-.2-.5-.7-.9-1.3-.9z'/%3E%3C/svg%3E");
	}

	.star-rating{
		width: 100%;
		padding: 0;
		border: 0;
		margin: 0 0 2rem 0;
	}

	.star-rating > div {
		position: relative;
		height: var(--star-rating-size);
		width: calc(var(--star-rating-size) * 5);
	}

	.star-rating > div:after {
		content: "";
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		-webkit-mask-image: var(--star-svg);
		-webkit-mask-size: var(--star-rating-size) var(--star-rating-size);
		-webkit-mask-position: 0% 50%;
		mask-image: var(--star-svg);
		mask-size: var(--star-rating-size) var(--star-rating-size);
		mask-position: 0% 50%;
		background: var(--blend-20);
		pointer-events: none;
	}

	.star-rating label {
		position: absolute;
		inset: 0;
		background-size: var(--star-rating-size) var(--star-rating-size);
	}

	.star-rating > div > span {
		position: absolute;
		height: 100%;
	}

	.star-rating > div > span:nth-of-type(1) {
		z-index: 10;
		width: calc(100% / 5 * 1);
	}

	.star-rating > div > span:nth-of-type(2) {
		z-index: 9;
		width: calc(100% / 5 * 2);
	}

	.star-rating > div > span:nth-of-type(3) {
		z-index: 8;
		width: calc(100% / 5 * 3);
	}

	.star-rating > div > span:nth-of-type(4) {
		z-index: 7;
		width: calc(100% / 5 * 4);
	}

	.star-rating > div >  span:nth-of-type(5) {
		z-index: 6;
		width: 100%;
	}

	.star-rating input:checked + label:after,
	.star-rating input:focus + label:after,
	.star-rating input:checked + label:hover:after,
	.star-rating label:hover:after {
		content: "";
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		-webkit-mask-image: var(--star-svg);
		-webkit-mask-size: var(--star-rating-size) var(--star-rating-size);
		-webkit-mask-position: 0% 50%;
		mask-image: var(--star-svg);
		mask-size: var(--star-rating-size) var(--star-rating-size);
		mask-position: 0% 50%;
		background: var(--accentColor);
		pointer-events: none;
		outline: 1px solid red;
	}

	.star-rating input,
	.star-rating label > span{
		border: 0;
		padding: 0;
		margin: 0;
		position: absolute !important;
		height: 1px; 
		width: 1px;
		overflow: hidden;
		clip-path: inset(50%);
		white-space: nowrap;
	}

	.wrap :global(.bigtick) {
		margin-top: 2rem;
	}

	/*.thanks {
		margin: 0;
		text-align: center;
		font-weight: 600;
		font-size: 0.6875rem;
	}*/

	.slate {
		padding-top: 0;
	}

	.slate :global(.bigtick) {
		text-align: left;
		padding: 0;
	}

</style>

{#if mounted}
	<div class="wrap" in:fly={{ duration: 300, y: 20 }}>

		{#if $rated}

			<div class="slate">
				<BigTick {animate} thickness={5}/>
				<p><strong>Thank you.</strong></p>
				<p>We appreciate your feedback.</p>
			</div>

		{:else}

			<form on:submit|preventDefault={sendRating}>

				<div class="field">
					<fieldset class="star-rating">
						<legend><strong>How useful was this session?</strong><br/><span>5 = very useful; 1 = not useful at all</span></legend>
						<div>
							<span>
								<input type="radio" name="rating" value="1" id="rating1" bind:group={rating} />
								<label for="rating1"><span>1</span></label>
							</span>
							<span>
								<input type="radio" name="rating" value="2" id="rating2" bind:group={rating} />
								<label for="rating2"><span>2</span></label>
							</span>
							<span>
								<input type="radio" name="rating" value="3" id="rating3" bind:group={rating} />
								<label for="rating3"><span>3</span></label>
							</span>
							<span>
								<input type="radio" name="rating" value="4" id="rating4" bind:group={rating} />
								<label for="rating4"><span>4</span></label>
							</span>
							<span>
								<input type="radio" name="rating" value="5" id="rating5" bind:group={rating} />
								<label for="rating5"><span>5</span></label>
							</span>
						 </div>
					</fieldset>
				</div>

				<Field
					bind:value={comments}
					component="TextArea"
					label="Any ideas for improvement?"
				/>

				<p class="actions"><Button label="Submit feedback" type="submit" disabled={!formOK} wide={true} /></p>

			</form>

		{/if}

	</div>
{/if}

