<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';

	import {	announcements, session, epoch, room, attendees, videoCall, selectedReport } from '../lib/stores.js';
	import { text_to_html } from '../lib/html.js';
	import { showEpoch } from '../lib/dt.js';
	import { callStatus } from '../lib/conversations.js';

	import Button from './Button.svelte';

	const dispatch = createEventDispatcher();

	export let announcement;

	let callTimeout;

	onMount(() => {
		if (announcement.type == 'videocall') {
			callTimeout = setTimeout(declineVideoCall, 240000); // 4 mins
		}
	});

	onDestroy(() => {
		clearTimeout(callTimeout);
	});

	function acceptVideoCall() {
		clearTimeout(callTimeout);
		$videoCall = {
			call: announcement.ref,
			jwt: announcement.jwt,
			host: announcement.host,
			participant: announcement.participant
		};
		callStatus(announcement.ref, 'started');
		dispatch('dismiss');
	}

	function declineVideoCall() {
		callStatus(announcement.ref, 'ended');
		dispatch('dismiss');
	}

	function viewReport() {
		$selectedReport = announcement.report.ref;
		dispatch('dismiss');
	}

</script>

<style>

	.announcement {
		background: var(--blend-10);
		border-radius: 4px;
		padding: 0.85rem 3.2rem 0.85rem 3.2rem;
		margin: 0.5rem auto;
		/*font-size: 0.8rem;*/
		font-size: 0.6875rem;
		line-height: 1.4;
		position: relative;
		box-sizing: border-box;
		width: 450px;
		max-width: 95vw;
	}
	.announcement:before {
		content: '';
		position: absolute;
		inset: 0;
		border-radius: 4px;
		box-shadow: 0 4px 10px 4px var(--shadow);
		z-index: -1;
	}
	.announcement:after {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor);
		border-radius: 4px;
		box-shadow: 0 0 0 3px var(--textColor);
		opacity: 0.4;
		z-index: -1;
	}
	.announcement .disc {
		position: absolute;
		top: 0.8rem;
		left: 0.8rem;
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 100%;
		/*background: var(--textColor);*/
		background: var(--accentColor);
		/*background: var(--red);*/
		color: var(--panelColor);
	}
	.announcement .disc svg {
		position: absolute;
		inset: 0.25rem;
	}
	.announcement p + p {
		margin-top: 0.5rem;
	}
	.close {
		position: absolute;
		top: 0rem;
		right: 0rem;
		width: 3rem;
		height: 3rem;
		border: 0;
		background: transparent;
		padding: 0.6rem;
		color: var(--textColor);
	}
	.close:hover,
	.close:active,
	.close:focus {
		color: var(--red);
	}
	.label {
		margin-bottom: 0.25rem;
		/*color: var(--red);*/
	}
	.label span {
		color: var(--blend-60);
		/*font-size: 0.6875rem;*/
		font-size: 0.625rem;
		margin-left: 0.5rem;
	}
	.actions {
		display: flex;
		gap: 0.5rem;
	}
</style>

<div class="announcement">
	{#if announcement.type == 'session'}
		<span class="disc"><svg viewBox="0 0 80 80"><circle cx="21.6" cy="33.18" r="2.63"/><circle cx="29.5" cy="33.18" r="2.63"/><path d="M68.29 25.37c-.44-.28-.99-.3-1.46-.07l-11.54 5.77h-8.08v-2.71c0-2.93-2.05-5.22-4.67-5.22h-26.87c-2.62 0-4.67 2.29-4.67 5.22v23.26c0 2.93 2.05 5.22 4.67 5.22h26.87c2.62 0 4.67-2.29 4.67-5.22v-2.74h8.08l11.54 5.77c.21.11.44.16.67.16.27 0 .55-.07.79-.22.44-.27.71-.76.71-1.28v-26.66c0-.52-.27-1.01-.71-1.28zm-25.07 25.49c0 1.1-.9 2-2 2h-24.22c-1.1 0-2-.9-2-2v-21.72c0-1.1.9-2 2-2h24.22c1.1 0 2 .9 2 2v21.72zm9.93-5.97h-4.89v-9.82h4.89v9.82zm3.87.37l-.04-10.56 8.02-4.01.04 18.6-8.02-4.03z"/></svg></span>
	{:else if announcement.type == 'videocall'}
		<span class="disc"><svg viewBox="0 0 80 80"><path d="M25.82 22.33l6.22 7.59-2.38 2.43c-1.09 1.11-1.43 2.75-.88 4.21 2.43 6.46 8.19 12.22 14.67 14.67.46.17.94.26 1.42.26 1.03 0 2.04-.4 2.8-1.14l2.43-2.38 7.59 6.22-4.22 4.22c-.96.85-2 1.23-3.33 1.23-13.07 0-29.75-16.68-29.75-29.75 0-1.34.38-2.37 1.23-3.33l4.2-4.23m0-4c-1.02 0-2.04.39-2.83 1.17l-4.29 4.29c-1.77 1.95-2.32 4.08-2.32 6.08 0 15.27 18.4 33.75 33.75 33.75 2 0 4.13-.55 6.08-2.32l4.29-4.29c1.67-1.67 1.54-4.42-.29-5.92l-10.37-8.49-4.99 4.89c-5.15-1.95-10.2-6.66-12.34-12.34l4.89-4.99-8.49-10.36c-.79-.98-1.94-1.47-3.09-1.47zM63.62 38.62c-1.1 0-2-.9-2-2 0-10.06-8.19-18.25-18.25-18.25-1.1 0-2-.9-2-2s.9-2 2-2c12.27 0 22.25 9.98 22.25 22.25 0 1.11-.89 2-2 2zM50.12 38.62c-1.1 0-2-.9-2-2 0-2.62-2.13-4.75-4.75-4.75-1.1 0-2-.9-2-2s.9-2 2-2c4.83 0 8.75 3.93 8.75 8.75 0 1.11-.89 2-2 2zM56.88 38.62c-1.1 0-2-.9-2-2 0-6.34-5.16-11.5-11.5-11.5-1.1 0-2-.9-2-2s.9-2 2-2c8.55 0 15.5 6.95 15.5 15.5 0 1.11-.9 2-2 2z"/></svg></span>
	{:else if announcement.type == 'report'}
		<span class="disc"><svg viewBox="0 0 80 80"><path d="M18.86 68.13l-4.69-53.58c-.05-.55.36-1.04.91-1.08l1.99-.17c.55-.05 1.04.36 1.08.91l4.69 53.93h-3.98zM31.46 16.51c2.97 0 6.14.57 9.11 1.18 3.26.67 7.08 1.36 10.68 1.36.78 0 1.54-.03 2.27-.1 1.03-.09 2.12-.21 3.22-.42.58-.11 1.12.31 1.18.89l1.76 20.28c.04.48-.26.91-.73 1.05-.82.24-1.93.44-3.49.57-.5.04-1.03.07-1.58.07-2.88 0-6.2-.6-9.07-1.2-3.39-.7-7.03-1.35-10.73-1.35-.84 0-1.67.03-2.46.1-1.37.12-2.54.34-3.56.63l-1.91-22.06c.89-.46 1.92-.8 3.53-.94.57-.04 1.17-.06 1.78-.06m0-4c-.71 0-1.41.03-2.11.09-3.95.34-5.63 1.72-7.39 2.8l2.63 30.34c1.76-1.08 3.44-2.46 7.39-2.8.7-.06 1.4-.09 2.11-.09 3.47 0 6.98.66 9.92 1.26 3.63.75 6.89 1.28 9.87 1.28.66 0 1.3-.03 1.93-.08 3.57-.31 5.9-.95 7.75-2.59.23-.21.35-.52.32-.83l-2.43-28c-.05-.55-.5-.91-.99-.91-.19 0-.38.05-.55.16-1.69 1.08-3.78 1.56-6.73 1.81-.63.05-1.27.08-1.93.08-2.98 0-6.25-.53-9.87-1.28-2.93-.58-6.45-1.24-9.92-1.24z"/></svg></span>
	{:else if announcement.type == 'poll'}
		<span class="disc"><svg viewBox="0 0 20 20"><path d="M13.48 15.53v-2.95c0-.55.45-1 1-1s1 .45 1 1v2.95h-2zM4.52 15.53v-9.91c0-.55.45-1 1-1s1 .45 1 1v9.92h-2zM9 15.53v-7.05c0-.55.45-1 1-1s1 .45 1 1v7.05h-2z"/></svg></span>
	{:else}
		<span class="disc"><svg viewBox="0 0 80 80"><path d="M58.18 28.35c-1.11 0-2-.9-2-2 0-1.35-.53-2.63-1.48-3.58-.96-.96-2.23-1.49-3.59-1.49-1.1 0-2-.9-2-2s.9-2 2-2c2.42 0 4.7.94 6.41 2.66 1.71 1.71 2.66 3.99 2.66 6.41 0 1.1-.89 2-2 2zM66.03 24.81c-1.1 0-1.99-.89-2-1.99-.01-2.46-.98-4.78-2.73-6.52-1.76-1.76-4.1-2.73-6.59-2.73h-.07c-1.1 0-1.99-.89-2-1.99-.01-1.1.88-2 1.99-2.01h.08c3.56 0 6.9 1.38 9.42 3.9 2.49 2.49 3.88 5.81 3.9 9.33.01 1.1-.88 2.01-1.99 2.01h-.01zM65.74 41.34l-.19-.22-29.43-29.43-.22-.19c-1.03-.77-2.39-.9-3.54-.34-1.09.53-1.79 1.6-1.81 2.8-.24 10.33-4.07 19.61-11.08 26.98l-4.88 4.87c-2.34 2.34-2.34 6.16 0 8.5l8.31 8.31c1.17 1.17 2.71 1.76 4.25 1.76s3.08-.58 4.25-1.76l3.08-3.08.19-.16c.29-.31.59-.6.88-.89l10.37 10.37c.38.38.89.59 1.41.59.26 0 .52-.05.77-.15l8.05-3.33c.61-.25 1.06-.79 1.19-1.44s-.06-1.32-.51-1.79l-11.16-11.66c5.34-2.74 11.27-4.24 17.6-4.39 1.2-.03 2.27-.72 2.8-1.81.56-1.15.43-2.51-.33-3.54zm-44.17 3.17l11.15 11.15-4.14 4.15c-.78.78-2.06.78-2.84 0l-8.31-8.31c-.78-.78-.78-2.06 0-2.84l4.14-4.15zm30.35 18.88l-4.11 1.7-9.29-9.29c1.16-.96 2.35-1.84 3.59-2.65l9.81 10.24zm-16.24-10.42l-11.41-11.4c6.14-7.31 9.64-16.13 10.2-25.85l27.06 27.05c-9.72.55-18.54 4.05-25.85 10.2z"/></svg></span>
	{/if}
	{#if (announcement.type != 'videocall') && (announcement.type != 'report') && (announcement.type != 'poll')}
		<p class="label">
			<strong>Announcement</strong>
			<span>{showEpoch(announcement.dt, 'HH:mm')}</span>
		</p>
	{/if}
	{#if announcement.type == 'session'}
		{#if announcement.sessions.length > 1}
			{#if announcement.sessions[0].epochStarts > $epoch}
				<p>There are {announcement.sessions.length} sessions about to start.</p>
			{:else if announcement.sessions[0].epochStarts > ($epoch - 120)}
				<p>There are {announcement.sessions.length} sessions just starting.</p>
			{:else}
				<p>There are {announcement.sessions.length} sessions in progress.</p>
			{/if}
			<p class="actions">
				<Button
					mini={true}
					grow={false}
					label="Go to Stage"
					on:click={() => { $room = 'auditorium'; dispatch('dismiss') }}
				/>
			</p>
		{:else}
			{#if announcement.sessions[0].epochStarts > $epoch}
				<p>The session <strong>{announcement.sessions[0].name}</strong> is about to start.</p>
			{:else if announcement.sessions[0].epochStarts > ($epoch - 120)}
				<p>The session <strong>{announcement.sessions[0].name}</strong> is just starting.</p>
			{:else}
				<p>The session <strong>{announcement.sessions[0].name}</strong> is in progress.</p>
			{/if}
			<p class="actions">
				<Button
					mini={true}
					grow={false}
					label="Join session"
					on:click={() => { $session = announcement.sessions[0]; $room = 'auditorium'; dispatch('dismiss') }}
				/>
			</p>
		{/if}
	{:else if announcement.type == 'videocall'}
		<p>Video call with <strong>{$attendees[announcement.participant].f} {$attendees[announcement.participant].l}</strong></p>
		<p class="actions">
			<Button
				mini={true}
				grow={false}
				label="Accept"
				on:click={acceptVideoCall}
			/>
			<Button
				mini={true}
				grow={false}
				red={true}
				ghost={true}
				label="Decline"
				on:click={declineVideoCall}
			/>
		</p>
	{:else if announcement.type == 'report'}
		<p class="label"><strong>Important!</strong></p>
		<p>An attendee has reported <strong>{$attendees[announcement.report.reportee].f} {$attendees[announcement.report.reportee].l}</strong> for problem behaviour.</p>
		<p class="actions">
			<Button
				mini={true}
				grow={false}
				label="View report"
				on:click={viewReport}
			/>
		</p>
	{:else if announcement.type == 'poll'}
		<p class="label"><strong>New poll</strong></p>
		<p>{announcement.question}</p>
		<p class="actions">
			<Button
				mini={true}
				grow={false}
				label="Vote now"
				on:click={() => {
					announcement.callback();
					dispatch('dismiss');
				}}
			/>
			<Button
				mini={true}
				grow={false}
				ghost={true}
				label="Dismiss"
				on:click={() => { dispatch('dismiss') }}
			/>
		</p>
	{:else}
		{@html text_to_html(announcement.message)}
	{/if}
	{#if (announcement.type != 'videocall') && (announcement.type != 'report')}
		<button type="button" class="close" on:click={() => {dispatch('dismiss')}}>
			<svg viewBox="0 0 80 80"><title>Dismiss</title><path d="M40.06 44.53l-11 11-4.5-4.49 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.49-11 11 11 11-4.5 4.49-11-10.99z"/></svg>
		</button>
	{/if}
</div>