import { get } from 'svelte/store';
import { conversation, bigError, busy, attendee, muted, videoCall as vc } from './stores.js';
import { postServerData } from './prelude.js';

export const openConversation = async (participants, exhibitor, help, message) => {

	busy.set(true);

	const p = JSON.stringify({
		participants: participants
	});

	let params = {
		participants: p
	};

	if (exhibitor) params.exhibitor = exhibitor;
	if (help) params.help = 1;

	const conversationData = await postServerData('virtual/conversations', params);

	let err = get(bigError);

	if (!err) {
		busy.set(false);
		conversation.set({
			ref: conversationData.ref,
			sid: conversationData.sid,
			participants: conversationData.participants,
			encryptionKey: conversationData.encryptionKey,
			exhibitor: conversationData.exhibitor,
			help: conversationData.help,
			videoCalls: conversationData.videoCalls,
			message: message
		});
	}

	// setTimeout(() => {
	// 	busy.set(false);
	// }, 2000);

};

export const isMuted = (participants) => {

	if (participants) {

		let m = get(muted);
		let a = get(attendee);

		let r = true;

		for (const p of participants) {
			// console.log(p, a.ref, m.includes(p));
			if (p != a.ref) {
				if (!m.includes(p)) {
					r = false;
					break;
				}
			}
		}

		// console.log('isMuted',r);

		return r;

	} else {

		return null;

	}

};

export const videoCall = async (conversationRef, status) => {

	busy.set(true);

	const conversationData = await postServerData('virtual/conversations/videocalls', {
		conversation: conversationRef,
		status: status
	});

	let err = get(bigError);

	if (!err) {
		busy.set(false);
		if (conversationData && (conversationData.call != undefined) && (conversationData.jwt != undefined) && (conversationData.participant != undefined)) {
			vc.set({
				call: conversationData.call,
				jwt: conversationData.jwt,
				host: conversationData.host,
				participant: conversationData.participant
			});
		}
		return true;
	}

	return false;

};

export const callStatus = async (callRef, status) => {
	// console.log('callStatus', callRef, status);
	postServerData('virtual/conversations/videocalls/status', {
		call: callRef,
		status: status
	});
};