<script>

	import { onMount, onDestroy, setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import { fade, fly } from "svelte/transition";

	import Nav from './InfoPanel/Nav.svelte';
	import Agenda from './InfoPanel/Agenda.svelte';
	import Sponsors from './InfoPanel/Sponsors.svelte';
	import Attendees from './InfoPanel/Attendees.svelte';
	import Speakers from './InfoPanel/Speakers.svelte';

	import { autoblur } from './lib/autoblur.js';
	import { leftDocked, leftSection, room } from './lib/stores.js';

	let deep = writable(false);
	setContext('deep', deep);

	let mounted = false;
	let tooltip = false;

	onMount(() => {
		// setTimeout(function(){
			mounted = true;
		// }, 400);
	});

	// $: if ($room == 'backstage') {
	// 	$leftDocked = false;
	// 	$leftSection = 'agenda';
	// }

</script>

<style>
	.panel {
		position: relative;
		grid-area: l;
		box-sizing: border-box;
		/*transition: transform 2.4s ease, width 2.4s ease;*/
		transition: margin-left 0.4s cubic-bezier(.24,.06,.23,.9);
		width: calc((100vw - 2rem) * 0.22);
		min-height: 200px;
	}
	.inner {
		overflow: hidden;
		position: absolute;
		inset: 0;
		box-shadow: 0 2px 10px -2px var(--shadow);
		border-radius: 4px;
	}
	.inner > div {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		/*inset: 0;*/
		border-radius: 4px;
		/*box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.4);*/
		/*width: calc((100vw - 2rem) * 0.22);*/
		display: flex;
		flex-direction: column;
		overflow: hidden;
		transition: left 0.25s ease-out;
	}
	.panel .more {
		left: 100%;
	}
	.panel.deep .main {
		left: -100%;
	}
	.panel.deep .more {
		left: 0;
	}
	.inner > div > div {
		flex: 1 1 auto;
		position: relative;
		overflow: hidden;
	}
	.inner > div:before {
		content: '';
		/*background: var(--blend-10, #ddd);*/
		background: var(--panelColor, #fff);
		position: absolute;
		/*top: 0;
		left: 0;
		right: 0;
		bottom: 0;*/
		inset: 0;
		border-radius: 4px;
		z-index: 0;
	}
	.panel.docked {
		/*width: 0;*/
		/*transform: translateX(-1rem);*/
		margin-left: calc(0px - ((100vw - 2rem) * 0.22) - 1rem);
	}
	button {
		position: absolute;
		top: 50%;
		right: -0.8rem;
		width: 0.8rem;
		height: 2.5rem;
		transform: translateY(-50%);
		color: var(--captionColor, #fff);
		background: transparent;
		border: 0;
		padding: 0;
		transition: width 0.2s ease, right 0.2s ease;
	}
	button:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		background: var(--captionColor, #fff);
		opacity: 0.15;
	}
	button svg {
		position: absolute;
		top: 50%;
		left: 0.2rem;
		width: 0.35rem;
		transform: translateY(-50%) rotate(-180deg);
		transition: left 0.2s ease;
	}
	button:hover,
	button:active,
	button:focus {
		outline: 0;
		color: var(--bgColor, #333);
		right: -1rem;
		width: 1rem;
	}
	button:hover:before,
	button:active:before,
	button:focus:before {
		opacity: 0.6;
	}
	button:hover svg,
	button:active svg,
	button:focus svg {
		left: 0.3rem;
	}
	.panel.docked button svg {
		transform: translateY(-50%) rotate(0);
	}

	/*@media (max-width: 940px) {*/
	@media (max-width: 1100px) {
		button {
			display: none;
		}
		.panel,
		.inner > div,
		.panel.docked,
		.panel.docked .inner > div {
			width: 100%;
			transform: none;
		}
		:global(.room-exhibition) .panel.docked,
		:global(.room-lobby) .panel.docked {
			margin-left: 0;
		}
	}

	/*@media (max-width: 940px) and (min-height: 600px) {*/
	@media (max-width: 1100px) and (min-height: 600px) {
		:global(.room-exhibition) button {
			display: block;
			left: 50%;
			top: -0.8rem;
			right: auto;
			height: 1rem;
			width: 2.5rem;
			transform: translateX(-50%);
			transition: height 0.2s ease, top 0.2s ease;
		}
		:global(.room-exhibition) button:before {
			border-bottom-right-radius: 0;
			border-top-left-radius: 6px;
		}
		:global(.room-exhibition) button svg {
			left: 50%;
			top: auto;
			bottom: 0.3rem;
			transform: translateX(-50%) rotate(90deg);
			transition: bottom 0.2s ease;
		}
		:global(.room-exhibition) button:hover,
		:global(.room-exhibition) button:active,
		:global(.room-exhibition) button:focus {
			right: auto;
			width: 2.5rem;
			top: -1rem;
			height: 1.2rem;
		}
		:global(.room-exhibition) button:hover svg,
		:global(.room-exhibition) button:active svg,
		:global(.room-exhibition) button:focus svg {
			left: 50%;
			bottom: 0.4rem;
		}
		:global(.room-exhibition) .panel.docked button svg {
			transform: translateX(-50%) rotate(-90deg);
		}

	}

	@media (max-width: 600px) {
		:global(.room-exhibition) button {
			display: none;
		}
	}

	@media (min-width: 600px) and (max-height: 500px) {
		.panel {
			min-height: calc(100vh - 6rem - 2rem);
			min-height: calc(100dvh - 6rem - 2rem);
		}
	}

	.more > :global(div) {
		position: absolute;
		inset: 0;
		overflow: auto;
		scrollbar-width: thin;
		/*padding: 1.4rem 1rem;*/
	}
	.tooltip {
		position: absolute;
		top: 50%;
		right: -1.8rem;
		transform: translate(100%, -50%);
		border-radius: 4px;
		box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.4);
		background: var(--panelColor);
		color: var(--textColor);
		padding: 0.4rem 0.8rem;
		line-height: 1;
		font-size: 0.6875rem;
		font-weight: 600;
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: 1000;
	}
	.tooltip:before {
		content: "";
		position: absolute;
		top: 50%;
		left: -0.3rem;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translateY(-50%);
		background: var(--panelColor);
		box-shadow: 0 3px 10px var(--shadow), 0 0 0 1px var(--blend-05);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--panelColor);
		z-index: -1;
		border-radius: 4px;
	}
</style>

{#if mounted}
	<div class="panel" class:docked={$leftDocked} class:deep={$deep} in:fly|local={{ delay: 100, duration: 800, x: -20, y: 20 }}>
		<div class="inner">
			<div class="main">
				<Nav/>
				<div>
					{#if $leftSection == 'agenda'}
						<Agenda/>
					{:else if $leftSection == 'sponsors'}
						<Sponsors/>
					{:else if $leftSection == 'attendees'}
						<Attendees on:deep={() => { deep = true }} on:back={() => { deep = false }}/>
					{:else if $leftSection == 'speakers'}
						<Speakers/>
					{/if}
				</div>
			</div>
			<div class="more" id="deepInfo">
			</div>
		</div>
		{#if $room != 'backstage'}
			<button
				transition:fade|local={{ duration: 400 }}
				use:autoblur
				on:click={() => { $leftDocked = !$leftDocked }}
				on:mouseover={() => { tooltip = true }}
				on:mouseout={() => { tooltip = false }}
				on:focus={() => { tooltip = true }}
				on:blur={() => { tooltip = false }}
			>
				<svg viewBox="0 0 16.7 29.5"><path d="M2 29.5c.5 0 1-.2 1.4-.6l12.7-12.7c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-12.7-12.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l11.3 11.3-11.3 11.3c-.8.8-.8 2 0 2.8.4.5.9.7 1.4.7z"/></svg>
			</button>
		{/if}
		{#if tooltip && $leftDocked}
			<span class="tooltip" in:fly|local={{ duration: 400, delay: 600, x: 5 }}>Expand side panel</span>
		{:else if tooltip}
			<span class="tooltip" in:fly|local={{ duration: 400, delay: 600, x: 5 }}>Collapse side panel</span>
		{/if}
	</div>
{/if}
