<script>

	import { fly } from "svelte/transition";

	import Sponsor from './Sponsors/Sponsor.svelte';

	import { event, sponsors } from '../lib/stores.js';

	// let whitebg = ($event.theme.colors[$event.theme.virtual['panelColor']] == '#ffffff') ? true : false;

</script>

<style>
	.sponsors {
		position: absolute;
		inset: 0;
		overflow: auto;
		scrollbar-width: thin;
	}
	.sponsors > div {
		margin: 1rem 1rem 2rem 1rem;
		border-top: 1px solid var(--blend-60);
		padding: 0.5rem 0;
	}
	h2 {
		margin: 0 0 1.5rem 0;
		font-size: 0.6875rem;
	}
	.sponsors > div > div {
		display: flex;
		flex-wrap: wrap;
		gap: 1.5rem;
		align-items: center;
	}
	.sponsors > div.boxed > div {
		gap: 1rem;
	}

</style>

<div class="sponsors" in:fly={{ duration: 300, y: 20 }}>
	{#if $sponsors}
		{#each $sponsors as g (g.ref)}
			<div class="{g.size}" class:boxed={g.logoStyle && (g.logoStyle == 'normal')}>
				<h2>{g.name.EN}</h2>
				<div>
					{#each g.sponsors as s (s.ref)}
						<!-- <Sponsor {s} size={g.size} {whitebg}/> -->
						<Sponsor {s} {g}/>
					{/each}
				</div>
			</div>
		{/each}
	{/if}
</div>
