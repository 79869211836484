<script>

	import { event } from '../lib/stores.js';

	let accountRef = $event.accountRef;
	let logo = $event.theme.virtual.logo;
	let logoStyle = $event.theme.virtual.logoStyle;
	let eventName = $event.name;

	let logoSvg = false;
	let mask = null;
	let wide = false;
	let extrawide = false;

	let aspectRatio = logo ? logo.aspectRatio : '0';

	if (logo) {

		// TODO: unsplash, unless we prohibit that

		logoSvg = (logo.format == 'svg') ? true : false;

		wide = false;
		extrawide = false;

		if (aspectRatio) {
			if (aspectRatio < 15) {
				extrawide = true;
			} else if (aspectRatio < 60) {
				wide = true;
			}
		}

		if (logoStyle == 'tinted') {
			if (logoSvg) {
				const regex = /\.svg$/;
				mask = 'https://cdn.attendzen.io/' + accountRef + '/mask_' + logo.filename.replace(regex, '.png');
			} else {
				const regex = /\.jpe?g$/;
				mask = 'https://cdn.attendzen.io/' + accountRef + '/mask_' + logo.filename.replace(regex, '.png');
			}
		} else {
			mask = null;
		}

	}

</script>

<style>

	.brand {
		box-sizing: border-box;
		position: relative;
		max-height: 3rem;
	}

	.brand img {
		max-width: 10rem;
		max-height: inherit;
	}

	.brand.wide {
		max-height: 2.5rem;
	}

	.brand.extrawide {
		max-height: 2rem;
	}

	.brand img.svg {
		height: 3rem;
	}

	.brand.wide img.svg {
		height: 2.5rem;
	}

	.brand.extrawide img.svg {
		height: 2rem;
	}

	@supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) {
		.mask:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			-webkit-mask-image: var(--mask);
			-webkit-mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 0 0;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: 0 0;
			background: var(--logoColor, #000);
			pointer-events: none;
			max-width: 10rem;
		}
		.mask > img {
			visibility: hidden;
		}
	}

</style>

{#if mask}
	<p class="brand mask" class:wide class:extrawide style="--mask:url('{mask}');--aspectRatio:{aspectRatio}%;">
		<img src="{mask}" alt="{eventName}"/>
	</p>
{:else}
	<p class="brand" class:wide class:extrawide style="--aspectRatio:{aspectRatio}%;">
		{#if logo && logoSvg}
			<img class="svg" src="https://cdn.attendzen.io/{accountRef}/{logo.filename}" alt="{eventName}"/>
		{:else if logo}
			<img src="https://cdn.attendzen.io/{accountRef}/small_{logo.filename}" alt="{eventName}"/>
		{:else}
			<span>{eventName}</span>
		{/if}
	</p>
{/if}