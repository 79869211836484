<script>
	
	import { fly } from 'svelte/transition';

	export let subtab;

	import { autoblur } from '../../../lib/autoblur.js';

</script>

<style>
	ul {
		flex: 0 0 auto;
		display: flex;
		justify-content: space-around;
		margin: 0;
		z-index: 1000;
		flex: 0 0 auto;
		background: var(--panelColor);
		padding: 0.25rem 0.75rem 0.75rem 0.75rem;
		box-shadow: 0 5px 6px var(--blend-05);
	}

	li {
		display: block;
		margin: 0;
		padding: 0 0.1rem;
		color: var(--textColor);
		text-align: center;
	}

	button {
		line-height: 1;
		padding: 0.4rem 0.5rem;
		margin: 0;
		border: 0;
		background: transparent;
		color: var(--textColor);
		text-align: center;
		width: 100%;
		cursor: pointer;
		outline: none;
		border-radius: 0.25rem;
		transition: background-color 0.4s ease;
	}

	li.current button {
		color: var(--accentBlend-120);
		background: var(--accentBlend-10);
	}

	button:hover,
	button:active {
		background: var(--blend-10);
	}

	button span {
		display: inline-block;
		font-size: 0.6875rem;
	}

</style>

<ul in:fly={{ duration: 300, y: -20 }}>
	<li class:current={subtab == 'banners'}>
		<button type="button" on:click={() => { subtab = 'banners' }} use:autoblur>
			<span>Banners</span>
		</button>
	</li>
	<li class:current={subtab == 'backgrounds'}>
		<button type="button" on:click={() => { subtab = 'backgrounds' }} use:autoblur>
			<span>Backgrounds</span>
		</button>
	</li>
	<li class:current={subtab == 'overlays'}>
		<button type="button" on:click={() => { subtab = 'overlays' }} use:autoblur>
			<span>Overlays</span>
		</button>
	</li>
	<li class:current={subtab == 'clips'}>
		<button type="button" on:click={() => { subtab = 'clips' }} use:autoblur>
			<span>Clips</span>
		</button>
	</li>
</ul>
