<script>

	import { onMount, tick } from 'svelte';

	import Session from './Auditorium/Session.svelte';
	import Trailer from './Auditorium/Trailer.svelte';

	import {	agenda, sessions, displayInEventTz, epoch, session, event, room, currentSessions, upcomingSessions } from './lib/stores.js';

	export let stageInteractions = true;

	let mounted = false;
	let sessionKey = null;

	onMount(() => {
		// console.log('$sessions',$sessions);
		// console.log('$currentSessions',$currentSessions);
		// console.log('$upcomingSessions',$upcomingSessions);
		mounted = true;
	});

	function setSessionKey() {

		let slug = '';

		if (($session.virtual.source == 'simulive') && $session.virtual.asset) {
			slug = $session.virtual.asset.playback;
		} else if (($session.virtual.source == 'live') && $session.virtual.livestream) {
			slug = $session.virtual.livestream;
		}

		sessionKey = $session.ref + '/' + $session.virtual.source + '/' + slug;

	}

	function checkEvent() {
		// console.log('checkEvent', $epoch, $event.epochEnds);
		if (!$session && ($epoch > $event.epochEnds)) {
			// You don't have to go home but you can't stay here
			$room = 'lobby';
		}
	}

	$: if ($epoch) {
		checkEvent();
	}

	$: if ($session) {
		setSessionKey();
	}

</script>


{#if mounted}
	{#if $session}

		{#key sessionKey}
			<Session bind:stageInteractions />
		{/key}

	{:else}

		{#key $displayInEventTz}
			{#if $currentSessions.length}
				<Trailer sessions={$currentSessions} isCurrent={true} bind:stageInteractions />
			{:else if $upcomingSessions.length && $sessions[$upcomingSessions[0]].length}
				<Trailer sessions={$sessions[$upcomingSessions[0]]} isCurrent={false} bind:stageInteractions />
			{/if}
		{/key}

	{/if}
{/if}

