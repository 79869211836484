<script>

	import { onMount, getContext } from 'svelte';
	import { fly } from "svelte/transition";

	import Portal from "svelte-portal";
	import latinize from 'latinize';

	import Speaker from "./Deep/Speaker.svelte";
	import Avatar from "../ui/Avatar.svelte";
	import Filter from "../ui/Filter.svelte";

	import {	speakers, event } from '../lib/stores.js';
	import { autoblur } from '../lib/autoblur.js';

	const deep = getContext('deep');

	let mounted = false;
	let syncMounted = false;
	let selectedSpeaker = null;
	let filterText = '';
	let displaySpeakers = [];

	onMount(() => {
		mounted = true;
	});

	function selectSpeaker(s) {
		selectedSpeaker = s;
		$deep = true;
	}

	let rowTimeout;

	function setDisplaySpeakers() {
		clearTimeout(rowTimeout);
		rowTimeout = setTimeout(function(){
			displaySpeakers = ($speakers && $speakers.length) ? $speakers.filter(filterByValue) : [];
		}, 100);
	}

	function filterByValue(o) {
		if (!filterText) return true;
		return Object.keys(o).some(f => (f == 'n' || f == 'o') && filterValue(o[f]).includes(filterText));
	}

	function filterValue(v) {
		return v ? latinize(v).toString().toLowerCase() : '';
	}

	$: if ($speakers || filterText) {
		setDisplaySpeakers();
	}

	$: filterText = filterText && latinize(filterText).toLowerCase();
	
</script>

<style>
	.speakers {
		position: absolute;
		inset: 0;
		overflow: auto;
		scrollbar-width: thin;
		padding: 0.2rem 0 0.5rem 0;
	}

	.bar {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		padding: 0 1rem;
	}

	.list > p {
		position: relative;
		padding: 0.3rem 0.4rem 0.3rem 3rem;
		font-size: 0.6875rem;
		line-height: 1.4;
		margin: 0.5rem;
		border-radius: 4px;
		cursor: pointer;
	}

	.list > p:hover {
		background: var(--blend-05);
	}

	.list > p + p {
		margin-top: 0.5rem;
	}

	.list :global(.avatar) {
		left: 0.8rem;
		top: 0.4rem;
	}

</style>

{#if mounted}
	<div class="speakers" in:fly={{ duration: 300, y: 20 }}>
		<div class="bar">
			<Filter bind:filterText icon={false}/>
		</div>
		<div class="list">
			{#each displaySpeakers as s}
				<!-- TODO: nest a button instead -->
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<p on:click={selectSpeaker(s)}>
					<Avatar identity={s}/>
					<strong>{s.n}</strong>
					{#if s.o}<br/>{s.o}{/if}
				</p>
			{/each}
		</div>
	</div>
{/if}

{#if selectedSpeaker}
	<Portal target="#deepInfo">
		<Speaker speaker={selectedSpeaker} on:back={() => { selectedSpeaker = null }}/>
	</Portal>
{/if}
