<script>

	import { onMount } from 'svelte';
	import { fade } from "svelte/transition";

	import fadeScale from 'svelte-transitions-fade-scale';

	import Button from "../../ui/Button.svelte";
	import Timer from '../../ui/Timer.svelte';

	import {	displayInEventTz, epoch, session, event } from '../../lib/stores.js';
	import {	showDate } from '../../lib/dt.js';

	// export let showRemaining = true;
	export let control = {};
	export let controlPanel = false;
	export let mod = false;
	export let livestreamStatus = null;
	export let useAutoCut = false;
	export let spot = [];

	let sessionDuration = $session.epochEnds - $session.epochStarts;

	let remainingLabel = '0s';
	let remainingPercent = 0;

	$: if ($session && ($session.epochEnds >= $epoch)) setRemaining();

	function setRemaining() {

		const secondsRemaining = $session.epochEnds - $epoch;

		if (secondsRemaining < 60) {
			remainingLabel = secondsRemaining + 's';
		} else {
			const minutesRemaining = secondsRemaining / 60;
			remainingLabel = Math.ceil(minutesRemaining) + 'm';
		}

		remainingPercent = Math.ceil((secondsRemaining / sessionDuration) * 100);

	}

</script>

<style>

	.session {
		flex: 0 0 auto;
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin: 0 auto;
		/*max-width: calc((100vh - 16rem) * 2.1);
		max-width: calc((100dvh - 16rem) * 2.1);*/
		gap: 1rem;
		padding: 0 0.2rem 0.5rem 0.2rem;
		max-width: var(--sw);
		box-sizing: border-box;
	}
	.session > div {
		flex: 1 1 auto;
	}
	h1 {
		font-weight: 700;
		font-style: normal;
		font-size: 1.25rem;
		line-height: 1.2;
		max-width: 90%;
		margin-bottom: 0.4rem;
		color: var(--captionColor);
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}
	h1.long {
		font-size: 1rem;
	}
	.times {
		display: flex;
		align-items: center;
		position: relative;
		top: 0.1rem;
		color: var(--captionColor);
	}
	.times svg {
		width: 0.8em;
		height: 0.8em;
		margin-right: 0.5em;
	}
	.times span {
		font-weight: 400;
		font-style: normal;
		font-size: 0.6875rem;;
	}
	.remaining {
		flex: 0 1 auto;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		font-size: 0.6875rem;;
		color: var(--captionColor);
		margin-top: 0.1rem;
	}
	.remaining div {
		text-align: right;
		width: 6rem;
		padding-top: 0.6rem;
		position: relative;
	}
	.remaining div:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 0.3rem;
		background: var(--captionColor);
		opacity: 0.4;
		border-radius: 100px;
	}
	.remaining div:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: var(--remainingPercent);
		height: 0.3rem;
		background: var(--captionColor);
		border-radius: 100px;
	}

	/*@media (max-width: 1100px) {
		.session {
			max-width: calc((100vh - 12rem) * 2.1);
		}
	}*/

	/*@media (max-width: 1100px) and (min-height: 500px) {
		.session {
			max-width: calc(100vh - 18rem);
			max-width: calc(100dvh - 18rem);
		}
	}

	@media (max-width: 1100px) and (max-height: 500px) {
		.session {
			max-width: calc((100vh - 10rem) * 2.1);
			max-width: calc((100dvh - 10rem) * 2.1);
		}
	}*/

	.session .controlpanel {
		flex: 0 0 auto;
		display: flex;
		gap: 1rem;
		align-items: center;
		justify-content: flex-end;
		padding-top: 0.2rem;
	}

	.controlpanel p {
		color: var(--captionColor);
		font-size: 0.6875rem;
		text-align: right;
	}

	.controlpanel :global(span.timer) {
		font-size: 1rem;
	}

	.controlpanel :global(button.icon) {
		border-radius: 100%;
		padding: 0;
		width: 2rem;
		height: 2rem;
		position: relative;
		box-shadow: 0 0 0 1px var(--captionColor);
		transition: transform 0.2s ease;
		background-color: var(--panelColor);
		color: var(--textColor);
		border-color: var(--panelColor);
	}
	.controlpanel :global(button:hover),
	.controlpanel :global(button:active),
	.controlpanel :global(button:focus) {
		background-color: var(--panelColor);
		color: var(--textColor);
		border-color: var(--panelColor);
		transform: scale(1.1);
	}
	.controlpanel :global(button svg) {
		width: 1.8rem;
		height: 1.8rem;
	}

	.spot {
		position: absolute;
		bottom: -0.2rem;
		right: -0.1rem;
		background: var(--red);
		color: var(--panelColor);
		font-size: 0.6rem;
		line-height: 0;
		min-width: 0.8rem;
		padding: 0 0.2rem;
		box-sizing: border-box;
		height: 0.8rem;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.8rem;
		text-shadow: 0 0 2px rgba(0,0,0,0.2);
		box-shadow: 0 0 0 1px var(--captionColor);
	}

</style>

{#if $session}
	<div class="session" in:fade={{ duration: 400 }}>
		<div>
			<h1 class:long={$session.name.length > 100}>{$session.name}</h1>
			<p class="times">
				<svg viewBox="0 0 48.1 48.1"><path d="M24.1 48.1c-13.3 0-24.1-10.8-24.1-24 0-13.3 10.8-24.1 24.1-24.1s24.1 10.8 24.1 24.1-10.9 24-24.1 24zm0-44.1c-11.1 0-20.1 9-20.1 20.1s9 20.1 20.1 20.1 20.1-9 20.1-20.1-9.1-20.1-20.1-20.1zM32.6 22.5h-6.5v-11.6c0-1.1-.9-2-2-2s-2 .9-2 2v13.5c0 1.1.9 2 2 2h8.5c1.1 0 2-.9 2-2s-.9-1.9-2-1.9z"/></svg>
				{#key $displayInEventTz}
					<span>{showDate($session.starts, 'dddd DD, HH:mm')}–{showDate($session.ends, 'HH:mm')}</span>
				{/key}
			</p>
		</div>
		{#if mod}
			<div class="controlpanel">
				<p>
					{#if useAutoCut && !control.cut && (livestreamStatus == 'active') && ($epoch > $session.epochEnds)}
						{#key control.autoCut}
							<span>Video cuts in:</span>
							<br/><Timer duration={($session.epochEnds + control.autoCut) - $epoch} />
						{/key}
					{:else}
						<span>Session ends in:</span>
						<br/><Timer duration={$session.epochEnds - $epoch} />
					{/if}
				</p>
				<Button on:click={() => { controlPanel = true }}>
					<svg viewBox="0 0 80 80"><path d="M15 38.8h50v22.19c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-22.19zm29.33 12l3.73-8h-6.98l-3.73 8h-6.72l3.73-8h-7.12l-3.73 8h-4.51v8.2c0 1.1.9 2 2 2h38c1.1 0 2-.9 2-2v-16.2h-6.13l-3.73 8h-6.81zM12.92 26.99c-.38-2.18 1.07-4.25 3.24-4.63l41.36-7.29c2.18-.38 4.25 1.07 4.63 3.24l2.08 11.81-49.23 8.68-2.08-11.81zm33.94 2.13l-5.06-7.23-6.87 1.21 5.06 7.23 6.87-1.21zm-13.62 2.4l-5.06-7.23-6.88 1.21 5.06 7.23 6.88-1.21zm26.37-4.65l-1.04-5.91c-.19-1.09-1.23-1.81-2.32-1.62l-7.54 1.33 5.11 7.22 5.89-1.04"/></svg>
					{#if spot.length}
						<span class="spot" transition:fadeScale|local={{ duration: 300, baseScale: 0.8 }}>
							{spot.length}
						</span>
					{/if}
				</Button>
			</div>
		{:else}
			{#if $epoch > $session.epochStarts}
				{#if $event.setup.virtual.showTimer}
					<div class="remaining" style="--remainingPercent:{remainingPercent}%">
						<div>
							<strong>Time left:</strong> {remainingLabel}
						</div>
					</div>
				{/if}
			{/if}
		{/if}
	</div>
{/if}