<script>

	import { onMount, onDestroy } from 'svelte';
	import { writable } from 'svelte/store';

	import Avatar from "../../../ui/Avatar.svelte";

	import { attendees } from '../../../lib/stores.js';
	import { pollAudioLevel } from '../../../lib/pollAudioLevel.js';

	export let attendeeId;
	export let audioTrack;
	export let invisible;
	export let screenShare;
	export let audioLevel = 0;

	let active = writable(true);

	onMount(() => {
		// console.log({audioTrack});
		if (invisible && audioTrack && !screenShare) {
			let t = audioTrack.track ? audioTrack.track : audioTrack;
			pollAudioLevel(t, active, level => {
				// console.log({level});
				if (level > 6) {
					audioLevel = level;
				} else if (level > 3) {
					audioLevel = level / 2;
				} else {
					audioLevel = 0;
				}
			});
		}
	});

	onDestroy(() => {
		$active = false;
	});

</script>

<Avatar identity={$attendees[attendeeId]} hasMenu={false} />