<script>

	import { onMount, getContext } from 'svelte';

	import Button from '../ui/Button.svelte';

	import {	displayInEventTz, epoch } from '../lib/stores.js';
	import {	showDate } from '../lib/dt.js';

	const session = getContext('session');

</script>

<style>
	.outer {
		grid-area: cl;
	}
	.track {
		color: var(--captionColor);
		font-size: 0.6875rem;
	}
	h1 {
		font-weight: 700;
		font-style: normal;
		font-size: 1.25rem;
		line-height: 1.2;
		max-width: 65%;
		margin-bottom: 0.4rem;
		color: var(--captionColor);
		margin-top: 0.4rem;
	}
	.times {
		display: flex;
		align-items: center;
		position: relative;
		top: 0.1rem;
		color: var(--captionColor);
	}
	.times svg {
		width: 0.8em;
		height: 0.8em;
		margin-right: 0.5em;
	}
	.times span {
		font-weight: 400;
		font-style: normal;
		font-size: 0.6875rem;
	}

	.remaining {
		margin: 2rem 0 0 0;
		display: flex;
		justify-content: space-between;
		color: var(--captionColor);
	}

	.remaining + .remaining {
		margin-top: 1rem;
	}

	.small {
		font-size: 0.6875rem;;
	}

	.actions {
		margin-top: 1.5rem;
		display: flex;
		gap: 1rem;
	}

	.ok:before {
		content: '';
		display: inline-block;
		background: var(--accentColor);
		width: 0.7em;
		height: 0.7em;
		border-radius: 100px;
		margin-right: 0.6em;
		vertical-align: middle;
		position: relative;
		top: -1px;
	}

	.lined {
		border-top: 1px solid var(--captionColor);
		padding-top: 2rem;
	}

	.actions + .actions.lined {
		margin-top: 2.5rem;
	}

</style>

<div class="outer">

	<p class="track">Track 2</p>

	<h1>{$session.name}</h1>

	<p class="times">
		<svg viewBox="0 0 48.1 48.1"><path d="M24.1 48.1c-13.3 0-24.1-10.8-24.1-24 0-13.3 10.8-24.1 24.1-24.1s24.1 10.8 24.1 24.1-10.9 24-24.1 24zm0-44.1c-11.1 0-20.1 9-20.1 20.1s9 20.1 20.1 20.1 20.1-9 20.1-20.1-9.1-20.1-20.1-20.1zM32.6 22.5h-6.5v-11.6c0-1.1-.9-2-2-2s-2 .9-2 2v13.5c0 1.1.9 2 2 2h8.5c1.1 0 2-.9 2-2s-.9-1.9-2-1.9z"/></svg>
		{#key $displayInEventTz}
			<span>{showDate($session.starts, 'dddd DD, HH:mm')}–{showDate($session.ends, 'HH:mm')}</span>
		{/key}
	</p>

	<p class="remaining"><span>Session ends in:</span> <strong>08:56</strong></p>
	<p class="remaining small"><span>Video cuts in:</span> <strong>12:56</strong></p>

	<p class="actions"><Button label="Cut video"/> <Button label="Extend video"/></p>

	<p class="remaining lined"><span>Live stream status:</span> <strong class="ok">Active</strong></p>

	<p class="actions"><Button ghost={false} label="Regenerate stream key"/> <Button ghost={false} label="Copy RTMP URL"/></p>

	<p class="actions lined"><Button ghost={false} label="Edit agenda"/> <Button ghost={false} label="Virtual settings"/></p>

</div>