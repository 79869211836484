import { colord, extend } from "colord";
import a11yPlugin from "colord/plugins/a11y";

extend([a11yPlugin]);

export const bestContrastHex = (basis, candidate1, candidate2, candidate3) => {

	let brightness1 = colord(candidate1).brightness();
	let brightness2 = colord(candidate2).brightness();

	const contrast1 = colord(basis).contrast(candidate1);
	const contrast2 = colord(basis).contrast(candidate2);

	let winner;

	if ((contrast1 < 2) || (contrast2 < 2)) {
		winner = (contrast2 > contrast1) ? candidate2 : candidate1;
	} else if (brightness2 > brightness1) {
		winner = (contrast1 > (contrast2 + 4)) ? candidate1 : candidate2;
	} else {
		winner = (contrast2 > (contrast1 + 4)) ? candidate2 : candidate1;
	}

	if (candidate3 && (candidate3 != candidate1 && candidate3 != candidate2)) {
		return bestContrastHex(basis, winner, candidate3);
	} else {
		return winner;
	}

};