<script>

	export let name = 'filter';
	export let filterText = '';
	export let placeholder = 'Search …';
	export let icon = true;

	function handleKeydown(event){
		if (event.key === 'Escape') {
			filterText = '';
		}
	}

</script>

<style>

	span {
		display: block;
		position: relative;
	}

	input {
		display: block;
		width: 100%;
		box-sizing: border-box;
		padding: 0.4rem 0.4rem;
		border-radius: 4px;
		background: var(--blend-10);
		border: none;
		color: var(--textColor);
		font-size: 0.6875rem;
	}

	input.icon {
		padding-left: 1.8rem;
	}

	input:focus {
		outline: none;
		box-shadow: inset 0 0 1px var(--textColor);
	}

	label {
		position: absolute;
		top: 0.2rem;
		left: 0.3rem;
		width: 1.2rem;
		height: 1.2rem;
		color: var(--blend-80);
	}

	label svg {
		position: absolute;
		inset: 0;
	}

</style>

<svelte:window on:keydown|stopPropagation={handleKeydown}/>

<span>
	{#if icon}
		<label for={name}><svg viewBox="0 0 80 80"><title>Search</title><path d="M62.05 56.1l-7.55-7.55c-.95-.95-2.26-1.32-3.5-1.11l1.03-1.03-3.14-3.14c1.63-2.64 2.59-5.73 2.59-9.06 0-9.54-7.76-17.3-17.3-17.3-9.54 0-17.3 7.76-17.3 17.3s7.76 17.3 17.3 17.3c3.32 0 6.42-.96 9.05-2.59l3.14 3.14 1.36-1.36c-.21 1.23.16 2.54 1.11 3.5l7.55 7.55c.78.78 1.8 1.17 2.83 1.17s2.05-.39 2.83-1.17c1.56-1.56 1.56-4.09 0-5.65zm-41.17-21.89c0-7.33 5.97-13.3 13.3-13.3s13.3 5.97 13.3 13.3c0 7.33-5.97 13.3-13.3 13.3s-13.3-5.96-13.3-13.3z"/></svg></label>
	{/if}
	<input id={name} class:icon type="text" {placeholder} bind:value={filterText} />
</span>