<script>

	import { onMount, createEventDispatcher } from 'svelte';
	
	import Field from "../../ui/Field.svelte";
	import Button from "../../ui/Button.svelte";

	import {	event } from '../../lib/stores.js';

	const dispatch = createEventDispatcher();

	export let attendee;
	export let ejectMessage;

	onMount(() => {
		if (!ejectMessage) ejectMessage = 'You have been ejected from this event by the organisers because your conduct has been deemed disorderly, disruptive or not in keeping with the purpose of the event.';
	});

</script>

<style>
	.overlay-main .ejectee {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 2rem;
	}
	.overlay-main .ejectee .avatar {
		margin: 0;
	}

	.overlay-main .instructions {
		font-size: 0.6875rem;
		margin: 0.5rem 0 0 0;
		line-height: 1.4;
	}

	.overlay-main .instructions + form {
		margin: 1.5rem 0 0 0;
	}

	.avatar {
		display: block;
		width: 4rem;
		height: 4rem;
		position: relative;
		margin-bottom: 1rem;
	}
	.avatar img,
	.avatar abbr {
		border-radius: 100%;
	}
	.avatar abbr {
		position: absolute;
		inset: 0;
		display: grid;
		place-content: center;
		border-radius: 100px;
		background: var(--textColor);
		color: var(--panelColor);
		line-height: 0;
		text-decoration: none;
		border: 0;
		font-size: 1.6rem;
		font-weight: 200;
		user-select: none;
	}
</style>

<div class="overlay-title">
	<h2>Eject attendee</h2>
</div>

<div class="overlay-main">
	<div class="ejectee">
		<div class="avatar">
			{#if attendee.a}
				<img src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{attendee.a}" alt=""/>
			{:else}
				<abbr title="{attendee.f} {attendee.l}">{attendee.f.charAt(0).toUpperCase()}{attendee.l.charAt(0).toUpperCase()}</abbr>
			{/if}
		</div>
		<div>
			<strong>{attendee.f} {attendee.l}</strong>
			{#if attendee.j}<br/>{attendee.j}{/if}
			{#if attendee.o}<br/>{attendee.o}{/if}
		</div>
	</div>
	<p class="instructions">Ejecting this attendee will immediately suspend their access to every part of this virtual event. As soon as you eject the attendee, they will see the message below. The attendee will be prevented from logging back in to the event.</p>
	<form id="overlay-eject" on:submit|preventDefault={() => { dispatch('confirm') }}>
		<Field
			bind:value={ejectMessage}
			component="TextArea"
			label="Message to attendee (click to edit)"
		/>
	</form>
</div>

<div class="overlay-actions">
	<Button label="Eject attendee" red={true} type="submit" form="overlay-eject" />
	<Button label="Cancel" ghost={true} on:click={() => { dispatch('escape') }}/>
</div>