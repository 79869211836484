<script>

	import { onMount, getContext } from 'svelte';
	import { fade } from "svelte/transition";

	import { event, room, session } from "../../../lib/stores.js";

	const background = getContext('background');
	const backgrounds = getContext('backgrounds');

	let image = null;
	let fadeDuration = 0;
	let format;

	onMount(() => {
		let img = null;
		if ($background) {
			if ($event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.backgrounds) {
				for (const b of $event.setup.virtual.studio.backgrounds) {
					if (b.content.ref == $background) {
						img = b.content;
						break;
					}
				}
			}
			if (!img) {
				for (const b of $backgrounds) {
					if (b.ref == $background) {
						img = b;
						break;
					}
				}
			}
		}
		if (img) {
			format = img.format;
			image = img;
		}
	});

	$: fadeDuration = ($room && $session) ? 200 : 0;

</script>

<style>
	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		object-fit: cover;
	}
</style>

{#if format == 'svg'}
	{#if image}
		<img
			class="background"
			src="https://cdn.attendzen.io/{$event.accountRef}/{image.filename}"
			alt=""
			transition:fade|local={{ duration: fadeDuration }}
		/>
	{/if}
{:else}
	{#if image}
		<img
			class="background"
			style="object-position: {image.focalPointX}% {image.focalPointY}%;"
			src="https://cdn.attendzen.io/{$event.accountRef}/max_{image.filename}"
			alt=""
			transition:fade|local={{ duration: fadeDuration }}
		/>
	{/if}
{/if}