<script>

	import { onMount, createEventDispatcher } from 'svelte';

	import AttendeeMenu from './AttendeeMenu.svelte';

	import {	event } from '../lib/stores.js';

	const dispatch = createEventDispatcher();

	export let identity = {};
	export let profile = null;
	export let hasMenu = false;
	export let anon = false;
	export let fixed = false;

	export const closeMenu = () => { menuOpen = false };
	
	let mounted = false;
	let menuOpen = false;

	let filename;

	function handleClick(e) {
		if (hasMenu) menuOpen = !menuOpen;
		dispatch('click', e);
	}

	onMount(() => {

		initAvatar();

		// console.log('avatar - identity', identity);

		mounted = true;

	});

	function initAvatar() {

		if (identity.avatar && identity.avatar.filename) {
			filename = identity.avatar.filename;
		} else if (identity.a) {
			filename = identity.a;
		} else {
			filename = undefined;
		}

		if ((!identity.f || !identity.l) && !filename) {
			anon = true;
		}
	
	}

	function emitOpenClose(m) {
		if (mounted) {
			if (m) {
				dispatch('open');
			} else {
				dispatch('close');
			}
		}
	}

	$: emitOpenClose(menuOpen);

	$: if (identity) initAvatar();

</script>

<style>

	div {
		position: absolute;
		top: 0.4rem;
		left: 1.3rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	.hasMenu {
		cursor: pointer;
	}

	img, abbr, .anon {
		position: absolute;
		inset: 0;
		border-radius: 100%;
	}

	abbr {
		display: grid;
		place-content: center;
		background: var(--textColor);
		color: var(--panelColor);
		line-height: 0;
		text-decoration: none;
		border: 0;
		font-size: 0.6rem;
		user-select: none;
	}

	.anon {
		background: var(--textColor);
		color: var(--panelColor);
	}

	.anon svg {
		position: absolute;
		inset: 0;
	}

	div :global(.menu) {
		position: absolute;
		left: 2.2rem;
		top: 0;
	}

	div.fixed :global(.menu) {
		position: fixed;
		margin-left: 2.2rem;
		margin-top: -0.25rem;
		top: auto;
		left: auto;
		bottom: auto;
		transform: translateY(-100%);
	}

	div :global(.menu.arrow:before) {
		top: 0.7rem !important;
		left: -0.4rem !important;
	}

</style>

{#if mounted}
	<div class="avatar" class:hasMenu class:fixed>
		{#if anon}
			<!-- TODO: nest a button instead -->
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<span class="anon" on:click|stopPropagation={handleClick}>
				<svg viewBox="0 0 25 25"><path d="M12.5 14.01c-1.99 0-3.61-1.62-3.61-3.62 0-1.99 1.62-3.62 3.61-3.62 1.99 0 3.62 1.62 3.62 3.62s-1.63 3.62-3.62 3.62zm0-6.23c-1.44 0-2.61 1.17-2.61 2.62 0 1.44 1.17 2.62 2.61 2.62s2.62-1.17 2.62-2.62c0-1.45-1.18-2.62-2.62-2.62zM10.44 16.02h4.13c1.79 0 2.87 1.34 3.24 2.2h1.06c-.35-1.24-1.83-3.2-4.3-3.2h-4.13c-2.47 0-3.95 1.96-4.31 3.2h1.06c.38-.86 1.46-2.2 3.25-2.2z"/></svg>
			</span>
		{:else}
			{#if identity}
				{#if filename}
					<!-- TODO: nest a button instead -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<img
						src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{filename}"
						alt="{identity.f} {identity.l}"
						title="{identity.f} {identity.l}"
						on:click|stopPropagation={handleClick}
					/>
				{:else}
					<!-- TODO: nest a button instead -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<abbr
						title="{identity.f} {identity.l}"
						on:click|stopPropagation={handleClick}
					>
						{identity.f.charAt(0).toUpperCase()}{identity.l.charAt(0).toUpperCase()}
					</abbr>
				{/if}
			{/if}
		{/if}
		{#if hasMenu}
			<AttendeeMenu
				attendee={identity}
				{profile}
				{menuOpen}
				on:escape={() => { menuOpen = false }}
			/>
		{/if}
	</div>
{/if}