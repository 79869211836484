<script>

	import { createEventDispatcher, getContext } from 'svelte';

	const deep = getContext('deep');
	const dispatch = createEventDispatcher();

	function goBack() {
		$deep = false;
		setTimeout(function(){
			dispatch('back');
		}, 200);
	}

</script>

<style>
	p {
		padding-bottom: 1rem;
	}
	button {
		position: relative;
		top: 0.9rem;
		left: 1rem;
		border: 0;
		background: var(--blend-10);
		border-radius: 100px;
		color: var(--textColor);
		font-size: 0.8rem;
		/*gap: 0.5rem;*/
		/*align-items: center;*/
		padding: 0;
		width: 1.6rem;
		height: 1.6rem;
	}
	button svg {
		position: absolute;
		inset: 0;
	}
	button:hover {
		background: var(--accentBlend-20);
		color: var(--accentBlend-120);
	}
</style>

<p>
	<button type="button" on:click={goBack}>
		<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
	</button>
</p>