<script>

	import { onMount, onDestroy, getContext, tick } from 'svelte';

	import { ParticipantEvent, Track } from 'livekit-client';

	import { attendee } from '../../../lib/stores.js';

	const room = getContext('room');

	export let participant;

	let mounted = false;
	let screenShare = false;
	let attendeeId;
	let feed;
	let audioTrack;
	let audioElement;
	let subscribed = false;

	let sid = '';

	onMount(async () => {
		mounted = true;
	});

	onDestroy(() => {
		if (audioTrack) {
			if (audioTrack.track && audioElement) {
				audioTrack.track.detach(audioElement);
			}
		}
		if (feed) {
			feed.removeListener(ParticipantEvent.TrackSubscribed, trackSubscribed);
		}
	});

	async function rtcTracks() {

		await tick();

		if (!feed) {
			if ((participant.id != $attendee.ref) && (participant.id.substring(2) != $attendee.ref)) {

				if (participant.id.startsWith('s_')) {
					screenShare = true;
					attendeeId = participant.id.substring(2);
				} else {
					attendeeId = participant.id;
				}

				feed = $room.getParticipantByIdentity(attendeeId);

				if (feed) {

					feed.on(ParticipantEvent.TrackSubscribed, trackSubscribed);

					let publications = Array.from(feed.trackPublications.values());

					publications.forEach(t => {
						trackInit(t);
					});

				}

			}
		}

	}

	async function trackInit(track) {
		if ((screenShare && (track.source == "screen_share_audio")) || (!screenShare && (track.source == "microphone"))) {
			audioTrack = track;
			audioTrack.setEnabled(false);
			if (audioTrack.isSubscribed) {
				trackSubscribed(audioTrack.track);
			}
		}
	};

	async function trackSubscribed(track) {
		if ((screenShare && (track.source == "screen_share_audio")) || (!screenShare && (track.source == "microphone"))) {
			subscribed = true;
			await tick();
			track.attach(audioElement);
		}
	};

	async function setMuted() {
		if (participant.o && !participant.m) {
			audioTrack.setEnabled(true);
		} else {
			audioTrack.setEnabled(false);
		}
	}

	function checkSid() {
		if (participant.t != sid) {
			sid = participant.t;
			if (mounted && $room) {
				rtcTracks();
			}
		}
	}

	$: if (mounted && $room) {
		rtcTracks();
	}

	$: checkSid(participant);

	$: if (participant && audioElement && audioTrack && subscribed) {
		setMuted();
	}

</script>

{#key sid}
	<audio id={'audio-' + participant.id} bind:this={audioElement} />
{/key}
