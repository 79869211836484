<script>

	import { onMount } from "svelte";
	import { fade } from 'svelte/transition';

	import { colord } from "colord";

	import Overlay from "./ui/Overlay.svelte";
	import Button from "./ui/Button.svelte";

	import Room from './VideoCall/Room.svelte';

	import { videoCall, attendees, event } from './lib/stores.js';
	import { callStatus } from './lib/conversations.js';

	let vc;
	let muted = false;
	let invisible = false;

	let tc = $event.theme.colors[$event.theme.virtual.textColor];
	let hsl = colord(tc).toHsl();
	hsl.l = '10';

	let dark = colord(hsl).toRgbString();
	let gradTop = colord(hsl).alpha(0.8).toRgbString();
	let gradBottom = colord(hsl).alpha(0).toRgbString();

	function setCallData() {
		// console.log('setCallData');
		vc = JSON.parse(JSON.stringify($videoCall));
	}

	function endCall() {
		callStatus($videoCall.call, 'ended');
		$videoCall = null;
	}

	$: if ($videoCall) {
		setCallData();
	}

</script>

<style>
	/*.overlay-title {
		padding: 1.4rem;
	}
	.overlay-title p {
		padding-right: 12vw;
	}
	.overlay-main {
		background: var(--panelColor);
		padding: 0;
	}
	.overlay-actions {
		justify-content: center;
	}
	.overlay-actions :global(button) {
		border-radius: 100%;
		padding: 0;
		width: 2.4rem;
		height: 2.4rem;
		position: relative;
	}
	.overlay-actions :global(button svg) {
		width: 1.8rem;
		height: 1.8rem;
	}
	.overlay-actions :global(button.ghost) {
		color: var(--blend-80);
		border: 0;
		background: var(--blend-05);
	}
	.overlay-actions :global(button.ghost.red) {
		background: var(--blend-05);
	}
	.overlay-actions :global(button.ghost.red:before) {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		transform: rotate(-45deg);
		transform-origin: center center;
		background: var(--red);
	}
	.overlay-actions :global(button.ghost.red:after) {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 2px;
		transform: rotate(-45deg) translateY(1px);
		transform-origin: center center;
		background: var(--blend-05);
	}
	.overlay-actions :global(button.ghost:hover),
	.overlay-actions :global(button.ghost:active),
	.overlay-actions :global(button.ghost:focus) {
		color: var(--red-120);
		border: 0;
		background: var(--blend-05);
	}
	.overlay-actions :global(button.ghost.red:hover),
	.overlay-actions :global(button.ghost.red:active),
	.overlay-actions :global(button.ghost.red:focus) {
		color: var(--red-120);
		border: 0;
		background: var(--blend-05);
	}
	.overlay-actions :global(button.ghost.red:hover:before),
	.overlay-actions :global(button.ghost.red:focus:before),
	.overlay-actions :global(button.ghost.red:active:before) {
		background: var(--red-120);
	}*/

	.videocall {
		width: calc((85vh - 6rem) * (4 / 3));
		max-width: 85vw;
		aspect-ratio: 4 / 3;
		min-height: 21rem;
		background: var(--blend-120);
		border-radius: 8px;
		overflow: hidden;
	}

	@media (max-height: 600px) {
		.videocall {
			width: calc(90vh * (4 / 3));
		}
	}

	.vc-title {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		color: #fff;
		background: linear-gradient(to bottom, var(--gradTop, rgba(16, 24, 32, 0.8)) 0%, var(--gradBottom, rgba(16, 24, 32, 0)) 100%);
		text-align: center;
		padding: 1rem 1rem 2.4rem 1rem;
		box-sizing: border-box;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		font-size: 1.2rem;
	}

	.vc-actions {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		padding: 1.4rem 1rem;
		display: flex;
		justify-content: center;
		gap: 0.8rem;
		background: var(--gradTop, rgba(16, 24, 32, 0.8));
		box-sizing: border-box;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.vc-actions :global(button) {
		border-radius: 100%;
		padding: 0;
		width: 2.4rem;
		height: 2.4rem;
		position: relative;
		transition: transform 0.2s ease, opacity 0.2s ease;
	}
	.vc-actions :global(button svg) {
		/*width: 2rem;
		height: 2rem;*/
		position: absolute;
		inset: 0;
		width: auto;
		height: auto;
	}

	.vc-actions :global(button:hover),
	.vc-actions :global(button:active),
	.vc-actions :global(button:focus) {
		transform: scale(1.15);
	}

	.vc-actions :global(button.red:hover),
	.vc-actions :global(button.red:active),
	.vc-actions :global(button.red:focus) {
		background: var(--red);
	}

	.vc-actions :global(button.ghost),
	.vc-actions :global(button.ghost:hover),
	.vc-actions :global(button.ghost:active),
	.vc-actions :global(button.ghost:focus) {
		color: var(--dark, #101820);
		border: 0;
		background: #fff;
	}
	.vc-actions :global(button.ghost.red),
	.vc-actions :global(button.ghost.red:hover),
	.vc-actions :global(button.ghost.red:active),
	.vc-actions :global(button.ghost.red:focus) {
		color: var(--dark, #101820);
		border: 0;
		background: #fff;
		opacity: 0.7;
	}

</style>

{#if $videoCall}
	<Overlay
		wide={true}
		modal={true}
		isVideoCall={true}
	>
		<div class="videocall" style="--dark:{dark};--gradTop:{gradTop};--gradBottom:{gradBottom};">
			<p class="vc-title">
				<strong>{$attendees[vc.participant].f} {$attendees[vc.participant].l}</strong>
				{#if $attendees[vc.participant].j}<br/>{$attendees[vc.participant].j}{/if}
				{#if $attendees[vc.participant].o}<br/>{$attendees[vc.participant].o}{/if}
			</p>
			{#if vc}
				<Room {vc} name={$attendees[vc.participant].f} {muted} {invisible}/>
			{/if}
			<div class="vc-actions">
				<Button grow={false} ghost={true} red={invisible} on:click={() => { invisible = !invisible }}>
					<svg viewBox="0 0 48 48">
						<title>Camera</title>
						{#if invisible}
							<path d="M14.81 21.91c-.73 0-1.31-.59-1.31-1.31 0-.73.59-1.31 1.31-1.31.73 0 1.31.59 1.31 1.31.01.72-.58 1.31-1.31 1.31zM38.12 16.7c-.22-.14-.5-.15-.73-.03l-5.76 2.88h-4.03v-1.35c0-1.46-1.02-2.61-2.33-2.61h-6.85l2 2h4.19c.55 0 1 .45 1 1v4.19l2.52 2.52v-3.75h2.44v4.9h-1.29l2 2h.37l5.76 2.88c.11.05.22.08.33.08.14 0 .27-.04.39-.11.22-.14.36-.38.36-.64v-13.32c-.01-.26-.15-.5-.37-.64zm-5.62 9.93l-.02-5.27 4-2 .02 9.29-4-2.02zM10.56 10.56l-1.41 1.41 3.61 3.61h-.9c-1.31 0-2.33 1.15-2.33 2.61v11.61c0 1.46 1.02 2.61 2.33 2.61h13.41c1.13 0 2.05-.86 2.28-2.04l8.47 8.47 1.41-1.41-26.87-26.87zm15.04 18.86c0 .55-.45 1-1 1h-12.08c-.55 0-1-.45-1-1v-10.84c0-.55.45-1 1-1h2.24l2.74 2.74-.05.27c0 .73.59 1.31 1.31 1.31l.27-.05 6.58 6.58v.99z"/>
						{:else}
							<circle cx="14.81" cy="20.6" r="1.31"/><circle cx="18.76" cy="20.6" r="1.31"/><path d="M38.12 16.7c-.22-.14-.5-.15-.73-.03l-5.76 2.88h-4.03v-1.35c0-1.46-1.02-2.61-2.33-2.61h-13.41c-1.31 0-2.33 1.15-2.33 2.61v11.61c0 1.46 1.02 2.61 2.33 2.61h13.41c1.31 0 2.33-1.15 2.33-2.61v-1.37h4.03l5.76 2.88c.11.05.22.08.33.08.14 0 .27-.04.39-.11.22-.14.36-.38.36-.64v-13.31c.01-.26-.13-.5-.35-.64zm-12.52 12.72c0 .55-.45 1-1 1h-12.08c-.55 0-1-.45-1-1v-10.84c0-.55.45-1 1-1h12.08c.55 0 1 .45 1 1v10.84zm4.96-2.98h-2.44v-4.9h2.44v4.9zm1.94.19l-.02-5.27 4-2 .02 9.29-4-2.02z"/>
						{/if}
					</svg>
				</Button>
				<Button grow={false} red={true} on:click={endCall}>
					<svg viewBox="0 0 48 48">
						<title>End call</title>
						<path d="M37.08 21.84c-5.95-5.95-20.24-5.92-26.15 0-.78.78-1.39 1.81-1.46 3.26v3.33c0 .61.25 1.15.64 1.55.45.45 1.08.7 1.77.63l7.3-.73.04-3.83c3.03-1.37 6.81-1.24 9.56 0l.04 3.83 7.3.73c1.29.13 2.41-.88 2.41-2.18v-3.33c-.07-1.45-.68-2.49-1.45-3.26zm-6.29 6.23l-.02-2.04c-.01-.52-.21-1.02-.58-1.39-.17-.17-.37-.31-.59-.41-3.41-1.54-7.81-1.54-11.2 0-.7.32-1.16 1.02-1.17 1.79l-.02 2.04-5.67.56-.08-.22v-3.26c.05-.77.32-1.35.87-1.91 2.41-2.41 6.78-3.86 11.67-3.86s9.25 1.44 11.67 3.86c.56.56.83 1.14.87 1.9v3.5l-5.75-.56z"/>
					</svg>
				</Button>
				<Button grow={false} ghost={true} red={muted} on:click={() => { muted = !muted }}>
					<svg viewBox="0 0 48 48">
						<title>Mute</title>
						{#if muted}
							<path d="M19.59 13.96c0-2.43 1.98-4.4 4.41-4.4 2.19 0 4 1.61 4.33 3.71h-4.33v2h4.41v1.61h-4.41v2h4.41v1.61h-4.4l2 2h2.4c0 .69-.19 1.34-.48 1.92l1.47 1.47c.63-.99 1-2.17 1-3.43v-8.48c0-3.53-2.87-6.4-6.41-6.4-3.53 0-6.4 2.87-6.4 6.4v.1l2 2v-2.11zM34.01 23.87v-3.22h-2v3.22c0 1.32-.37 2.55-1.01 3.6l1.45 1.45c.98-1.44 1.56-3.18 1.56-5.05zM10.92 10.22l-1.41 1.41 8.09 8.08v2.72c0 3.53 2.87 6.4 6.4 6.4.81 0 1.57-.16 2.29-.43l1.75 1.75c-.92.44-1.94.7-3.02.7h-2.05c-3.85 0-6.99-3.14-6.99-6.99v-3.22h-2v3.22c0 4.95 4.03 8.99 8.99 8.99h.03v5.59h-2.6v2h7.21v-2h-2.61v-5.59h.03c1.64 0 3.17-.45 4.49-1.22l6.85 6.85 1.41-1.41-26.86-26.85zm13.08 16.62c-2.44 0-4.41-1.98-4.41-4.4v-.73l5.06 5.06c-.21.04-.42.07-.65.07z"/>
						{:else}
							<path d="M24 28.84c3.54 0 6.41-2.87 6.41-6.4v-8.48c0-3.53-2.87-6.4-6.41-6.4-3.53 0-6.4 2.87-6.4 6.4v8.48c-.01 3.53 2.87 6.4 6.4 6.4zm-4.41-14.88c0-2.43 1.98-4.4 4.41-4.4 2.19 0 4 1.61 4.33 3.71h-4.33v2h4.41v1.61h-4.41v2h4.41v1.61h-4.41v2h4.4c-.02 2.41-1.98 4.37-4.4 4.37-2.43 0-4.4-1.98-4.4-4.4v-8.5zM32.01 20.65v3.22c0 3.85-3.14 6.99-6.99 6.99h-2.05c-3.85 0-6.99-3.14-6.99-6.99v-3.22h-2v3.22c0 4.95 4.03 8.99 8.99 8.99h.03v5.59h-2.6v2h7.21v-2h-2.61v-5.59h.03c4.95 0 8.99-4.03 8.99-8.99v-3.22h-2.01z"/>
						{/if}
					</svg>
				</Button>
			</div>
		</div>
	</Overlay>
{/if}