<script>

	import { getContext } from 'svelte';
	import fadeScale from 'svelte-transitions-fade-scale';

	import Button from "../../ui/Button.svelte";

	const layout = getContext('layout');
	const participantCount = getContext('participantCount');
	const participants = getContext('participants');

	export let sw = 0;

	function switchLayout(l) {
		$layout = l;
		const timeoutDelay = (l == 'inset') ? 200 : 0;
		setTimeout(() => {
			cancelSpotlight();
		}, timeoutDelay);
	}

	function cancelSpotlight() {
		for (const p of $participants) {
			if (p.s) {
				p.s = false;
				$participants = $participants;
				break;
			}
		}
	}

</script>

<style>
	.outer {
		flex: 0 0 auto;
		width: 100%;
	}
	.panel {
		position: relative;
		background: transparent;
		box-shadow: 0 2px 10px -2px var(--shadow);
		width: 100%;
		box-sizing: border-box;
		padding: 0.6rem 0.8rem;
		display: flex;
		gap: 0.8rem;
		justify-content: center;
		align-items: center;
	}
	.panel:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--panelColor);
/*		background: var(--dark, #101820);*/
/*		opacity: 0.4;*/
		border-radius: 4px;
	}
	.panel :global(.wrap) {
		position: relative;
		aspect-ratio: 16/9;
/*		width: calc(var(--sw) / 6);*/
		max-width: 6rem;
	}
	.panel :global(button.subtle.icon) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0;
	}
	.panel :global(button.subtle.icon:before) {
		content: '';
		position: absolute;
		inset: 0;
/*		border: 2px solid var(--blend-60);*/
		border-radius: 4px;
/*		background: var(--panelColor);*/
/*		background: var(--dark, #101820);*/
		background: var(--blend-60);
	}
	.panel :global(button.subtle.icon.current:before) {
		background: var(--panelColor);
		border: 2px solid var(--accentColor);
	}
	.panel :global(button.subtle.icon:not(.current):not([disabled]):hover:before),
	.panel :global(button.subtle.icon:not(.current):not([disabled]):active:before),
	.panel :global(button.subtle.icon:not(.current):not([disabled]):focus:before) {
/*		transform: scale(1.1);*/
		background: var(--blend-80);
	}
	.panel svg {
		position: absolute;
		top: 0.2rem;
		left: 0.2rem;
		width: calc(100% - 0.4rem);
		height: calc(100% - 0.4rem);
		margin: unset;
	}
	.st0 {
		fill: var(--panelColor);
		opacity: 0.9;
	}
	.st1 {
		fill: var(--panelColor);
		opacity: 0.7;
	}
	.st2 {
		fill: var(--panelColor);
		opacity: 0.6;
	}
	:global(button.current) .st0 {
		fill: var(--accentColor);
	}
	:global(button.current) .st1 {
		fill: var(--accentColor);
	}
	:global(button.current) .st2 {
		fill: var(--accentColor);
	}
	.outer :global(button[disabled]) {
		opacity: 0.4;
	}
</style>

<div class="outer" in:fadeScale|local={{ duration: 200, baseScale: 0.9 }} style="--sw:{sw}px">
	<div class="panel">
		<Button
			tooltip="Grid" 
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'gap' ? 'current' : '' }
			on:click={() => { switchLayout('gap') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<path class="st0" d="M42 12.94h34v19.12h-34zM4 12.94h34v19.12h-34z"/>
			</svg>
		</Button>
		<Button
			tooltip="Connected" 
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'grid' ? 'current' : '' }
			on:click={() => { switchLayout('grid') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<rect class="st1" x="40" y="12.38" width="36" height="20.25"/>
				<rect class="st0" x="4" y="12.38" width="36" height="20.25"/>
			</svg>
		</Button>
		<Button
			tooltip="Stretch" 
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'stretch' ? 'current' : '' }
			on:click={() => { switchLayout('stretch') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<path class="st0" d="M42 4.08h34v36.83h-34zM4 4.08h34v36.83h-34z"/>
			</svg>
		</Button>
		<Button
			tooltip="Side left" 
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'side' ? 'current' : '' }
			disabled={$participantCount > 6}
			on:click={() => { switchLayout('side') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<path class="st0" d="M26 8.44h50v28.12h-50zM4 17.44h18v10.12h-18z"/>
			</svg>
		</Button>
		<Button
			tooltip="Side right" 
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'side2' ? 'current' : '' }
			disabled={$participantCount > 6}
			on:click={() => { switchLayout('side2') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<path class="st0" d="M54 36.56h-50v-28.12h50zM76 27.56h-18v-10.12h18z"/>
			</svg>
		</Button>
		<Button
			tooltip="Cinema" 
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'cinema' ? 'current' : '' }
			disabled={$participantCount > 6}
			on:click={() => { switchLayout('cinema') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<path class="st0" d="M61.33 28h-42.67v-24h42.67zM47.11 40h-14.22v-8h14.22z"/>
			</svg>
		</Button>
		<Button
			tooltip="Inset"
			subtle={true}
			tooltipPosition="bottom"
			customClass={ $layout == 'inset' ? 'current' : '' }
			disabled={$participantCount > 6}
			on:click={() => { switchLayout('inset') }}
		>
			<svg viewBox="0 0 80 45" preserveAspectRatio="none">
				<path class="st2" d="M4 4h72v37h-72z"/>
				<path class="st0" d="M8 25.75h20v11.25h-20z"/>
			</svg>
		</Button>
	</div>
</div>