<script>

	import Brand from './ui/Brand.svelte';
	import Field from './ui/Field.svelte';
	import Button from './ui/Button.svelte';

	import { busy, bigError, studioSessionToken } from './lib/stores.js';
	import { postServerData } from './lib/prelude.js';

	let email;
	let fieldErr;

	let dataOK = false;
	let sent = false;

	async function send() {

		$busy = true;

		const requestData = await postServerData('virtual/linkrequest', {
			email: email,
			studioSessionToken: $studioSessionToken
		});

		if (!$bigError) {
			$busy = false;
			sent = true;
		}

	}

	$: dataOK = (email && !fieldErr);

</script>

<style>
	.login {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 11002;
	}

	.login > div {
		max-width: 80vw;
		max-height: 80dvh;
		overflow: auto;
		width: 600px;
		box-sizing: border-box;
		padding: 2rem;
		background: var(--panelColor);
		color: var(--textColor);
		box-shadow: 0 0 20px -8px rgba(0,0,0,0.2);
		position: relative;
	}

	h2 {
		font-size: 1.2rem;
		margin: 2rem 0 0 0;
	}

	p {
		font-size: 0.8rem;
		margin-top: 1rem;
	}

	:global(.brand) + p {
		margin-top: 2rem;
	}

	:global(.field) + p {
		margin-top: 1.5rem;
	}

	.gap {
		margin-bottom: 1rem;
	}

	.actions {
		display: flex;
	}

</style>

<div class="login">
	<div>
		<Brand/>
		{#if sent}
			<h2>Thank you</h2>
			<p class="gap">We have emailed you with your attendee access link.</p>
		{:else}
			<p><strong>Please enter your attendee email address:</strong></p>
			<form on:submit|preventDefault={send}>
				<Field
					bind:value={email}
					component="EmailField"
					bind:err={fieldErr}
				/>
				<p class="actions">
					<Button wide={false} grow={false} type="submit" label="Send" disabled={!dataOK} />
				</p>
			</form>
		{/if}
	</div>
</div>
