<script>

	import { onMount, onDestroy } from 'svelte';
	// import fadeScale from 'svelte-transitions-fade-scale';

	import Portal from "svelte-portal";

	import List from "./Reports/List.svelte";
	import Report from "./Reports/Report.svelte";

	import Overlay from "../ui/Overlay.svelte";

	import { syncChannels, syncClient, selectedReport } from '../lib/stores.js';
	import {	autoblur } from '../lib/autoblur.js';
	import { randomIntFromInterval } from '../lib/utils.js';

	let reports = [];
	let channel;
	let spot = [];
	let openOverlay = false;
	let report = null;
	let notesChannels = {};
	let datestamps = {};

	// let shake = false;

	onMount(async () => {

		try {

			// console.log("opening attendeeNotifications channel", $syncChannels.attendee);
			channel = await $syncClient.list($syncChannels.attendee);

			channel.on('itemAdded', itemAdded);
			channel.on('itemUpdated', itemUpdated);
			channel.on('itemRemoved', itemRemoved);

			getItems();
			
		} catch (e) {

			console.log("Sync error", e, $syncChannels.attendee);
			
		}

	});

	onDestroy(() => {
		if (channel) channel.close();
		Object.keys(notesChannels).forEach(c => {
			notesChannels[c].close();
		});
	});

	async function itemAdded(i) {
		let item = i.item ? i.item : i;
		if (item.data.type == 'report') {
			// console.log('report', item.data);
			datestamps[item.data.report.ref] = item.data.dt;
			reports.push({
				...item.data,
				index: item.index
			});
			reports = reports;
			await openNotesChannel(item.index, item.data.report.ref, item.data.report.channel);
			if (!item.data.read || (datestamps[item.data.report.ref] > item.data.read)) {
				spot.push(item.index);
				spot = spot;
			}
		}
	}

	function itemUpdated(i) {
		let item = i.item ? i.item : i;
		if (item.data.type == 'report') {
			if (item.data.read > datestamps[item.data.report.ref]) {
				spot = spot.filter(r => {
					if (r != item.index) return r;
				});
			}
		}
	}

	function itemRemoved(item) {
		if (item.previousItemData.type == 'report') {
			reports = reports.filter(r => {
				if (r.index != item.index) return r;
			});
			spot = spot.filter(r => {
				if (r != item.index) return r;
			});
		}
	}

	function pageHandler(paginator) {
		paginator.items.forEach(function(item) {
			itemAdded(item);
		});
		// if (paginator.hasNextPage) {
		// 	await paginator.nextPage();
		// 	await pageHandler(paginator);
		// }
		return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
	};

	async function getItems() {
		let paginator;
		try {
			paginator = await channel.getItems({
				from: 0,
				order: 'asc',
				pageSize: 100
			});
		} catch (error) {
			if (error.message.match('Maximum attempt')) {
				// back off and try again later...
				const smear = 1000 * randomIntFromInterval(5,10);
				setTimeout(async () => {
					await getItems();
				}, smear);
			} else {
				console.error('getItems failed', error);
			}
		}

		if (paginator) await pageHandler(paginator);

	}

	async function openNotesChannel(idx,r,c) {
		try {

			// console.log('openNotesChannel', idx, r, c);
			notesChannels[c] = await $syncClient.list(c);

			notesChannels[c].on('itemAdded', function(i) {
				// console.log('notes added', i.item.data, i.item.data.dt, datestamps[r]);
				if (i.item.data.dt && (i.item.data.dt > datestamps[r]) && (!report || (r != report.report.ref))) {
					datestamps[r] = i.item.data.dt;
					if (!spot.includes(idx)) spot.push(idx);
					spot = spot;				
				}
			});

			await notesChannels[c].getItems({
				order: 'desc',
				pageSize: 100
			}).then(p => {
				for (const i of p.items) {
					// console.log(c, i.data.dt, datestamps[r]);
					if (i.data && i.data.dt && (i.data.dt > datestamps[r]) && (!report || (r != report.report.ref))) {
						datestamps[r] = i.data.dt;
						break;
					}
				}
			});
			
		} catch (e) {

			console.log("Sync error", e, c);
			
		}
	}

	function setReportFromStore() {
		for (const r of reports) {
			if (r.report.ref == $selectedReport) {
				report = r;
				openOverlay = true;
				$selectedReport = null;
			}
		}
	}

	$: if ($selectedReport) {
		setReportFromStore();
	}

	// function doShake() {
	// 	setTimeout(() => {
	// 		shake = true;
	// 		setTimeout(() => {
	// 			shake = false;
	// 		}, 400);
	// 	}, 400);
	// }

	// $: console.log('spot', spot);

</script>

<style>
	.nav-reports {
		cursor: pointer;
	}
	.nav-reports,
	.nav-reports button {
		display: inline-block;
		/*width: 28px;
		height: 28px;*/
		width: 1.6rem;
		height: 1.6rem;
		line-height: 1;
	}

	.nav-reports button {
		padding: 0;
		border: 0;
		background: transparent;
		position: relative;
		color: var(--textColor);
	}

	.nav-reports button:hover,
	.nav-reports button:active,
	.nav-reports button:focus {
		color: var(--accentColor);
	}

	.nav-reports svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	/*.nav-reports:hover .bell,
	.nav-reports:focus .bell,
	.nav-reports.shake .bell {
		animation: bellshake .4s cubic-bezier(.36,.07,.19,.97) both;
		backface-visibility: hidden;
		transform-origin: top center;
	}

	@keyframes bellshake {
	  0% { transform: rotate(0); }
	  15% { transform: rotate(5deg); }
	  30% { transform: rotate(-5deg); }
	  45% { transform: rotate(4deg); }
	  60% { transform: rotate(-4deg); }
	  75% { transform: rotate(2deg); }
	  85% { transform: rotate(-2deg); }
	  92% { transform: rotate(1deg); }
	  100% { transform: rotate(0); }
	}

	.nav-reports:hover .clapper,
	.nav-reports:focus .clapper,
	.nav-reports.shake .clapper {
		animation: clappershake .5s cubic-bezier(.36,.07,.19,.97) both;
		backface-visibility: hidden;
		transform-origin: top center;
	}

	@keyframes clappershake {
	  0% { transform: rotate(0); }
	  15% { transform: rotate(12deg); }
	  30% { transform: rotate(-12deg); }
	  45% { transform: rotate(10deg); }
	  60% { transform: rotate(-10deg); }
	  75% { transform: rotate(6deg); }
	  85% { transform: rotate(-6deg); }
	  92% { transform: rotate(3deg); }
	  100% { transform: rotate(0); }
	}*/

	/*.nav-reports .alert-counter {
		position: absolute;
		top: -6px;
		right: -6px;
		width: 16px;
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.625rem;;
		border: 0;
		text-decoration: none;
		line-height: 1;
		border: 1px solid var(--panelColor, #fff);
		border-radius: 50%;
		background: var(--red, #d73531);
		color: var(--redText, #fff);
	}

	.nav-reports:hover .alert-counter,
	.nav-reports:focus .alert-counter {
		background: var(--accentColor, #e6007e);
	}*/

	.nav-reports button:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0.4rem;
		height: 0.4rem;
		background: var(--red);
		border: 2px solid var(--panelColor);
		border-radius: 100%;
		opacity: 0;
	}

	.nav-reports button.spot:after {
		opacity: 1;
		transition: opacity 0.2s ease;
		transition-delay: 0.4s;
	}

	.inner {
		position: absolute;
		inset: 0;
		overflow: hidden;
	}

	.inner > div {
		display: flex;
		/* setting explicit width (and compensating with overflow) needed for firefox */
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		top: 0;
		overflow: visible;
		/*transition: left 0.25s ease-out;*/
	}

	.inner > div.report {
		left: -100%;
	}

	.inner > div > div {
		flex: 0 0 100%;
		height: 90vh;
		height: 90dvh;
		position: relative;
	}

	.inner :global(.overlay-main) {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

</style>

<!-- <li class="nav-reports" class:shake>
	<button class:spot={spot.length} on:click={() => { openOverlay = true }} use:autoblur>
		<svg class="bell" viewBox="0 0 80 80"><title>Notifications</title><path d="M52.9 58.6h6c2.5 0 4.8-1.4 5.9-3.5 1.1-2.1.9-4.5-.5-6.4-1.2-1.7-4-3.8-4.5-4.3v-11.9c0-8-4.8-15.1-12-18-.6-3.6-3.8-6.3-7.5-6.3h-.5c-3.8 0-6.9 2.7-7.5 6.3-7.2 3-12 10-12 18v11.8c-.5.5-3.2 2.6-4.5 4.3-1.4 1.9-1.6 4.3-.5 6.4 1.1 2.2 3.4 3.5 5.9 3.5l31.7.1z"/></svg>
		<svg class="clapper" viewBox="0 0 80 80"><path d="M44.1 62.6h-8.5c-.8 0-1.3.8-1.1 1.5.8 2.2 2.9 3.8 5.3 3.8s4.6-1.6 5.3-3.8c.3-.8-.3-1.5-1-1.5z"/></svg>
	</button>
</li> -->

<li class="nav-reports">
	<button class:spot={spot.length} on:click={() => { openOverlay = true }} use:autoblur>
		<svg viewBox="0 0 80 80"><path d="M18.86 68.13l-4.69-53.58c-.05-.55.36-1.04.91-1.08l1.99-.17c.55-.05 1.04.36 1.08.91l4.69 53.93h-3.98zM31.46 16.51c2.97 0 6.14.57 9.11 1.18 3.26.67 7.08 1.36 10.68 1.36.78 0 1.54-.03 2.27-.1 1.03-.09 2.12-.21 3.22-.42.58-.11 1.12.31 1.18.89l1.76 20.28c.04.48-.26.91-.73 1.05-.82.24-1.93.44-3.49.57-.5.04-1.03.07-1.58.07-2.88 0-6.2-.6-9.07-1.2-3.39-.7-7.03-1.35-10.73-1.35-.84 0-1.67.03-2.46.1-1.37.12-2.54.34-3.56.63l-1.91-22.06c.89-.46 1.92-.8 3.53-.94.57-.04 1.17-.06 1.78-.06m0-4c-.71 0-1.41.03-2.11.09-3.95.34-5.63 1.72-7.39 2.8l2.63 30.34c1.76-1.08 3.44-2.46 7.39-2.8.7-.06 1.4-.09 2.11-.09 3.47 0 6.98.66 9.92 1.26 3.63.75 6.89 1.28 9.87 1.28.66 0 1.3-.03 1.93-.08 3.57-.31 5.9-.95 7.75-2.59.23-.21.35-.52.32-.83l-2.43-28c-.05-.55-.5-.91-.99-.91-.19 0-.38.05-.55.16-1.69 1.08-3.78 1.56-6.73 1.81-.63.05-1.27.08-1.93.08-2.98 0-6.25-.53-9.87-1.28-2.93-.58-6.45-1.24-9.92-1.24z"/></svg>
	</button>
</li>

{#if openOverlay}
	<Portal target="#breakout">
		<Overlay
			on:escape={() => { openOverlay = false; }}
			modal={true}
			narrow={!report}
			wide={report}
			tall={true}
		>
			<div class="inner">
				<div class:report={report}>
					<div>
						<List {reports} {spot} bind:report/>
					</div>
					<div>
						{#if report}
							<Report {channel} bind:report/>
						{/if}
					</div>
				</div>
			</div>
		</Overlay>
	</Portal>
{/if}